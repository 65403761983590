import { ContractModesEnum, ContractTermEnum, CurrencyConverterService, CurrencyExchangeRequestDto, CurrencyTypesEnum, PerformaCoalCostingService, PerFormaReqIdDto } from "@exportx/shared-models-and-services";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../../common";
import moment from "moment";
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { Card, Col, Descriptions, Divider, Flex, Popover, Row, Typography } from "antd";
import { Table } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import QualityPriceAdjustmentsDeatils from "./quality-price-adjustments-deatils";

interface PerformaApprovalDetailsProps {
  costingId: string;
  contractType: ContractModesEnum;
  remainingAmount?: number,
  children?: React.ReactNode;
}


export const PerformaApprovalDetails = (props: PerformaApprovalDetailsProps) => {
  const { costingId, contractType, children, remainingAmount } = props;
  const { authContext } = useAuthState();
  const performaCostingsService = new PerformaCoalCostingService();
  const [data, setData] = useState<any>();
  const [advTotal, setAdvTotal] = useState<number>(0);
  const [bargeDetails, setBargeDetails] = useState<any>([])
  const { Text } = Typography;

  useEffect(() => {
    getAllPerFormaData()
  }, [costingId])


  const getAllPerFormaData = async () => {
    const req = new PerFormaReqIdDto(costingId, contractType, authContext.defaultPlant);
    await performaCostingsService.getAllPerFormaData(req).then(async res => {
      if (res.status) {

        setData({
          ...res.data,
        });

        const bargeDetails = res.data?.transactionBarges?.map((item) => ({
          ...item,
          incoterm: res.data?.incoterm,
          advAdjustment: res.data.advanceAdjustment,
          totalPayable: res.data?.totalAmount,
          coalPricePmt: res.data?.basePrice,
          contractId: res.data?.contractId,
          contractNo: res?.data?.contractNo,
          layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
          priceAfterExchg: res.data?.priceAfterExchgRate,
          remaining: + res.data?.totalAmount

        }))
        setBargeDetails(bargeDetails)
        setAdvTotal(advTotal)
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => console.log(err.message))
  };

  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: bargeDetails.length }
    else return { rowSpan: 0 }
  };

  const columns = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      width: 500,
      render: (value, record) => {
        if (record.bargeId) {
          const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a>
            <Typography className="small-text"> {record.bgName}</Typography>
          </div>

        }
        return <div>
          <Text className="small-text"> {value}</Text>
          <Typography className="small-text"> {record.bgName}</Typography>
        </div>

      }
    },
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      key: 'businessNo',
      render: (value, record) => {
        if (record.bnId) {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <div>
            <a href={link} className="link" target="_blank">{record.businessNo}</a>
            <Typography className="small-text"> {record.mvName}</Typography>
          </div>

        }

      }
    },
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      key: 'contractNo',
      render: (value, record) => {
        if (record.contractId) {
          let link = `/#/po-detail-view?pc_id=${record.contractId}`
          if (contractType === ContractModesEnum.SALES || contractType === ContractModesEnum.MARKETING_FEES) {
            link = `/#/so-detail-view?sc_id=${record.contractId}`;
          }
          return <a href={link} className="link" target="_blank">
            {record.contractNo}
          </a>

        }
      }
    },
    {
      title: 'Laycan (Quality)',
      dataIndex: 'layCan',
      key: 'layCan',
      width: 400,
      onCell: sharedOnCell,
      render: (value, record) => <Typography className="small-text"> {value}</Typography>
    },
    // {
    //     title: 'Inco Term',
    //     dataIndex: 'incoterm',
    //     key: 'incoterm',
    //     onCell: sharedOnCell,
    // },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      width: 150,
      onCell: sharedOnCell,
      render: (value, record) => <Text className="small-text">{internationalFormattedValue(value, 3)}</Text>
    },
    {
      title: `${contractType === ContractModesEnum.FREIGHT_COSTING ? 'Freight' : 'Price'}  PMT`,
      dataIndex: 'coalPricePerMt',
      key: 'coalPricePerMt',
      width: 200,
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.coalPricePerMt, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    {
      title: `${contractType === ContractModesEnum.FREIGHT_COSTING ? 'Freight' : ''}  Price`,
      dataIndex: 'priceAfterExchg',
      key: 'priceAfterExchg',
      width: 300,
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.priceAfterExchg, 3)}</Text>

        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>
      </>
    },
    {
      title: 'Total',
      dataIndex: 'totalPayable',
      key: 'totalPayable',
      width: 400,
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.totalPayable, 3)}</Text>

        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>
      </>

    },
    {
      title: 'Advance',
      dataIndex: 'advAdjustment',
      key: 'advAdjustment',
      width: 400,
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.advAdjustment, 3)}</Text>

        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>
      </>

    },
    {
      title: 'Balance',
      dataIndex: 'remaining',
      key: 'remaining',
      width: 400,
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency}</span>
      </>


    },
  ];

  return (
    <>
      <Row gutter={8}>
        <Col span={16}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 10px 15px' } }}
            style={{ marginBottom: 8 }}

            title={<h6>Barge Details</h6>}
          >
            <Table
              className='contracts-list'
              dataSource={bargeDetails}
              columns={columns}
              size="small"
              pagination={false}
              scroll={{ x: 1200 }}
            />

          </Card>

          {(data?.purchasingTerms === ContractTermEnum.SPEC && data?.noPriceAdjustment === 'false') &&

            <QualityPriceAdjustmentsDeatils
              contractType={contractType}
              selectedRowData={bargeDetails[0]}
              formInitialValues={data}

            />
          }

          {children}

        </Col >

        <Col span={8}>
          {!!data?.includeFreight &&

            <Card
              className="card-shadow"
              title={<span className="card-title-md">Freight Price Details</span>}
              styles={{ body: { padding: '6px 14px' } }}
              style={{ marginBottom: 8 }}

            >

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Freight Pmt</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.freightInfo?.freightPricePmt, 3)}  {data?.baseCurrency}
                </Text>
              </Flex>

              {bargeDetails.length && (<Flex justify="space-between" align='center'>
                <Text className="price-card-text">Currency Type</Text>
                <Text className="price-card-text"> {bargeDetails[0]?.currencyType ?? 'N/A'}</Text>
              </Flex>)}
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Date</Text>
                <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Rate
                  {bargeDetails.length && bargeDetails[0]?.currencyType === "Middle Rate" && (
                    <span style={{ paddingLeft: 5 }}>
                      <Popover
                        content={
                          <>
                            <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{bargeDetails[0]?.purchaseRate}</b></Typography>
                            <Typography style={{ fontSize: 10 }}>Selling Rate <b>{bargeDetails[0]?.sellingRate}</b></Typography>
                          </>
                        }
                        title="Rates"
                        placement="bottom"
                      >
                        <InfoCircleOutlined style={{ color: "#0295D4" }} />
                      </Popover>
                    </span>
                  )}
                </Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Freight Price After Exchange Rate</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.freightInfo?.freightAfterExchangeRate, 3)}  {data?.baseCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-heading">Total Freight</Text>
                <Text className="price-card-heading">
                  {internationalFormattedValue(data?.freightInfo?.freightBeforeTax, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>
              <Divider dashed={true} />

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Tax 1</Text>
                <Text className="price-card-text">{`${data?.freightInfo?.freightTaxName1 || ''} (${data?.freightInfo?.freightTaxPerc1 || ''})`}</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.freightInfo?.freightTaxTotal1, 3)}  {data?.freightInfo?.billingCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Tax 2</Text>
                <Text className="price-card-text">{`${data?.freightInfo?.freightTaxName2 || ''} (${data?.freightInfo?.freightTaxPerc2 || ''})`}</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.freightInfo?.freightTaxTotal2, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">TDS</Text>
                <Text className="price-card-text">{`${data?.freightInfo?.freightTdsName || ''} (${data?.freightInfo?.freightTdsPerc || ''})`}</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.freightInfo?.freightTdsTotal, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>

              <Divider dashed={true} />

              <Flex justify="space-between" align='center'>
                <Text className="price-card-green">Total Freight</Text>
                <Text className="price-card-green">
                  {internationalFormattedValue(data?.freightInfo?.freightCostingTotal, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>

            </Card>
          }
          <Card
            className="card-shadow"
            title={<span className="card-title-md">Price Details</span>}
            styles={{ body: { padding: '6px 14px' } }}
          >
            {data?.baseCurrency !== data?.billingCurrency && (<Flex justify="space-between" align='center'>
              <Text className="price-card-text">Currency Type</Text>
              <Text className="price-card-text"> {data?.currencyType ?? 'N/A'}</Text>
            </Flex>)}
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Date</Text>
              <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Actual Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.actualExchangeRate, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate Adjustment</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.exchangeRateAdjustment, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate
                {data?.currencyType === CurrencyTypesEnum.MIDDLE_RATE && (
                  <span style={{ paddingLeft: 5 }}>
                    <Popover
                      content={
                        <>
                          <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{bargeDetails[0]?.purchaseRate}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Selling Rate <b>{bargeDetails[0]?.sellingRate}</b></Typography>
                        </>
                      }
                      title="Rates"
                      placement="bottom"
                    >
                      <InfoCircleOutlined style={{ color: "#0295D4" }} />
                    </Popover>
                  </span>
                )}
              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.displayExchangeRate, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Price After Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.priceAfterExchgRate, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Total Coal Price</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.totalCoalPrice, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(data?.amountBeforeTax, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 1</Text>
              <Text className="price-card-text">{`${data?.taxName1 || ''} (${data?.taxPercentage1 || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.taxTotal1, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 2</Text>
              <Text className="price-card-text">{`${data?.taxName2 || ''} (${data?.taxPercentage2 || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.taxTotal2, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text className="price-card-text">{`${data?.tdsName || ''} (${data?.tdsPercentage || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(data?.amountAfterTax, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            {data?.advanceTarnsactionsData?.map((adv) => {
              let link = `/#/advanceDetailView?id=${adv.adjustedCostingId}`;
              if (contractType === ContractModesEnum.SALES || contractType === ContractModesEnum.MARKETING_FEES) {
                link = `/#/advance-receivables-detailed-view?id=${adv.adjustedCostingId}`
              }
              return (
                <Flex justify="space-between" align='center' key={adv.id}>
                  <Text className="price-card-text">Deduction (<a href={link} className="link" target='_blank'>{adv.adjustedCostingNo}</a>)</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}  {data?.billingCurrency}
                  </Text>
                </Flex>
              )
            })}

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Total Advance Adjustment</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.advanceAdjustment, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">To be paid to Supplier</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(data?.totalAmount, 3)}  {data?.billingCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text"><b>Previous Costing Value</b></Text>
              <Text className="price-card-text">
                <b>{internationalFormattedValue(data?.previousCostingValue, 3)}  {data?.quoteCurrency}</b>
              </Text>
            </Flex>


            {Number(remainingAmount) > 0 &&

              <Flex justify="space-between" align='center'>
                <Text className="price-card-red">Remaining Amount</Text>
                <Text className="price-card-red">
                  {internationalFormattedValue(remainingAmount, 3)}  {data?.billingCurrency}
                </Text>
              </Flex>
            }
          </Card>


        </Col>
      </Row>

    </>
  )
}
