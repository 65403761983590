import {
  DeleteOutlined,
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
} from '@ant-design/icons';
import {
  BargeCostingsService,
  BgDemurrageIdReqDto,
  BpCategoryGetDto,
  BPDropDownDto,
  BusinessNoDropDownDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  BusinessPartnerService,
  BusinessPartnerTypeEnum,
  ContractStatusEnum,
  ContractStatusReqDto,
  GetDataDemurrageDto,
  PaymentTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  FormInstance,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../common';
import AddInvoiceDrawer from '../add-invoice-drawer';
import Invoice from '../invoice/invoice';
import { getLocalFormat, internationalFormattedValue } from '@exportx/ui-utils';
import { SequenceUtils } from '@exportx/ui-utils';
import { useNavigate } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import DemurrageApproval from './demurrage-approval';
import debounce from 'lodash/debounce';
import moment from 'moment';
interface IProps {
  gridData?: GetDataDemurrageDto[];
  getDemurrageData?: () => void;
  setInitialValues?: (value: any) => void;
  demurrageApproval?: boolean;
  formRef?: FormInstance<any>;
  accessId?: any;
}

export const DemurrageList = (props: IProps) => {
  const { authContext } = useAuthState();
  const { setInitialValues, demurrageApproval, formRef, accessId } = props;
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [searchedText, setSearchedText] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();
  const [businessDropDown, setBusinessDropDown] =
    useState<BusinessNoDropDownDto[]>();
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [businessPartners, setbusinessPartners] = useState<BPDropDownDto[]>();
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState(null);
  const [activeTab, setActiveTab] = useState<any>('costing-creation');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  const [dateRangeType, setDateRangeType] = useState<string | null>(null);
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: null,
    to: null,
  });

  const bargeCostingService = new BargeCostingsService();
  const businessService = new BusinessNumberService();
  const businessPartnerService = new BusinessPartnerService();

  const navigate = useNavigate();

  useEffect(() => {
    getBusinessNoDropDown();
    getAllBusinessPartnersVendorsDropDown();
  }, []);

  useEffect(() => {
    if (!isPermissionExist([727, 730, 732])) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    getDemurrageData();
  }, [
    searchedText,
    selectedBusinessNo,
    selectedBusinessPartner,
    dateRangeValues,
    pagination.pageSize,
    pagination.current,
  ]);

  const getDemurrageData = () => {
    const req = new ContractStatusReqDto(
      undefined,
      authContext.defaultPlant,
      undefined,
      undefined,
      pagination.pageSize,
      (pagination.current - 1) * pagination.pageSize,
      searchedText,
      selectedBusinessNo,
      selectedBusinessPartner,
      dateRangeValues.from?.format('YYYY-MM-DD'),
      dateRangeValues.to?.clone().add(1, 'day').format('YYYY-MM-DD'),
    );
    bargeCostingService
      .getDemurrageData(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
          setPagination((prev) => ({
            ...prev,
            total: res.totalCount || 0,
          }));
        } else {
          setGridData([]);
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        setGridData([]);
        console.log(err.message);
      });
  };

  const deleteDemurrage = (bgDemurrageId) => {
    const req = new BgDemurrageIdReqDto(
      bgDemurrageId,
      authContext.defaultPlant,
    );
    bargeCostingService
      .deleteDemurrage(req)
      .then((res) => {
        if (res.status) {
          getDemurrageData();
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessService
      .getBusinessNoDropDown(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllBusinessPartnersVendorsDropDown = () => {
    const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR);
    businessPartnerService
      .getAllBusinessPartnersForDropDown(req)
      .then((res) => {
        if (res.status) {
          setbusinessPartners(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleBusinessNoChange = (value: string) => {
    setSelectedBusinessNo(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleBusinessPartnerChange = (value: string) => {
    setSelectedBusinessPartner(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const debouncedSearch = useCallback((searchValue: string) => {
    setSearchedText(searchValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
  }, []);

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((searchValue: string) => {
        debouncedSearch(searchValue);
      }, 500),
    [debouncedSearch],
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSearchHandler(value);
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 25,
    }));
  };

  const dateRangeDropDown = [
      { value: 'this_month', label: 'This Month' },
      { value: 'previous_month', label: 'Previous Month' },
      { value: 'this_quarter', label: 'This Quarter' },
      { value: 'previous_quarter', label: 'Previous Quarter' },
      { value: 'this_year', label: 'This Year' },
      { value: 'previous_year', label: 'Previous Year' },
      { value: 'custom', label: 'Custom Date Range' },
    ];
  
    const handleDateRangeTypeChange = (value: string | null) => {
      setDateRangeType(value);
  
      if (value === 'custom') {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      if (!value) {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      const now = moment();
      let fromDate: moment.Moment;
      let toDate: moment.Moment;
  
      switch (value) {
        case 'this_month':
          fromDate = now.clone().startOf('month');
          toDate = now.clone().endOf('month');
          break;
        case 'previous_month':
          fromDate = now.clone().subtract(1, 'month').startOf('month');
          toDate = now.clone().subtract(1, 'month').endOf('month');
          break;
        case 'this_quarter':
          fromDate = now.clone().startOf('quarter');
          toDate = now.clone().endOf('quarter');
          break;
        case 'previous_quarter':
          fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
          toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
          break;
        case 'this_year':
          fromDate = now.clone().startOf('year');
          toDate = now.clone().endOf('year');
          break;
        case 'previous_year':
          fromDate = now.clone().subtract(1, 'year').startOf('year');
          toDate = now.clone().subtract(1, 'year').endOf('year');
          break;
        default:
          return;
      }
  
      setDateRangeValues({ from: fromDate, to: toDate });
    };
  
    const handleDateRangeValuesChange = (
      dates: [any | null, any | null] | null,
    ) => {
      if (!dates || !dates[0] || !dates[1]) {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      const [from, to] = dates;
      setDateRangeValues({ from, to });
    };

  const tableColumns: TableColumnsType<any> = [
    // {
    //     title: 'DemurrageAction',
    //     width: '100%',
    //     align: 'center',
    //     render: (text, rowData) => (
    //         <span>
    //             {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([accessId?.demurrageEdit]) && (

    //             <Tooltip placement="topRight" title="Edit">
    //                 <EditOutlined type="edit"
    //                     onClick={() =>  navigate(`/demurrage?costingId=${rowData.bgDemurrageId}`)}
    //                     style={{ color: '#1890ff', fontSize: '20px' }}
    //                 /></Tooltip>)}

    //             <Divider type="vertical" />
    //             {isPermissionExist([accessId?.demurrageDelete]) && (
    //             <Popconfirm
    //                 onConfirm={e => {
    //                     deleteDemurrage(rowData.bgDemurrageId)
    //                 }}
    //                 title={'Are you sure to Delete Record ?'}>
    //                 <Tooltip placement="topRight" title="Delete">
    //                     <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
    //                 </Tooltip>
    //             </Popconfirm>)}
    //             <Divider type="vertical" />
    //             <Tooltip placement="topRight" title="Add Invoice">
    //                {isPermissionExist([accessId?.view]) && (
    //                 <PlusSquareTwoTone onClick={() => {
    //                     setDrawerOpen(true);
    //                     setCostingId(rowData.bgDemurrageId)
    //                     setCostingId((prev) => { return { ...prev, costingId: rowData.bgDemurrageId, totalAmount: rowData.totalSumAmount } });

    //                 }}
    //                     style={{ color: '#1890ff', fontSize: '20px' }}
    //                 />)}
    //                 <Divider type='vertical'></Divider>
    //                 <PDFDownloadLink
    //                     document={<Invoice invoice={''} demurrageName={''} costingName={ReferenceFeatures.MV_DEMURRAGE} />}
    //                     fileName={`${rowData?.costingNo}.pdf`}
    //                 >
    //                     <Tooltip placement="topRight" title="Invoice">
    //                         <FilePdfTwoTone style={{ color: '#1890ff', fontSize: '20px' }}
    //                         />
    //                     </Tooltip>
    //                 </PDFDownloadLink>
    //             </Tooltip>

    //         </span>
    //     )

    // },
    {
      title: 'Ref',
      dataIndex: 'costingNo',
      // width: '40%',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
            <a
              className="link"
              href={`/#/demurrage-detail-view?costing_id=${record.bgDemurrageId}`}
            >
              {record.costingNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'businessNumber',
      // width: '40%',
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`;
        return (
          <>
            <a href={href} className="link">
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Business Partner',
      dataIndex: 'bpName',
      // width: '40%',
    },
    {
      title: 'Cost Type',
      dataIndex: 'allowedTimeToLoad', //timeCompletedForLoadMV
      render: (value, record) => {
        let costType: string = '';
        if (Number(value) > Number(record.timeCompletedForLoadMV)) {
          costType = 'Dispatch';
        } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
          costType = 'Demurrage';
        } else {
          costType = ' ';
        }
        return <>{costType}</>;
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'account',
      // width: '40%',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      // width: '40%',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.baseCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, _) => {
        return (
          <>
            <StatusTag status={value} />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card className="card-radius">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            ...(isPermissionExist([727])
              ? [
                  {
                    key: 'costing-creation',
                    label: 'Costing Creation',
                    children: (
                      <>
                        <Row
                          justify="space-between"
                          className="mb-12 mt-8"
                          gutter={[0, 8]}
                        >
                          <Col xs={24} md={20}>
                            <Flex gap={10} wrap="wrap">
                              <Input.Search
                                className="input-search"
                                style={{ width: 225 }}
                                placeholder="Search"
                                allowClear
                                value={searchInput}
                                onChange={(e) => handleSearch(e.target.value)}
                              />
                              <Select
                                style={{ width: 175 }}
                                placeholder="Business"
                                value={selectedBusinessNo}
                                onChange={handleBusinessNoChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                showSearch
                                allowClear
                              >
                                {businessDropDown?.map((item, index) => (
                                  <Select.Option
                                    value={item.bnId}
                                    key={item.bnId + '-' + index}
                                  >
                                    {item.businessName}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Select
                                style={{ width: 175 }}
                                placeholder="business Partner"
                                value={selectedBusinessPartner}
                                onChange={handleBusinessPartnerChange}
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                                showSearch
                                allowClear
                              >
                                {businessPartners?.map((item, index) => (
                                  <Select.Option
                                    value={item.bpId}
                                    key={item.bpId + '-' + index}
                                  >
                                    {item.bpName}
                                  </Select.Option>
                                ))}
                              </Select>
                              <Select
                                placeholder="Date Range"
                                style={{ width: 175 }}
                                allowClear
                                value={dateRangeType}
                                onChange={handleDateRangeTypeChange}
                              >
                                {dateRangeDropDown.map((item, index) => (
                                  <Select.Option
                                    value={item.value}
                                    key={item.value + '-' + index}
                                  >
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                              {dateRangeType === 'custom' && (
                                <DatePicker.RangePicker
                                  style={{ maxWidth: 175 }}
                                  value={[
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  ]}
                                  onChange={handleDateRangeValuesChange}
                                />
                              )}
                            </Flex>
                          </Col>
                          <Col>
                            {isPermissionExist([726]) && (
                              <Button
                                className="new-button"
                                onClick={() => navigate('/create-demurrage')}
                              >
                                New Demurrage
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Table
                          className="contracts-list"
                          scroll={{ x: 500 }}
                          style={{
                            display: `${demurrageApproval ? 'none' : ''}`,
                          }}
                          columns={tableColumns}
                          dataSource={gridData}
                          pagination={{
                            ...pagination,
                            showSizeChanger: true,
                            pageSizeOptions: ['25', '50', '100'],
                            showTotal: (total) => (
                              <span className="page-total-items">
                                Total {total} items
                              </span>
                            ),
                            style: {
                              paddingTop: '12px',
                            },
                          }}
                          onChange={handlePageChange}
                          rowClassName={(record, index) =>
                            record?.approvalStatus ===
                            ContractStatusEnum.CANCELLED
                              ? 'bg-warning'
                              : ''
                          }
                        />
                      </>
                    ),
                  },
                ]
              : []),
            ...(isPermissionExist([730])
              ? [
                  {
                    key: 'business-approval',
                    label: 'Business Approval',
                    children: (
                      <DemurrageApproval
                        type={ContractStatusEnum.BUSINESS_APPROVAL}
                        paymentType={PaymentTypeEnum.PAYABLE}
                      />
                    ),
                  },
                  {
                    key: 'Additional-info',
                    label: 'Additional Information',
                    children: (
                      <DemurrageApproval
                        type={ContractStatusEnum.FREEZED}
                        paymentType={PaymentTypeEnum.PAYABLE}
                      />
                    ),
                  },
                ]
              : []),
            ...(isPermissionExist([732])
              ? [
                  {
                    key: 'management-review',
                    label: 'Management Review',
                    children: (
                      <DemurrageApproval
                        type={ContractStatusEnum.MANAGEMENT_APPROVAL}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Card>
      {/* <AddInvoiceDrawer
        referenceFeatures={ReferenceFeatures.MV_DEMURRAGE}
        key={dummyRefresh}
        setDummyRefresh={setDummyRefresh}
        openDrawer={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        costingId={costingId}
        accessId={accessId}
      /> */}
    </>
  );
};
export default DemurrageList;
