import React from 'react';
import { ClassValidator } from '@exportx/ui-utils';
import { Row, Col, Form, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useIntl } from 'react-intl';

interface IPropsContactDetails {
  field: FormListFieldData
}
const ContactDetails = (props: IPropsContactDetails) => {
  const { formatMessage: fm } = useIntl();
  const classValidator = new ClassValidator();
  const { field } = props;
  return (

    <>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
          <Form.Item
            {...field}
            label={fm({ id: "common.contactForm.landLineNo", defaultMessage: `LandLine Number`  })}
            name={[field.name, 'landLineNo']}
            rules={[
              {
                required: true, message: fm({ id: "common.contactForm.reqLandLineNo" })
              },
              ...classValidator.phoneNumberValidations(),
              {
                max: 20, message: fm({ id: "common.contactForm.reqMaxMobile" })
              },
              { min: 8, message: fm({ id: "common.contactForm.reqMinLndLineMobile" }) }]}
          >
            <Input placeholder={fm({ id: `common.contactForm.reqLandLineNo`, defaultMessage: `Please enter LandLine Number` })} autoComplete='off' />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
          <Form.Item
            {...field}
            label={fm({ id: "common.contactForm.mobileNo", defaultMessage: `Mobile Number` })}
            name={[field.name, 'mobileNo']}
            rules={[
              {
                required: true, message: fm({ id: "common.contactForm.reqMobileNo" })
              },
              ...classValidator.phoneNumberValidations(),
              {
                max: 15, message: fm({ id: "common.contactForm.reqMaxMobile" })
              },
              { min: 10, message: fm({ id: "common.contactForm.reqMinMobile" }) }
            ]}
          >
            <Input placeholder={fm({ id: `common.contactForm.reqMobileNo`, defaultMessage: `Please enter Mobile Number` })} autoComplete='off' />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
          <Form.Item label={fm({ id: "common.contactForm.email", defaultMessage: `Email` })}
            {...field}
            name={[field.name, 'email']}
            rules={[
              {
                required: true, message: fm({ id: "common.contactForm.reqEmail" })
              },
              {
                max: 80,
                message: fm({
                  id: "common.contactForm.reqMaxEmail"
                })
              },
              ...classValidator.emialPatternValidator(),
            ]}
          >
            <Input placeholder={fm({ id: `common.contactForm.reqEmail`, defaultMessage: `Please enter Email` })} autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default ContactDetails;