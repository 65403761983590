import {
  DeleteOutlined,
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
} from '@ant-design/icons';
import {
  BargeCostingsService,
  BgDeadFreightIdReqDto,
  ContractStatusEnum,
  ContractStatusReqDto,
  GetDataDeadFreightDto,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Table,
  TableColumnsType,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../common';
import AddInvoiceDrawer from '../add-invoice-drawer';
import Invoice from '../invoice/invoice';
import { getLocalFormat, internationalFormattedValue } from '@exportx/ui-utils';
import { SequenceUtils } from '@exportx/ui-utils';
import TabPane from 'antd/es/tabs/TabPane';
import DeadFreightApproval from './dead-freight-approval';
import { useNavigate } from 'react-router-dom';

interface IProps {
  gridData?: GetDataDeadFreightDto[];
  getDeadFreightData?: () => void;
  setInitialValues?: (value: any) => void;
  deadFreightApproval?: boolean;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
  accessId?: any;
}

export const DeadFreightList = (props: IProps) => {
  const bargeCostingService = new BargeCostingsService();
  const { authContext } = useAuthState();
  const {
    gridData,
    getDeadFreightData,
    setInitialValues,
    deadFreightApproval,
    setDummyRefresh,
    accessId,
  } = props;
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [dummyRefreshValue, setDummyRefreshValue] = useState<number>(1);
  const [searchedText, setSearchedText] = useState('');
  const [data, setData] = useState<GetDataDeadFreightDto[]>();

  const navigate = useNavigate();

  useEffect(() => {
    if(!isPermissionExist([707, 710, 712])) navigate('/')
  }, [])

  useEffect(()=> {
    deadFreightData();
  },[])

  const deadFreightData = () => {
      const req = new ContractStatusReqDto(
        undefined,
        authContext.defaultPlant,
      );
      bargeCostingService
        .getDeadFreightData(req)
        .then((res) => {
          if (res.status) {
            setData(res.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

  const deleteDeadFreight = (bgDeadFreightId) => {
    const req = new BgDeadFreightIdReqDto(
      bgDeadFreightId,
      authContext.defaultPlant,
    );
    bargeCostingService
      .deleteDeadFreight(req)
      .then((res) => {
        if (res.status) {
          getDeadFreightData();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const tableColumns: TableColumnsType<any> = [
    // {
    //     title: 'DeadFreightAction',
    //     width: 80,
    //     render: (text, rowData) => (
    //         <span>
    //       {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([accessId?.deadFreightEdit]) && (

    //             <Tooltip placement="topRight" title="Edit">
    //                 <EditOutlined type="edit"
    //                     onClick={() => { setInitialValues(rowData); setDummyRefresh(prev => prev + 1) }}
    //                     style={{ color: '#1890ff', fontSize: '20px' }}
    //                 /></Tooltip>)}

    //             <Divider type="vertical" />
    //             {isPermissionExist([accessId?.deadFreightdelete]) && (
    //             <Popconfirm
    //                 onConfirm={e => {
    //                     deleteDeadFreight(rowData.bgDeadFreightId)
    //                 }}
    //                 title={'Are you sure to Delete Record ?'}>
    //                 <Tooltip placement="topRight" title="Delete">
    //                     <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
    //                 </Tooltip>
    //             </Popconfirm>)}
    //             <Divider type="vertical" />
    //             {isPermissionExist([accessId?.view])}
    //             <Tooltip placement="topRight" title="Add Invoice">
    //                 <PlusSquareTwoTone onClick={() => {
    //                     setDrawerOpen(true);
    //                     setCostingId((prev) => { return { ...prev, costingId: rowData.bgDeadFreightId, totalAmount: rowData.totalAmount } });
    //                 }}
    //                     style={{
    //                         color: '#1890ff', fontSize: '20px'
    //                     }}
    //                 />
    //             </Tooltip>

    //             <Divider type='vertical'></Divider>
    //             <PDFDownloadLink
    //                 document={<Invoice invoice={''} demurrageName={''} costingName={ReferenceFeatures.MV_DEAD_FREIGHT} />}
    //                 fileName={`${rowData.costingNo}.pdf`}
    //             >
    //                 <Tooltip placement="topRight" title="Invoice">
    //                     <FilePdfTwoTone style={{ color: '#1890ff', fontSize: '20px' }}
    //                     />
    //                 </Tooltip>

    //             </PDFDownloadLink>
    //         </span >
    //     )

    // },
    {
      title: 'Dead Freight',
      dataIndex: 'costingNo',
      // width: 40,
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
            <a
              className="link"
              href={`/#/dead-freight-detail-view?costing_id=${record.bgDeadFreightId}`}
            >
              {record.costingNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'businessNo',
      // width: 40,
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.bnId}`;
        return (
          <>
            {
              <a href={href} className="link">
                {value + ' ' + `(${record.motherVesselName})`}
              </a>
            }
          </>
        );
      },
    },

    {
      title: 'Price PMT',
      dataIndex: 'pricePerMT',
      align: 'right',
      // width: 40,
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.baseCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'account',
    },
    {
      title: 'Business Partner',
      dataIndex: 'supplierName',
    },
    {
      title: 'Dead Freight Qty',
      dataIndex: 'deadFreight',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="quality-style">MT</span>
          </>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.baseCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      render: (value) => {
        return (
          <>
            <StatusTag status={value} />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Card className="card-radius">
        <Tabs>
          {isPermissionExist([707]) && (
            <TabPane tab="Costing Creation" key="costing-creation">
              <Row justify={'space-between'} className="mb-12 mt-8">
                <Col>
                  <Input.Search
                    placeholder="Search"
                    allowClear
                    onChange={(e) => {
                      setSearchedText(e.target.value);
                    }}
                    onSearch={(value) => {
                      setSearchedText(value);
                    }}
                    style={{
                      // width: 200,
                      // float: 'right',
                      display: `${deadFreightApproval ? 'none' : ''}`,
                    }}
                    className="input-search"
                  />
                </Col>
                <Col>
                  { isPermissionExist([706]) && <Button
                    className="new-button"
                    onClick={() => navigate('/create-dead-freight')}
                  >
                    New Dead Freight
                  </Button>}
                </Col>
              </Row>
              <Table
                className="contracts-list"
                // bordered={true}
                scroll={{ x: 500 }}
                style={{ display: `${deadFreightApproval ? 'none' : ''}` }}
                columns={tableColumns}
                dataSource={data}
                // pagination={false}
                pagination={{
                  showTotal: (total) => (
                    <span className="page-total-items">
                      Total {total} items
                    </span>
                  ),
                  showSizeChanger: true,
                  pageSizeOptions: ['25', '50', '100'],
                  defaultPageSize: 25,
                  style: {
                    paddingTop: '12px',
                  },
                }}
                rowClassName={(record, index) =>
                  record.approvalStatus === ContractStatusEnum.CANCELLED
                    ? 'bg-warning'
                    : ''
                }
              ></Table>
            </TabPane>
          )}
          {isPermissionExist([710]) && (
            <>
            <TabPane tab="Business Approval" key="business-approval">
              <DeadFreightApproval
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="dead-freight-approval"
              />
            </TabPane>
            <TabPane tab="Additonal Information" key="additional-Info">
              <DeadFreightApproval
                type={ContractStatusEnum.FREEZED}
                key="dead-freight-approval"
              />
            </TabPane>
            </>
          )}
          {isPermissionExist([712]) && (
            <TabPane tab="Management Review" key="management-review">
              <DeadFreightApproval
                type={ContractStatusEnum.MANAGEMENT_APPROVAL}
              />
            </TabPane>
          )}
        </Tabs>
        <AddInvoiceDrawer
          referenceFeatures={ReferenceFeatures.MV_DEAD_FREIGHT}
          key={dummyRefreshValue}
          setDummyRefresh={setDummyRefreshValue}
          openDrawer={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          costingId={costingId}
          accessId={accessId}
        />
      </Card>
    </>
  );
};
export default DeadFreightList;
