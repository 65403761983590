import { faArrowLeft, faDownload, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Flex,
  Layout,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CostingInvoice } from '../../costing-invoice';
import { EditFilled, MessageFilled } from '@ant-design/icons';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import { useEffect, useState } from 'react';
// import { PerformaProps } from "../../coal-costings";
import queryString from 'query-string';
import {
  BargeCostingIdReqDto,
  BargeCostingsService,
  ContractModesEnum,
  ContractStatusEnum,
  CostingInvoiceReqDto,
  CostingInvoiceService,
  CostingTypesEnum,
  ExpenseEntryIdReq,
  ExpenseEntryService,
  PerformaCoalCostingService,
  PriceTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { useLocation, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import CommentsPage from '../../../../common/comments/comments-page';

const { Text } = Typography;

interface ExpenseProps {
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
}

export const ExpenseEntryDetailView = (props: ExpenseProps) => {
  const { referenceFeatures, contractType } = props;
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  // const [costingIds, setCostingId] = useState<any>()
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const expenseEntryService = new ExpenseEntryService();
  const invoiceService = new CostingInvoiceService();
  const [data, setData] = useState<any>();
  const [bargeData, setBargeData] = useState<any>();
  const [advTotal, setAdvTotal] = useState<number>(0);
  const paramBargeId: any = queryString.parse(location.search)?.costing_id;
  let { costingId } =
    paramBargeId != null ? { costingId: paramBargeId } : { costingId: null };
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingIds, setCostingId] = useState<any>();
  const [invoice, setInvoice] = useState<any>();
  const [downloadPermission, setDownloadPermission] = useState<any>(1);
  const [editPermission, setEditPermission] = useState<any>(1);
  const [viewInvoice, setViewInvoice] = useState<any>(1);
  const [accessId, setAccessId] = useState<any>({});
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');
  const [costPriority, setCostPriority] = useState<string>('')
  const [updatedCostPriority, setUpdatedCostPriority] = useState(false);

  const performaCostingsService = new PerformaCoalCostingService();

  let path;

  if (contractType === ContractModesEnum.PURCHASE) {
    path = 'expense-entry';
  } else {
    path = 'other-income-expense-entry';
  }

  useEffect(() => {
    if (
      !isPermissionExist([
        417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667, 687,
      ])
    ) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    if (data?.expenseLedgerName === 'Other Income') {
      setDownloadPermission(914);
      setEditPermission(668);
      setViewInvoice(675);
      setAccessId({
        create: 674,
        view: 675,
        update: 676,
        delete: 677,
        approve: 678,
        reject: 679,
        release: 680,
        files: 681,
      });
    } else if (data?.expenseLedgerName === 'Other Expenses') {
      setDownloadPermission(908);
      setEditPermission(544);
      setViewInvoice(552);
      setAccessId({
        create: 551,
        view: 552,
        update: 553,
        delete: 554,
        approve: 555,
        reject: 556,
        release: 557,
        files: 558,
      });
    } else if (data?.expenseLedgerName === 'Return Cargo Charges') {
      setDownloadPermission(909);
      setEditPermission(565);
      setViewInvoice(573);
      setAccessId({
        create: 572,
        view: 573,
        update: 574,
        delete: 575,
        approve: 576,
        reject: 577,
        release: 578,
        files: 579,
      });
    } else if (data?.expenseLedgerName === 'Floating Crane Charges') {
      setDownloadPermission(910);
      setEditPermission(586);
      setViewInvoice(594);
      setAccessId({
        create: 593,
        view: 594,
        update: 595,
        delete: 596,
        approve: 597,
        reject: 598,
        release: 599,
        files: 600,
      });
    } else if (data?.expenseLedgerName === 'Stevedore Costing') {
      setDownloadPermission(911);
      setEditPermission(607);
      setViewInvoice(615);
      setAccessId({
        create: 614,
        view: 615,
        update: 616,
        delete: 617,
        approve: 618,
        reject: 619,
        release: 620,
        files: 621,
      });
    } else if (data?.expenseLedgerName === 'Documentation Charges') {
      setDownloadPermission(904);
      setEditPermission(460);
      setViewInvoice(468);
      setAccessId({
        create: 467,
        view: 468,
        update: 469,
        delete: 470,
        approve: 471,
        reject: 472,
        release: 473,
        files: 474,
      });
    } else if (data?.expenseLedgerName === 'Chemical Spray') {
      setDownloadPermission(902);
      setEditPermission(418);
      setViewInvoice(426);
      setAccessId({
        create: 425,
        view: 426,
        update: 427,
        delete: 428,
        approve: 429,
        reject: 430,
        release: 431,
        files: 432,
      });
    } else if (data?.expenseLedgerName === 'Laporan Charges') {
      setDownloadPermission(905);
      setEditPermission(481);
      setViewInvoice(489);
      setAccessId({
        create: 488,
        view: 489,
        update: 490,
        delete: 491,
        approve: 492,
        reject: 493,
        release: 494,
        files: 495,
      });
    } else if (data?.expenseLedgerName === 'Sampling & Analysis Charges') {
      setDownloadPermission(906);
      setEditPermission(502);
      setViewInvoice(510);
      setAccessId({
        create: 509,
        view: 510,
        update: 511,
        delete: 512,
        approve: 513,
        reject: 514,
        release: 415,
        files: 416,
      });
    } else if (data?.expenseLedgerName === 'Pre Shipment Inspection') {
      setDownloadPermission(907);
      setEditPermission(523);
      setViewInvoice(531);
      setAccessId({
        create: 530,
        view: 531,
        update: 532,
        delete: 533,
        approve: 534,
        reject: 535,
        release: 536,
        files: 437,
      });
    } else if (data?.expenseLedgerName === 'Insurance') {
      setDownloadPermission(915);
      setEditPermission(688);
      setViewInvoice(695);
      setAccessId({
        create: 694,
        view: 695,
        update: 696,
        delete: 697,
        approve: 698,
        reject: 699,
        release: 700,
        files: 701,
      });
    } else if (data?.expenseLedgerName === 'PNBP Charges') {
      setDownloadPermission(913);
      setEditPermission(648);
      setViewInvoice(655);
      setAccessId({
        create: 654,
        view: 655,
        update: 656,
        delete: 657,
        approve: 658,
        reject: 659,
        release: 660,
        files: 661,
      });
    } else if (data?.expenseLedgerName === 'Freight Costing') {
      setDownloadPermission(912);
      setEditPermission(628);
      setViewInvoice(635);
      setAccessId({
        create: 634,
        view: 635,
        update: 636,
        delete: 637,
        approve: 638,
        reject: 639,
        release: 640,
        files: 641,
      });
    } else if (data?.expenseLedgerName === 'Export Tax') {
      setDownloadPermission(903);
      setEditPermission(439);
      setViewInvoice(447);
      setAccessId({
        create: 446,
        view: 447,
        update: 448,
        delete: 449,
        approve: 450,
        reject: 451,
        release: 452,
        files: 453,
      });
    } else {
      setDownloadPermission(1);
      setEditPermission(1);
      setViewInvoice(1);
      setAccessId({
        create: 1,
        view: 1,
        update: 1,
        delete: 1,
        approve: 1,
        reject: 1,
        release: 1,
        files: 1,
      });
    }
  }, [data]);

  useEffect(() => {
    if (costingId) {
      updateExpensive(costingId);
    }
    getAllCostingInvoice();
  }, [costingId, updatedCostPriority]);

  const updateExpensive = (value) => {
    const req = new ExpenseEntryIdReq();
    req.expenseEntryId = value;
    req.plantCode = authContext.defaultPlant;
    expenseEntryService
      .updateExpensive(req)
      .then((res) => {
        if (res.status) {
          setData({
            ...res.data,
            currencyType: res.data?.expenseEntryTransactions[0]?.currencyType,
            jisdorDate: res.data?.expenseEntryTransactions[0]?.jisdorDate,
            currency: res.data?.expenseEntryTransactions[0]?.currency,
            quoteCurrency: authContext.defaultPlantCurrency,
          });

          setBargeData(res.data?.expenseEntryTransactions);
          const advTotal = Number(
            res.data.advanceTarnsactionsData
              .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
              .toFixed(2),
          );
          setAdvTotal(advTotal);
          setCostPriority(res.data.priority)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(
      costingId,
      authContext.defaultPlant,
      undefined,
      referenceFeatures,
    );
    console.log('reqqq', req);
    invoiceService
      .getAllCostingInvoice(req)
      .then((res) => {
        if (res.status) {
          setInvoice(res.data);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const editHandler = () => {
    navigate(`/create-expense-entry?costingId=${costingId}`);
  };

  const updateCostingPriority = () => {
    const priority = costPriority === 'NORMAL' ? 'HIGH' : 'NORMAL'
    const req = {id: costingId, plantCode: authContext.defaultPlant, priority, featuresRef: referenceFeatures}
    performaCostingsService.updateCostingPriority(req).then((res) => {
      if(res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setUpdatedCostPriority((prev) => !prev)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      console.log(err);
    });

  }

  const adjustedQty = bargeData?.reduce(
    (total, item) => total + Number(item.qty),
    0,
  );

  const footer = () => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          textAlign: 'right',
        }}
      >
        <div style={{ marginRight: '35px' }}>
          <div>
            <b>Adjust Quantity:</b>
          </div>
        </div>
        <div style={{ marginRight: '10px' }}>
          <div style={{ color: '#0283C8' }}>
            <b>{Number(adjustedQty)?.toFixed(3)}</b>
          </div>
        </div>
      </div>
    </div>
  );

  const tableColumns: any = [
    {
      title: 'Barge No',
      dataIndex: 'bargesId',
      render: (value, record) => {
        console.log('record =>', record);
        if (record.bargeId) {
          const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
          return (
            <div>
              <a href={link} className="link" target="_blank">
                {value}
              </a>
            </div>
          );
        }
        return <Typography className="small-text"> {value}</Typography>;
      },
    },
    {
      title: 'Business No',
      dataIndex: 'businessNumber',
      render: (text, record) => (
        <>
          <Text>{text}</Text>
        </>
      ),
    },
    {
      title: 'Jetty',
      dataIndex: 'loadingJetty',
      width: 100,
      render: (value, record) => <Text> {value}</Text>,
    },
    {
      title: 'Vendor',
      dataIndex: 'actLedName',
      render: (text, record) => (
        <>
          <Text>{text}</Text>
        </>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      render: (text, record) => (
        <>
          <Text>{text}</Text>
        </>
      ),
    },
    {
      title: 'Payable (lumpsum or PMT)',
      dataIndex: 'payable',
      render: (text, record) => (
        <>
          <Text>{text}</Text>
        </>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'expensivePrice',
      align: 'right',
      render: (text, record) => (
        <>
          <Text>{internationalFormattedValue(text, 2)}</Text>
          <span className="currency-style">{record?.currency}</span>
        </>
      ),
    },
  ];

  const columns2: any = [
    {
      title: 'Advance Type',
      dataIndex: 'advanceType',
    },
    {
      title: 'Advance Balance',
      dataIndex: 'totalAdjustedAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{Number(value).toFixed(3)}</span>;
      },
    },
    {
      title: 'Costing No',
      dataIndex: 'adjustedCostingNo',
    },
    {
      title: 'Adjusted Amount',
      dataIndex: 'adjustAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{Number(value).toFixed(3)}</span>;
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: ""
    // },
  ];

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  return (
    <>
      <Layout style={{ backgroundColor: '#F5F5F5', border: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'none',
            marginBottom: '10px',
          }}
        >
          <Space size={5}>
            <a
              href={`/#/${path}`}
              style={{ fontSize: '18px', color: '#6B7280' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}
            >
              {data?.expenseEntryNo}
            </span>
            &nbsp;
            <StatusTag status={data?.approvalStatus} />
            <Tooltip title={ costPriority === 'NORMAL' ? "Set as High Priority" : "Remove High Priority"}>
              <FontAwesomeIcon
                icon={faFlag}
                style={{ color: costPriority === 'NORMAL' ? "gray" : "red", cursor: "pointer" }}
                onClick={() => updateCostingPriority()}
              />
            </Tooltip>
          </Space>
          <Space size={10}>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() =>
                  commentsData(ReferenceFeatures.EXPENSE_ENTRY, 'All Comments')
                }
              />
            </Tooltip>
            {isPermissionExist([downloadPermission]) && (
              <PDFDownloadLink
                document={
                  <CostingInvoice
                    costingDetails={{ ...data, invoices: invoice }}
                    costingName={referenceFeatures}
                    plantCode={authContext.defaultPlant}
                  />
                }
                fileName={`${data?.expenseEntryNo}.pdf`}
              >
                <Tooltip placement="topRight" title="Costing Invoice">
                  <FontAwesomeIcon
                    style={{
                      border: 'none',
                      fontSize: '15px',
                      padding: '7px',
                      borderRadius: '50%',
                      color: '#0295D4',
                      backgroundColor: '#DEECF3',
                    }}
                    icon={faDownload}
                    onClick={() => {
                      setDummyRefresh((prev) => prev + 1);
                    }}
                  />
                </Tooltip>
              </PDFDownloadLink>
            )}

            {([
              ContractStatusEnum.CANCELLED,
              ContractStatusEnum.DRAFT,
              ContractStatusEnum.PENDING_APPROVAL,
            ].includes(data?.approvalStatus) ||
            invoice?.some((invoice) => invoice.status === ContractStatusEnum.DRAFT)) &&
              isPermissionExist([editPermission]) && (
                <EditFilled
                  style={{
                    border: 'none',
                    fontSize: '15px',
                    padding: '7px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3',
                  }}
                  onClick={editHandler}
                />
              )}
            {isPermissionExist([viewInvoice]) && (
              <Button
                type="primary"
                htmlType="submit"
                className="new-button"
                onClick={() => {
                  setDrawerOpen(true);
                  setCostingId((prev) => {
                    return {
                      ...prev,
                      costingId: data?.expenseEntryId,
                      totalAmount: data?.totalAmount,
                      costingNo: data?.expenseEntryNo,
                      accessId: data?.addInvoicePermission,
                    };
                  });
                }}
              >
                Invoices
              </Button>
            )}
          </Space>
        </div>

        <Row gutter={8}>
          <Col span={16} xs={24} md={24} lg={16}>
            <Col span={10} style={{ display: 'flex' }} xs={24} md={24} lg={24}>
              <Card
                title={'Contract Details'}
                headStyle={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#323F49',
                  border: 'none',
                  paddingTop: '10px',
                }}
                bordered={false}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                }}
              >
                <Row>
                  <Col span={16} xs={24} md={24} lg={24}>
                    <Descriptions
                      className="descriptions-label"
                      title=""
                      column={{
                        xxl: 5,
                        xl: 5,
                        lg: 5,
                        md: 3,
                        sm: 1,
                        xs: 1,
                      }}
                      layout="vertical"
                      colon={false}
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Descriptions.Item label={'Business Number'}>
                        <span className="descriptions">
                          <a
                            style={{ color: '#0083C9' }}
                            href={`/#/bn-detail-view?bn_Id=${data?.businessNumber}`}
                          >
                            {data?.businessNo ? data?.businessNo : '--'}
                          </a>
                        </span>
                      </Descriptions.Item>

                      <Descriptions.Item label="Vendor Ledger" span={1}>
                        <span className="descriptions">{data?.actLedName}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Expense Ledger">
                        <span className="descriptions">
                          {data?.expenseLedgerName}
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Expense Type">
                        <span className="descriptions">
                          {data?.expenseType}
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Transaction Date">
                        <span className="descriptions">
                          {`${moment(data?.date).format('DD-MMM-YYYY')}`}
                        </span>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col style={{ margin: '12px 0px ' }}>
              <Card
                className="card-shadow"
                styles={{ body: { padding: '0px 10px 15px' } }}
                style={{ marginBottom: 8 }}
                title={<h6>Barges</h6>}
              >
                <Table
                  className="contracts-list"
                  dataSource={bargeData}
                  columns={tableColumns}
                  pagination={false}
                  scroll={{ x: 1200 }}
                  footer={footer}
                />
              </Card>
            </Col>

            <Col style={{ margin: '12px 0px ' }}>
              <Card
                className="card-shadow"
                styles={{ body: { padding: '0px 10px 15px' } }}
                style={{ marginBottom: 8 }}
                title={<h6>Advance Adjustment</h6>}
              >
                <Table
                  className="contracts-list"
                  dataSource={data?.advanceTarnsactionsData}
                  columns={columns2}
                  pagination={false}
                  // scroll={{ x: 1200 }}costing
                />
              </Card>
            </Col>

            {/* {children} */}
          </Col>

          <Col span={8} xs={24} md={24} lg={8}>
            <Card
              className="card-shadow"
              title={<span className="card-title-md">Price Adjustment</span>}
              styles={{ body: { padding: '6px 14px' } }}
            >
              <Flex justify="space-between" align="center">
                <Text className="price-card-text">Currency Type</Text>
                <Text className="price-card-text">
                  {' '}
                  {data?.currencyType ?? 'N/A'}
                </Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text className="price-card-text">Exchange Date</Text>
                <Text className="price-card-text">
                  {' '}
                  {data?.exchangeDate
                    ? moment(data?.exchangeDate)?.format('DD-MM-YYYY')
                    : 'N/A'}
                </Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text className="price-card-text">Exchange Rate</Text>
                <Text className="price-card-text">
                  {data?.exchangeRateValue} {data?.quoteCurrency}
                </Text>
              </Flex>

              <Flex justify="space-between" align="center">
                <Text className="price-card-heading">Total Amount</Text>
                <Text className="price-card-heading">
                  {Number(data?.total).toFixed(3)} {data?.quoteCurrency}
                </Text>
              </Flex>
              <Divider dashed={true} />

              <Flex justify="space-between" align="center">
                <Text className="price-card-text">VAT</Text>
                <Text className="price-card-text">{`${
                  data?.vatApplicable ? 'Yes' : 'No'
                }`}</Text>
                <Text className="price-card-text">
                  {Number(data?.vat).toFixed(3)} {data?.quoteCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text className="price-card-text">TDS</Text>
                <Text className="price-card-text">{`${
                  data?.tdsName || 'N/A'
                } (${data?.tdsPercent || '0'})`}</Text>
                <Text className="price-card-text">
                  {Number(data?.tds).toFixed(3)} {data?.quoteCurrency}
                </Text>
              </Flex>

              <Divider dashed={true} />

              {data?.advanceTarnsactionsData?.map((adv) => (
                <Flex justify="space-between" align="center" key={adv.id}>
                  <Text className="price-card-text">{`Deduction (${adv.adjustedCostingNo}) `}</Text>
                  <Text className="price-card-text">
                    {Number(adv?.totalAdjustedAmount).toFixed(3)}{' '}
                    {data?.quoteCurrency}
                  </Text>
                </Flex>
              ))}

              <Flex justify="space-between" align="center">
                <Text className="price-card-text">
                  Total Advance Adjustment
                </Text>
                <Text className="price-card-text">
                  {Number(advTotal).toFixed(3)} {data?.quoteCurrency}
                </Text>
              </Flex>

              <Flex justify="space-between" align="center">
                <Text className="price-card-green">To be paid to Supplier</Text>
                <Text className="price-card-green">
                  {Number(data?.totalAmount - advTotal).toFixed(3)}{' '}
                  {data?.quoteCurrency}
                </Text>
              </Flex>
              {/* {Number(remainingAmount) > 0 &&

                  <Flex justify="space-between" align='center'>
                    <Text className="price-card-red">Remaining Amount</Text>
                    <Text className="price-card-red">
                      <CountUp
                        start={0}
                        end={Number(0.00)}
                        duration={2.75}
                        decimals={3}
                        suffix={` ${data?.quoteCurrency}`} />
                    </Text>
                  </Flex>
                } */}
            </Card>
          </Col>
        </Row>
      </Layout>
      <AddInvoiceDrawer
        openDrawer={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        costingId={costingIds}
        setDummyRefresh={setDummyRefresh}
        referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY}
        accessId={accessId}
        vendorId={data?.bpId}
      />
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
  );
};
