import {
  AdvanceTypeReqDto,
  CalculationFilterDataReq,
  CalculationTableDto,
  CommercialCoalCostingService,
  CommercialGetAllDto,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTermEnum,
  CostingTypesEnum,
  PerFormaReqIdDto,
  PerformaAdvanceDataDto,
  ProcurementContractTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import {
  Affix,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
} from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { CoalCostingFilterComponent, QualityPriceAdjustments } from '../common';
import CmAdvanceAdjustments from './cm-pc-advance-adjustments';
import CommercialCostingsBargeSelection from '../common/cm-coal-costings-bg-selection';
import CommercialPriceAdjustment from './cm-pc-price-adjustment';
import moment from 'moment';

interface IPropsCommercialCoalCostings {
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
  pcrContractType?: ProcurementContractTypeEnum;
  costingType?: CostingTypesEnum;
}

export const CmCoalCostingMainForm = (props: IPropsCommercialCoalCostings) => {
  const [formRef] = Form.useForm();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<CommercialGetAllDto>();
  const { contractType, referenceFeatures, pcrContractType, costingType } =
    props;
  const [bargeData, setBargeData] = useState<CalculationTableDto[]>([]);
  const [bargeSelection, setBargeSelection] = useState<any>([]);
  const [bargeSelectionRowKeys, setBargeSelectionRowKeys] = useState<any[]>([]);
  const cmService = new CommercialCoalCostingService();
  const { authContext } = useAuthState();
  const [performaAdvance, setPerformaAdvance] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [adjustedData, setAdjustedData] = useState<any[]>([]);

  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [invoiceContracts, setInvoiceContracts] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [accessId, setAccessId] = useState({});
  const [createPermission, setCreatePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [refreshAdjustment, setRefreshAdjustment] = useState<boolean>(true);

  const [taxTotal, setTaxTotal] = useState<any>({
    taxTotal1: 0,
    taxTotal2: 0,
    tdsTotal: 0,
    fcCharges: 0,
    freightPricePmt: 0,
    totalCoalPrice: 0,
  });

  const location = useLocation();
  const costingId: any = queryString.parse(location.search)?.costingId;

  useEffect(() => {
    let permission;
    if (contractType === ContractModesEnum.PURCHASE) {
      permission = [292];
      setViewPermission(isPermissionExist([292]));
      setCreatePermission(isPermissionExist([291]));
      setAccessId({
        create: 300,
        view: 301,
        update: 302,
        delete: 303,
        approve: 304,
        reject: 305,
        release: 306,
        files: 307,
        purchaseEdit: 293,
      });
    } else {
      permission = [334];
      setViewPermission(isPermissionExist([334]));
      setCreatePermission(isPermissionExist([333]));
      setAccessId({
        create: 342,
        view: 343,
        update: 344,
        delete: 345,
        approve: 346,
        reject: 347,
        release: 348,
        files: 349,
        purchaseEdit: 335,
      });
    }
    if (!isPermissionExist(permission)) navigate('/');
  }, [contractType]);

  useEffect(() => {
    if (costingId) getAllCommercialData(costingId);
  }, [costingId]);

  const pcCommercialBargeData = (values) => {
    values.contractType = contractType;
    cmService
      .getBargesDataForCommercial(values)
      .then((res) => {
        if (res.status) {
          setBargeData(res.data);
          // setPerformaAdvance(res.perFormaData);
        } else {
          setBargeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setBargeData([]);
      });
  };

  const loadBargesOnClickHandler = (values) => {
    const req = new CalculationFilterDataReq(
      authContext.defaultPlant,
      values.businessNumber,
      values.contractId,
      values.qualityId,
      values.incoterm,
      values.purchaseType,
      contractType,
      values.pcrContractId,
    );
    pcCommercialBargeData(req);
  };

  const getAllCommercialData = (id: string) => {
    const req = new PerFormaReqIdDto(
      id,
      contractType,
      authContext.defaultPlant,
    );
    cmService
      .getAllCommercialCostingsData(req)
      .then((res) => {
        if (res.status) {
          setInitialValues(res.data);
          setRefreshAdjustment(false)
          const req: any = new CalculationFilterDataReq(
            authContext.defaultPlant,
            undefined,
            res.data.contractId,
            res.data.qualityId,
            res.data.incoterm,
            res.data.purchaseType,
            contractType,
            res.data.pcrContractId,
          );
          // purchase commercial
          if (contractType === ContractModesEnum.PURCHASE) {
            cmService
              .getBargesDataForCommercial(req)
              .then((response) => {
                if (res.status) {
                  const bargeId = [];
                  const bargesData = [];
                  for (const bg of res.data.transactionBarges) {
                    bargeId.push(bg.bgUId);
                    const bgD = response.data.filter(
                      (rec) => rec.bgUId === bg.bgUId,
                    )[0];
                    bargesData.push(bgD);
                  }
                  rowSelection.onChange(bargeId, bargesData, false);
                  setBargeData(response.data);
                  setTaxTotal((prev) => {
                    return {
                      ...prev,
                      ...res?.data,
                      totalPayable: res?.data?.totalAmount
                    };
                  });
                  setAdjustedData(res.data.advanceTarnsactionsData);
                  formRef.setFieldsValue({
                    ...res?.data,
                    qualityAdjustPrice: res.data.adjustedPriceValue,
                    totalAdjustedValue: res.data.advanceAdjustment,
                    totalBonusPrice: res.data.totalPenalty,
                  });
                  // setDummyRefresh(prev => prev + 1)

                } else {
                  setBargeData([]);
                }
              })
              .catch((err) => {
                console.log(err.message);
                setBargeData([]);
              });
          }
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setInitialValues(null);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setInitialValues(null);
      });
  };


  const rowSelection = {
    columnTitle: <></>,
    onChange: (selectedRowKeys: string[], selectedRows: any[], isUpdate?: boolean) => {
      console.log(isUpdate);

      setBargeSelection(selectedRows);
      setBargeSelectionRowKeys(selectedRowKeys);
      if (isUpdate === false) return;
      const totalQuantity = selectedRows.reduce((sum, row) => sum + Number(row.bargeQuantityInMt), 0);
      formRef.setFieldsValue({
        adjustedPriceValue: selectedRows[0]?.coalBasePrice,
        totalBargeQuantity: totalQuantity.toFixed(3),
        baseCurrency: selectedRows[0]?.baseCurrency,
        freightPricePmt: selectedRows[0]?.freightPricePmt,

      })
      setRefreshAdjustment(true);
      setDummyRefresh((prev) => prev + 1);
    },
    getCheckboxProps: (record) => {
      if (costingId) {
        record.isBargeMapped = false;
      }
      if (bargeSelection[0]?.qualityGroupId || record.isBargeMapped) {
        if (
          bargeSelection[0]?.qualityGroupId !== record?.qualityGroupId ||
          record.isBargeMapped
        ) {
          return { disabled: true };
        }
      }

      return {};
    },
    selectedRowKeys: bargeSelectionRowKeys,
  };

  const submitHandler = () => {
    formRef.validateFields().then((values) => {
      const barges = bargeSelection?.map(bg => ({
        bargeId: bg?.bgUId,
        coalPricePerMt: bg.coalBasePrice,
        baseCurrency: values.baseCurrency,
        quoteCurrency: authContext.defaultPlantCurrency,
        exchangeRate: values.exchangeRate,
        exchangeDate: values.exchangeDate ? moment(values.exchangeDate).format('YYYY-MM-DD') : null,
        total: taxTotal?.totalPayable,
        qualityGroupId: bg.qualityGroupId,
        plantCode: authContext.defaultPlant,
        userName: authContext.user.userName,
        cmBgTransactionId: initialValues ? bg.cmBgTransactionId : undefined,
        purchasingTerms: bg.purchasingTerms ? bg.purchasingTerms : '',
      }));
      const payload = {
        ...taxTotal,
        ...values,
        totalPenalty: values.totalBonusPrice,
        costingDate: values?.costingDate ? moment(values?.costingDate).format('YYYY-MM-DD') : null,
        exchangeDate: values?.exchangeDate ? moment(values.exchangeDate).format('YYYY-MM-DD') : null,
        costingType: costingType,
        transactionBarges: barges,
        plantCode: authContext.defaultPlant,
        userName: authContext.user.userName,
        quoteCurrency: authContext.defaultPlantCurrency,
        coalPricePMT: bargeSelection[0]?.coalBasePrice,
        contractType: contractType,
        incoterm: bargeSelection[0]?.incoterm,
        costingNo: initialValues?.costingNo,
        adjustmentTransactions: adjustedData,
        vendorId: bargeSelection[0]?.coalSupplierId,
      }
      cmService
        .saveCommercialCostings(payload)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              if (costingId) {
                window.location.href = `#/commercial-pc-detail-view?costing_id=${costingId}`;
              }
              else {
                window.location.href = `#/commercial-coal-costing`;
              }
            }, 1000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        });
    });
  };

  const qualityOnChange = (e: number) => {
    setDummyRefresh((prev) => prev + 1);
  };

  return (
    <>
      <h5>{`${costingType} Costings ${contractType === ContractModesEnum.PURCHASE
        ? '- Purchase Contract'
        : contractType === ContractModesEnum.SALES
          ? '- Sales Contract'
          : ''
        } `}</h5>
      <br />

      <Row>
        <Col span={24}>
          {createPermission && (
            <CoalCostingFilterComponent
              formRef={formRef}
              loadBargesOnClickHandler={loadBargesOnClickHandler}
              isBusinessNumberMandatory={
                contractType === ContractModesEnum.SALES
              }
              initialValues={initialValues}
              contractType={contractType}
              setBargeData={setBargeData}
              pcrContractType={pcrContractType}
            />
          )}
        </Col>
      </Row>
      <br></br>
      <Row gutter={20}>
        <Col span={14}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Barge Details</h6>}
          >
            <CommercialCostingsBargeSelection
              bargeGridData={bargeData}
              rowSelection={rowSelection}
            />
            <br />
            <Flex align='center' justify='flex-end'>
              <Form form={formRef} layout="horizontal">
                <Form.Item name={'totalBargeQuantity'} label={<b>Quantity</b>}>
                  <Input
                    type="number"
                    placeholder="Quantity"
                    onChange={(e) =>
                      qualityOnChange(Number(e.target.value))
                    }
                  ></Input>
                </Form.Item>
              </Form>
            </Flex>
          </Card>

          {(bargeSelection[0]?.purchasingTerms === ContractTermEnum.SPEC && bargeSelection[0]?.noPriceAdjustment === 'false') &&
            <Card
              className="card-shadow"
              styles={{ body: { padding: '0px 15px 15px 10px' } }}
              style={{ marginBottom: 8 }}
              title={<h6>Quality Adjustment</h6>}
            >
              <QualityPriceAdjustments
                formInitialValues={initialValues}
                contractType={contractType}
                selectedRowData={bargeSelection[0]}
                setDummyRefresh={setDummyRefresh}
                commercialProp={"Commercial"}
                formRef={formRef}
                costingId={costingId}
                refreshAdjustment={refreshAdjustment}
                setRefreshAdjustment={setRefreshAdjustment}
              />
            </Card>
          }
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Advance Adjustment</h6>}
          >
            <CmAdvanceAdjustments
              bargeSelectionRowKeys={bargeSelectionRowKeys}
              formRef={formRef}
              vendorId={bargeSelection[0]?.coalSupplierId}
              contractId={bargeSelection[0]?.contractId}
              totalInvoice={Number(taxTotal?.totalPayable)}
              performaAdvance={performaAdvance}
              setAdjustedData={setAdjustedData}
              adjustedData={adjustedData}
              setInvoiceContracts={setInvoiceContracts}
              invoiceContracts={invoiceContracts}
              setDummyRefresh={setDummyRefresh}
            />
          </Card>

        </Col>
        <Col span={10}>
          <Card
            // className="default-card-class"
            title={'Price Adjustment'}
          >
            <CommercialPriceAdjustment
              contractType={contractType}
              initialValues={initialValues}
              taxTotal={taxTotal}
              setTaxTotal={setTaxTotal}
              formRef={formRef}
              bargeSelection={bargeSelection[0]}
              dummyRefresh={dummyRefresh}
              setDummyRefresh={setDummyRefresh}
              adjustedData={adjustedData}
            />
          </Card>
        </Col>
      </Row>

      <Affix offsetBottom={20}>
        <Button className='new-button' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => submitHandler()} style={{ float: 'right' }}>Submit Invoice</Button>
      </Affix>

    </>
  );
};

export default CmCoalCostingMainForm;
