import { DownCircleOutlined, MessageFilled, RightCircleOutlined } from '@ant-design/icons';
import {
  BGCostingParentReqDto,
  ContractStatusEnum,
  CostingWorkLogsEnum,
  PaymentNoticeGetDataDto,
  PaymentNoticeService,
  PaymentTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { SequenceUtils, internationalFormattedValue } from '@exportx/ui-utils';
import { Card, Drawer, Select, Table, Tooltip, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuthState } from '../../../common';
import ReceivablesChild from './receivables-child';
import { AccountPayableCostings } from '../costing-detail-view';
import debounce from 'lodash/debounce';
import { CostingFilters } from '../costing-detail-view/costing-filters';
import CommentsPage from '../../../common/comments/comments-page';

interface IPaymentNoticePage {
  type: PaymentNoticePageEnum;
  paymentType?: PaymentNoticePageEnum;
}
const { Option } = Select;

export enum PaymentNoticePageEnum {
  APPROVAL = 'approval',
  CREATE = 'create',
  RELEASE = 'Release',
  RECEIVABLES = 'Receivables',
  CLOSED = 'Closed',
}

export const Receivables = (props: IPaymentNoticePage) => {
  const { paymentType } = props;
  const [expandedIndex, setExpandedIndex] = useState([]);
  const paymentNoticeService = new PaymentNoticeService();
  const { authContext } = useAuthState();
  const [paymentNoticeData, setPaymentNoticeData] = useState<
    PaymentNoticeGetDataDto[]
  >([]);
  const [searchedText, setSearchedText] = useState('');
  const [type, setActiveTab] = useState<PaymentNoticePageEnum>(
    PaymentNoticePageEnum.CREATE,
  );
  const [serachInput, setSerachInput] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [businessNo, setBusinessNo] = useState(null);
  const [costingType, setCostingType] = useState<CostingWorkLogsEnum>(null);
  const [bargeId, setBarge] = useState<string>(null);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [costingId, setCostingId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');

  useEffect(() => {
    if (
      searchedText ||
      type ||
      paymentType ||
      currentPage > 1 ||
      pageSize !== 25 ||
      businessNo ||
      bargeId ||
      costingType
    ) {
      getParentData();
    }
  }, [
    searchedText,
    type,
    paymentType,
    currentPage,
    pageSize,
    businessNo,
    bargeId,
    costingType,
  ]);

  let status = ContractStatusEnum.ACTIVE;
  if (type === PaymentNoticePageEnum.CREATE) {
    status = ContractStatusEnum.DRAFT;
  } else if (type === PaymentNoticePageEnum.APPROVAL) {
    status = ContractStatusEnum.PENDING_APPROVAL;
  } else if (type === PaymentNoticePageEnum.CLOSED) {
    status = ContractStatusEnum.CLOSED;
  }

  const getParentData = () => {
    const req = new BGCostingParentReqDto();
    req.plantCode = authContext.defaultPlant;
    req.offset = (currentPage - 1) * pageSize;
    req.limit = pageSize;
    req.searchTerm = searchedText;
    req.businessNo = businessNo;
    req.bargeId = bargeId;
    req.costingType = costingType;
   
    req.status = status;
    if (paymentType) {
      req.paymentType = paymentType;
    }
    paymentNoticeService
      .getPaymentNotice(req)
      .then((res) => {
        if (res.status) {
          setPaymentNoticeData(
            res.data?.paymentNotices ? res.data?.paymentNotices : [],
          );
          setTotal(res.data?.total);
          setExpandedIndex([]);
        } else {
          setPaymentNoticeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setPaymentNoticeData([]);
        setTotal(0);
      });
  };

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((searchValue: string) => {
        setSearchedText(searchValue);
        setCurrentPage(1);
      }, 500),
    [],
  );

  const routes = (key) => {
    let object = {
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: `performaCoalCosting`,
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: `performaCostingSale`,
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
      [CostingWorkLogsEnum.DEMURRAGE]: `demurrage`,
      [CostingWorkLogsEnum.DISPATCH]: `demurrage`,
      [CostingWorkLogsEnum.DEAD_FREIGHT]: `dead-freight`,
      [CostingWorkLogsEnum.ADVANCE_INVOICE]: `advanceDetailView`,
      [CostingWorkLogsEnum.EXPENSE_ENTRY]: 'expense-entry',
      [CostingWorkLogsEnum.ADVANCE_RECEIVABLES]:
        'advance-receivables-detailed-view',
    };

    let id = '?costingId=';

    if (key === ReferenceFeatures.ADVANCE_INVOICE) {
      id = '?id=';
    } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      id = '?id=';
    }

    return object[key] + id;
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  // Handle search input change
  const handleSearch = (value: string) => {
    setSerachInput(value);
    debouncedSearchHandler(value);
  };

  const handleBusinessNoChange = (value: string) => {
    setBusinessNo(value);
    setCurrentPage(1);
  };

  const handleCostingTypeChange = (value: CostingWorkLogsEnum) => {
    setCostingType(value);
    setCurrentPage(1);
  };

  const bargeChangeHandler = (value: string) => {
    setBarge(value);
    setCurrentPage(1);
  };

  const commentsData = (title) => {
    setCommentsVisible(true);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData('All Comments');
    setCostingId(record.costingId);
    setFeaturesRefName(record.expenseType === 'Demurrage' || record.expenseType === 'Dispatch' ? ReferenceFeatures.MV_DEMURRAGE : record.expenseType);
  }

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Expense Reference',
      dataIndex: 'costingNo',
      render: (value, record) => {
        let link: string;
        return (
          <>
            <a
              href={`/#/${routes(record.expenseType) + record.costingId}`}
              className="link"
            >
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (value, row: any, index) => {
        const bnValues = [
          ...new Map(
            row.paymentNoticeBusinessNos?.map((rec) => [rec.businessNo, rec]),
          ).values(),
        ];
        return (
          <>
            {new Set(
              row.paymentNoticeBusinessNos?.filter(
                (rec) => rec.businessNo !== null || rec.businessNo != undefined,
              ),
            ).size
              ? bnValues.map(
                  (
                    rec: {
                      businessNo: string;
                      businessNoName: string;
                      bnId: string;
                    },
                    index,
                  ) => {
                    const comma = index !== bnValues.length - 1 ? ' , ' : '';
                    const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`;
                    return (
                      <>
                        {
                          <a key={rec.bnId} href={link} className="link">
                            {rec.businessNo +
                              ` (${rec.businessNoName})` +
                              comma}
                          </a>
                        }
                      </>
                    );
                  },
                )
              : 'NA'}
          </>
        );
      },
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',
      render: (value, row: any, index) => {
        const bg = [];
        row.paymentNoticeBarges?.forEach((rec) => {
          if (rec.bargeNo)
            bg.push({
              bargeNo: rec.bargeNo,
              bargeId: rec.bargeId,
              bargeNomination: rec.bargeNomination,
            });
        });
        return (
          <>
            {new Set(
              row.paymentNoticeBarges?.filter(
                (rec) => rec.bargeNo !== null || rec.bargeNo !== undefined,
              ),
            ).size
              ? bg.map((rec, index) => {
                  const comma = index !== bg.length - 1 ? ' , ' : '';
                  const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
                  return (
                    <a href={`${link}`} className="link" key={rec?.bargeId}>
                      {SequenceUtils.formatNumberToSpecificLength(
                        rec?.bargeNo,
                      ) +
                        ` (${rec.bargeNomination})` +
                        comma}
                    </a>
                  );
                })
              : 'NA'}
          </>
        );
      },
    },

    {
      title: 'Total',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };
  const renderItems = (
    record: PaymentNoticeGetDataDto,
    index,
    indent,
    expanded,
  ) => {
    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
        <AccountPayableCostings
          costingId={record.costingId}
          costingType={record.expenseType}
          remainingAmount={0}
        >
          <ReceivablesChild
            paymentType={paymentType}
            record={record}
            type={type}
            getParentData={getParentData}
            status={status}
          />
        </AccountPayableCostings>
      </div>
    );
  };

  const tabListNoTitle = [
    {
      key: PaymentNoticePageEnum.CREATE,
      label: 'Creation',
    },
    {
        key: PaymentNoticePageEnum.APPROVAL,
        label: 'Approval',
    },
    {
      key: PaymentNoticePageEnum.RELEASE,
      label: 'Release',
    },
    {
      key: PaymentNoticePageEnum.CLOSED,
      label: 'Closed',
    },
  ];

  const onTab2Change = (key: PaymentNoticePageEnum) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  return (
    <Card className="card-radius">
      <Card
        className="no-shadow"
        tabList={tabListNoTitle}
        bordered={false}
        activeTabKey={type}
        size="small"
        onTabChange={onTab2Change}
        tabProps={{
          size: 'middle',
          tabBarStyle: { width: '100%' },
        }}
      >
        {/* <Card
          bordered={false}
          className='contracts-list card-radius no-shadow '
          size='small'
          title={ */}
        <CostingFilters
          handleSearch={handleSearch}
          serachInput={serachInput}
          handleBusinessNoChange={handleBusinessNoChange}
          businessNo={businessNo}
          handleCostingTypeChange={handleCostingTypeChange}
          costingType={costingType}
          bargeChangeHandler={bargeChangeHandler}
          bargeId={bargeId}
          paymentType={PaymentTypeEnum.RECEIVABLE}
        />
        {/* }
        > */}

        <Table
          size="small"
          className="contracts-list"
          rowKey={(record) => record.costingId}
          columns={tableColumns}
          dataSource={paymentNoticeData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
                Expand
              </RightCircleOutlined>
            )
          }
          scroll={{ x: 500 }}
          bordered={false}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            size: 'small',
          }}
          onChange={handleTableChange}
        />
      </Card>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
      {/* </Card> */}
    </Card>
  );
};

export default Receivables;
