import { ReferenceFeatures } from "@exportx/shared-models-and-services";
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
// import { Fragment } from "react"

interface BargeInvoiceProps {
  BargeInvoice: any;
  costingName: ReferenceFeatures;
}

export const BargeInvoice = (props: BargeInvoiceProps) => {
  const { BargeInvoice, costingName } = props;


  const styles = StyleSheet.create({
    container: {
      width: "100%",
      marginTop: 20,
    },
    header: {
      flexDirection: "row",
      backgroundColor: "#f2f7fd",
      paddingVertical: 6,
    },
    headerText: {
      flex: 1,
      fontSize: 8,
      fontWeight: "bold",
    },
    row: {
      flexDirection: "row",
      paddingVertical: 6,
      fontSize: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#ddd",
    },

    cell: {
      flex: 1,
      fontSize: 8,
      textAlign: "left",
    },
    bargeNominationCell: {
      width: 220,
      fontSize: 8,
      textAlign: "center",
    },
    rightAlignedCell: {
      flex: 1,
      fontSize: 8,
      paddingRight: 8,
      textAlign: "right",
    },
  });

  let barge =  BargeInvoice?.costingTransactions || BargeInvoice?.transactionBarges || BargeInvoice?.bargeCostings || BargeInvoice?.expenseEntryTransactions


  const rows = barge?.map((item => {
    if(costingName !== ReferenceFeatures.EXPENSE_ENTRY){
      return <View key={item?.bargeId} style={styles.row}>
      <Text style={[styles.cell,{ paddingLeft: 8 }]}>{SequenceUtils?.formatNumberToSpecificLength(item?.bargeNo?.toString())}</Text>
      <Text style={styles.bargeNominationCell}>{item?.bgName || item?.bargeNomination}</Text>
      <Text style={styles.cell}>{item?.loadingJetty}</Text>
      <Text style={styles.cell}>{BargeInvoice?.quality}</Text>
      <Text style={styles.cell}>{internationalFormattedValue(item?.inWardQty, 3)}</Text>
      <Text style={styles.rightAlignedCell}>{internationalFormattedValue(item?.coalPricePerMt || item?.total, 3) || internationalFormattedValue(item?.total, 3)}</Text>
    </View>
    } else {
      return <View key={item?.bargeId} style={styles.row}>
      <Text style={[styles.cell,{ paddingLeft: 8 }]}>{item?.bargesId}</Text>
      <Text style={styles.cell}>{item?.businessNumber}</Text>
      <Text style={styles.cell}>{item?.actLedName}</Text>
      <Text style={styles.cell}>{item?.payable}</Text>
      <Text style={styles.cell}>{internationalFormattedValue(item?.qty, 3)}</Text>
      <Text style={styles.rightAlignedCell}>{internationalFormattedValue(item?.total, 3)}</Text>
    </View>
    }
  }))

  return (
  <>
    
    <View style={styles.container}>
      {costingName !== ReferenceFeatures.EXPENSE_ENTRY ? (
        <View style={styles.header}>
        <Text style={[styles.headerText,{ paddingLeft: 8 }]}>Barge ID</Text>
        <Text style={styles.bargeNominationCell}>Barge Name</Text>
        <Text style={styles.headerText}>Loading Jetty</Text>
        <Text style={styles.headerText}>Quality</Text>
        <Text style={styles.headerText}>Quantity</Text>
        <Text style={styles.rightAlignedCell}>Price PMT</Text>
      </View>
      ) : (
        <View style={styles.header}>
        <Text style={[styles.headerText,{ paddingLeft: 8 }]}>Barge ID</Text>
        <Text style={styles.headerText}>Business No</Text>
        <Text style={styles.headerText}>Vendor</Text>
        <Text style={styles.headerText}>Payable (lumpsum / PMT)</Text>
        <Text style={styles.headerText}>Quantity</Text>
        <Text style={styles.rightAlignedCell}>Price PMT</Text>
      </View>
      )}
      {rows}

    </View>


  </>
  )
}

export default BargeInvoice;