import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Descriptions, Divider, Drawer, Flex, Layout, Row, Space, Tooltip, Typography } from "antd";
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from "../../../common";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CostingInvoice } from "../costing-invoice";
import { faArrowLeft, faDownload, faFlag } from "@fortawesome/free-solid-svg-icons";
import { EditFilled, MessageFilled } from "@ant-design/icons";
import { BargeCostingIdReqDto, BargeCostingsService, ContractStatusEnum, CostingInvoiceReqDto, CostingInvoiceService, PerformaCoalCostingService, ReferenceFeatures } from "@exportx/shared-models-and-services";
import { useEffect, useState } from "react";
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import CountUp from "react-countup";
import AddInvoiceDrawer from "../add-invoice-drawer";
import CommentsPage from "../../../common/comments/comments-page";

const { Text } = Typography;

interface DeadFrightProps {
  referenceFeatures: ReferenceFeatures
}

export const DeadFrieghtDetailView = (props: DeadFrightProps) => {
  const { referenceFeatures } = props
  const { authContext } = useAuthState();
  const location = useLocation();
  const navigate = useNavigate()
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const bargeCostingService = new BargeCostingsService();
  const invoiceService = new CostingInvoiceService();
  const [invoice, setInvoice] = useState<any>();
  const [data, setData] = useState<any>();
  const paramBargeId: any = queryString.parse(location.search)?.costing_id
  let { costingId } = paramBargeId != null ? { costingId: paramBargeId } : { costingId: null };
  const [dummyRefreshValue, setDummyRefreshValue] = useState<number>(1);
  const [drawerOpen, setDrawerOpen] = useState<boolean>()
  const [costingIds, setCostingId] = useState<any>()
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');
  const [updatedCostPriority, setUpdatedCostPriority] = useState(false);

  const performaCostingsService = new PerformaCoalCostingService();

  useEffect(() => {
    if (!isPermissionExist([707])) {
      return navigate('/')
    }
  }, [])

  useEffect(() => {

    const req = new BargeCostingIdReqDto(costingId, authContext.defaultPlant)
    bargeCostingService.getDeadFreightDataById(req).then(res => {
      if (res.status) {
        setData(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })

    getAllCostingInvoice()

  }, [costingId, updatedCostPriority])

  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(costingId, authContext.defaultPlant, undefined, referenceFeatures)
    invoiceService.getAllCostingInvoice(req).then(res => {
      if (res.status) {
        setInvoice(res.data);
      }
    }).catch(err => console.log(err.message));
  };

  const editHandler = () => {
    navigate(`/create-dead-freight?costingId=${costingId}`);
  }

  const updateCostingPriority = () => {
    const priority = data?.priority === 'NORMAL' ? 'HIGH' : 'NORMAL'
    const req = {id: costingId, plantCode: authContext.defaultPlant, priority, featuresRef: referenceFeatures}
    performaCostingsService.updateCostingPriority(req).then((res) => {
      if(res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setUpdatedCostPriority((prev) => !prev)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      console.log(err);
    });

  }

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  return (
    <>
      <Layout style={{ backgroundColor: '#F5F5F5', border: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'none',
            marginBottom: '10px',
          }}
        >
          <Space size={5}>
            <a
              href={`/#/dead-freight`}
              style={{ fontSize: '18px', color: '#6B7280' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}
            >
              {data?.costingNo}
            </span>
            &nbsp;
            <StatusTag status={data?.approvalStatus} />
            <Tooltip title={ data?.priority === 'NORMAL' ? "Set as High Priority" : "Remove High Priority"}>
              <FontAwesomeIcon
                icon={faFlag}
                style={{ color: data?.priority === 'NORMAL' ? "gray" : "red", cursor: "pointer" }}
                onClick={() => updateCostingPriority()}
              />
            </Tooltip>
          </Space>
          <Space size={10}>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() =>
                  commentsData(ReferenceFeatures.MV_DEAD_FREIGHT, 'All Comments')
                }
              />
            </Tooltip>
            {isPermissionExist([916]) && (<PDFDownloadLink
              document={<CostingInvoice costingDetails={{ ...data, invoices: invoice }} costingName={referenceFeatures} plantCode={authContext.defaultPlant} />}
              fileName={`${data?.costingNo}.pdf`}
            >
              <Tooltip placement="topRight" title="Costing Invoice">
                <FontAwesomeIcon
                  style={{
                    border: 'none',
                    fontSize: '15px',
                    padding: '7px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3',
                  }}
                  icon={faDownload} onClick={() => {
                    setDummyRefresh(prev => prev + 1)
                  }}
                />
              </Tooltip>
            </PDFDownloadLink>)}

            {([ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(data?.approvalStatus) ||
            invoice?.some((invoice) => invoice.status === ContractStatusEnum.DRAFT)) && (<EditFilled
              style={{
                border: 'none',
                fontSize: '15px',
                padding: '7px',
                borderRadius: '50%',
                color: '#0295D4',
                backgroundColor: '#DEECF3',
              }}
              onClick={editHandler} />)}
            <Button type="primary" htmlType="submit" className="new-button" onClick={() => {
              setDrawerOpen(true);
              setCostingId((prev) => { return { ...prev, costingId: data?.bgDeadFreightId, costingNo: data.costingNo, totalAmount: data?.totalAmount } });
            }}>
              Add Invoice
            </Button>
          </Space>
        </div>

        <Row gutter={[8, 8]}>
          <Col span={16} xs={24} md={24} lg={16}>
            {/* <Col span={10} style={{ display: 'flex' }} xs={24} md={24} lg={24}> */}
            <Card
              title={'Contract Details'}
              headStyle={{
                fontSize: '16px',
                fontWeight: 600,
                color: '#323F49',
                border: 'none',
                paddingTop: '10px',
              }}
              bordered={false}
              style={{
                width: '100%',
                borderRadius: '8px',
              }}
            >
              <Row>
                <Col span={16} xs={24} md={24} lg={24}>
                  <Descriptions
                    className="descriptions-label"
                    title=""
                    column={{
                      xxl: 5,
                      xl: 5,
                      lg: 5,
                      md: 3,
                      sm: 1,
                      xs: 1,
                    }}
                    layout="vertical"
                    colon={false}
                    style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                  >


                    <Descriptions.Item label={'Business Number'}>
                      <span className="descriptions">
                        <a
                          style={{ color: '#0083C9' }}
                          href={`/#/bn-detail-view?bn_Id=${data?.businessNumber}`}
                        >
                          {data?.businessNo ? data?.businessNo : "--"}
                        </a>
                      </span>
                    </Descriptions.Item>

                    <Descriptions.Item label="Supplier" span={1}>
                      <span className="descriptions">{data?.supplierName}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Dead Freight Entry">
                      <span className="descriptions">{data?.deadFreightEntryDate}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Account">
                      <span className="descriptions">{data?.account}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Dead Freight Entry Date">
                      <span className="descriptions">
                        {`${moment(data?.deadFreightDate).format('DD-MMM-YYYY')}`}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
            {/* </Col> */}

          </Col>

          <Col span={8} xs={24} md={24} lg={8}>
            <Card
              className="card-shadow"
              title={<span className="card-title-md">Price Details</span>}
              styles={{ body: { padding: '6px 14px' } }}
            >

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">dead Freight</Text>
                <Text className="price-card-text">{Number(data?.deadFreight).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Price</Text>
                <Text className="price-card-text">{Number(data?.pricePerMT).toFixed(3)} {data?.quoteCurrency}</Text>
              </Flex>

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Date</Text>
                <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Rate</Text>
                <Text className="price-card-text">{data?.exchangeRate} {data?.baseCurrency}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Amount</Text>
                <Text className="price-card-text">{Number(data?.amount).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Discount</Text>
                <Text className="price-card-text">{Number(data?.discount).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Amount After Discount</Text>
                <Text className="price-card-text">{Number(data?.amtAftDiscount).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>

              <Divider dashed={true} />

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Tax 1</Text>
                <Text className="price-card-text">{`${data?.taxName1 || 'N/A'} (${data?.taxPercent1 || '0'})`}</Text>
                <Text className="price-card-text">{Number(data?.taxTotal1).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Tax 2</Text>
                <Text className="price-card-text">{`${data?.taxName2 || 'N/A'} (${data?.taxPercent2 || '0'})`}</Text>
                <Text className="price-card-text">{Number(data?.taxTotal2).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">TDS</Text>
                <Text className="price-card-text">{`${data?.tdsName || 'N/A'} (${data?.tdsPercentage || '0'})`}</Text>
                <Text className="price-card-text">{Number(data?.tdsTotal).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>


              <Divider dashed={true} />
              <Flex justify="space-between" align='center'>
                <Text className="price-card-heading">Total Amount</Text>
                <Text className="price-card-heading">{Number(data?.totalAmount).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>

              <Flex justify="space-between" align='center'>
                <Text className="price-card-green">To be paid to Supplier</Text>
                <Text className="price-card-green">{Number(data?.totalAmount).toFixed(3)} {data?.baseCurrency}</Text>
              </Flex>
              {/* {Number(remainingAmount) > 0 &&

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-red">Remaining Amount</Text>
                  <Text className="price-card-red">
                    <CountUp
                      start={0}
                      end={Number(remainingAmount)}
                      duration={2.75}
                      decimals={3}
                      suffix={` ${data?.baseCurrency}`} />
                  </Text>
                </Flex>
              } */}
            </Card>
          </Col>

        </Row>
      </Layout>
      <AddInvoiceDrawer referenceFeatures={ReferenceFeatures.MV_DEAD_FREIGHT} key={dummyRefreshValue} setDummyRefresh={setDummyRefreshValue} openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingIds} />
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
  )
}