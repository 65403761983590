import { AccountsService, BargeService, BNoRequest, BpCategoryGetDto, BPDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerService, BusinessPartnerTypeEnum, CostingInvoiceService, PaymentNoticeService, ReferenceFeatures } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Row, Select, Table, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { debounce } from "lodash";
import { useMemo } from "react";
import { TablePaginationConfig } from 'antd/es/table';

const {Option} = Select;

export const CostingDetailReport = () => {
  const { authContext } = useAuthState();
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [bargeDropDown, setBargeDropDown] = useState<any[]>([]);
  const [supplier, setSupplier] = useState<any[]>();
  const [invoiceNo, setInvoiceNo] = useState<any[]>([]);
  const [costingDetails, setCostingDetails] = useState<any[]>([])
  const [selectedBusinessNo, setSelectedBusinessNo] = useState<string>('')
  const [selectedBarge, setSelectedBarge] = useState<string>('')
  const [selectedSupplier, setSelectedSupplier] = useState<string>('')
  const [selectedInvoice, setSelectedInvoice] = useState<string>('')
  const [searchText, setSearchedText] = useState<string>('')
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const businessService = new BusinessNumberService();
  const bargeService = new BargeService();
  const actLedgerService = new AccountsService();
  const invoiceService = new CostingInvoiceService()
  const pnService = new PaymentNoticeService()

  useEffect(() => {
    getBusinessNoDropDown()
    getBargeDropForBusinessNo(undefined)
    getAllSupplierDropDown({})
    getAllInvoiceNo()
  }, [])

  useEffect(() => {
    getAllCostingDetails();
  }, [
    searchText,
    currentPage,
    pageSize,
    selectedBusinessNo,
    selectedSupplier,
    selectedInvoice,
    selectedBarge,
  ]);

  const getAllCostingDetails = () => {
    const req = { 
      offset: (currentPage - 1) * pageSize, 
      limit: pageSize, 
      plantCode: authContext.defaultPlant,
      searchTerm: searchText,
      businessNo: selectedBusinessNo,
      bargeId: selectedBarge,
      supplierId: selectedSupplier,
      invoiceId: selectedInvoice
    }
    pnService.getCostingDetailsById(req).then(res => {
      if (res.status) {
        setCostingDetails(res.data?.allCostings)
        setTotal(res.data?.total)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const getBusinessNoDropDown = () => {
      const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
      businessService.getBusinessNoDropDown(req).then(res => {
        if (res.status) {
          let dropdown = res.data.map(item => {
            let bnNo = item.businessNo.split(" ").pop()
            return {
              ...item,
              bnNo: bnNo
            }
          })
          setBusinessDropDown(dropdown)
        } else {
          console.log(res.internalMessage)
        }
      }).catch(err => {
        console.log(err.message)
      })
    }

  const getBargeDropForBusinessNo = (businessNo?: string) => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant);
    bargeService.getBargeDropForBusinessNo(req).then(res => {
      if (res.status) {
        const mappedData = res.data.map((bgData) => ({
          bgId: bgData.bargeId,
          Id: bgData.id,
          plannedQuantity: bgData.bargeQuantityInMt,
          bargingBasePrice: bgData.chemicalSprayPrice,
          chemicalSprayCurrency: bgData.chemicalSprayCurrency,
          bargeNomination: bgData.bargeNomination,
        }));
        setBargeDropDown(mappedData);
      } else {
        setBargeDropDown([])
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getAllSupplierDropDown = (req: any) => {
      actLedgerService.getActLedForBpCatCode(req)
        .then((res) => {
          if (res.status) {
            setSupplier(res.data)
          } else {
            setSupplier([])
          }
        })
        .catch((err) => {
          console.log('error: ' + err.message);
        });
    };

  const getAllInvoiceNo = () => {
    invoiceService.getAllCostingInvoiceNo({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setInvoiceNo(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const debouncedSearchHandler = useMemo(
      () =>
        debounce((searchValue: string) => {
          setSearchedText(searchValue);
          setCurrentPage(1);
        }, 500),
      [],
    );

  const handleTableChange = (pagination: TablePaginationConfig) => {
      setCurrentPage(pagination.current || 1);
      setPageSize(pagination.pageSize || 25);
    };
  
    // Handle search input change
    const handleSearch = (value: string) => {
      setSearchedText(value);
      debouncedSearchHandler(value);
    };
  
    const handleBusinessNoChange = (value: string) => {
      setSelectedBusinessNo(value);
      setCurrentPage(1);
      getBargeDropForBusinessNo(value)
    };
  
    const handleInvoiceChange = (value: string) => {
      setSelectedInvoice(value);
      setCurrentPage(1);
    };

    const handleSupplierChange = (value: string) => {
      setSelectedSupplier(value);
      setCurrentPage(1);
    };
  
    const bargeChangeHandler = (value: string) => {
      setSelectedBarge(value);
      setCurrentPage(1);
    };

  const costingDetailsData = (costingDetails) => {
    let costingData = [];
  
    costingDetails?.forEach((item) => {
      if (!Array.isArray(item?.invoices) || item.invoices.length === 0) {
        costingData.push({
          costingId: item.costingId,
          costingNo: item.costingNo,
          costingType: item.expenseType,
          bpName: item.bpName,
          businessNos: item.businessNos,
          barges: item.barges,
          totalAmount: item.totalAmount,
          rowSpan: 1,
          invoiceNo: null,
          invoiceAmount: 0,
          paid: 0,
          outstanding: 0,
        });
      } else {
        item.invoices.forEach((invoice, index) => {
          costingData.push({
            costingId: item.costingId,
            costingNo: item.costingNo,
            costingType: item.expenseType,
            bpName: item.bpName,
            businessNos: item.businessNos,
            barges: item.barges,
            totalAmount: item.totalAmount,
            rowSpan: index === 0 ? item.invoices.length : 0,
            invoiceNo: invoice.invoiceNo,
            invoiceAmount: invoice.invoiceAmount,
            paid: invoice.transactionAmount,
            outstanding: invoice.outstandingAmount,
          });
        });
      }
    });
  
    return costingData;
  };

  const contractLink = (referenceFeatures: ReferenceFeatures, id: string) => {
      const object = {
        [ReferenceFeatures.PERFORMA_PC_COSTING]: `/#/performa-pc-detail-view?costing_id=${id}`,
        [ReferenceFeatures.PERFORMA_SC_COSTING]: `/#/performa-sc-detail-view?costing_id=${id}`,
        [ReferenceFeatures.COMMERCIAL_PC_COSTING]: `/#/commercial-pc-detail-view?costing_id=${id}`,
        [ReferenceFeatures.COMMERCIAL_SC_COSTING]: `/#/commercial-sc-detail-view?costing_id=${id}`,
        [ReferenceFeatures.PROCUREMENT_FEES]: `/#/procurement-fee-detail-view?costing_id=${id}`,
        [ReferenceFeatures.MARKETING_FEES]: `/#/marketing-fee-detail-view?costing_id=${id}`,
        [ReferenceFeatures.SALES_FREIGHT_COSTING]: `/#/freight-detail-view?costing_id=${id}`,
        [ReferenceFeatures.BARGE_COSTINGS]: `/#/barge-costing-detail-view?costing_id=${id}`,
        ['Expense Entry']: `/#/expense-entry-detail-view?costing_id=${id}`,
        ['Dead Freight']: `/#/dead-freight-detail-view?costing_id=${id}`,
        ['Demurrage']: `/#/demurrage-detail-view?costing_id=${id}`,
        ['Dispatch']: `/#/demurrage-detail-view?costing_id=${id}`,
      };
      return object[referenceFeatures];
    };

  const columns: any = [
    {
      title: 'Costing No',
      dataIndex: 'costingNo',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, record: any, index) => {
        const link = contractLink(record.costingType, record.costingId);
        return (
          <>
            {
              <a href={link} className="link">
                {value}
              </a>
            }
          </>
        );
      },
    },
    {
      title: 'Costing Type',
      dataIndex: 'costingType',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
    },
    {
      title: 'Supplier',
      dataIndex: 'bpName',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, row: any, index) => {
        const bnValues = [
          ...new Map(
            row.businessNos?.map((rec) => [rec.businessNo, rec]),
          ).values(),
        ];
        return (
          <>
            {new Set(
              row.businessNos?.filter(
                (rec) => rec.businessNo !== null || rec.businessNo != undefined,
              ),
            ).size
              ? bnValues.map(
                (
                  rec: {
                    businessNo: string;
                    businessNoName: string;
                    bnId: string;
                  },
                  index,
                ) => {
                  const comma = index !== bnValues.length - 1 ? ' ' : '';
                  const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`;
                  return (
                    <>
                      {
                        <a key={rec.bnId} href={link} className="link">
                          {rec.businessNo +
                            ` (${rec.businessNoName})` +
                            comma}
                        </a>
                      }
                      <br />
                    </>
                  );
                },
              )
              : 'NA'}
          </>
        );
      },
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      render: (value, row: any) => {
        const bg = row.barges?.filter(rec => rec.bargeNo)?.map(rec => ({
          bargeNo: rec.bargeNo,
          bargeId: rec.bargeId,
          bargeNomination: rec.bargeNomination,
        })) || [];
    
        return (
          <>
            {bg.length > 0 ? (
              bg.map((rec) => {
                const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
                return (
                  <div key={rec?.bargeId}>
                    <a href={link} className="link">
                      {SequenceUtils.formatNumberToSpecificLength(rec?.bargeNo)} ({rec.bargeNomination})
                    </a>
                  </div>
                );
              })
            ) : (
              <span>NA</span>
            )}
          </>
        );
      },
    },
    
    {
      title: 'Costing Amount',
      dataIndex: 'totalAmount',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan
      }),
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      }
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>
      }
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>
      }
    },
    {
      title: 'Outstanding',
      dataIndex: 'outstanding',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>
      }
    },
  ]

  return (
    <Card 
      className='card-radius'>
        <Row gutter={10} style={{ marginBottom: 16 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Input.Search
                  placeholder="Search"
                  allowClear
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                placeholder="Filter By Business Number"
                style={{ width: "100%" }}
                onChange={(value) => handleBusinessNoChange(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={selectedBusinessNo || null}
                allowClear
                showSearch
              >
                {businessDropDown.map((bn) => {
                  return (
                    <Option value={bn.bnId}>
                      {bn.businessName}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                <Select
                  placeholder="Filter By Barge"
                  style={{ width: "100%" }}
                  onChange={(value) => bargeChangeHandler(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  value={selectedBarge || null}
                  allowClear
                  showSearch
                >
                   {bargeDropDown.map((rec) => {
                    return (
                      <Option value={rec.Id}>
                        {SequenceUtils.formatNumberToSpecificLength(rec.bgId.toString()) + " " + `(${rec.bargeNomination})`}
                      </Option>
                    );
                  })}
                </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
               <Select
                  placeholder= "Filter By Supplier"
                  style={{ width: "100%" }}
                  onChange={(value) => handleSupplierChange(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  value={selectedSupplier || null}
                  allowClear
                  showSearch
                >
                  {/* {supplier?.map((rec) => {
                    return (
                      <Option value={rec.bpId}>{rec.bpName}</Option>
                    );
                  })} */}
                  {supplier?.map((rec) => {
                    return (
                      <Option value={rec.bp_id}>{rec.actLedName}</Option>
                    );
                  })}
                </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
               <Select
                  placeholder= "Filter By Invoices"
                  style={{ width: "100%" }}
                  onChange={(value) => handleInvoiceChange(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  value={selectedInvoice || null}
                  allowClear
                  showSearch
                >
                  {invoiceNo?.map((rec) => {
                    return (
                      <Option value={rec.id}>{rec.invoiceNo}</Option>
                    );
                  })}
                </Select>
            </Col>
          </Row>
          <Table
              className="contracts-list"
              columns={columns}
              dataSource={costingDetailsData(costingDetails)}
              rowClassName={(record) => (record.rowSpan > 0 ? "main-row" : "sub-row")}
              scroll={{ x: 'max-content' }}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
              }}
              onChange={handleTableChange}
            />
    </Card>
  )
}

export default CostingDetailReport;