import { ReferenceFeatures } from '@exportx/shared-models-and-services';
import { Drawer } from 'antd'
import React from 'react'
import AddInvoice from './add-invoice'

interface InvoiceDrawerIProps {
    openDrawer: boolean;
    setDrawerOpen: (value: boolean) => void;
    costingId: any;
    setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
    referenceFeatures: ReferenceFeatures;
    accessId?: any;
    vendorId?: string;
}


const AddInvoiceDrawer = (props: InvoiceDrawerIProps) => {
    const { openDrawer, setDrawerOpen, costingId, setDummyRefresh, referenceFeatures, accessId, vendorId } = props; 
    const costingData = { referenceFeatures, costingId: costingId?.costingId, totalAmount: costingId?.totalAmount, ...costingId }
    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => { setDrawerOpen(false); setDummyRefresh(prev => prev + 1) }}
                width={'1000'}
            >
                <AddInvoice costingData={costingData} accessId={costingId?.accessId ? costingId?.accessId : accessId} vendorId={vendorId} />
            </Drawer>

        </>
    )
}

export default AddInvoiceDrawer