import { DownCircleOutlined, MessageFilled, RightCircleOutlined } from '@ant-design/icons';
import {
  BGCostingParentReqDto,
  BPFCostingParentDto,
  BargeCostingsService,
  CommercialCoalCostingService,
  ContractModesEnum,
  ContractStatusEnum,
  CostingWorkLogsEnum,
  PaymentTypeEnum,
  PerformaCoalCostingService,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { SequenceUtils, internationalFormattedValue } from '@exportx/ui-utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../../common';
import { BargeCostingChild } from './barge-costing-child';
import { Card, Drawer, Input, Row, Table, TableColumnsType, Tooltip, Typography } from 'antd';
import { PaymentNoticePageEnum } from '../../../payment-notice';
import CommentsPage from '../../../../../common/comments/comments-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

interface IBargeCostingApproval {
  type?: ContractStatusEnum;
  costingType: CostingWorkLogsEnum;
  contractType?: ContractModesEnum;
  paymentType?: PaymentTypeEnum;
}

export enum BargeCostingApprovalScreen {
  PMT_NOTICE = 'pmt-notice',
}
export const BargeCostingApproval = (props: IBargeCostingApproval) => {
  const { type, costingType, contractType, paymentType } = props;
  const [expandedIndex, setExpandedIndex] = useState([]);
  const bargeCostingsService = new BargeCostingsService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();
  const { authContext } = useAuthState();
  const [bargeCostingData, setBargeCostingData] = useState<
    BPFCostingParentDto[]
  >([]);
  const [searchedText, setSearchedText] = useState('');
  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [costingId, setCostingId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');

  // useEffect(()=>{
  //     console.log('type',type)
  //     console.log('costingType',costingType)
  //     console.log('contractType',contractType)
  //     let permission;
  //     if(type === ContractStatusEnum.BUSINESS_APPROVAL){
  //         if(contractType === ContractModesEnum.PURCHASE){
  //             if(costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING){
  //                 permission = [275]
  //             } else if(costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING){
  //                 permission = []
  //             }
  //         } else if(contractType === ContractModesEnum.SALES){
  //             if(costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING){
  //                 console.log('sales performa')
  //             } else if(costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING){
  //                 console.log('sales commercial')
  //             }
  //         } else if(contractType === ContractModesEnum.PROCUREMENT_FEES){
  //             if(costingType === CostingWorkLogsEnum.PROCUREMENT_FEES){

  //             }
  //         } else {
  //             if(costingType === CostingWorkLogsEnum.MARKETING_FEES){

  //             }
  //         }
  //     }
  // },[])

  const costingsApiReq = new BGCostingParentReqDto();
  costingsApiReq.status = ContractStatusEnum.PENDING_APPROVAL;
  if (type === ContractStatusEnum.AUDIT_APPROVAL) {
    costingsApiReq.status = ContractStatusEnum.PENDING_AUDIT_APPROVAL;
  } else if (type === ContractStatusEnum.MANAGEMENT_APPROVAL) {
    costingsApiReq.status = ContractStatusEnum.PENDING_MANGEMENT_APPROVAL;
  } else if (type === ContractStatusEnum.FREEZED) {
    costingsApiReq.status = ContractStatusEnum.FREEZED;
  }
  costingsApiReq.plantCode = authContext.defaultPlant;
  costingsApiReq.costingType = costingType;

  useEffect(() => {
    getAllCostingsData();
  }, [dummyRefresh, type]);


  const getAllCostingsData = () => {
    if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
      getBargeCostingParentData();
    } else if (
      costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ||
      costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING ||
      costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING
    ) {
      getPerFormData();
    } else if (
      costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING ||
      costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING ||
      costingType === CostingWorkLogsEnum.PROCUREMENT_FEES ||
      costingType === CostingWorkLogsEnum.MARKETING_FEES
    ) {
      getCommercialData();
    }
  };

  const getBargeCostingParentData = () => {
    bargeCostingsService
      .getBargeCostingParentData(costingsApiReq)
      .then((res) => {
        if (res.status) {
          setBargeCostingData(res.data);
          setDummyRefresh(false);
        } else {
          setBargeCostingData([]);
        }
      })
      .catch((err) => {
        setBargeCostingData([]);
        console.log(err.message);
      });
  };

  const getPerFormData = () => {
    perFormaService
      .getPerformaApprovalCostings(costingsApiReq)
      .then((res) => {
        if (res.status) {
          setBargeCostingData(res.data);
          setDummyRefresh(false);
        } else {
          setBargeCostingData([]);
        }
      })
      .catch((err) => {
        setBargeCostingData([]);
        console.log(err.message)
      });
  };

  const getCommercialData = () => {
    commercialService
      .getCommercialApprovalCostings(costingsApiReq)
      .then((res) => {
        if (res.status) {
          setBargeCostingData(res.data);
          setDummyRefresh(false);
        } else {
          setBargeCostingData([]);
        }
      })
      .catch((err) => {
        setBargeCostingData([]);
        console.log(err.message)
      });
  };

  const getCostingDetails = (costingType) => {
    const details = {
      [CostingWorkLogsEnum.BARGE_COSTING]: {
        route: 'barge-costing-detail-view',
        featuresRef: ReferenceFeatures.BARGE_COSTINGS,
      },
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: {
        route: 'performa-pc-detail-view',
        featuresRef: ReferenceFeatures.PERFORMA_PC_COSTING,
      },
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: {
        route: 'performa-sc-detail-view',
        featuresRef: ReferenceFeatures.PERFORMA_SC_COSTING,
      },
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: {
        route: 'commercial-pc-detail-view',
        featuresRef: ReferenceFeatures.COMMERCIAL_PC_COSTING,
      },
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: {
        route: 'commercial-sc-detail-view',
        featuresRef: ReferenceFeatures.COMMERCIAL_SC_COSTING,
      },
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: {
        route: 'procurement-fee-detail-view',
        featuresRef: ReferenceFeatures.PROCUREMENT_FEES,
      },
      [CostingWorkLogsEnum.MARKETING_FEES]: {
        route: 'marketing-fee-detail-view',
        featuresRef: ReferenceFeatures.MARKETING_FEES,
      },
      [CostingWorkLogsEnum.SALES_FREIGHT_COSTING]: {
        route: 'freight-detail-view',
        featuresRef: ReferenceFeatures.SALES_FREIGHT_COSTING,
      },
    };

    return details[costingType];
  };

  const { route, featuresRef } = getCostingDetails(costingType);

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData(featuresRef, 'All Comments');
    setCostingId(record.costingId);
  }


  const tableColumns: TableColumnsType<any> = [
    {
      title: 'Costing Number',
      dataIndex: 'costingNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
            <a
              href={`/#/${route}?costing_id=${record.costingId
                }`}
              className="link"
              target='_blank'
            >
              {value}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {record.priority === 'HIGH' ? <FontAwesomeIcon icon={faFlag} style={{ color: "red" }} /> : null}
          </>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'costingDate',
      render: (value, record) => {
        return <>{moment(value).format('DD-MMM-YYYY')}</>;
      },
    },

    {
      title: 'Purchase Contract No',
      dataIndex: 'purchaseContractNo',
      render: (v, record) => {
        const id =
          costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING ||
            costingType === CostingWorkLogsEnum.PROCUREMENT_FEES ||
            costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING
            ? 'pc_id'
            : 'sc_id';
        const route =
          costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING ||
            costingType === CostingWorkLogsEnum.PROCUREMENT_FEES ||
            costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING
            ? 'p'
            : 's';
        const link = `/#/${route}o-detail-view?${id}=${record.pcId}`;
        return (
          <>
            {
              <a href={link} className="link">
                {v}
              </a>
            }
          </>
        );
      },
    },
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      render: (v, record) => {
        const link = `/#/${record.contractType}-detailedView?cpcId=${record.contractId}`;
        return (
          <>
            {
              <a href={link} className="link">
                {v}
              </a>
            }
          </>
        );
      },
    },

    {
      title: 'Supplier Name',
      dataIndex: 'bargeSupplier',
    },
    {
      title: 'Amount To Be Paid',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };
  const renderItems = (record: any, index, indent, expanded) => {
    return (
      <>
        <BargeCostingChild
          paymentType={paymentType}
          contractType={contractType}
          type={type}
          costingInfo={record}
          getBargeCostingParentData={getBargeCostingParentData}
          costingType={costingType}
          setDummyRefresh={setDummyRefresh}

        />
      </>
    );
  };

  return (
    <>
      <Row className="card-title-buttons mb-3">
        <Input.Search
          placeholder="Search Orders"
          allowClear
          value={searchedText}
          onChange={(e) => setSearchedText(e.target.value)}
          className="input-search"
        />
      </Row>

      <Table
        // size="small"
        className="contracts-list"
        rowKey={(record) => record.costingId}
        columns={tableColumns}
        dataSource={bargeCostingData}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right',
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
              Expand
            </RightCircleOutlined>
          )
        }
        scroll={{ x: 500 }}
        bordered={false}
      />
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
  );
};

export default BargeCostingApproval;
