import {
  AdvanceEnumType,
  AdvanceTypeReqDto,
  CalculationFilterDataReq,
  CalculationTableDto,
  CommercialCoalCostingService,
  CommercialGetAllDto,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTermEnum,
  CostingTypesEnum,
  CostingWorkLogsEnum,
  CurrencyDto,
  CurrencyService,
  GetAllPerFormaDataDto,
  PerFormaReqIdDto,
  PerformaAdvanceDataDto,
  PerformaCoalCostingService,
  ProcurementContractTypeEnum,
  ReferenceFeatures,
  SalesContractService,
  ShippingTypes,
} from '@exportx/shared-models-and-services';
import {
  Affix,
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Flex,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Tabs,
} from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { QualityPriceAdjustments } from '../common';
import { CmSaleAdvanceAdjustments } from './cm-sale-advance-adjustments';
import CmSaleFilterComponent from './cm-sale-costing-filter-component';
import CmSalePriceAdjustment from './cm-sale-price-adjustment';
import CommercialCostingsBargeSelection from '../common/cm-coal-costings-bg-selection';
import moment from 'moment';

interface IPropsCommercialCoalCostings {
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
  pcrContractType?: ProcurementContractTypeEnum;
  costingType?: CostingTypesEnum;
}

export const CmSalesCostingMainForm = (props: IPropsCommercialCoalCostings) => {
  const [formRef] = Form.useForm();
  const [initialValues, setInitialValues] = useState<
    CommercialGetAllDto | GetAllPerFormaDataDto
  >();
  const navigate = useNavigate();
  const { contractType, referenceFeatures, pcrContractType, costingType } =
    props;
  const [bargeData, setBargeData] = useState<CalculationTableDto[]>([]);
  const [bargeSelection, setBargeSelection] = useState<any>([]);
  const [bargeSelectionRowKeys, setBargeSelectionRowKeys] = useState<any[]>([]);

  const cmService = new CommercialCoalCostingService();
  const sales = new SalesContractService();
  const performaCostingsService = new PerformaCoalCostingService();

  const { authContext } = useAuthState();
  const [performaAdvance, setPerformaAdvance] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  let location = useLocation();
  let costingId: any = queryString.parse(location.search)?.costingId;
  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [invoiceContracts, setInvoiceContracts] = useState<
    PerformaAdvanceDataDto[]
  >([]);

  const [accessId, setAccessId] = useState({});
  const [createPermission, setCreatePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [refreshAdjustment, setRefreshAdjustment] = useState<boolean>(true);

  const [taxTotal, setTaxTotal] = useState<any>({
    taxTotal1: 0,
    taxTotal2: 0,
    tdsTotal: 0,
    invoiceTotal: 0,
    exchangeRateValue: 0,
    fcTotal: 0,
    fcCharges: 0,
    freightPricePmt: 0,
    freightTotalAmt: 0,
    freightTaxTotal1: 0,
    freightTaxTotal2: 0,
    freightTdsTotal: 0,
  });

  useEffect(() => {
    let permission;
    if (contractType === ContractModesEnum.PURCHASE) {
      permission = [292];
      setViewPermission(isPermissionExist([292]));
      setCreatePermission(isPermissionExist([291]));
      setAccessId({
        create: 300,
        view: 301,
        update: 302,
        delete: 303,
        approve: 304,
        reject: 305,
        release: 306,
        files: 307,
        purchaseEdit: 293,
      });
    } else {
      permission = [334];
      setViewPermission(isPermissionExist([334]));
      setCreatePermission(isPermissionExist([333]));
      setAccessId({
        create: 342,
        view: 343,
        update: 344,
        delete: 345,
        approve: 346,
        reject: 347,
        release: 348,
        files: 349,
        purchaseEdit: 335,
      });
    }
    if (!isPermissionExist(permission)) navigate('/');
  }, [contractType]);

  useEffect(() => {
    if (costingId) {
      getAllCommercialData(costingId);
    }
  }, [costingId]);


  const scCommercialBargeData = (values) => {
    if (
      costingType === CostingTypesEnum.COMMERCIAL &&
      values?.incoterm === ShippingTypes.FOB_BARGE
    ) {
      cmService
        .getBargesDataForSalesCommercial(values)
        .then((res) => {
          if (res.status) {
            setBargeData(res.data);
          } else {
            setBargeData([]);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setBargeData([]);
        });
    } else {
      sales
        .getPerformaNonSalesFOBBargeData(values)
        .then((res: any) => {
          if (res.status) {
            setBargeData(res.data);
          } else {
            setBargeData([]);
          }
        })
        .catch((err) => {
          console.log('error: ' + err.message);
          setBargeData([]);
        });
    }
  };

  const loadBargesOnClickHandler = (values) => {
    const req = new CalculationFilterDataReq(
      authContext.defaultPlant,
      values.businessNumber,
      values.contractId,
      values.qualityId,
      values.incoterm,
      values.purchaseType,
      contractType,
      values.pcrContractId,
    );
    scCommercialBargeData(req);
  };
  // for 113 line at update time
  // const bgDs = { contractNo: res.data.contractId, bgUId: bg.bgUId, bargeQuantityInMt: bg.bargeQuantityInMt, purchasingTerms: res.data.purchasingTerms, coalSupplierName: res.data.bpName, freightPricePmt: res.data.coalPricePMT, cmBgTransactionId: bg.cmBgTransactionId, incoterm: res.data.incoterm, qualityGroupId: bg.qualityGroupId, baseCurrency: res.data.baseCurrency }
  // bargeData.push(bgDs);

  const getAllCommercialData = async (id: string) => {
    const req = new PerFormaReqIdDto(
      id,
      contractType,
      authContext.defaultPlant,
    );
    if (costingType === CostingTypesEnum.COMMERCIAL) {
      cmService
        .getAllCommercialCostingsData(req)
        .then((res) => {
          if (res.status) {
            setInitialValues({
              ...res.data,
              costingCurrency: res.data.billingCurrency
            });
            setRefreshAdjustment(false);


            const req: any = new CalculationFilterDataReq(
              authContext.defaultPlant,
              res.data.businessNumber,
              res.data.contractId,
              res.data.qualityId,
              res.data.incoterm,
              res.data.purchaseType,
              contractType,
              res.data.pcrContractId,
            );
            // formRef.setFieldValue('taxId1', res)
            // sales commercial
            if (res.data.incoterm === ShippingTypes.FOB_BARGE) {
              cmService
                .getBargesDataForSalesCommercial(req)
                .then((response) => {
                  if (res.status) {
                    const bargeId = [];
                    const bargesData = [];
                    for (const bg of res.data.transactionBarges) {
                      bargeId.push(bg.bgUId);
                      const bgD = response.data.filter(
                        (rec) => rec.bgUId === bg.bgUId,
                      )[0];
                      bargesData.push(bgD);
                    }
                    rowSelection.onChange(bargeId, bargesData, false);
                    setBargeData(response.data);
                  } else {
                    setBargeData([]);
                  }
                })
                .catch((err) => {
                  console.log(err.message);
                  setBargeData([]);
                });
            } else {
              sales
                .getPerformaNonSalesFOBBargeData(req)
                .then((response: any) => {
                  if (response.status) {
                    setBargeData(response.data);
                    const {
                      inWardQty,
                      cmBgTransactionId,
                      baseCurrency,
                      quoteCurrency,
                    } = res.data?.transactionBarges[0] || {};
                    const bargeData = response.data?.map((item) => ({
                      ...item,
                      bargeQuantityInMt: inWardQty,
                      // coalBasePrice: adjustedPriceValue,
                      cmBgTransactionId: cmBgTransactionId,
                      baseCurrency,
                      quoteCurrency,
                    }));
                    rowSelection.onChange([undefined], bargeData, false);

                  } else {
                    setBargeData([]);
                  }
                })
                .catch((err) => {
                  console.log('error: ' + err.message);
                  setBargeData([]);
                });
            }

            setTaxTotal((prev) => {
              return {
                ...prev,
                ...res?.data,
                totalPayable: res?.data?.totalAmount,
                ...res?.data?.freightInfo,

              };
            });
            setAdjustedData(res.data.advanceTarnsactionsData);
            formRef.setFieldsValue({
              ...res?.data,
              costingCurrency: res.data.billingCurrency,
              qualityAdjustPrice: res.data.adjustedPriceValue,
              totalAdjustedValue: res.data.advanceAdjustment,
              totalBonusPrice: res.data.totalPenalty,
              costingDate: res.data.costingDate ? moment(res.data.costingDate) : null,
              exchangeDate: res.data.exchangeDate ? moment(res.data.exchangeDate) : null,
              ...res?.data?.freightInfo,

            });
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
            setInitialValues(null);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setInitialValues(null);
        });
    } else {
      await performaCostingsService
        .getAllPerFormaData(req)
        .then((res) => {
          if (res.status) {
            setInitialValues({
              ...res.data,
              costingCurrency: res.data.billingCurrency
            });
            setRefreshAdjustment(false)
            const req: any = new CalculationFilterDataReq(
              authContext.defaultPlant,
              res.data.businessNumber,
              res.data.contractId,
              res.data.qualityId,
              res.data.incoterm,
              res.data.purchaseType,
              contractType,
            );

            sales
              .getPerformaNonSalesFOBBargeData(req)
              .then((response: any) => {
                if (response.status) {
                  setBargeData(response.data);
                  const {
                    inWardQty,
                    coalPricePerMt,
                    perFormaTransactionId,
                    baseCurrency,
                    quoteCurrency,
                  } = res.data?.transactionBarges[0] || {};
                  const bargeData = response.data?.map((item) => ({
                    ...item,
                    bargeQuantityInMt: inWardQty,
                    coalBasePrice: coalPricePerMt,
                    cmBgTransactionId: perFormaTransactionId,
                    baseCurrency,
                    quoteCurrency,
                  }));

                  rowSelection.onChange([undefined], bargeData, false);
                  setTaxTotal((prev) => {
                    return {
                      ...prev,
                      ...res?.data,
                      totalPayable: res?.data?.totalAmount,
                      ...res?.data?.freightInfo,
                    };
                  });
                  setAdjustedData(res.data.advanceTarnsactionsData);
                  formRef.setFieldsValue({
                    ...res?.data,
                    costingCurrency: res.data.billingCurrency,
                    qualityAdjustPrice: res.data.adjustedPriceValue,
                    totalAdjustedValue: res.data.advanceAdjustment,
                    totalBonusPrice: res.data.totalPenalty,
                    costingDate: res.data.costingDate ? moment(res.data.costingDate) : null,
                    exchangeDate: res.data.exchangeDate ? moment(res.data.exchangeDate) : null,
                    ...res?.data?.freightInfo,

                  });

                } else {
                  setBargeData([]);
                }
              })
              .catch((err) => {
                console.log('error: ' + err.message);
                setBargeData([]);
              });
          } else {
            console.log(res.internalMessage);
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  const getInvoiceContracts = (req: AdvanceTypeReqDto, incoterm: string) => {
    if (req?.bargeId?.length) {
      cmService
        .getInvoiceContracts(req)
        .then((res) => {
          if (res.status) {
            setInvoiceContracts(res.data);
            if (incoterm === 'FOB BARGE') {
              setPerformaAdvance(res.data);
            }
          } else {
            // AlertMessages.getErrorMessage(res.internalMessage)
            setInvoiceContracts([]);
            setPerformaAdvance([]);
          }
        })
        .catch((err) => console.log(err.message));
    } else {
      formRef.setFieldValue('advanceType', undefined);
      formRef.setFieldValue('invoiceContractId', undefined);
      formRef.setFieldValue('advanceBalance', undefined);
      formRef.setFieldValue('advanceToAdjust', undefined);
    }
  };

  const rowSelection = {
    columnTitle: <></>,
    onChange: (selectedRowKeys: string[], selectedRows: any[], isUpdate?: boolean) => {
      setBargeSelection(selectedRows);
      setBargeSelectionRowKeys(selectedRowKeys);

      if (isUpdate === false) return;
      const totalQuantity = selectedRows.reduce((sum, row) => sum + Number(row.bargeQuantityInMt), 0);
      formRef.setFieldsValue({
        adjustedPriceValue: selectedRows[0]?.coalBasePrice,
        totalBargeQuantity: totalQuantity.toFixed(3),
        baseCurrency: selectedRows[0]?.baseCurrency,
        freightPricePmt: selectedRows[0]?.freightPricePmt,
        fcChargesPmt: selectedRows[0]?.fcCharges,
      })
      setRefreshAdjustment(true);
      setDummyRefresh((prev) => prev + 1);

      const req = new AdvanceTypeReqDto(
        authContext.defaultPlant,
        AdvanceEnumType.Against_Performa_Invoice,
        formRef.getFieldValue('contractId'),
        selectedRowKeys,
        formRef.getFieldValue('purchaseType'),
        formRef.getFieldValue('qualityId'),
        bargeSelection[0]?.coalSupplierId,
      );
      getInvoiceContracts(req, selectedRows[0]?.incoterm);
    },
    getCheckboxProps: (record) => {
      if (costingId) {
        record.isBargeMapped = false;
      }
      if (bargeSelection[0]?.qualityGroupId || record.isBargeMapped) {
        if (
          bargeSelection[0]?.qualityGroupId !== record?.qualityGroupId ||
          record.isBargeMapped
        ) {
          return { disabled: true };
        }
      }

      return {};
    },
    selectedRowKeys: bargeSelectionRowKeys,
  };

  const submitHandler = () => {
    formRef.validateFields().then((values) => {

      const barges = bargeSelection?.map(bg => ({
        bargeId: bg?.bgUId,
        inWardQty: values.totalBargeQuantity,
        coalPricePerMt: bg.coalBasePrice,
        adjustedPriceValue: values?.adjustedPriceValue,

        baseCurrency: values.baseCurrency,
        quoteCurrency: values.costingCurrency,
        costingCurrency: values.costingCurrency,
        exchangeRate: values.exchangeRate,
        exchangeDate: values.exchangeDate ? moment(values.exchangeDate).format('YYYY-MM-DD') : null,
        total: taxTotal?.totalPayable,
        qualityGroupId: bg.qualityGroupId,
        plantCode: authContext.defaultPlant,
        userName: authContext.user.userName,
        cmBgTransactionId: initialValues ? bg.cmBgTransactionId : undefined,
        purchasingTerms: bg.purchasingTerms ? bg.purchasingTerms : '',
      }));
      const payload = {
        ...taxTotal,
        ...values,

        totalPenalty: values.totalBonusPrice,
        freightId: initialValues?.freightInfo?.freightId,
        costingDate: values?.costingDate ? moment(values?.costingDate).format('YYYY-MM-DD') : null,
        exchangeDate: values?.exchangeDate ? moment(values?.exchangeDate).format('YYYY-MM-DD') : null,
        costingType: costingType,
        transactionBarges: barges,
        costingId: costingId,
        plantCode: authContext.defaultPlant,
        userName: authContext.user.userName,
        quoteCurrency: values.costingCurrency,
        coalPricePMT: bargeSelection[0]?.coalBasePrice,
        contractType: contractType,
        incoterm: bargeSelection[0]?.incoterm || initialValues?.incoterm,
        costingNo: initialValues?.costingNo,
        adjustmentTransactions: adjustedData,
        vendorId: bargeSelection[0]?.coalSupplierId,
      }

      cmService
        .saveCommercialCostings(payload)
        .then((res) => {
          if (res.status) {
            setTimeout(() => {
              if (costingId) {
                window.location.href = costingType === CostingTypesEnum.COMMERCIAL ? `#/commercial-sc-detail-view?costing_id=${costingId}` : `#/performa-sc-detail-view?costing_id=${costingId}`;
              }
              else {
                window.location.href = costingType === CostingTypesEnum.COMMERCIAL ? `#/commercial-costing-sales` : `#/performaCostingSale`;
              }
            }, 1000);

            AlertMessages.getSuccessMessage(res.internalMessage);

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        });
    });
  };

  const qualityOnChange = (e: number) => {
    setDummyRefresh((prev) => prev + 1);
  };

  return (
    <>
      <h5>{`${costingType} Costings ${contractType === ContractModesEnum.PURCHASE
        ? '- Purchase Contract'
        : contractType === ContractModesEnum.SALES
          ? '- Sales Contract'
          : ''
        } `}</h5>
      <Row>

        <Col span={24}>
          {createPermission && (
            <CmSaleFilterComponent
              key={costingId}
              formRef={formRef}
              loadBargesOnClickHandler={loadBargesOnClickHandler}
              isBusinessNumberMandatory={
                contractType === ContractModesEnum.SALES
              }
              initialValues={initialValues}
              contractType={contractType}
              setBargeData={setBargeData}
              pcrContractType={pcrContractType}
            />
          )}
        </Col>
      </Row>
      <br></br>
      <Row gutter={20}>
        <Col span={14}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Barge Details</h6>}
          >
            <CommercialCostingsBargeSelection
              key={costingId}
              bargeGridData={bargeData}
              rowSelection={rowSelection}
            />
            <br />
            <Flex align='center' justify='flex-end'>
              <Form form={formRef} layout="horizontal">
                <Form.Item name={'totalBargeQuantity'} label={<b>Quantity</b>}>
                  <Input
                    type="number"
                    placeholder="Quantity"
                    onChange={(e) =>
                      qualityOnChange(Number(e.target.value))
                    }
                  ></Input>
                </Form.Item>
              </Form>
            </Flex>

          </Card>

          {(bargeSelection[0]?.purchasingTerms === ContractTermEnum.SPEC && bargeSelection[0]?.noPriceAdjustment === 'false') &&

            <Card
              className="card-shadow"
              styles={{ body: { padding: '0px 15px 15px 10px' } }}
              style={{ marginBottom: 8 }}
              title={<h6>Quality Adjustment</h6>}
            >
              <QualityPriceAdjustments
                formInitialValues={initialValues}
                contractType={contractType}
                selectedRowData={bargeSelection[0]}
                setDummyRefresh={setDummyRefresh}
                commercialProp={"Commercial"}
                formRef={formRef}
                costingId={costingId}
                refreshAdjustment={refreshAdjustment}
                setRefreshAdjustment={setRefreshAdjustment}

              />
            </Card>
          }


          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Advance Adjustment</h6>}
          >
            <CmSaleAdvanceAdjustments
              key={costingId}
              bargeSelectionRowKeys={bargeSelectionRowKeys}
              formRef={formRef}
              vendorId={bargeSelection[0]?.coalSupplierId}
              contractId={bargeSelection[0]?.contractId}
              totalInvoice={Number(taxTotal?.invoiceTotal)}
              performaAdvance={performaAdvance}
              setAdjustedData={setAdjustedData}
              adjustedData={adjustedData}
              setDummyRefresh={setDummyRefresh}
              setInvoiceContracts={setInvoiceContracts}
              invoiceContracts={invoiceContracts}
              costingType={costingType}
            />
          </Card>
        </Col>

        <Col span={10}>
          <Card
            // className="default-card-class"
            title={'Price Adjustment'}
          >
            <CmSalePriceAdjustment
              key={costingId}
              contractType={contractType}
              initialValues={initialValues}
              taxTotal={taxTotal}
              setTaxTotal={setTaxTotal}
              formRef={formRef}
              bargeSelection={bargeSelection[0]}
              dummyRefresh={dummyRefresh}
              setDummyRefresh={setDummyRefresh}
              submitHandler={submitHandler}
              costingCurrency={formRef.getFieldValue('costingCurrency')}
              costingType={costingType}
              adjustedData={adjustedData}
            />
          </Card>
        </Col>
      </Row>

      <Affix offsetBottom={20}>
        <Button className='new-button' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => submitHandler()} style={{ float: 'right' }}>Submit Invoice</Button>
      </Affix>
    </>
  );
};

export default CmSalesCostingMainForm;
