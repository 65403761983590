import {
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
} from '@ant-design/icons';
import {
  BNoRequest,
  BPDropDownDto,
  BargeCostIdReqDto,
  BargeCostingVouchersParentDto,
  BargeService,
  BpCategoryGetDto,
  BusinessNoDropDownDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  BusinessPartnerService,
  BusinessPartnerTypeEnum,
  CommercialCoalCostingService,
  ContractIdRequest,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTypeDto,
  CostingTypesEnum,
  CostingWorkLogsEnum,
  PaymentTypeEnum,
  PerFormaGridDataDto,
  PerformaCoalCostingService,
  ProcurementContractService,
  ProcurementContractTypeEnum,
  PurchaseContractService,
  PurchaseDropDownDto,
  ReferenceFeatures,
  SalesContractService,
} from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import Invoice from '../../invoice/invoice';
import {
  getLocalFormat,
  internationalFormattedValue,
  SequenceUtils,
} from '@exportx/ui-utils';
import { BargeCostingApproval } from '../../barge-costings';
import { Navigate, useNavigate } from 'react-router-dom';
import { get } from 'http';
import { CostingInvoice } from '../../costing-invoice';
import { PaymentNoticePageEnum } from '../../payment-notice';
import debounce from 'lodash/debounce';
import { DatePicker } from 'antd';

interface CostingsIProps {
  contractType: ContractModesEnum;
  setPerFormaId?: (value: string) => void;
  costingType: CostingTypesEnum;
  referenceFeatures: ReferenceFeatures;
  setGridDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  formRef?: FormInstance<any>;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
  accessId?: any;
}

export const SavedCostingsGrid = (props: CostingsIProps) => {
  const [data, setData] = useState<PerFormaGridDataDto[]>([]);
  const {
    contractType,
    costingType,
    setPerFormaId,
    referenceFeatures,
    setGridDrawer,
    accessId,
  } = props;
  const { authContext } = useAuthState();
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [vouchers, setVouchers] = useState<BargeCostingVouchersParentDto>();
  const commercialCostingsService = new CommercialCoalCostingService();
  const [searchedText, setSearchedText] = useState('');
  const [supplier, setSupplier] = useState<BPDropDownDto[]>();
  const [businessDropDown, setBusinessDropDown] =
    useState<BusinessNoDropDownDto[]>();
  const [bargeDropDown, setBargeDropDown] = useState<any[]>();
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedBarge, setSelectedBarge] = useState(null);
  const [contracts, setContracts] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<any>('costing-creation');
  const [costingView, setCostingView] = useState<any>();
  const [costingApproval, setCostingApproval] = useState<any>();
  const [costingReview, setCostingReview] = useState<any>();
  const [costingCreate, setCostingCreate] = useState<any>();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  const [dateRangeType, setDateRangeType] = useState<string | null>(null);
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: null,
    to: null,
  });

  const businessService = new BusinessNumberService();
  const businessPartnerService = new BusinessPartnerService();
  const purchase = new PurchaseContractService();
  const salesService = new SalesContractService();
  const bargeService = new BargeService();
  const procurementContractService = new ProcurementContractService();

  const hasPermission = (props: any) => {
    if (props) {
      return isPermissionExist([props]);
    }
    return true;
  };

  useEffect(() => {
    let permission;
    let approval;
    let review;
    let create;
    if (costingType === CostingTypesEnum.PERFORMA) {
      permission = contractType === ContractModesEnum.PURCHASE ? 271 : 313;
      approval = contractType === ContractModesEnum.PURCHASE ? 275 : 317;
      review = contractType === ContractModesEnum.PURCHASE ? 277 : 319;
      create = contractType === ContractModesEnum.PURCHASE ? 270 : 312;
    } else if (costingType === CostingTypesEnum.COMMERCIAL) {
      permission = contractType === ContractModesEnum.PURCHASE ? 292 : 334;
      approval = contractType === ContractModesEnum.PURCHASE ? 296 : 338;
      review = contractType === ContractModesEnum.PURCHASE ? 298 : 340;
      create = contractType === ContractModesEnum.PURCHASE ? 291 : 333;
    } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
      permission = 1;
      approval = 1;
      review = 1;
      create = 1;
    } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
      permission = 376;
      approval = 379;
      review = 381;
      create = 375;
    } else {
      permission = 355;
      approval = 359;
      review = 361;
      create = 354;
    }
    setCostingView(permission);
    setCostingApproval(approval);
    setCostingReview(review);
    setCostingCreate(create);
    if (!isPermissionExist([permission, approval, review, create])) navigate('/');
  }, [costingType, contractType]);

  useEffect(() => {
    setSearchedText(null);
    setSelectedBusinessNo(null);
    setSelectedBarge(null);
    setSelectedContract(null);
    setSelectedSupplier(null);
    setDateRangeType(null);
  }, [contractType, costingType])

  useEffect(() => {
    setActiveTab('costing-creation');
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getPerFormaCoalGridData();
    } else {
      getCommercialCoalGridData();
    }
  }, [contractType, costingType]);

  useEffect(() => {
    getBusinessNoDropDown();
    getAllBusinessPartnersVendorsDropDown();
    getBargeDropDown();
    if (contractType === ContractModesEnum.PURCHASE) {
      getAllPurchaseContracts();
    } else if (
      contractType === ContractModesEnum.PROCUREMENT_FEES ||
      contractType === ContractModesEnum.MARKETING_FEES
    ) {
      procurementDropDown();
    } else if (
      contractType === ContractModesEnum.SALES ||
      contractType === ContractModesEnum.FREIGHT_COSTING
    ) {
      getAllSaleContractsForDropDown();
    }
  }, [contractType]);

  let costingTypes: CostingWorkLogsEnum;
  if (costingType === CostingTypesEnum.PERFORMA) {
    costingTypes =
      contractType === ContractModesEnum.SALES
        ? CostingWorkLogsEnum.PERFORMA_SC_COSTING
        : CostingWorkLogsEnum.PERFORMA_PC_COSTING;
  } else if (costingType === CostingTypesEnum.COMMERCIAL) {
    costingTypes =
      contractType === ContractModesEnum.SALES
        ? CostingWorkLogsEnum.COMMERCIAL_SC_COSTING
        : CostingWorkLogsEnum.COMMERCIAL_PC_COSTING;
  } else if (costingType === CostingTypesEnum.PROCUREMENT_FEES) {
    costingTypes = CostingWorkLogsEnum.PROCUREMENT_FEES;
  } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
    costingTypes = CostingWorkLogsEnum.MARKETING_FEES;
  } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
    costingTypes = CostingWorkLogsEnum.SALES_FREIGHT_COSTING;
  }

  const getPerFormaCoalGridData = useCallback(
    (
      page = 1,
      pageSize = 25,
      search?: string,
      businessId?: string,
      supplierId?: string,
      purchaseId?: string,
      bargeId?: string,
      fromDate?: moment.Moment,
      toDate?: moment.Moment,
    ) => {
      const req = new ContractTypeDto();
      req.contractType = contractType;
      req.plantCode = authContext.defaultPlant;
      req.limit = pageSize;
      req.offset = (page - 1) * pageSize;
      req.searchString = search;
      req.businessNoId = businessId;
      req.businessPartnerId = supplierId;
      req.purchaseContractId = purchaseId;
      req.bargeId = bargeId;
      req.fromDate = fromDate?.format('YYYY-MM-DD');
      req.toDate = toDate?.clone().add(1, 'day').format('YYYY-MM-DD');

      const performaCostingsService = new PerformaCoalCostingService();
      performaCostingsService
        .getPerFormaCoalGridData(req)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            setPagination((prev) => ({
              ...prev,
              current: page,
              pageSize: pageSize,
              total: res.totalCount || 0,
            }));
          } else {
            setData([]);
            console.log(res.internalMessage);
          }
        })
        .catch((err) => {
          setData([]);
          console.log(err.message);
        });
    },
    [authContext.defaultPlant, contractType],
  );

  const getCommercialCoalGridData = useCallback(
    (
      page = 1,
      pageSize = 25,
      search?: string,
      businessId?: string,
      supplierId?: string,
      purchaseId?: string,
      bargeId?: string,
      fromDate?: moment.Moment,
      toDate?: moment.Moment,
    ) => {
      const req = new ContractTypeDto();
      req.contractType = contractType;
      req.plantCode = authContext.defaultPlant;
      req.limit = pageSize;
      req.offset = (page - 1) * pageSize;
      req.searchString = search;
      req.businessNoId = businessId;
      req.businessPartnerId = supplierId;
      req.purchaseContractId = purchaseId;
      req.bargeId = bargeId;
      req.fromDate = fromDate?.format('YYYY-MM-DD');
      req.toDate = toDate?.clone().add(1, 'day').format('YYYY-MM-DD');

      commercialCostingsService
        .getCommercialCoalGridData(req)
        .then((res) => {
          if (res.status) {
            setData(res.data);
            setPagination((prev) => ({
              ...prev,
              current: page,
              pageSize: pageSize,
              total: res.totalCount || 0,
            }));
          } else {
            setData([]);
            console.log(res.internalMessage);
          }
        })
        .catch((err) => {
          setData([]);
          console.log(err.message);
        });
    },
    [authContext.defaultPlant, contractType],
  );

  const getVouchersData = (id) => {
    if (costingType === CostingTypesEnum.PERFORMA) {
      perFormaVouchersData(id);
    } else {
      getAllCommercialVouchersData(id);
    }
  };

  const getAllCommercialVouchersData = (id) => {
    const req = new BargeCostIdReqDto(
      id,
      authContext.defaultPlant,
      contractType,
    );
    commercialCostingsService
      .getAllCommercialVouchersData(req)
      .then((res) => {
        if (res.status) {
          setVouchers(res.data);
        } else {
          console.log(res.internalMessage);
          setVouchers(undefined);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const perFormaVouchersData = (id) => {
    const performaCostingsService = new PerformaCoalCostingService();
    const req = new BargeCostIdReqDto(
      id,
      authContext.defaultPlant,
      contractType,
    );
    performaCostingsService
      .getPerformanceContractsInvoice(req)
      .then((res) => {
        if (res.status) {
          setVouchers(res.data);
        } else {
          console.log(res.internalMessage);
          setVouchers(undefined);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const objectValue = (referenceFeatures: ReferenceFeatures, id: string) => {
    const object = {
      [ReferenceFeatures.PERFORMA_PC_COSTING]: `/#/po-detail-view?pc_id=${id}`,
      [ReferenceFeatures.COMMERCIAL_PC_COSTING]: `/#/po-detail-view?pc_id=${id}`,
      [ReferenceFeatures.PERFORMA_SC_COSTING]: `/#/so-detail-view?sc_id=${id}`,
      [ReferenceFeatures.COMMERCIAL_SC_COSTING]: `/#/so-detail-view?sc_id=${id}`,
      [ReferenceFeatures.PROCUREMENT_FEES]: `/#/procurement-contract-detailedView?cpcId=${id}`,
      [ReferenceFeatures.MARKETING_FEES]: `/#/marketing-detailedView?cpcId=${id}`,
    };
    return object[referenceFeatures];
  };

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessService
      .getBusinessNoDropDown(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllBusinessPartnersVendorsDropDown = () => {
    const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR);
    businessPartnerService
      .getAllBusinessPartnersForDropDown(req)
      .then((res) => {
        if (res.status) {
          setSupplier(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBargeDropDown = () => {
    const req = new BNoRequest('');
    req.plantCode = authContext.defaultPlant;
    bargeService
      .getBargeDropForBusinessNo(req)
      .then((res) => {
        if (res.status) {
          let barge = res.data?.map((item) => {
            let bargeNo =
              SequenceUtils.formatNumberToSpecificLength(
                item.bargeId.toString(),
              ) +
              ' ' +
              `(${item.bargeNomination})`;
            return {
              id: item.id,
              bargeNo,
            };
          });
          setBargeDropDown(barge);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAllPurchaseContracts = () => {
    purchase
      .getAllPurchaseContractsForDropDown({
        plantCode: authContext.defaultPlant,
      })
      .then((res) => {
        if (res.status) {
          let contract = res.data?.map((item) => {
            let id = item.pcId;
            let contractNo = item.pcNo.toString() + ' ' + `(${item.bpName})`;
            return {
              id,
              contractNo,
            };
          });

          setContracts(contract);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
      });
  };

  const getAllSaleContractsForDropDown = () => {
    salesService
      .getAllSaleContractsForDropDown({ plantCode: authContext.defaultPlant })
      .then((res) => {
        if (res.status) {
          let contract = res.data?.map((item) => {
            let id = item.pcId;
            return {
              id,
              contractNo: item.contractAndSupplier,
            };
          });
          setContracts(contract);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
      });
  };

  const procurementDropDown = () => {
    procurementContractService
      .procurementDropDown({
        plantCode: authContext.defaultPlant,
        contractType:
          contractType === ContractModesEnum.PROCUREMENT_FEES
            ? 'Procurement Fees'
            : 'Marketing Fees',
      })
      .then((res) => {
        if (res.status) {
          let contract = res.data?.map((item) => {
            let id = item.pcID;
            return {
              id,
              contractNo: item.contractNo,
            };
          });

          setContracts(contract);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const contractLink = (referenceFeatures: ReferenceFeatures, id: string) => {
    const object = {
      [ReferenceFeatures.PERFORMA_PC_COSTING]: `/#/performa-pc-detail-view?costing_id=${id}`,
      [ReferenceFeatures.PERFORMA_SC_COSTING]: `/#/performa-sc-detail-view?costing_id=${id}`,
      [ReferenceFeatures.COMMERCIAL_PC_COSTING]: `/#/commercial-pc-detail-view?costing_id=${id}`,
      [ReferenceFeatures.COMMERCIAL_SC_COSTING]: `/#/commercial-sc-detail-view?costing_id=${id}`,
      [ReferenceFeatures.PROCUREMENT_FEES]: `/#/procurement-fee-detail-view?costing_id=${id}`,
      [ReferenceFeatures.MARKETING_FEES]: `/#/marketing-fee-detail-view?costing_id=${id}`,
      [ReferenceFeatures.SALES_FREIGHT_COSTING]: `/#/freight-detail-view?costing_id=${id}`,
    };
    return object[referenceFeatures];
  };

  const tableColumns: any = [
    // {
    //     width: 100,
    //     onCell: (record, index) => ({
    //         rowSpan: record.rowSpan
    //     }),
    //     render: (text, rowData) => {
    //         return (
    //         <span>
    //             {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && hasPermission(accessId?.purchaseEdit) && (
    //                 <Tooltip placement="topRight" title="Edit">
    //                     <EditOutlined type="edit"
    //                         onClick={() => {
    //                             setPerFormaId(rowData.costingId);
    //                             setTimeout(()=> setGridDrawer(false), 500)
    //                         }}
    //                         style={{ color: '#1890ff', fontSize: '15px' }}
    //                     />
    //                 </Tooltip>
    //             )}
    //             <Divider type='vertical'></Divider>
    //             {hasPermission(accessId?.view) && (
    //             <Tooltip placement="topRight" title="Add Invoice">
    //                 <PlusSquareTwoTone onClick={() => {
    //                     setDrawerOpen(true);
    //                     setCostingId((prev) => { return { ...prev, costingId: rowData.costingId, totalAmount: rowData.amount, ...rowData } });

    //                 }}
    //                     style={{ color: '#1890ff', fontSize: '15px' }}
    //                 />
    //             </Tooltip>)}
    //             <Divider type='vertical'></Divider>

    //             <PDFDownloadLink
    //                 document={<Invoice invoice={vouchers} key={dummyRefresh} demurrageName={''} costingName={referenceFeatures} />}
    //                 fileName={`${rowData.costingNo}.pdf`}
    //             >
    //                 <Tooltip placement="topRight" title="Invoice">
    //                     <FilePdfTwoTone onClick={() => {
    //                         getVouchersData(rowData.costingId);
    //                         setDummyRefresh(prev => prev + 1)
    //                     }}
    //                         style={{ color: '#1890ff', fontSize: '15px' }}
    //                     />
    //                 </Tooltip>
    //             </PDFDownloadLink>
    //             {/* <Divider type='vertical'></Divider>
    //             <ViewAllFilesByFeatureId featuresRefId={rowData.costingId} featuresRefName={referenceFeatures}/> */}
    //         </span>
    //     )}
    // },
    {
      title: 'Costing No',
      dataIndex: 'costingNo',
      // onCell: (record, index) => ({
      //   rowSpan: record.rowSpan,
      // }),
      // filteredValue: [String(searchedText).toLowerCase()],
      // onFilter: (value, record) => {
      //   return SequenceUtils.globalFilter(value, record);
      // },
      render: (value, record: any, index) => {
        const link = contractLink(referenceFeatures, record.costingId);
        return (
          <>
            {
              <a href={link} className="link">
                {value}
              </a>
            }
          </>
        );
      },
    },
    // {
    //     title: 'Costing Date',
    //     dataIndex: 'costingDate',
    //     onCell: (record, index) => ({
    //         rowSpan: record.rowSpan
    //     }),
    //     render: (text, record) => {
    //         return moment(record.costingDate).format('YYYY-MM-DD')
    //     },
    // },
    {
      title: 'Coal Supplier',
      dataIndex: 'bpName',
      // onCell: (record, index) => ({
      //   rowSpan: record.rowSpan,
      // }),
    },
    {
      title: 'Contract',
      dataIndex: 'contractNo',
      // onCell: (record, index) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, rowData) => {
        const link = objectValue(referenceFeatures, rowData.contractId);
        return (
          <>
            {
              <a href={link} className="link">
                {value}
              </a>
            }
          </>
        );
      },
    },
    {
      title: 'Laycan',
      dataIndex: 'laycanFromDate',
      // onCell: (record, index) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, record) => {
        return (
          <>
            {record.laycanFromDate && record.laycanToDate ? (
              <span>
                {`${moment(record.laycanFromDate).format(
                  'DD\u2011MMM\u2011YYYY',
                )} to
            ${moment(record.laycanToDate).format('DD\u2011MMM\u2011YYYY')}`}
              </span>
            ) : (
              '--'
            )}
          </>
        );
      },
    },
    ...(contractType !== ContractModesEnum.PROCUREMENT_FEES &&
      contractType !== ContractModesEnum.MARKETING_FEES
      ? [
        {
          title: 'Business No',
          dataIndex: 'motherVesselName',
          // onCell: (record, index) => ({
          //   rowSpan: record.rowSpan,
          // }),
          render: (value, rowData) => {
            if (rowData.businessNumber) {
              const link = `/#/bn-detail-view?bn_Id=${rowData.businessNumber}`;
              return (
                <a
                  href={link}
                  target="_blank"
                  className="link"
                  rel="noreferrer"
                >
                  {value}
                </a>
              );
            }
            return <span>{value}</span>;
          },
        },
      ]
      : []),
    {
      title: 'Barges',
      dataIndex: 'barges',
      render: (barges) => {
        return (
          <>
            {barges.map((barge, index) => {
              const link = `/#/barge-detail-view?barge_id=${barge.bargeId}`;
              return (
                <div key={barge.bargeId + '-' + index}>
                  <a href={link} className="link">
                    {SequenceUtils.formatNumberToSpecificLength(barge.bargeNo) +
                      ' ' +
                      `(${barge.bgNomination})`}
                  </a>
                  {index !== barges.length - 1 && <Divider />}
                </div>
              );
            })}
          </>
        );
      },
    },
    ...(contractType === ContractModesEnum.SALES
      ? [
        {
          title: 'Coal Amount',
          dataIndex: 'coalAmount',
          align: 'right',
          // onCell: (record, index) => ({
          //   rowSpan: record.rowSpan,
          // }),
          render: (value, record: any, index) => {
            return (
              <span>
                <Typography.Text>
                  {internationalFormattedValue(value, 3)}
                </Typography.Text>
                <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
              </span>
            );
          },
        },
        {
          title: 'Freight Amount',
          dataIndex: 'freightAmt',
          align: 'right',
          // onCell: (record, index) => ({
          //   rowSpan: record.rowSpan,
          // }),
          render: (value, record: any, index) => {
            return (
              <span>
                <Typography.Text>
                  {internationalFormattedValue(value, 3)}
                </Typography.Text>
                <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
              </span>
            );
          },
        },
      ]
      : []),
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      // onCell: (record, index) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, record: any, index) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value ?? record?.coalAmount, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      render: (value, record) => <StatusTag status={record.approvalStatus} />,
    },
  ];

  const getTableData = (values: PerFormaGridDataDto[]): any[] => {
    const empty = [];
    values?.forEach((rec, pIndex) => {
      rec?.barges?.forEach((child, index) => {
        empty.push({
          costingId: rec.costingId,
          amount: rec.totalAmount,
          costingNo: rec.costingNo,
          costingDate: rec.costingDate,
          bpName: rec.bpName,
          contractNo: rec.contractNo,
          businessNumber: rec.businessNumber,
          motherVesselName: rec.motherVesselName,
          contractId: rec.contractId,
          laycanFromDate: rec.laycanFromDate,
          laycanToDate: rec.laycanToDate,
          bpId: rec.bpId,
          bnId: rec.bnId,
          rowSpan: index === 0 ? rec.barges.length : 0,
          bargeId: child.bargeId,
          bargeNo: child.bargeNo,
          bgNomination: child.bgNomination,
          approvalStatus: rec.approvalStatus,
          freightAmt: rec?.freightAmt || 0,
          includeFreight: rec?.includeFreight || false,
          coalAmount: rec.coalAmount || rec.totalAmount,
        });
      });
    });
    return empty;
  };

  const handleBusinessNoChange = (value: string) => {
    setSelectedBusinessNo(value);
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getPerFormaCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        value,
        selectedSupplier,
        selectedContract,
        selectedBarge,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    } else {
      getCommercialCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        value,
        selectedSupplier,
        selectedContract,
        selectedBarge,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    }
  };

  const handleSupplierChange = (value: string) => {
    setSelectedSupplier(value);
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getPerFormaCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        value,
        selectedContract,
        selectedBarge,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    } else {
      getCommercialCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        value,
        selectedContract,
        selectedBarge,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    }
  };

  const handleContractChange = (value: string) => {
    setSelectedContract(value);
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getPerFormaCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        value,
        selectedBarge,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    } else {
      getCommercialCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        value,
        selectedBarge,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    }
  };

  const handleBargeChange = (value: string) => {
    setSelectedBarge(value);
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getPerFormaCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedContract,
        value,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    } else {
      getCommercialCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedContract,
        value,
        dateRangeValues.from,
        dateRangeValues.to,
      );
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string, page: number, pageSize: number) => {
        if (
          costingType === CostingTypesEnum.PERFORMA ||
          costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
        ) {
          getPerFormaCoalGridData(
            page,
            pageSize,
            value,
            selectedBusinessNo,
            selectedSupplier,
            selectedContract,
            selectedBarge,
            dateRangeValues.from,
            dateRangeValues.to,
          );
        } else {
          getCommercialCoalGridData(
            page,
            pageSize,
            value,
            selectedBusinessNo,
            selectedSupplier,
            selectedContract,
            selectedBarge,
            dateRangeValues.from,
            dateRangeValues.to,
          );
        }
      }, 500),
    [
      getPerFormaCoalGridData,
      getCommercialCoalGridData,
      costingType,
      selectedBusinessNo,
      selectedSupplier,
      selectedContract,
      selectedBarge,
      dateRangeValues,
    ],
  );

  const creationLink = () => {
    if (costingType === CostingTypesEnum.PERFORMA) {
      return contractType === ContractModesEnum.SALES
        ? '/create-performa-costing-sale'
        : '/create-performa-coal-costing';
    } else if (costingType === CostingTypesEnum.COMMERCIAL) {
      return contractType === ContractModesEnum.SALES
        ? '/create-commercial-costing-sale'
        : '/create-commercial-coal-costing';
    } else if (costingType === CostingTypesEnum.PROCUREMENT_FEES) {
      return '/create-procurement-coal-costing';
    } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
      return '/create-marketing-coal-costing';
    } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
      return '/create-sale-freight-costing';
    }
  };

  const dateRangeDropDown = [
    { value: 'this_month', label: 'This Month' },
    { value: 'previous_month', label: 'Previous Month' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'previous_quarter', label: 'Previous Quarter' },
    { value: 'this_year', label: 'This Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Date Range' },
  ];

  const handleDateRangeTypeChange = (value: string | null) => {
    setDateRangeType(value);

    if (value === 'custom') {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    if (!value) {
      setDateRangeValues({ from: null, to: null });
      // Call API when clearing the date range selection
      if (
        costingType === CostingTypesEnum.PERFORMA ||
        costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
      ) {
        getPerFormaCoalGridData(
          1,
          pagination.pageSize,
          searchedText,
          selectedBusinessNo,
          selectedSupplier,
          selectedContract,
          selectedBarge,
          null,
          null,
        );
      } else {
        getCommercialCoalGridData(
          1,
          pagination.pageSize,
          searchedText,
          selectedBusinessNo,
          selectedSupplier,
          selectedContract,
          selectedBarge,
          null,
          null,
        );
      }
      return;
    }

    const now = moment();
    let fromDate: moment.Moment;
    let toDate: moment.Moment;

    switch (value) {
      case 'this_month':
        fromDate = now.clone().startOf('month');
        toDate = now.clone().endOf('month');
        break;
      case 'previous_month':
        fromDate = now.clone().subtract(1, 'month').startOf('month');
        toDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'this_quarter':
        fromDate = now.clone().startOf('quarter');
        toDate = now.clone().endOf('quarter');
        break;
      case 'previous_quarter':
        fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
        toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'this_year':
        fromDate = now.clone().startOf('year');
        toDate = now.clone().endOf('year');
        break;
      case 'previous_year':
        fromDate = now.clone().subtract(1, 'year').startOf('year');
        toDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      default:
        return;
    }

    setDateRangeValues({ from: fromDate, to: toDate });

    if (costingType === CostingTypesEnum.PERFORMA) {
      getPerFormaCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedContract,
        selectedBarge,
        fromDate,
        toDate,
      );
    } else {
      getCommercialCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedContract,
        selectedBarge,
        fromDate,
        toDate,
      );
    }
  };

  const handleDateRangeValuesChange = (
    dates: [any | null, any | null] | null,
  ) => {
    if (!dates || !dates[0] || !dates[1]) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const [from, to] = dates;
    setDateRangeValues({ from, to });

    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getPerFormaCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedContract,
        selectedBarge,
        from,
        to,
      );
    } else {
      getCommercialCoalGridData(
        1,
        pagination.pageSize,
        searchedText,
        selectedBusinessNo,
        selectedSupplier,
        selectedContract,
        selectedBarge,
        from,
        to,
      );
    }
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            ...(isPermissionExist([costingView])
              ? [
                {
                  key: 'costing-creation',
                  label: 'Costing Creation',
                  children: (
                    <>
                      <Row justify="space-between" className="mb-12 mt-8" gutter={[0, 8]}>
                        <Col xs={24} md={20}>
                          <Flex gap={10} wrap="wrap">
                            <Input.Search
                              className="input-search"
                              style={{ width: 225 }}
                              placeholder="Search"
                              allowClear
                              onChange={(e) => {
                                const value = e.target.value;
                                setSearchedText(value);
                                debouncedSearch(
                                  value,
                                  1,
                                  pagination.pageSize,
                                );
                              }}
                              onSearch={(value) => {
                                setSearchedText(value);
                                // Immediate search on enter key or search button click
                                if (
                                  costingType === CostingTypesEnum.PERFORMA ||
                                  costingType ===
                                  CostingTypesEnum.SALE_FREIGHT_COSTING
                                ) {
                                  getPerFormaCoalGridData(
                                    pagination.current,
                                    pagination.pageSize,
                                    value,
                                    selectedBusinessNo,
                                    selectedSupplier,
                                    selectedContract,
                                    selectedBarge,
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  );
                                } else {
                                  getCommercialCoalGridData(
                                    pagination.current,
                                    pagination.pageSize,
                                    value,
                                    selectedBusinessNo,
                                    selectedSupplier,
                                    selectedContract,
                                    selectedBarge,
                                    dateRangeValues.from,
                                    dateRangeValues.to,
                                  );
                                }
                              }}
                            />
                            <>
                              {contractType !==
                                ContractModesEnum.PROCUREMENT_FEES &&
                                contractType !==
                                ContractModesEnum.MARKETING_FEES && (
                                  <Select
                                    style={{ width: 175 }}
                                    placeholder="Business"
                                    value={selectedBusinessNo}
                                    onChange={handleBusinessNoChange}
                                    filterOption={(input, option) =>
                                      (option!.children as unknown as string)
                                        .toString()
                                        .toLocaleLowerCase()
                                        .includes(input.toLocaleLowerCase())
                                    }
                                    showSearch
                                    allowClear
                                  >
                                    {businessDropDown?.map((item, index) => (
                                      <Select.Option
                                        value={item.bnId}
                                        key={item.bnId + '-' + index}
                                      >
                                        {item.businessName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )}
                            </>
                            <Select
                              style={{ width: 175 }}
                              placeholder="Coal Supplier"
                              value={selectedSupplier}
                              onChange={handleSupplierChange}
                              filterOption={(input, option) =>
                                (option!.children as unknown as string)
                                  .toString()
                                  .toLocaleLowerCase()
                                  .includes(input.toLocaleLowerCase())
                              }
                              showSearch
                              allowClear
                            >
                              {supplier?.map((item, index) => (
                                <Select.Option
                                  value={item.bpId}
                                  key={item.bpId + '-' + index}
                                >
                                  {item.bpName}
                                </Select.Option>
                              ))}
                            </Select>
                            <Select
                              style={{ width: 175 }}
                              placeholder="Contract"
                              value={selectedContract}
                              onChange={handleContractChange}
                              filterOption={(input, option) =>
                                (option!.children as unknown as string)
                                  .toString()
                                  .toLocaleLowerCase()
                                  .includes(input.toLocaleLowerCase())
                              }
                              showSearch
                              allowClear
                            >
                              {contracts?.map((item, index) => (
                                <Select.Option
                                  value={item.id}
                                  key={item.id + '-' + index}
                                >
                                  {item.contractNo}
                                </Select.Option>
                              ))}
                            </Select>
                            <Select
                              style={{ width: 175 }}
                              placeholder="Barge"
                              value={selectedBarge}
                              onChange={handleBargeChange}
                              filterOption={(input, option) =>
                                (option!.children as unknown as string)
                                  .toString()
                                  .toLocaleLowerCase()
                                  .includes(input.toLocaleLowerCase())
                              }
                              showSearch
                              allowClear
                            >
                              {bargeDropDown?.map((item, index) => (
                                <Select.Option
                                  value={item.id}
                                  key={item.id + '-' + index}
                                >
                                  {item.bargeNo}
                                </Select.Option>
                              ))}
                            </Select>
                            <Select
                              placeholder="Date Range"
                              style={{ width: 175 }}
                              allowClear
                              value={dateRangeType}
                              onChange={handleDateRangeTypeChange}
                            >
                              {dateRangeDropDown.map((item, index) => (
                                <Select.Option
                                  value={item.value}
                                  key={item.value + '-' + index}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                            {dateRangeType === 'custom' && (
                              <DatePicker.RangePicker
                                style={{ maxWidth: 175 }}
                                value={[
                                  dateRangeValues.from,
                                  dateRangeValues.to,
                                ]}
                                onChange={handleDateRangeValuesChange}
                              />
                            )}
                          </Flex>
                        </Col>
                        <Col>
                          {isPermissionExist([costingCreate]) && <Button
                            className="new-button"
                            onClick={() => navigate(creationLink())}
                          >{`New ${costingType}`}</Button>}
                        </Col>
                      </Row>
                      <Table
                        className="contracts-list"
                        scroll={{ x: 500 }}
                        columns={tableColumns}
                        dataSource={data}
                        pagination={{
                          ...pagination,
                          showTotal: (total) => (
                            <span className="page-total-items">
                              Total {total} items
                            </span>
                          ),
                          showSizeChanger: true,
                          pageSizeOptions: ['25', '50', '100'],
                          onChange: (page, pageSize) => {
                            if (
                              costingType === CostingTypesEnum.PERFORMA ||
                              costingType ===
                              CostingTypesEnum.SALE_FREIGHT_COSTING
                            ) {
                              getPerFormaCoalGridData(
                                page,
                                pageSize,
                                searchedText,
                                selectedBusinessNo,
                                selectedSupplier,
                                selectedContract,
                                selectedBarge,
                                dateRangeValues.from,
                                dateRangeValues.to,
                              );
                            } else {
                              getCommercialCoalGridData(
                                page,
                                pageSize,
                                searchedText,
                                selectedBusinessNo,
                                selectedSupplier,
                                selectedContract,
                                selectedBarge,
                                dateRangeValues.from,
                                dateRangeValues.to,
                              );
                            }
                          },
                          style: {
                            paddingTop: '12px',
                          },
                        }}
                      />
                    </>
                  ),
                },
              ]
              : []),
            ...(isPermissionExist([costingApproval])
              ? [
                {
                  key: 'business-approval',
                  label: 'Business Approval',
                  children: (
                    <BargeCostingApproval
                      contractType={contractType}
                      costingType={costingTypes}
                      type={ContractStatusEnum.BUSINESS_APPROVAL}
                      key={`business-approval-${contractType}-${costingTypes}`}
                      paymentType={(contractType === ContractModesEnum.PROCUREMENT_FEES || contractType === ContractModesEnum.MARKETING_FEES || contractType === ContractModesEnum.PURCHASE) ? PaymentTypeEnum.PAYABLE : PaymentTypeEnum.RECEIVABLE}
                    />
                  ),
                },
              ]
              : []),
            ...(costingType === CostingTypesEnum.COMMERCIAL &&
              contractType === ContractModesEnum.PURCHASE && isPermissionExist([costingReview])
              ? [
                {
                  key: 'management-review',
                  label: 'Management Review',
                  children: (
                    <BargeCostingApproval
                      contractType={ContractModesEnum.PURCHASE}
                      costingType={CostingWorkLogsEnum.COMMERCIAL_PC_COSTING}
                      type={ContractStatusEnum.MANAGEMENT_APPROVAL}
                      key="commercial-pc-management-approval"
                    />
                  ),
                },
              ]
              : []),
            ...(isPermissionExist([costingApproval])
              ? [
                {
                  label: 'Additional Information',
                  key: 'additional-info',
                  children: (
                    <BargeCostingApproval
                      contractType={contractType}
                      costingType={costingTypes}
                      type={ContractStatusEnum.FREEZED}
                      key={`additional-info-${contractType}-${costingTypes}`}
                      paymentType={(contractType === ContractModesEnum.PROCUREMENT_FEES || contractType === ContractModesEnum.PURCHASE) ? PaymentTypeEnum.PAYABLE : PaymentTypeEnum.RECEIVABLE}

                    />
                  ),
                },
              ]
              : []),
          ]}
        />
      </Card>
      {/* <AddInvoiceDrawer
                referenceFeatures={referenceFeatures}
                key={dummyRefresh}
                setDummyRefresh={setDummyRefresh}
                openDrawer={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                costingId={costingId}
                accessId={accessId}
            /> */}
    </>
  );
};

export default SavedCostingsGrid;
