import { BargeIdRequestDto, BargeService, CalculationTableDto, CommercialGetAllDto, ContractModesEnum, ContractTermEnum, GetAllPerFormaDataDto, MutualAgreed, PortTypeEnum, PurchaseSupplierReportDto, SamplingAndAnalysisService, ScqTypicalRequestDto } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Button, Card, Col, Flex, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
interface IProsQualityPriceAdjustments {
  selectedRowData: CalculationTableDto;
  closeQualityModal?: () => void;
  commercialProp?: string;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
  formRef?: FormInstance<any>;
  contractType: ContractModesEnum;
  formInitialValues?: CommercialGetAllDto | GetAllPerFormaDataDto;
  costingId?: string;
  refreshAdjustment?:boolean;
  setRefreshAdjustment?: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;
export const QualityPriceAdjustments = (props: IProsQualityPriceAdjustments) => {
  const { contractType, selectedRowData, commercialProp, setDummyRefresh, formRef, formInitialValues, costingId, refreshAdjustment } = props;
  const [bargeGridData, setBargeGridData] = useState<PurchaseSupplierReportDto[]>([]);
  const [initialValues, setInitialValues] = useState(undefined);
  const { authContext } = useAuthState();
  const qualityService = new SamplingAndAnalysisService();

  useEffect(() => {
    if (contractType !== ContractModesEnum.SALES) {
      getPurchaseSamplingSupplier();
    }
  }, []);

  useEffect(() => {
    if (selectedRowData?.qualityId && contractType === ContractModesEnum.SALES && costingId) {
      getScqTypicalSpecs(false);
    }

  }, [selectedRowData?.qualityId])


  // values was not set 
  useEffect(() => {
    if (formInitialValues) {
      formRef.setFieldsValue(formInitialValues);
    }

  }, [formInitialValues])


  useEffect(() => {
    if (bargeGridData) {
      if (refreshAdjustment) setTotalValuesToFields();
    }
  }, [bargeGridData]);

  const getScqTypicalSpecs = (callCalculation: boolean) => {
    const req = new ScqTypicalRequestDto(selectedRowData?.qualityId, authContext.defaultPlant, formRef.getFieldValue('portType'));
    qualityService.getSalesSamplingSupplier(req).then(res => {
      if (res.status && res.data?.length) {
        setBargeGridData(res.data);
        if (callCalculation)
          calculateDefaultBonusOrPenalty(res.data);
      }
      else {
        AlertMessages.getErrorMessage('No data found.');
        setBargeGridData([]);
        calculateDefaultBonusOrPenalty([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setBargeGridData([]);
      calculateDefaultBonusOrPenalty([]);
    })
  }

  const setTotalValuesToFields = async () => {
    await formRef.validateFields().then(values => {
      const bonusPenaltyTotal = Number(
        (Number(values.tsPriceAdjustment) + Number(values.gcvAdjustment) + Number(values.ashPriceAdjustment)).toFixed(3)
      );

      const adjustedPrice = Number(
        (Number(selectedRowData?.coalBasePrice) + bonusPenaltyTotal).toFixed(3)
      );

      // Set form values with NaN check
      formRef.setFieldValue('totalBonusPrice', !isNaN(bonusPenaltyTotal) ? bonusPenaltyTotal : 0);
      formRef.setFieldValue('qualityAdjustPrice', !isNaN(adjustedPrice) ? adjustedPrice : selectedRowData?.coalBasePrice);
      formRef.setFieldValue('adjustedPriceValue', !isNaN(adjustedPrice) ? adjustedPrice : selectedRowData?.coalBasePrice);

      setDummyRefresh(prev => prev + 1);
    });
  };

  const rejectionHandler = (name: string, rowData: PurchaseSupplierReportDto) => {
    let disabled = true;
    let initialValue = null;
    if (name === 'gcvRejection') {
      if (Number(rowData?.cvActual) < Number(rowData?.cvRejection)) {
        disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else if (name === 'ashRejection') {
      if (Number(rowData?.ashTypical) < Number(rowData?.ashActual)) {
        disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else {
      if (Number(rowData?.tsTypical) < Number(rowData?.tsActual)) {
        disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    }

    formRef.setFieldValue(name, initialValue);
    return <Form.Item name={name} initialValue={initialValue}>
      <Select allowClear showSearch disabled={disabled} >
        <Option value={null} >{'No Rejection'}</Option>
        {React.Children.toArray(Object.keys(MutualAgreed).map(key => {
          return <Option value={MutualAgreed[key]} key={MutualAgreed[key]}>{MutualAgreed[key]}</Option>
        }))}
      </Select>
    </Form.Item>
  }

  const tableColumns: any = [
    {
      title: 'Typical Analysis',
      dataIndex: 'typicalAnalysis',
      width: 150,
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Units',
      dataIndex: 'units',
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Typical',
      dataIndex: 'typical',
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Rejection',
      dataIndex: 'rejection',
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Bonus Cap',
      dataIndex: 'bonusCap',
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Penalty',
      dataIndex: 'penalty',
      align: 'center',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Actual',
      align: 'center',
      dataIndex: 'actual',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Rejection',
      align: 'center',
      dataIndex: 'rejectionApplied',
      render: (name, row) => {
        return rejectionHandler(name, bargeGridData[0]);
      }
    },
    {
      title: 'Bonus/Penalty',
      width: 130,
      align: 'center',
      fixed: 'right',
      dataIndex: 'bonusOrPenaltyCap',
      render: (name, row) => {
        return <Form.Item name={name}>
          <InputNumber onChange={() => {
            const one = formRef.getFieldValue("gcvAdjustment")
            const two = formRef.getFieldValue("ashPriceAdjustment")
            const th = formRef.getFieldValue("tsPriceAdjustment")
            formRef.setFieldValue('totalBonusPrice', Number(one) + Number(two) + Number(th));
            setTotalValuesToFields();
          }} disabled={false} />
        </Form.Item>
      }
    },
  ];

  const processTableData = (item: PurchaseSupplierReportDto, dischargeSamp: PurchaseSupplierReportDto) => {
    return [
      {
        typicalAnalysis: 'Gross Calorific Value-1',
        name: 'qualityCalorificValueActual',
        units: 'Kcal/Kg',
        basis: item?.cvBasis || dischargeSamp?.cvBasis
          ? `${item?.cvBasis || ''}${dischargeSamp?.cvBasis ? ` / ${dischargeSamp?.cvBasis}` : ''}`
          : 'NA',
        typical: item?.cvTypical || dischargeSamp?.cvTypical
          ? `${item?.cvTypical || ''}${dischargeSamp?.cvTypical ? ` / ${dischargeSamp?.cvTypical}` : ''}`
          : 'NA',
        rejection: item?.cvRejection || dischargeSamp?.cvRejection
          ? `${item?.cvRejection || ''}${dischargeSamp?.cvRejection ? ` / ${dischargeSamp?.cvRejection}` : ''}`
          : 'NA',
        bonusCap: item?.cvBonusCap || dischargeSamp?.cvBonusCap
          ? `${item?.cvBonusCap || ''}${dischargeSamp?.cvBonusCap ? ` / ${dischargeSamp?.cvBonusCap}` : ''}`
          : 'NA',
        penalty: item?.cvPenality || dischargeSamp?.cvPenality
          ? `${item?.cvPenality || ''}${dischargeSamp?.cvPenality ? ` / ${dischargeSamp?.cvPenality}` : ''}`
          : 'NA',
        actual: item?.cvActual || dischargeSamp?.cvActual
          ? `${item?.cvActual || ''}${dischargeSamp?.cvActual ? ` / ${dischargeSamp?.cvActual}` : ''}`
          : 'NA',
        rejectionApplied: "gcvRejection",
        bonusOrPenaltyCap: 'gcvAdjustment'
      },
      {
        typicalAnalysis: 'Ash',
        name: 'qualityAshActual',
        units: '%',
        basis: item?.ashBasis || dischargeSamp?.ashBasis
          ? `${item?.ashBasis || ''}${dischargeSamp?.ashBasis ? ` / ${dischargeSamp?.ashBasis}` : ''}`
          : 'NA',
        typical: item?.ashTypical || dischargeSamp?.ashTypical
          ? `${item?.ashTypical || ''}${dischargeSamp?.ashTypical ? ` / ${dischargeSamp?.ashTypical}` : ''}`
          : 'NA',
        rejection: item?.ashRejection || dischargeSamp?.ashRejection
          ? `${item?.ashRejection || ''}${dischargeSamp?.ashRejection ? ` / ${dischargeSamp?.ashRejection}` : ''}`
          : 'NA',
        penalty: item?.ashPenality || dischargeSamp?.ashPenality
          ? `${item?.ashPenality || ''}${dischargeSamp?.ashPenality ? ` / ${dischargeSamp?.ashPenality}` : ''}`
          : 'NA',
        actual: item?.ashActual || dischargeSamp?.ashActual
          ? `${item?.ashActual || ''}${dischargeSamp?.ashActual ? ` / ${dischargeSamp?.ashActual}` : ''}`
          : 'NA',
        rejectionApplied: "ashRejection",
        bonusOrPenaltyCap: 'ashPriceAdjustment'
      },
      {
        typicalAnalysis: 'Total Sulphur',
        name: 'qualityTotalSulphurActual',
        units: '%',
        basis: item?.tsBasis || dischargeSamp?.tsBasis
          ? `${item?.tsBasis || ''}${dischargeSamp?.tsBasis ? ` / ${dischargeSamp?.tsBasis}` : ''}`
          : 'NA',
        typical: item?.tsTypical || dischargeSamp?.tsTypical
          ? `${item?.tsTypical || ''}${dischargeSamp?.tsTypical ? ` / ${dischargeSamp?.tsTypical}` : ''}`
          : 'NA',
        rejection: item?.tsRejection || dischargeSamp?.tsRejection
          ? `${item?.tsRejection || ''}${dischargeSamp?.tsRejection ? ` / ${dischargeSamp?.tsRejection}` : ''}`
          : 'NA',
        penalty: item?.tsPenality || dischargeSamp?.tsPenality
          ? `${item?.tsPenality || ''}${dischargeSamp?.tsPenality ? ` / ${dischargeSamp?.tsPenality}` : ''}`
          : 'NA',
        actual: item?.tsActual || dischargeSamp?.tsActual
          ? `${item?.tsActual || ''}${dischargeSamp?.tsActual ? ` / ${dischargeSamp?.tsActual}` : ''}`
          : 'NA',
        rejectionApplied: "tsRejection",
        bonusOrPenaltyCap: 'tsPriceAdjustment'
      }
    ];
  }


  // const calculateDefaultBonusOrPenalty = (data: PurchaseSupplierReportDto[]) => {
  //   const pSQR = data[0];
  //   if (selectedRowData.purchasingTerms === ContractTermEnum.SPEC) {

  //     const bonusCap = Number.isNaN(Number(pSQR?.cvBonusCap)) ? 0 : Number(pSQR?.cvBonusCap);
  //     const gcvActual = Number.isNaN(Number(pSQR?.cvActual)) ? 1 : Number(pSQR?.cvActual);
  //     const gcvTypical = Number.isNaN(Number(pSQR?.cvTypical)) ? 1 : Number(pSQR?.cvTypical);
  //     const minOfActualToBonusCap = bonusCap ? Math.min(...[bonusCap, gcvActual]) : gcvActual;
  //     console.log('minOfActualToBonusCap', minOfActualToBonusCap);
  //     //Price * (Actual/Typical)
  //     const gcvAdjustmentPrice = selectedRowData.coalBasePrice * (Number(minOfActualToBonusCap) / Number(gcvTypical));
  //     console.log(`${selectedRowData.coalBasePrice} * (${Number(minOfActualToBonusCap)} / ${Number(gcvTypical)})`);
  //     const gcvAdjustment = (gcvAdjustmentPrice - selectedRowData.coalBasePrice).toFixed(2);
  //     const tsPenality = Number.isNaN(Number(pSQR?.tsPenality)) ? 1 : Number(pSQR?.tsPenality);
  //     const tsActual = Number.isNaN(Number(pSQR?.tsActual)) ? 1 : Number(pSQR?.tsActual);
  //     const tsTypical = Number.isNaN(Number(pSQR?.tsTypical)) ? 1 : Number(pSQR?.tsTypical);
  //     let tsPriceAdjustment: any = 0;
  //     if (tsTypical < tsActual) {
  //       //Penalty *(Actual TS - Typical)/0.1
  //       tsPriceAdjustment = (-(tsPenality * (tsActual - tsTypical) / 0.1)).toFixed(2);
  //     }

  //     const ashPenality = Number.isNaN(Number(pSQR?.ashPenality)) ? 1 : Number(pSQR?.ashPenality);
  //     const ashActual = Number.isNaN(Number(pSQR?.ashActual)) ? 1 : Number(pSQR?.ashActual);
  //     const ashTypical = Number.isNaN(Number(pSQR?.ashTypical)) ? 1 : Number(pSQR?.ashTypical);
  //     let ashPriceAdjustment: any = 0;
  //     if (ashTypical < ashActual) {
  //       //Penalty *(Actual ASH - Typical)/0.1
  //       ashPriceAdjustment = (-(ashPenality * ((ashActual - ashTypical) / 1))).toFixed(2);
  //     }


  //     const obj = {
  //       gcvRejection: null,
  //       ashRejection: null,
  //       tsRejection: null,
  //       gcvAdjustment,
  //       ashPriceAdjustment,
  //       tsPriceAdjustment,
  //     };
  //     setInitialValues(obj);
  //     formRef.setFieldsValue(obj);
  //   } else {
  //     const obj = {
  //       gcvRejection: null,
  //       ashRejection: null,
  //       tsRejection: null,
  //       gcvAdjustment: 0,
  //       ashPriceAdjustment: 0,
  //       tsPriceAdjustment: 0,
  //     };
  //     setInitialValues(obj);
  //     formRef.setFieldsValue(obj);
  //   }
  // }

  const calculateDefaultBonusOrPenalty = (data: PurchaseSupplierReportDto[]) => {
    if (!data?.length || !selectedRowData) return;

    const getAverage = (key: keyof PurchaseSupplierReportDto) => {
      const validNumbers = data
        ?.map((item) => Number(item[key] ?? 0)) // Ensure conversion to number
        .filter((num) => !Number.isNaN(num));

      return validNumbers.length ? validNumbers.reduce((sum, num) => sum + num, 0) / validNumbers.length : 1;
    };

    if (selectedRowData.purchasingTerms === ContractTermEnum.SPEC) {
      const bonusCap = getAverage('cvBonusCap');
      const gcvActual = getAverage('cvActual');
      const gcvTypical = getAverage('cvTypical') || 1; // Prevent division by zero
      const minOfActualToBonusCap = bonusCap ? Math.min(bonusCap, gcvActual) : gcvActual;

      // Ensure valid values before calculation
      const coalBasePrice = Number(selectedRowData.coalBasePrice ?? 0);
      const gcvAdjustmentPrice = coalBasePrice * (minOfActualToBonusCap / gcvTypical);

      console.log(`${coalBasePrice} * (${minOfActualToBonusCap} / ${gcvTypical})`);

      const gcvAdjustment = isNaN(gcvAdjustmentPrice - coalBasePrice)
        ? "0.000"
        : (gcvAdjustmentPrice - coalBasePrice).toFixed(3);

      const tsPenality = getAverage('tsPenality');
      const tsActual = getAverage('tsActual');
      const tsTypical = getAverage('tsTypical');
      let tsPriceAdjustment: any = 0;

      if (tsTypical < tsActual) {
        tsPriceAdjustment = isNaN(-tsPenality * ((tsActual - tsTypical) / 0.1))
          ? "0.000"
          : (-tsPenality * ((tsActual - tsTypical) / 0.1)).toFixed(3);
      }

      const ashPenality = getAverage('ashPenality');
      const ashActual = getAverage('ashActual');
      const ashTypical = getAverage('ashTypical');
      let ashPriceAdjustment: any = 0;

      if (ashTypical < ashActual) {
        ashPriceAdjustment = isNaN(-ashPenality * ((ashActual - ashTypical) / 1))
          ? "0.000"
          : (-ashPenality * ((ashActual - ashTypical) / 1)).toFixed(3);
      }

      const obj = {
        gcvRejection: null,
        ashRejection: null,
        tsRejection: null,
        gcvAdjustment,
        ashPriceAdjustment,
        tsPriceAdjustment,
      };

      setInitialValues(obj);
      formRef.setFieldsValue(obj);
    } else {
      const obj = {
        gcvRejection: null,
        ashRejection: null,
        tsRejection: null,
        gcvAdjustment: "0.000",
        ashPriceAdjustment: "0.000",
        tsPriceAdjustment: "0.000",
      };

      setInitialValues(obj);
      formRef.setFieldsValue(obj);
    }
  };


  const getPurchaseSamplingSupplier = () => {
    const req = new BargeIdRequestDto(selectedRowData?.bgUId, authContext.defaultPlant);
    qualityService.getPurchaseSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        if (refreshAdjustment) calculateDefaultBonusOrPenalty(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const onChangePortType = (event) => {
    getScqTypicalSpecs(true)
  }

  return (
    <div>
      {/* {bargeGridData.length !== 0 || contractType === ContractModesEnum.SALES && */}

      <Form form={formRef} initialValues={initialValues} autoComplete='off'>
        {/* {commercialProp === 'Commercial' &&
          <>
            <Card title={'Price Adjustment'} className='default-card-class'>
              <Table columns={columns} pagination={false} className='contracts' dataSource={[selectedRowData]}>
              </Table>
            </Card>
            <br />
          </>
        } */}


        {contractType === ContractModesEnum.SALES &&

          <Form.Item name={"portType"} label={`Port Type :`}
            rules={[{ required: false, message: "Port Type is Required" }]}>
            <Radio.Group
              onChange={onChangePortType}
            >
              <Radio value={PortTypeEnum.LOADING_PORT}>{PortTypeEnum.LOADING_PORT}</Radio>
              <Radio value={PortTypeEnum.DISCHARGE_PORT}>{PortTypeEnum.DISCHARGE_PORT}</Radio>
              <Radio value={PortTypeEnum.AVERAGE_INDEX}>{PortTypeEnum.AVERAGE_INDEX}</Radio>
            </Radio.Group>
          </Form.Item>
        }


        <Table
          className='contracts-list'
          columns={tableColumns}
          dataSource={processTableData(bargeGridData[0], bargeGridData[1])}
          pagination={false}
          bordered={false}
          size='small'
          scroll={{ x: 1000 }}
        />

        <br></br>

        {commercialProp === 'Commercial' &&
          <Flex align='end' justify='flex-end' vertical>
            <Form.Item label={<b>Total Penalty / Bonus</b>} name='totalBonusPrice'>
              <Input disabled={true} >
              </Input>
            </Form.Item>
            <Form.Item label={<b>Adjusted Price</b>} name={'qualityAdjustPrice'}>
              <Input disabled={true}>
              </Input>
            </Form.Item>

          </Flex>
        }

        {/* 
        <Row justify='space-around'>
          <Col>
            <Button onClick={() => { cancelHandler(); }}>Cancel</Button>
          </Col>
          <Col>
            <Form.Item >
              <Button onClick={() => { submitButtonHandler(); }} type="primary">Submit</Button>
            </Form.Item>

          </Col>
        </Row> */}



      </Form>
      {/* } */}
    </div >
  )
}

export default QualityPriceAdjustments;