import { BargeCostIdReqDto, BargeCostingDetailsDto, BargeCostingIdReqDto, BargeCostingsService, BPFCostingParentDto, BPFCostingRequestIdDto, CmApprovalsReqDto, CommercialCoalCostingService, ContractModesEnum, ContractStatusEnum, CostingWorkLogsEnum, PaymentTypeEnum, PerformaCoalCostingService, PerFormaReqIdDto, VendorAdvService, VendorAgainstBpId } from '@exportx/shared-models-and-services';
import { getLocalFormat, SequenceUtils } from '@exportx/ui-utils';
import { Button, Col, Flex, Form, Input, InputNumber, List, Row, Space, Table, Timeline, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Card from 'antd/lib/card/Card';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../../common';
import { PaymentNoticePageEnum } from '../../../payment-notice';
import { InvoiceDetails } from '../../../costing-detail-view';
import { AccountPayableCostings } from '../../../costing-detail-view';

import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IBargeCostingChild {
  costingInfo: BPFCostingParentDto;
  type: ContractStatusEnum;
  getBargeCostingParentData: () => void;
  children?: React.ReactNode;
  costingType?: CostingWorkLogsEnum;
  contractType: ContractModesEnum;
  paymentType?: PaymentTypeEnum;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BargeCostingChild = (props: IBargeCostingChild) => {
  const [formRef] = Form.useForm();
  const fileUploadPath = window[`_env_`]['APP_MDM_SERVICE_URL'];
  const { type, costingInfo, getBargeCostingParentData, costingType, contractType, paymentType, setDummyRefresh } = props;
  const { authContext } = useAuthState();
  const [formValues, setFormValues] = useState<BargeCostingDetailsDto>();
  const [initialValues, setInitialValues] = useState({ amountAdjustMent: [], balance: formValues?.totalAmount })
  const [accessId, setAccessId] = useState<any>({});
  const service = new BargeCostingsService();
  const vendorAdvService = new VendorAdvService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();

  let status = ContractStatusEnum.PENDING_AUDIT_APPROVAL;
  if (type === ContractStatusEnum?.BUSINESS_APPROVAL || type === ContractStatusEnum?.FREEZED) {
    status = ContractStatusEnum.PENDING_AUDIT_APPROVAL
  }
  else if (type === ContractStatusEnum?.AUDIT_APPROVAL && costingType === CostingWorkLogsEnum?.COMMERCIAL_PC_COSTING) {
    status = ContractStatusEnum.PENDING_MANGEMENT_APPROVAL
  }
  else status = ContractStatusEnum.ACTIVE


  useEffect(() => {
      if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING) {
        setAccessId({ costingApprove : 275, costingReject: 274, costingInvoiceApprove: 283, costingInvoiceReject: 284 });
      } else if (costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING) {
        setAccessId({ costingApprove: 317, costingReject: 316, costingInvoiceApprove: 325, costingInvoiceReject: 326 });
      } else if (costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING) {
        setAccessId({ costingApprove : 296, costingReject: 295, costingInvoiceApprove: 304, costingInvoiceReject: 305 });
      } else if (costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING) {
        setAccessId({ costingApprove: 338, costingReject: 337, costingInvoiceApprove: 346, costingInvoiceReject: 347 });
      } else if (costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
        setAccessId({ costingApprove: 1, costingReject: 1, costingInvoiceApprove: 1, costingInvoiceReject: 1 });
      } else if (costingType === CostingWorkLogsEnum.MARKETING_FEES) {
        setAccessId({ costingApprove: 379, costingReject: 379, costingInvoiceApprove: 387, costingInvoiceReject: 388 });
      } else if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
        setAccessId({ costingApprove: 400, costingReject: 399, costingInvoiceApprove: 408, costingInvoiceReject: 409 });
      } else {
        setAccessId({ costingApprove: 359, costingReject: 358, costingInvoiceApprove: 367, costingInvoiceReject: 368 });
      }
    }, [costingType]);

  useEffect(() => {
    getAllCostingsData();
  }, [costingType]);

  useEffect(() => {
    formRef.setFieldsValue(initialValues);
  }, [initialValues]);

  const getAllCostingsData = () => {
    if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
      bgCostingData();
    } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING || costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
      getPerformaDataByCostingId();
    } else {
      getCommercialDataByCostingId();
    }

  }


  const bgCostingData = () => {
    service.getBargeCostingDataByCostingId(new BargeCostingIdReqDto(costingInfo?.costingId, authContext.defaultPlant)).then(res => {
      if (res.status) {
        setFormValues(res.data);

        const vReq = new VendorAgainstBpId(res.data.bargeBpId, authContext.defaultPlant);
        vendorData(vReq, res.data.totalAmount);
      } else {
        setFormValues(null);
      }
    }).catch(err => {
      console.log(err)
      setFormValues(null);
    });
  }

  const vendorData = (req, totalAmount) => {
    vendorAdvService.getVendorAdvanceAgainstBpId(req).then(res => {
      if (res.status) {
        setInitialValues(prev => {
          return {
            ...prev,
            amountAdjustMent: res.data,
            balance: totalAmount
          }
        });
      } else {
        setInitialValues(prev => {
          return {
            ...prev,
            amountAdjustMent: []
          }
        });
      }
    }).catch(err => {
      setInitialValues(prev => {
        return {
          ...prev,
          amountAdjustMent: []
        }
      });
    })
  }


  const getPerformaDataByCostingId = () => {
    const type = costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING ? ContractModesEnum.FREIGHT_COSTING : contractType
    const req = new BargeCostIdReqDto(costingInfo?.costingId, authContext.defaultPlant, type);
    perFormaService.getPerformaDataByCostingId(req).then(res => {
      if (res.status) {
        setFormValues(res.data);
      }
    }).catch(err => {
      console.log(err)
      setFormValues(null);
    });
  }

  const getCommercialDataByCostingId = () => {
    const req = new PerFormaReqIdDto(costingInfo?.costingId, contractType, authContext.defaultPlant, costingType)
    commercialService.getCommercialDataByCostingId(req).then(res => {
      if (res.status) {
        setFormValues(res.data);
      }
    }).catch(err => console.log(err.message))
  }

  const approvalHandler = () => {
    if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
      bpfCostingStatusApproval();
    } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING || costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
      perFormaApprovals();
    } else {
      commercialApproval();
    };
  };


  const rejectedHandler = (status: ContractStatusEnum) => {
    if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
      bpfStatusRejected(status);
    } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING) {
      performaApprovalOperations(status);
    } else {
      commercialApprovalOperations(status);
    };
  };


  const bpfCostingStatusApproval = () => {
    formRef.validateFields().then(values => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(costingInfo?.costingId, values['remarks'], status, authContext.user.userName, authContext.defaultPlant, type);
      service.bpfCostingStatusApproval(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getBargeCostingParentData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err)
      });
    })
  };

  const commercialApproval = () => {
    formRef.validateFields().then(values => {
      const req = new CmApprovalsReqDto(costingInfo?.costingId, authContext.defaultPlant, authContext.user.userName, status, costingType, contractType, values.remarks, paymentType, type);
      commercialService.commercialApprovals(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(true)
          getAllCostingsData();
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      });
    })

  }

  const perFormaApprovals = () => {
    formRef.validateFields().then(values => {
      const conType = costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING ? ContractModesEnum.FREIGHT_COSTING : contractType
      const req = new CmApprovalsReqDto(costingInfo?.costingId, authContext.defaultPlant, authContext.user.userName, status, costingType, conType, values.remarks, paymentType, type);
      perFormaService.performaApproval(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(true)
          getAllCostingsData();
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      })
    })

  }





  const bpfStatusRejected = (status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(costingInfo?.costingId, values['remarks'], status, authContext.user.userName, authContext.defaultPlant, type)
      service.bpfStatusRejected(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getBargeCostingParentData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err)
      })
    })

  }
  const performaApprovalOperations = (status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req = new CmApprovalsReqDto(costingInfo?.costingId, authContext.defaultPlant, authContext.user.userName, status, costingType, contractType, values.remarks);
      perFormaService.performaApprovalOperations(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(true)
          getAllCostingsData();
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      })
    })

  }

  const commercialApprovalOperations = (status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req = new CmApprovalsReqDto(costingInfo?.costingId, authContext.defaultPlant, authContext.user.userName, status, costingType, contractType, values.remarks);
      commercialService.commercialApprovalOperations(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(true)
          getAllCostingsData();
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      });
    })

  }

  const submit = () => {

    formRef.validateFields().then(values => {
      service.createBPFAdjustment({ ...values, plantCode: authContext.defaultPlant, userName: authContext.user.userName, bpfBargeAdjustmentId: costingInfo?.costingId }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage)
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err.message)
      })
    })
  }

  const amountAdjustMentColumns: ColumnsType<any> = [
    {
      title: "Advance Ref",
      dataIndex: "contractNo",
      render: (value, record, index) => {
        return <>
          <Form.Item style={{ display: 'none' }} name={[index, 'contractNo']} >
            <Input hidden ></Input>
          </Form.Item>
          {value}
        </>
      }
    },
    {
      title: "Date",
      dataIndex: "contractDate",
      render: (value, row, index) => {
        return (
          <>
            <Form.Item style={{ display: 'none' }} name={[index, 'contractDate']}>
              <Input hidden ></Input>
            </Form.Item>
            {moment(value).format('YYYY-MM-DD')}
          </>
        );
      }
    },
    {
      title: "Type",
      dataIndex: "contractType",
      render: (value, record, index) => {
        return <>
          <Form.Item style={{ display: 'none' }} name={[index, 'contractType']}>
            <Input hidden ></Input>
          </Form.Item>
          {value}
        </>
      }
    },
    {
      title: "Advance Paid",
      dataIndex: "advanceAmount",
      align: 'right',
      render: (value, record, index) => {
        return <>
          <Form.Item style={{ display: 'none' }} name={[index, 'advanceAmount']}>
            <Input hidden ></Input>
          </Form.Item>
          {value}
        </>
      }
    },
    {
      title: "Recovered",
      dataIndex: "recoveredAmount",
      align: 'right',
      render: (value, record, index) => {
        return <>
          <Form.Item style={{ display: 'none' }} name={[index, 'recoveredAmount']} >
            <Input hidden ></Input>
          </Form.Item>
          {value}
        </>
      }
    },
    {
      title: "Balance",
      dataIndex: "balance",
      align: 'right',
      render: (value, record, index) => {
        return <>
          <Form.Item style={{ display: 'none' }} name={[index, 'balance']}></Form.Item>
          <Input hidden ></Input>
          {value}
        </>
      }
    },
    {
      title: "Amount to Adjusted",
      dataIndex: "adjustmentAmount",
      align: 'right',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "adjustmentAmount"]}>
              <InputNumber
                onChange={valeChangeHandler}
                min={0}
                max={Number(row.balance)}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => Number(value.replace(/[^0-9]/g, ""))}
                disabled={Number(row.balance) < 1}
              />
            </Form.Item>
          </>
        );
      }

    },


  ]

  const columns: any = [
    {
      title: 'Barge No',
      dataIndex: 'bargeNo',
      render: (value, row, index) => {
        return (
          <>
            {SequenceUtils.formatNumberToSpecificLength(formValues?.bargeCostings[index]?.['bargeNo'])}
          </>
        );
      }
    },
    {
      title: 'Inward Date',
      dataIndex: 'inWardDate',
      render: (value, row, index) => {
        return (
          <>
            {moment(formValues?.bargeCostings[index]?.inWardDate).format('YYYY-MM-DD')}
          </>
        );
      }
    },
    {
      title: 'Inward Qty',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      render: (text, record, index) => {
        const rowSpan = index === 0 ? formValues?.bargeCostings.length : 0; // Example: Merge first two rows
        return {
          children: <span>{text}</span>,
          props: {
            rowSpan: rowSpan,
          },
        };
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'right',
      render: (value, row, index) => {
        const rowSpan = index === 0 ? formValues?.bargeCostings.length : 0; // Example: Merge first two rows
        return {
          children: <span>{value}</span>,
          props: {
            rowSpan: rowSpan,
          },
        };
      }
    },
  ]

  const valeChangeHandler = () => {
    const amountAdjustMent = formRef.getFieldValue('amountAdjustMent');
    let adjustmentsTotal = 0;
    amountAdjustMent.forEach(rec => {
      adjustmentsTotal += Number(rec.adjustmentAmount) ? Number(rec.adjustmentAmount) : 0;
    })
    const amountPaidToBargeSupplier = formRef.getFieldValue('amountPaidToBargeSupplier') ? formRef.getFieldValue('amountPaidToBargeSupplier') : 0;
    const balance = formValues.totalAmount - adjustmentsTotal - Number(amountPaidToBargeSupplier);
    setInitialValues(prev => {
      return {
        ...prev,
        ...formRef.getFieldsValue(),
        balance
      }
    })
    formRef.setFieldValue('balance', balance);
  }

  const timeLines = [];
  formValues?.workLog?.forEach((rec) => {
    timeLines.push({
      color: 'green',
      children: (
        <>
          <Typography style={{ fontSize: 10 }}> {rec?.approvalLevel ?? rec?.actionType} By <b>{rec?.createdUser}</b>  On <b>{rec?.createdAt ? moment(rec?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
          <pre>{rec.workLog}</pre>
        </>
      )

    });
  });


  return (
    <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }} >
      <AccountPayableCostings
        costingId={costingInfo?.costingId}
        costingType={costingType}
      >


        <Card
          bordered={false}
          className="card-shadow mb-2"
          styles={{ body: { padding: '0px 10px 15px' } }}
        >

          <Form
            form={formRef}
            initialValues={initialValues}
            layout='vertical'
            autoComplete='off'
          >
            <Row className='pt-2'>
              <Col span={16}>
                <Form.Item style={{ width: '100%' }} name='remarks' label="Remarks"
                  rules={[
                    {
                      required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                    }
                  ]}>
                  <Input.TextArea style={{ width: '100%' }} placeholder='Notes' onChange={() => { }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {formValues?.['filesData']?.length > 0 &&
                <Col span={16}>
                  <a href={fileUploadPath + '/' + formValues?.['filesData']?.[0]?.filePath?.slice(7)} target="_blank" download={`Invoice_${formValues?.costingNo}`}>Download Invoice</a>
                </Col>
              }
            </Row>
            <br />
            <Row>
              <Col span={16}>
                <Timeline
                  items={timeLines}
                />

              </Col>
            </Row>
            <Flex align='middle' justify='flex-end'>
              <Space size={10}>
                { isPermissionExist([accessId?.costingApprove]) && (formValues?.approvalStatus === ContractStatusEnum.PENDING_APPROVAL || formValues?.approvalStatus === ContractStatusEnum.PENDING_MANGEMENT_APPROVAL) &&
                  <Button className='approve' size='small'
                    onClick={() => { approvalHandler() }} type="primary">
                    <FontAwesomeIcon icon={faCheckCircle} /> Approve
                  </Button>}

                {(formValues?.approvalStatus === ContractStatusEnum.FREEZED) &&
                  <Button size='small' className='new-button' onClick={() => { rejectedHandler(ContractStatusEnum.PENDING_AUDIT_APPROVAL) }}> <FontAwesomeIcon icon={faCheckCircle} />Send For Audit Approval</Button>
                }
                { isPermissionExist([accessId?.costingReject]) && !(formValues?.invoices?.some(rec => rec.releaseStatus === true)) &&
                  <Button size='small' className='reject' onClick={() => { rejectedHandler(ContractStatusEnum.CANCELLED) }}> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>

                }
              </Space>


            </Flex>
            {/* <Row className='pt-2' justify='space-between'>
                            <Col>
                                {(formValues?.approvalStatus === ContractStatusEnum.PENDING_APPROVAL || formValues?.approvalStatus === ContractStatusEnum.PENDING_MANGEMENT_APPROVAL) &&
                                    <Button className='approve' size='small'
                                        onClick={() => { approvalHandler() }} type="primary">
                                        <FontAwesomeIcon icon={faCheckCircle} /> Approve
                                    </Button>}&nbsp;&nbsp;&nbsp;
                                <Button size='small' className='reject' onClick={() => { rejectedHandler() }}> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                            </Col>
                        </Row> */}
            {
              type === ContractStatusEnum.PMT_NOTICE && <>
                <div><b>Amount to Adjusted against Advances</b></div>
                <br />
                <Form.List name="amountAdjustMent">
                  {(adjustmentData, { add, remove }) => {
                    return <Table columns={amountAdjustMentColumns} pagination={false}
                      bordered={true} dataSource={initialValues.amountAdjustMent} />
                  }}
                </Form.List>
                <table style={{ width: '80%' }}>
                  <tr>
                    <td>
                      Amount to be paid to Barge Supplier
                    </td>
                    <td>
                      <Form.Item name='amountPaidToBargeSupplier'
                        rules={[
                          {
                            required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                          }
                        ]}
                      >
                        <InputNumber
                          onChange={valeChangeHandler}
                          min={Number(0)}
                          //clint requirement he doesn't need this condition
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                        // parser={(value) => Number(value.replace(/[^0-9]/g, ""))}
                        />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Balance
                    </td>
                    <td>
                      {getLocalFormat(Number(initialValues?.balance), formValues?.billingCurrency)}
                      <Form.Item style={{ display: 'none' }} name={'balance'}>
                        <Input hidden ></Input>
                      </Form.Item>
                    </td>
                  </tr>
                </table>
                <Button type='primary' style={{ float: 'right' }} onClick={submit} disabled={Number(initialValues?.balance) < 0}>Submit</Button>
              </>
            }
          </Form>
        </Card>
        <InvoiceDetails
          costingId={costingInfo?.costingId}
          includeFreight={costingInfo?.includeFreight}
          invoiceStatus={ContractStatusEnum.PENDING_APPROVAL}
          status={status}
          level={type}
          invApproveDisabled={costingInfo?.costing_status === ContractStatusEnum.PENDING_APPROVAL}
          setDummyRefresh={setDummyRefresh}
          accessId={accessId}
        />
      </AccountPayableCostings>


      {props?.children}


    </div >
  )
}

export default BargeCostingChild;