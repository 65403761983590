import { BusinessPartnerService, PurchaseContractService, ReportsService } from "@exportx/shared-models-and-services";
import { Card, Col, DatePicker, Row, Select, Table, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../common";
import { internationalFormattedValue } from "@exportx/ui-utils";
import moment from "moment";
import { DownCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

interface PoHeaderProps {
  supplier: any,
  pcContract: any
}

export const PoHeaderReport = (props: PoHeaderProps) => {
  const { Option } = Select;
  const { supplier, pcContract } = props
  const { authContext } = useAuthState()
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [selectedContract, setSelectedContract] = useState<string>('')
  const [selectedSupplier, setSelectedSupplier] = useState<any[]>([])
  const [selectedYear, setSelectedYear] = useState<string>('')
  const [poHeaderReport, setPoHeadeeReport] = useState<any[]>([])
  const [expandedIndex, setExpandedIndex] = useState([]);

  const reportService = new ReportsService();

  useEffect(() => {
    getPoReport();
  }, [
    currentPage,
    pageSize,
    selectedSupplier,
    selectedContract,
    selectedYear
  ]);

  const getPoReport = () => {
      const req = { 
        offset: (currentPage - 1) * pageSize, 
        limit: pageSize, 
        plantCode: authContext.defaultPlant,
        contract: selectedContract,
        supplierId: selectedSupplier,
        year: selectedYear
      }
      reportService.getPoHeaderReport(req).then(res => {
        if(res.status){
          setPoHeadeeReport(res.data?.pcData)
          setTotal(res.data?.total)
        } else {
          AlertMessages.getSuccessMessage(res.internalMessage)
          setPoHeadeeReport([])
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message)
      })
    }

  const handleTableChange = (pagination: TablePaginationConfig) => {
      setCurrentPage(pagination.current || 1);
      setPageSize(pagination.pageSize || 25);
    };
  
  const handleContractChange = (value: string) => {
    setSelectedContract(value);
    setCurrentPage(1);
  };

  const handleSupplierChange = (value: any) => {
    setSelectedSupplier([...value]);
    setCurrentPage(1);
  };

  const handleYearChange = (date, dateString) => {
    setSelectedYear(dateString)
  };

  const procurementData = (qualityData: any) => {
    let pcData: any[] = [];
  
    qualityData?.forEach((qualitiesRecords) => {
      const { pcId, layCan, quality, quantity, qualitySpecifications, purchaseTypeData } = qualitiesRecords;
  
      purchaseTypeData?.forEach((pcTypeRecords, index) => {
        pcData.push({
          pcId,
          laycan: layCan,
          quality,
          quantity,
          qualitySpecifications,
          rowSpan: index === 0 ? purchaseTypeData.length : 0,
          incoterm: pcTypeRecords.purchaseType,
        });
      });
    });
  
    return pcData;
  };
  


  const renderItems = (record: any, index, indent, expanded) => {
    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: 10 }}>
        <Card 
          styles={{ 
            header: { 
              minHeight: '10px', 
              paddingTop: '10px', 
            }, 
            body: { 
              padding: '10px', 
            } 
          }}
          title="Laycan Details"
          >
          <Table
            className="contracts-list"
            columns={columns2}
            dataSource={procurementData(record.QualityData)}
            pagination={false}
          />
        </Card>
      </div>
    );
  };

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.contractNo);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const columns: any = [
    {
      title: 'PO Order',
      dataIndex: 'contractNo',
      render: (value, record) => {
        const link = `/#/po-detail-view?pc_id=${record.pcId}`;
        return <a href={link} className="link">{value}</a>;
      },
    },
    {
      title: 'Supplier Name',
      dataIndex: 'bpName',
    },
    {
      title: 'Total Quantity',
      dataIndex: 'totalQty',
      align: 'right',
      render: (value) => {
        return <span>{internationalFormattedValue(value, 3)}</span>;
      },
    },
    {
      title: "Delivered Quantity",
      dataIndex: 'totalDelivered',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>;
      },
    },
    {
      title: "Remaining Quantity",
      dataIndex: '',
      align: 'right',
      render: (value, record) => {
        let balance = Number(record.totalQty) - Number(record.totalDelivered)
        return <span>{internationalFormattedValue(balance, 3)}</span>;
      },
    },
  ]

  const columns2: any = [
    {
      title: 'Laycan',
      dataIndex: 'laycan',
      onCell: (record) => ({
        rowSpan: record.rowSpan,
      }),
      render: (value, record) => {
        let [fromDate, toDate] = value?.split(',');
        return (
          <span>{`${moment(fromDate).format('DD-MMM-YYYY')} to ${moment(toDate).format('DD-MMM-YYYY')}`}</span>
        );
      },
    },
    {
      title: 'Quality',
      dataIndex: 'quality',
      onCell: (record) => ({
        rowSpan: record.rowSpan,
      }),
      render: (value, record) => {
        return (
          <>
            <span>{value}</span>
            <span className="currency-style">{`${record.qualitySpecifications ?? ''}`}</span>
          </>
        );
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
      onCell: (record) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: <span style={{ paddingLeft: '20px' }}>Incoterm</span>,
      dataIndex: 'incoterm',
      render: (value, record) => {
        return (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{value}</span>
          </>
        );
      }
    },
  ]

  return (<>
        <Row gutter={[10, 10]} style={{ marginBottom: 16 }}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Select
                placeholder= "Filter By Contract"
                style={{ width: "100%" }}
                onChange={(value) => handleContractChange(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={selectedContract || null}
                allowClear
                showSearch
              >
                {pcContract?.map((rec) => {
                  return (
                    <Option value={rec.id}>{rec.contractNo}</Option>
                  );
                })}
              </Select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Select
                mode="multiple"
                placeholder= "Filter By Supplier"
                style={{ width: "100%" }}
                onChange={(value) => handleSupplierChange(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={selectedSupplier || null}
                allowClear
                showSearch
              >
                {supplier?.map((rec) => {
                  return (
                    <Option value={rec.bpId}>{rec.bpName}</Option>
                  );
                })}
            </Select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <DatePicker 
              onChange={handleYearChange} 
              picker="year"
              disabledDate={(current) => current.year() < 2018}
            />
          </Col>
        </Row>
        <Table
          className="contracts-list"
          columns={columns}
          rowKey={(record) => record.contractNo}
          dataSource={poHeaderReport}
          scroll={{ x: 'max-content' }}
          pagination={{
            current: currentPage,
            pageSize: pageSize, 
            total: total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
                Expand
              </RightCircleOutlined>
            )
          }
          onChange={handleTableChange}
        />
  </>)
}

export default PoHeaderReport;