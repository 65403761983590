import { ContractStatusEnum, ExpenseEntryApprovalDto, ExpenseEntryService, GetExpApprovalDataDto, ReferenceFeatures } from "@exportx/shared-models-and-services"
import { Button, Card, Col, Drawer, Flex, Form, Input, List, Popconfirm, Row, Space, Table, TableColumnsType, Tooltip, Timeline, Typography } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { AlertMessages, isPermissionExist, useAuthState } from "../../../../common"
import { PaymentNoticePageEnum } from "../../payment-notice"
import { DownCircleOutlined, MessageFilled, RightCircleOutlined } from "@ant-design/icons"
import { ExpenseEntryCostingDetails, InvoiceDetails } from "../../costing-detail-view/"
import { faCheckCircle, faCircleXmark, faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { internationalFormattedValue } from "@exportx/ui-utils"
import CommentsPage from "../../../../common/comments/comments-page"

interface IProps {
  type?: PaymentNoticeApprovalScreen;
  paymentType?: PaymentNoticePageEnum;
  level?: PaymentNoticeApprovalScreen;
}

export enum PaymentNoticeApprovalScreen {
  BUSINESS_APPROVAL = 'Business Approval',
  AUDIT_APPROVAL = 'Audit Approval',
  FREEZED = 'Freezed',
}

const PaymentNoticeApproval = (props: IProps) => {
  const { type, paymentType } = props;
  const [approval, setApproval] = useState([]);
  const { authContext } = useAuthState();
  const [searchedText, setSearchedText] = useState('');
  const expenseEntryService = new ExpenseEntryService();
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [expenseEntryId, setExpenseEntryId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');

  useEffect(() => {
    getApprovalData();
  }, [dummyRefresh]);
  const [formRef] = Form.useForm();

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData(ReferenceFeatures.EXPENSE_ENTRY, 'All Comments');
    setExpenseEntryId(record.expenseEntryId);
  }

  const tableColumns: TableColumnsType<any> = [
    {
      title: 'Costing No',
      dataIndex: 'expenseEntryNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const aaa = new Set(
          Object.keys(record).map((key) => {
            return String(record[key])
              .toLowerCase()
              .includes(value.toLocaleString());
          }),
        );
        if (aaa.size && aaa.has(true)) return true;
        else return false;
      },
      render: (value, record) => {
        const viewPath = paymentType === PaymentNoticePageEnum.RECEIVABLES ? 'other-income-expense-entry-detail-view' : 'expense-entry-detail-view';
        return (
          <>
            <a
              href={`/#/${viewPath}?costing_id=${record.expenseEntryId}`}
              className="link"
            >
              {value}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {record.priority === 'HIGH' ? <FontAwesomeIcon icon={faFlag} style={{ color: "red" }} /> : null}
          </>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'expenseEntryDate',
      sorter: (a, b) =>
        moment(a.expenseEntryDate).unix() - moment(b.expenseEntryDate).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (value) => moment(value).format('DD-MMM-YYYY'),
    },
    {
      title: 'Aging',
      dataIndex: 'expenseEntryDate',
      sorter: (a, b) =>
        Math.floor(
          (new Date(moment(a.etd).format('DD-MMM-YYYY')).getTime() -
            new Date().getTime()) /
          (1000 * 60 * 60 * 24),
        ) -
        Math.floor(
          (new Date(b.etd).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24),
        ),
      // sorter: (a, b) => a.aging.localeCompare(b.aging),
      sortDirections: ['descend', 'ascend'],
      // ...getColumnSearchProps('aging'),
      render: (text, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {Math.floor(
              (new Date().getTime() -
                new Date(moment(text).format('DD-MMM-YYYY')).getTime()) /
              (1000 * 60 * 60 * 24),
            )}
          </div>
        );
      },
    },
    {
      title: 'Expense type',
      dataIndex: 'expenseType',
    },

    {
      title: 'Amount To Be Paid',
      dataIndex: 'total',
      align: 'right',
      render: (value, record) => {
        return <>
          <Typography.Text>{internationalFormattedValue(value, 3)}</Typography.Text>
          <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
        </>

      }
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const ledgerPermissions = {
    'Chemical Spray': { permission: 417, costingApprove: 421, costingReject: 420, costingInvoiceApprove: 429, costingInvoiceReject: 430 },
    'Export Tax': { permission: 438, costingApprove: 442, costingReject: 441, costingInvoiceApprove: 450, costingInvoiceReject: 451 },
    'Documentation Charges': { permission: 459, costingApprove: 463, costingReject: 462, costingInvoiceApprove: 471, costingInvoiceReject: 472 },
    'Laporan Charges': { permission: 480, costingApprove: 484, costingReject: 483, costingInvoiceApprove: 492, costingInvoiceReject: 493 },
    'Sampling & Analysis Charges': { permission: 501, costingApprove: 505, costingReject: 504, costingInvoiceApprove: 513, costingInvoiceReject: 514 },
    'Pre Shipment Inspection': { permission: 522, costingApprove: 526, costingReject: 525, costingInvoiceApprove: 534, costingInvoiceReject: 535 },
    'Other Expenses': { permission: 543, costingApprove: 547, costingReject: 546, costingInvoiceApprove: 555, costingInvoiceReject: 556 },
    'Return Cargo Charges': { permission: 564, costingApprove: 568, costingReject: 567, costingInvoiceApprove: 576, costingInvoiceReject: 577 },
    'Floating Crane Charges': { permission: 585, costingApprove: 589, costingReject: 588, costingInvoiceApprove: 597, costingInvoiceReject: 598 },
    'Stevedore Costing': { permission: 606, costingApprove: 610, costingReject: 609, costingInvoiceApprove: 618, costingInvoiceReject: 619 },
    'Freight Costing': { permission: 627, costingApprove: 630, costingReject: 630, costingInvoiceApprove: 638, costingInvoiceReject: 639 },
    'PNBP Charges': { permission: 647, costingApprove: 650, costingReject: 650, costingInvoiceApprove: 658, costingInvoiceReject: 659 },
    'Other Income': { permission: 667, costingApprove: 670, costingReject: 670, costingInvoiceApprove: 678, costingInvoiceReject: 679 },
    'Insurance': { permission: 687, costingApprove: 690, costingReject: 690, costingInvoiceApprove: 698, costingInvoiceReject: 699 },
  };
  
  const hasLedgerPermission = (ledger) => {
    if (ledgerPermissions[ledger.expenseLedger] && isPermissionExist([ledgerPermissions[ledger.expenseLedger].permission])) {
      return { ...ledger, ...ledgerPermissions[ledger.expenseLedger] };
    }
  };

  const getApprovalData = () => {
    const status =
      type === PaymentNoticeApprovalScreen.FREEZED ?
        ContractStatusEnum.FREEZED
        : type === PaymentNoticeApprovalScreen.BUSINESS_APPROVAL
          ? ContractStatusEnum.PENDING_APPROVAL
          : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    const req = new GetExpApprovalDataDto(
      paymentType,
      authContext.defaultPlant,
      status,
    );
    expenseEntryService
      .getExpensesDataForApproval(req)
      .then((res) => {
        if (res.status) {
          const data = res.data?.map(hasLedgerPermission) || [];
          setApproval(data);
          setDummyRefresh(false);
        } else {
          setApproval([]);
        }
      })
      .catch((err) => {
        setApproval([]);
      });
  };

  const approveExpenses = (expenseId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then((values) => {
      const req = new ExpenseEntryApprovalDto();
      req.expenseEntryId = expenseId;
      req.plantCode = authContext.defaultPlant;
      req.status = status;
      req.approvalLevel = type;
      req.userName = authContext.user.userName;
      req.remarks = values['remarks'];
      expenseEntryService
        .approveExpense(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            formRef.resetFields();
            setDummyRefresh(true);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log('err : ', err);
        });
    });
  };

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.expenseEntryId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };


  const renderItems = (record: any, index, indent, expanded) => {
    const timeLines = [];
    record?.workLog?.forEach((rec) => {
      timeLines.push({
        color: 'green',
        children: (
          <>
            <Typography style={{ fontSize: 10 }}> {rec?.actionType ?? rec?.approvalLevel} By <b>{rec?.createdUser}</b>  On <b>{rec?.createdAt ? moment(rec?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
            <pre>{rec.workLog}</pre>
          </>
        )

      });
    });
    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
        <ExpenseEntryCostingDetails
          expenseEntryId={record.expenseEntryId}
          remainingAmount={0}
        >
          <Card
            bordered={false}
            className="card-shadow mb-2"
            styles={{ body: { padding: '0px 10px 15px' } }}
          >
            <Form
              form={formRef}
              // initialValues={initialValues}
              layout="vertical"
              autoComplete="off"
            >
              <Row className="p-2">
                <Form.Item
                  style={{ width: '100%' }}
                  name="remarks"
                  label="Remarks"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Work Log', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Notes" />
                </Form.Item>
              </Row>
              <Row>
                <Col span={16}>
                  <Timeline
                    items={timeLines}
                  />
                </Col>
              </Row>
              <Flex align='middle' justify='flex-end'>
                <Space size={10}>
                  { isPermissionExist([record?.costingApprove]) && (record.costing_status === ContractStatusEnum.PENDING_APPROVAL) &&
                    <Button className='approve' size='small'
                      onClick={() => approveExpenses(record?.expenseEntryId, ContractStatusEnum.ACTIVE)} type="primary">
                      <FontAwesomeIcon icon={faCheckCircle} /> Approve
                    </Button>}

                  {(record.costing_status === ContractStatusEnum.FREEZED) &&
                    <Button size='small' className='new-button' onClick={() => approveExpenses(record?.expenseEntryId, ContractStatusEnum.PENDING_AUDIT_APPROVAL)}> <FontAwesomeIcon icon={faCheckCircle} />Send For Audit Approval</Button>
                  }
                  { isPermissionExist([record?.costingReject]) && !(record?.invoices?.some(rec => rec.releaseStatus === true)) &&
                    <Button size='small' className='reject' onClick={() => approveExpenses(record?.expenseEntryId, ContractStatusEnum.CANCELLED)}> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                  }
                </Space>


              </Flex>

            </Form>
          </Card>
          <InvoiceDetails
            costingId={record.expenseEntryId}
            status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
            level={ContractStatusEnum.BUSINESS_APPROVAL}
            invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
            invApproveDisabled={
              record.costing_status === ContractStatusEnum.PENDING_APPROVAL
            }
            setDummyRefresh={setDummyRefresh}
            accessId={record}
          />
        </ExpenseEntryCostingDetails>
      </div>
    );
  };

  return (
    <>
      <Row className="card-title-buttons mb-3">
        <Input.Search
          placeholder="Search Orders"
          allowClear
          value={searchedText}
          onChange={(e) => setSearchedText(e.target.value)}
          className="input-search"
        />
      </Row>

      <Table
        // size="small"
        rowKey={(rowData) => rowData.expenseEntryId}
        columns={tableColumns}
        dataSource={approval}
        bordered={false}
        className="contracts-list"
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right',
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
              Expand
            </RightCircleOutlined>
          )
        }
      ></Table>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={expenseEntryId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
  );
};

export default PaymentNoticeApproval;
