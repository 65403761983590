import { CommercialGetAllDto, CompanyCodeReq, CompanyTdsService, ContractModesEnum, ContractStatusEnum, ContractTermEnum, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, CurrencyTypesEnum, PriceTypeEnum, TaxDropDownDto, TaxesService, TdsResModel } from '@exportx/shared-models-and-services';
import { getLocalFormat, getNumberFromLocalString, internationalFormattedValue } from '@exportx/ui-utils';
import { Button, Col, Divider, Drawer, Flex, Form, FormInstance, Input, InputNumber, Popover, Row, Select, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PriceIProps {
  formRef: FormInstance<any>;
  bargeSelection: any;
  taxTotal: any;
  setTaxTotal: React.Dispatch<any>;
  initialValues?: CommercialGetAllDto;
  setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
  dummyRefresh: number;
  adjustedData: any[];


}

const ProcurementPriceAdjustment = (props: PriceIProps) => {
  const { formRef, bargeSelection, setTaxTotal, taxTotal, initialValues, setDummyRefresh, dummyRefresh, adjustedData } = props;
  const { Option } = Select;
  const { Text } = Typography;
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);

  const { authContext } = useAuthState();
  const companyTdsService = new CompanyTdsService();
  const taxesService = new TaxesService();
  const currencyConverterService = new CurrencyConverterService();
  const currencyService = new CurrencyService();
  const currency = authContext.defaultPlantCurrency;
  const [exchangeDateVisible, setExchangeDateVisible] = useState<boolean>(true);


  useEffect(() => {
    getTdsData();
    taxActiveDropDown();
    getCurrency();
  }, []);

  useEffect(() => {
    if (initialValues) {

      if (initialValues?.baseCurrency !== authContext.defaultPlantCurrency) {
        exChangeDateHandler();
        visibleDisable(initialValues?.baseCurrency, currency);
      } else {
        visibleDisable(initialValues?.baseCurrency, currency);
      };

    } else {
      visibleDisable(formRef.getFieldValue('baseCurrency'), currency);
    }
  }, [initialValues]);



  const visibleDisable = (bargeSelectionCurrency: string, systemCurrency: string) => {
    if (bargeSelectionCurrency === systemCurrency) {
      setExchangeDateVisible(true);
      formRef.setFieldValue('exchangeDate', undefined);
    } else {
      setExchangeDateVisible(false);
    };
    setDummyRefresh((prev) => prev + 1);

  };

  if (initialValues?.exchangeDate) {
    const ex: any = moment(initialValues.exchangeDate)
    initialValues.exchangeDate = ex;
  }

  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = moment().format('YYYY-MM-DD');
    companyTdsService.getTdsByCompanyCode(req).then(res => {
      if (res.status) {
        setTdsModel(res.data);
      } else {
        setTdsModel([]);
      }
    }).catch(err => {
      console.log(err);
      setTdsModel([]);
    });
  };

  const taxActiveDropDown = () => {
    taxesService.getActiveTaxDropDown().then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      } else {
        setTaxDropDown([])
      }
    }).catch(err => {
      console.log(err.message);
      setTaxDropDown([]);
    })
  };

  const getCurrency = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  };

  const getCurrencyExchangeRate = (fromCurrency, toCurrency, date, currencyType, adjustment) => {
    const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'), currencyType, adjustment);
    if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
      return;
    }
    currencyConverterService.getCurrencyExchangeRate(req).then(res => {
      if (res.status) {
        if (res.data.exchangeRate) {
          formRef.setFieldValue('exchangeRate', res.data.exchangeRate)
          formRef.setFieldValue('displayExchangeRate', res.data.displayExchangeRate)
          setTaxTotal((prev) => {
            return {
              ...prev,
              exchangeRate: Number(res.data.exchangeRate),
              actualExchangeRate: Number(res.data?.actualExchangeRate),
              displayExchangeRate: Number(res.data.displayExchangeRate),
              currencyType: res.data.currencyType,
              purchaseRate: Number(res.data.purchaseRate),
              sellingRate: Number(res.data.sellingRate)
            }
          });

          setDummyRefresh((prev) => prev + 1);
        };
      } else {
        formRef.setFieldValue('exchangeRate', 0)
        formRef.setFieldValue('displayExchangeRate', 0)
        setDummyRefresh((prev) => prev + 1);
      }
    }).catch(err => { console.log(err.message) });
  };

  const exChangeDateHandler = () => {
    const baseCurrency = formRef.getFieldValue('baseCurrency');
    const currencyType = formRef.getFieldValue('currencyType');
    const exchangeDate = formRef.getFieldValue('exchangeDate');
    const exchangeRateAdjustment = formRef.getFieldValue('exchangeRateAdjustment');

    if (currencyType && exchangeDate) {
      console.log('exchangeDate', exchangeDate);
      getCurrencyExchangeRate(baseCurrency, authContext.defaultPlantCurrency, moment(exchangeDate), currencyType, exchangeRateAdjustment);
    }
  };


  const currencyOnChangeHandler = (value) => {
    visibleDisable(value, currency);
    exChangeDateHandler()
  };


  const suffixSelector = (
    <Form.Item name="baseCurrency" noStyle label="Please Select">
      <Select onChange={currencyOnChangeHandler} filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
      >
        {currencies?.map((rec) => {
          return <Option value={rec.currencyCode} key={rec.currencyId}>{rec.currencyCode}</Option>
        })}
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    calculationHandler();
  }, [dummyRefresh]);

  const calculationHandler = () => {
    const finalQty = formRef.getFieldValue('totalBargeQuantity') ? Number(formRef.getFieldValue('totalBargeQuantity')) : 0;
    const finalPrice = formRef.getFieldValue('adjustedPriceValue') ? Number(formRef.getFieldValue('adjustedPriceValue')) : 0;

    let exchangeRate = formRef.getFieldValue('exchangeRate') ? Number(formRef.getFieldValue('exchangeRate')) : 1;
    let displayExchangeRate = formRef.getFieldValue('displayExchangeRate') ? formRef.getFieldValue('displayExchangeRate') : 1;
    const exchangeRateAdjustment = formRef.getFieldValue('exchangeRateAdjustment') ? formRef.getFieldValue('exchangeRateAdjustment') : 0;
    const baseCurrency = formRef.getFieldValue('baseCurrency') ? formRef.getFieldValue('baseCurrency') : currency;
    if (baseCurrency === currency) {
      exchangeRate = 1;
      displayExchangeRate = 1;
      setExchangeDateVisible(true);
    }
    else {
      setExchangeDateVisible(false);
    }
    // Coal Calculation

    const exchgRateAftAdjust = exchangeRate;

    const priceAfterExchgRate = finalPrice * exchgRateAftAdjust;
    const totalCoalPrice = bargeSelection?.price_type === PriceTypeEnum.PER_MT ? finalQty * priceAfterExchgRate : priceAfterExchgRate;

    const amountBeforeTax = totalCoalPrice;
    const tax1 = formRef.getFieldValue('taxId1') ? taxDropDown?.filter((rec) => rec?.taxId === formRef.getFieldValue('taxId1'))[0]?.taxPercentage : 0;
    const tax2 = formRef.getFieldValue('taxId2') ? taxDropDown?.filter((rec) => rec?.taxId === formRef.getFieldValue('taxId2'))[0]?.taxPercentage : 0;
    const tds = formRef.getFieldValue('tdsId') ? tdsModel?.filter((rec) => rec?.companyTdsId === formRef.getFieldValue('tdsId'))[0]?.tdsValue : 0;
    const taxTotal1 = (tax1 / 100) * amountBeforeTax;
    const taxTotal2 = (tax2 / 100) * amountBeforeTax;
    const tdsTotal = (tds / 100) * amountBeforeTax;
    const amountAfterTax = amountBeforeTax + taxTotal1 + taxTotal2 - tdsTotal;
    const advanceAdjustment = adjustedData.reduce((sum, item) => sum + Number(item.totalAdjustedAmount), 0);
    const totalPayable = amountAfterTax - advanceAdjustment;

    if (!formRef.getFieldValue('taxId1')) {
      delete taxTotal.taxId1;
      delete taxTotal.taxPercentage1;
      delete taxTotal.taxTotal1;
    }
    if (!formRef.getFieldValue('taxId2')) {
      delete taxTotal.taxId2;
      delete taxTotal.taxPercentage2;
      delete taxTotal.taxTotal2;
    }
    if (!formRef.getFieldValue('tdsId')) {
      delete taxTotal.tdsId;
      delete taxTotal.tdsPercentage;
      delete taxTotal.tdsTotal;
    }


    formRef.setFieldsValue({
      // Coal

      exchangeRateAdjustment: exchangeRateAdjustment,
      priceAfterExchgRate: priceAfterExchgRate,
      totalCoalPrice: totalCoalPrice,
      amountBeforeTax: amountBeforeTax,
      taxTotal1: taxTotal1,
      taxTotal2: taxTotal2,
      tdsTotal: tdsTotal,
      amountAfterTax: amountAfterTax,
      totalPayable: totalPayable,
    });

    setTaxTotal((prev) => {
      return {
        ...prev,
        totalBargeQuantity: finalQty,
        adjustedPriceValue: finalPrice,
        exchangeRate: exchangeRate,
        displayExchangeRate: displayExchangeRate,
        exchangeRateAdjustment: exchangeRateAdjustment,
        // Coal
        priceAfterExchgRate: priceAfterExchgRate,
        totalCoalPrice: totalCoalPrice,
        amountBeforeTax: amountBeforeTax,
        taxPercentage1: tax1,
        taxPercentage2: tax2,
        tdsPercentage: tds,
        taxTotal1: taxTotal1,
        taxTotal2: taxTotal2,
        tdsTotal: tdsTotal,
        amountAfterTax: amountAfterTax,
        totalPayable: totalPayable,
        advanceAdjustment: advanceAdjustment,
        priceType: bargeSelection?.price_type,
      }
    });
  };

  const onTaxChange1 = (v: string) => {
    setDummyRefresh((prev) => prev + 1);
  };

  const onTaxChange2 = (v: string) => {
    setDummyRefresh((prev) => prev + 1);
  }

  const tdsOnchange = (v: string) => {
    setDummyRefresh((prev) => prev + 1);
  };


  return (
    <>
      <Form
        form={formRef}
        layout='vertical'
        initialValues={initialValues}
      >
        <Row >
          <Form.Item name={'costingId'} hidden>
            <Input></Input>
          </Form.Item>
        </Row>
        <Row gutter={16} >

          <Col span={8}>
            <Form.Item label={"Adjusted Price PMT"} name={'adjustedPriceValue'}>
              <InputNumber
                addonAfter={suffixSelector}
                disabled={true}>
              </InputNumber>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Currency Type"
              name="currencyType"
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                defaultValue={!formRef.getFieldValue('currencyType') && formRef.setFieldValue('currencyType', "JISDOR")}
                placeholder='Select Currency'
                onChange={exChangeDateHandler}
                disabled={exchangeDateVisible}

              >
                {Object.values(CurrencyTypesEnum).map((item) => (
                  <Option value={item} key={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'exchangeDate'} label={"Exchange Date"} >
              <DatePicker onChange={exChangeDateHandler}
                disabled={exchangeDateVisible}
              />
            </Form.Item>
          </Col>
          <Col span={8} >
            <Form.Item label={"Exchange Rate Adjustment"} name={'exchangeRateAdjustment'}>
              <InputNumber
                onChange={exChangeDateHandler}
                disabled={exchangeDateVisible}
              >
              </InputNumber>
            </Form.Item>
          </Col>


        </Row>
        <Row>
          <Col span={24}>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.displayExchangeRate, 3)}  &nbsp;
                {formRef.getFieldValue('currencyType') === 'Middle Rate' && (<Popover
                  content={
                    <>
                      <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{taxTotal?.purchaseRate}</b></Typography>
                      <Typography style={{ fontSize: 10 }}>Selling Rate <b>{taxTotal?.sellingRate}</b></Typography>
                    </>
                  }
                  title="Rates"
                  placement="bottom"
                >
                  <InfoCircleOutlined style={{ color: "#0295D4" }} />

                </Popover>)}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Price After Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.priceAfterExchgRate, 3)} {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Total Coal Price</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.totalCoalPrice, 3)}  {currency}
              </Text>
            </Flex>
            {/* <Flex justify="space-between" align='center'>
              <Text className="price-card-text">FC Charges</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.toalFcCharges, 3)}  {currency}
              </Text>
            </Flex> */}
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(taxTotal?.amountBeforeTax, 3)}  {currency}
              </Text>
            </Flex>
            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TAX1</Text>
              <Text>
                <Form.Item name={"taxId1"}>
                  <Select
                    style={{ width: '160px' }}
                    onChange={(v) => onTaxChange1(v)}
                    placeholder='Select tax'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    <Option value={undefined}>Not Applicable</Option>
                    {taxDropDown?.map((rec) => {
                      return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.taxTotal1, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TAX2</Text>
              <Text>
                <Form.Item name={"taxId2"}>
                  <Select
                    style={{ width: '160px' }}
                    onChange={(v) => onTaxChange2(v)}
                    placeholder='Select tax'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    <Option value={undefined}>Not Applicable</Option>
                    {taxDropDown?.map((rec) => {
                      return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                    })}
                  </Select>
                </Form.Item >
              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.taxTotal2, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text>
                <Form.Item name={"tdsId"}>
                  <Select
                    style={{ width: '160px' }}
                    onChange={(v) => tdsOnchange(v)}
                    placeholder='Select TDS'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    <Option value={undefined}>Not Applicable</Option>
                    {tdsModel?.map((rec) => {
                      return <Option value={rec.companyTdsId} key={rec.companyTdsId}>{`${rec.tdsName} - ${rec.tdsValue}`}</Option>
                    })}
                  </Select>
                </Form.Item >

              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.tdsTotal, 3)}  {currency}
              </Text>
            </Flex>
            <Divider dashed={true} />
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading"> Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(taxTotal?.amountAfterTax, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text"><b>Advance Adjustment</b></Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.advanceAdjustment, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">To be paid to Supplier</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(taxTotal?.totalPayable, 3)}  {currency}
              </Text>
            </Flex>
          </Col>
        </Row >
      </Form >
    </>
  )
}

export default ProcurementPriceAdjustment



