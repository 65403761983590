import { BusinessNoPlantCodeReq, BusinessNumberService, IndexedDataDto, PurchaseDetailsDto, ReportsService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Divider, Drawer, Popover, Row, Select, Table } from "antd";
import { AlertMessages, useAuthState } from "../../../../common";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { DatePicker } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { CoalIndexView } from "../../../masters/components/coal-index-prices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

interface ProcurementProps {
  supplier: any,
  pcContract: any
}

export const ProcurementReport = (props: ProcurementProps) => {
  const { supplier, pcContract } = props
  const { authContext } = useAuthState();
  const { Option } = Select;
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [selectedBusinessNo, setSelectedBusinessNo] = useState<string>('')
  const [selectedContract, setSelectedContract] = useState<string>('')
  const [selectedSupplier, setSelectedSupplier] = useState<string>('')
  const [procurementReport, setProcurementReport] = useState<any[]>([])
  const [activePurchaseId, setActivePurchaseId] = useState(null);
  const [activeIndexedFormData, setActiveIndexedFormData] = useState<IndexedDataDto>(null);
  const [searchText, setSearchedText] = useState<string>('')
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [dateRangeType, setDateRangeType] = useState<string | null>('this_year');
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: moment().startOf('year'),
    to: moment(), 
  });

  const businessService = new BusinessNumberService();
  const reportService = new ReportsService();

  useEffect(() => {
    getBusinessNoDropDown();
  }, []);


  const getAllProcurementData = (currentPage, pageSize) => {
    let fromDate = dateRangeValues.from ? moment(dateRangeValues.from.toDate()).startOf('day').format('YYYY-MM-DD') : null
    let toDate = dateRangeValues.from ? moment(dateRangeValues.to.toDate()).endOf('day').format('YYYY-MM-DD') : null
    if(!selectedBusinessNo && !selectedContract && !selectedSupplier && !fromDate && !toDate){
      return AlertMessages.getWarningMessage('Please select atleast one filter')
    }
    const req = { 
      offset: (currentPage - 1) * pageSize, 
      limit: pageSize, 
      plantCode: authContext.defaultPlant,
      searchTerm: searchText,
      businessNo: selectedBusinessNo,
      contract: selectedContract,
      supplierId: selectedSupplier,
      dateRange: { fromDate: fromDate, toDate: toDate },
    }
    reportService.getProcurementReport(req).then(res => {
      if(res.status){
        setProcurementReport(res.data?.pcData)
        setTotal(res.data?.total)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
        setProcurementReport([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
    businessService.getBusinessNoDropDown(req).then(res => {
      if (res.status) {
        let dropdown = res.data.map(item => {
          let bnNo = item.businessNo.split(" ").pop()
          return {
            ...item,
            bnNo: bnNo
          }
        })
        setBusinessDropDown(dropdown)
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
    getAllProcurementData(pagination.current || 1, pagination.pageSize || 25);
  };

  const handleBusinessNoChange = (value: string) => {
    setSelectedBusinessNo(value);
    setCurrentPage(1);
  };

  const handleContractChange = (value: string) => {
    setSelectedContract(value);
    setCurrentPage(1);
  };

  const handleSupplierChange = (value: string) => {
    setSelectedSupplier(value);
    setCurrentPage(1);
  };

  const dateRangeDropDown = [
      { value: 'this_month', label: 'This Month' },
      { value: 'previous_month', label: 'Previous Month' },
      { value: 'this_quarter', label: 'This Quarter' },
      { value: 'previous_quarter', label: 'Previous Quarter' },
      { value: 'this_year', label: 'This Year' },
      { value: 'previous_year', label: 'Previous Year' },
      { value: 'custom', label: 'Custom Date Range' },
    ];
  
    const handleDateRangeTypeChange = (value: string | null) => {
      setDateRangeType(value);
  
      if (value === 'custom' || value === undefined) {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      const now = moment();
      let fromDate: moment.Moment;
      let toDate: moment.Moment;
  
      switch (value) {
        case 'this_month':
          fromDate = now.clone().startOf('month');
          toDate = now.clone().endOf('month');
          break;
        case 'previous_month':
          fromDate = now.clone().subtract(1, 'month').startOf('month');
          toDate = now.clone().subtract(1, 'month').endOf('month');
          break;
        case 'this_quarter':
          fromDate = now.clone().startOf('quarter');
          toDate = now.clone().endOf('quarter');
          break;
        case 'previous_quarter':
          fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
          toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
          break;
        case 'this_year':
          fromDate = now.clone().startOf('year');
          toDate = now.clone().endOf('year');
          break;
        case 'previous_year':
          fromDate = now.clone().subtract(1, 'year').startOf('year');
          toDate = now.clone().subtract(1, 'year').endOf('year');
          break;
        default:
          return;
      }
  
      setDateRangeValues({ from: fromDate, to: toDate });

    };
  
    const handleDateRangeValuesChange = (
      dates: [any | null, any | null] | null,
    ) => {
      if (!dates || !dates[0] || !dates[1]) {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      const [from, to] = dates;
      setDateRangeValues({ from, to });   
    };

    // const procurementData = (contract: any) => {
    //   let pcData = [];
    //   contract?.forEach((contract) => {
    //   const contractRowSpan = contract.QualityData?.reduce((total, quality) => {
    //       return total + (quality.purchaseTypeData?.length || 0);
    //     }, 0);
    
    //     contract?.QualityData?.forEach((qualitiesRecords, qualityIndex) => {
    //       const qualitiesRowSpan = qualitiesRecords.purchaseTypeData?.length || 0;
    
    //       qualitiesRecords?.purchaseTypeData?.forEach((pcTypeRecords, typeIndex) => {
    //         pcData.push({
    //           laycan: qualitiesRecords.layCan,
    //           quality: qualitiesRecords.quality,
    //           quantity: qualitiesRecords.quantity,
    //           qualitySpecifications: qualitiesRecords.qualitySpecifications,
    //           purchaseTermData: qualitiesRecords.purchaseTermData,
    //           tolerance: `${qualitiesRecords.tolerance}%`,
    //           specStandard: qualitiesRecords.specStandard,
    //           astmAsh: qualitiesRecords.astmAsh,
    //           astmTs: qualitiesRecords.astmTs,
    //           isoAsh: qualitiesRecords.isoAsh,
    //           isoTs: qualitiesRecords.isoTs,
    //           incoterm: pcTypeRecords.purchaseType,
    //           jetty: pcTypeRecords.jettyName,
    //           fasAnchorage: pcTypeRecords.fasAnchorage,
    //           coalPricePMT: pcTypeRecords.coalPricePMT,
    //           fcCharges: pcTypeRecords.fcCharges,
    //           freightPricePMT: pcTypeRecords.freightPricePMT,
    //           initialPricePMT: pcTypeRecords.initialPricePMT,
    //           isIndexLinked: pcTypeRecords.isIndexLinked,
    //           indexedData: pcTypeRecords.indexedData,
    //           totalDelivered: pcTypeRecords.totalDelivered,
    //           vat: pcTypeRecords.vat,
    //           vatPercent: pcTypeRecords.vatPercent,
    //           typeSeq: pcTypeRecords.typeSeq,
    //           qualitiesRowSpan: typeIndex === 0 ? qualitiesRowSpan : 0, 
    //           rowSpan: qualityIndex === 0 && typeIndex === 0 ? contractRowSpan : 0,
    //           pcId: contract.pcId,
    //           contractNo: contract.contractNo,
    //           bpName: contract.bpName,
    //           totalQty: contract.totalQty,
    //           businessNos: contract.businessNos,
    //           barges: contract.barges
    //         });
    //       });
    //     });
    //   });
    //   return pcData;
    // };
  
    const columns: any = [
      {
        title: 'PO Order',
        dataIndex: 'contractNo',
        width: 140,
        align: 'center',
        fixed: 'left',
        render: (value, record) => {
          const link = `/#/po-detail-view?pc_id=${record.pcId}`;
          return <a href={link} className="link">{value}</a>;
        },
      },
      {
        title: 'Supplier Name',
        dataIndex: 'bpName',
        width: 250,
        align: 'center',
      },
      {
        title: 'Total Quantity',
        dataIndex: 'totalQty',
        width: 100,
        align: 'center',
      },
      {
        title: 'Laycan',
        dataIndex: 'laycan',
        width: 200,
        fixed: 'left',
        render: (value, record) => {
          return record?.QualityData?.map((item, index) => {
            let [fromDate, toDate] = item?.layCan?.split(',');
            const purchaseTypeCount = (item?.purchaseTypeData?.length || 1) + 
            (item?.purchaseTypeData?.reduce((acc, pt) => acc + (pt?.bargeQuality?.length > 1 ? pt?.bargeQuality?.length - 1  : 0), 0));
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `${purchaseTypeCount * 35}px`,
                  }}
                >
                  {`${moment(fromDate).format('DD-MMM-YYYY')} to ${moment(toDate).format('DD-MMM-YYYY')}`}
                </div>
                {index !== record?.QualityData.length - 1 && <Divider style={{ margin: '0px 0px', padding: '0px 0px' }} />}
              </div>
            );
          });
        },
      },
      {
        title: 'Quality',
        dataIndex: 'quality',
        width: 100,
        align: 'center',
        render: (value, record) => {
          return record?.QualityData?.map((item, index) => {
            const purchaseTypeCount = (item?.purchaseTypeData?.length || 1) + 
            (item?.purchaseTypeData?.reduce((acc, pt) => acc + (pt?.bargeQuality?.length > 1 ? pt?.bargeQuality?.length - 1  : 0), 0));
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `${purchaseTypeCount * 35}px`,
                  }}
                >
                  {item.quality} {item.qualitySpecifications}
                </div>
                {index !== record?.QualityData.length - 1 && <Divider style={{ margin: '0px 0' }} />}
              </div>
            );
          });
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        width: 100,
        align: 'center',
        render: (value, record) => {
          return record?.QualityData?.map((item, index) => {
            const purchaseTypeCount = (item?.purchaseTypeData?.length || 1) + 
            (item?.purchaseTypeData?.reduce((acc, pt) => acc + (pt?.bargeQuality?.length > 1 ? pt?.bargeQuality?.length - 1  : 0), 0));
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `${purchaseTypeCount * 35}px`,
                  }}
                >
                  {internationalFormattedValue(item.quantity, 3)}
                </div>
                {index !== record?.QualityData.length - 1 && <Divider style={{ margin: '0px 0' }} />}
              </div>
            );
          });
        },
      },
      {
        title: 'Tolerance',
        dataIndex: 'tolerance',
        width: 60,
        align: 'center',
        render: (value, record) => {
          return record?.QualityData?.map((item, index) => {
            const purchaseTypeCount = (item?.purchaseTypeData?.length || 1) + 
            (item?.purchaseTypeData?.reduce((acc, pt) => acc + (pt?.bargeQuality?.length > 1 ? pt?.bargeQuality?.length - 1  : 0), 0));
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `${purchaseTypeCount * 35}px`,
                  }}
                >
                  {item.tolerance}{`%`}
                </div>
                {index !== record?.QualityData.length - 1 && <Divider style={{ margin: '0px 0' }} />}
              </div>
            );
          });
        },
      },
      {
        title: 'Incoterm',
        dataIndex: 'purchaseType',
        width: 160,
        align: 'center',
        fixed: 'left',
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
              purchaseType: purchaseTypeItem.purchaseType,
            }))
          );
      
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {purchaseTypeItem.purchaseType}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0px', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'Load Port/Jetty',
        dataIndex: 'jetty',
        width: 200,
        align: 'center',
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
              jetty: purchaseTypeItem.jettyName,
              fasAnchorage: purchaseTypeItem?.fasAnchorage
            }))
          );
      
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {purchaseTypeItem.jetty || purchaseTypeItem.fasAnchorage}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'Index Linked (Y/N)',
        dataIndex: 'isIndexLinked',
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
              isIndexLinked: purchaseTypeItem.isIndexLinked,
            }))
          );
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {(purchaseTypeItem.isIndexLinked === true || purchaseTypeItem.isIndexLinked === 'Yes') ? 'Yes' : 'No'}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'Index Link Calculation',
        dataIndex: 'finalCoalBasePricePMT',
        align: 'right',
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              ...purchaseTypeItem,
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
            }))
          );
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {(purchaseTypeItem.isIndexLinked === true || purchaseTypeItem.isIndexLinked === 'Yes') ? 
                  <a style={{ color: '#0083C9', fontWeight: 500 }} onClick={() => showIndexFormData(purchaseTypeItem)}>{purchaseTypeItem?.indexedData?.finalCoalBasePricePMT}</a> : <span>{purchaseTypeItem?.indexedData?.finalCoalBasePricePMT}</span>
                }
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'PMT(Exclusive VAT)',
        dataIndex: 'coalPricePMT',
        align: 'right',
        width: 160,
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
              coalPricePMT: purchaseTypeItem.coalPricePMT
            }))
          );
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {internationalFormattedValue(purchaseTypeItem.coalPricePMT, 3)}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'VAT',
        dataIndex: 'vatPercent',
        align: 'right',
        width: 120,
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              vatPercent: purchaseTypeItem.vatPercent,
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
            }))
          );
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {internationalFormattedValue(purchaseTypeItem.vatPercent, 3)}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'PMT(Inclusive VAT)',
        dataIndex: 'coalPricePMT',
        align: 'right',
        width: 160,
        render: (value, record) => {
      
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
              coalPricePMT: purchaseTypeItem.coalPricePMT,
              vatPercent: purchaseTypeItem.vatPercent
            }))
          );
      
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => {
            const inclusivePMT =
              purchaseTypeItem.coalPricePMT * (1 + purchaseTypeItem.vatPercent / 100);
      
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                  }}
                >
                  {internationalFormattedValue(inclusivePMT, 3)}
                </div>
                {index !== flattenedPurchaseTypes.length - 1 && (
                  <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                )}
              </div>
            );
          });
        },
      },
      
      {
        title: 'Frieght Charges',
        dataIndex: 'freightPricePMT',
        align: 'right',
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              freightPricePMT: purchaseTypeItem.freightPricePMT,
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
            }))
          );
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {internationalFormattedValue(purchaseTypeItem.freightPricePMT, 3)}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'FC Charges',
        dataIndex: 'fcCharges',
        align: 'right',
        width: 100,
        render: (value, record) => {
          const flattenedPurchaseTypes = record?.QualityData?.flatMap((item) =>
            item?.purchaseTypeData?.map((purchaseTypeItem) => ({
              fcCharges: purchaseTypeItem.fcCharges,
              bargeQualityLength: purchaseTypeItem?.bargeQuality?.length || 1,
            }))
          );
          return flattenedPurchaseTypes?.map((purchaseTypeItem, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  height: `${purchaseTypeItem.bargeQualityLength * 35}px`,
                }}
              >
                {purchaseTypeItem.fcCharges}
              </div>
              {index !== flattenedPurchaseTypes.length - 1 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </div>
          ))
        },
      },
      {
        title: 'Barge No',
        dataIndex: 'barges',
        // width: 320,
        align: 'center',
        fixed: 'left',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          <a href={`/#/barge-detail-view?barge_id=${barge?.bgId}`} className="link">
                            {SequenceUtils.formatNumberToSpecificLength(barge?.bargeId)}
                          </a>
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      }, 
      {
        title: 'Barge Nomination',
        dataIndex: 'barges',
        width: 320,
        align: 'center',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {barge.bgName}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      },
      {
        title: 'Barge Delivered Quantity',
        dataIndex: 'totalDelivered',
        align: 'right',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {internationalFormattedValue(barge.totalDelivered, 3)}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      },
      {
        title: 'Delivered Quantity',
        dataIndex: 'deliveredQuantity',
        render: (value, record) => {
          return record?.QualityData?.map((item, index) => {
            const purchaseTypeCount = (item?.purchaseTypeData?.length || 1) + 
            (item?.purchaseTypeData?.reduce((acc, pt) => acc + (pt?.bargeQuality?.length > 1 ? pt?.bargeQuality?.length - 1  : 0), 0));
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: `${purchaseTypeCount * 35}px`,
                  }}
                >
                  {internationalFormattedValue(item.totalDelivered, 3)}
                </div>
                {index !== record?.QualityData.length - 1 && <Divider style={{ margin: '0px 0px', padding: '0px 0px' }} />}
              </div>
            );
          });
        },
      },
      {
        title: 'Remaining Quantity',
        dataIndex: 'Quantity',
        render: (value, record) => {
          return record?.QualityData?.map((item, index) => {
            const purchaseTypeCount = (item?.purchaseTypeData?.length || 1) + 
            (item?.purchaseTypeData?.reduce((acc, pt) => acc + (pt?.bargeQuality?.length > 1 ? pt?.bargeQuality?.length - 1  : 0), 0));
            return (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: `${purchaseTypeCount * 35}px`,
                  }}
                >
                  {internationalFormattedValue(item.quantity - item.totalDelivered, 3)}
                </div>
                {index !== record?.QualityData.length - 1 && <Divider style={{ margin: '0px 0px', padding: '0px 0px' }} />}
              </div>
            );
          });
        },
      },
      {
        title: 'GAR',
        dataIndex: 'GAR',
        width: 100,
        align: 'center',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {barge.GAR}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      }, 
      {
        title: 'ASH',
        dataIndex: 'ASH',
        width: 100,
        align: 'center',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {barge.ash}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      }, 
      {
        title: 'TS',
        dataIndex: 'TS',
        width: 100,
        align: 'center',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {barge.ts}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      }, 
      {
        title: 'Invoiced Quantity',
        dataIndex: '',
        align: 'right',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {internationalFormattedValue(barge.invoicedQty, 3)}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      }, 
      {
        title: 'Price After Adjustment',
        dataIndex: '',
        align: 'right',
        render: (_, record) => { 
          return  record?.QualityData?.flatMap((item, qualityIndex) =>
              item?.purchaseTypeData?.flatMap((purchaseTypeItem, purchaseIndex) => {
                const isLastQualityRecord = qualityIndex === record?.QualityData?.length - 1 && purchaseIndex === item?.purchaseTypeData.length - 1;
                if (!Array.isArray(purchaseTypeItem.bargeQuality) || purchaseTypeItem.bargeQuality.length === 0) {
                  return (
                    <div key={`empty-${qualityIndex}-${purchaseIndex}`}>
                      <div style={{ 
                        height: '35px',
                         }} />
                      {!isLastQualityRecord && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
                    </div>
                  );
                }

                return (
                  <div>
                    {purchaseTypeItem?.bargeQuality?.map((barge, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            height: '35px',
                          }}
                        >
                          {internationalFormattedValue(barge.pricePMTAdjustment, 3)}
                        </div>
                        {!(qualityIndex === record?.QualityData.length - 1 && index === purchaseTypeItem?.bargeQuality.length - 1 && purchaseIndex === item?.purchaseTypeData?.length - 1) && 
                        <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                        }
                      </div>
                    ))}
                  </div>
                );
              }) || []
            ) || [];
        },
      },  
      {
        title: 'Linked Business No',
        dataIndex: 'businessNos',
        width: 350,
        align: 'center',
        render: (_, record) => {
          const qualityData = record?.QualityData || [];
          
          return qualityData.flatMap((qualityItem, qualityIndex) => 
            qualityItem?.purchaseTypeData?.map((purchaseTypeItem, purchaseTypeIndex) => {
              const bargeCount = purchaseTypeItem?.bargeQuality?.length || 1;
              const businessNos = purchaseTypeItem.businessNos || '';
              
              const parsedEntries = businessNos.split(' - ')
                .filter(entry => entry.trim())
                .map(entry => {
                  const [bnId, businessNo, mvName] = entry.split(',');
                  return { bnId, businessNo, mvName };
                });
      
              const isLastQualityItem = qualityIndex === qualityData.length - 1;
              const isLastPurchaseType = purchaseTypeIndex === qualityItem.purchaseTypeData.length - 1;
              const showDivider = !(isLastQualityItem && isLastPurchaseType);
      
              const displayedEntries = parsedEntries.slice(0, bargeCount);
              const remainingEntries = parsedEntries.slice(bargeCount);
      
              return (
                <div key={`${purchaseTypeItem.pcTypeId}-${purchaseTypeItem.qualityId}`}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: `${bargeCount * 35}px`,
                      padding: '4px 0',
                      gap: '4px'
                    }}
                  >
                    {parsedEntries.length > 0 ? (
                      <>
                        {displayedEntries.map((entry, idx) => (
                          <div key={idx} style={{ lineHeight: '1.2', padding: '2px 0' }}>
                            <a href={`/#/bn-detail-view?bn_Id=${entry.bnId}`} className="link" >
                              {entry.businessNo} ({entry.mvName})
                            </a>
                            {idx < displayedEntries.length - 1 || remainingEntries.length > 0 ? ',' : ''}
                          </div>
                        ))}
                        {remainingEntries.length > 0 && (
                          <Popover
                            title="Additional Business Numbers"
                            placement="right"
                            content={
                              <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {remainingEntries.map((entry, index) => (
                                  <div key={index} style={{ padding: '4px 0' }}>
                                    <a href={`/#/bn-detail-view?bn_Id=${entry.bnId}`} className="link">
                                      {entry.businessNo} ({entry.mvName})
                                    </a>
                                  </div>
                                ))}
                              </div>
                            }
                          >
                            <a className="link" style={{ lineHeight: '1.0',marginLeft: '4px' }}>
                              +{remainingEntries.length}
                            </a>
                          </Popover>
                        )}
                      </>
                    ) : (
                      <div style={{ color: '#999' }}>-</div>
                    )}
                  </div>
                  {showDivider && <Divider style={{ margin: '0px 0px', padding: '0px 0px' }} />}
                </div>
              );
            })
          );
        }
      }
    ];

    const cancelHandler = () => {
      setActivePurchaseId(null);
      setActiveIndexedFormData(null);
    }
    
    const hideDrawer = () => {
      setActivePurchaseId(false);
    }

    const showIndexFormData = (purchaseData: PurchaseDetailsDto) => {
      setActivePurchaseId(purchaseData.typeId || true);
      setActiveIndexedFormData(purchaseData.indexedData);
    }
  return (
        <>
          <Row gutter={[10, 10]} style={{ marginBottom: 16 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                  placeholder= "Filter By Contract"
                  style={{ width: "100%" }}
                  onChange={(value) => handleContractChange(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  value={selectedContract || null}
                  allowClear
                  showSearch
                >
                  {pcContract?.map((rec) => {
                    return (
                      <Option value={rec.id}>{rec.contractNo}</Option>
                    );
                  })}
                </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                  mode="multiple"
                  placeholder= "Filter By Supplier"
                  style={{ width: "100%" }}
                  onChange={(value) => handleSupplierChange(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  value={selectedSupplier || null}
                  allowClear
                  showSearch
                >
                  {supplier?.map((rec) => {
                    return (
                      <Option value={rec.bpId}>{rec.bpName}</Option>
                    );
                  })}
                </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                placeholder="Filter By Business Number"
                style={{ width: "100%" }}
                onChange={(value) => handleBusinessNoChange(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={selectedBusinessNo || null}
                allowClear
                showSearch
              >
                {businessDropDown.map((bn) => {
                  return (
                    <Option value={bn.bnId}>
                      {bn.businessName}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                placeholder="Date Range"
                style={{ width: "100%" }}
                allowClear
                value={dateRangeType}
                onChange={handleDateRangeTypeChange}
              >
                {dateRangeDropDown.map((item, index) => (
                  <Select.Option
                    value={item.value}
                    key={item.value + '-' + index}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            {dateRangeType === 'custom' && (<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  value={[
                    dateRangeValues.from,
                    dateRangeValues.to,
                  ]}
                  onChange={handleDateRangeValuesChange}
                />
            </Col>)}
            <Col>
              <Button className="new-button" onClick={() => getAllProcurementData(currentPage, pageSize)}>Submit</Button>
            </Col>
          </Row>
          {/* <Table
            className="contracts-list"
            columns={columns}
            dataSource={procurementData(procurementReport)}
            scroll={{ x: 'max-content' }}
            rowClassName={(record) => (record.rowSpan > 0 ? "main-row" : "sub-row")}
            pagination={{
              current: currentPage,
              pageSize: pageSize, 
              total: total,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
          /> */}
          <Table
            className="procurement-list"
            columns={columns}
            dataSource={procurementReport}
            scroll={{ x: 'max-content' }}
            pagination={{
              current: currentPage,
              pageSize: pageSize, 
              total: total,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
          />
          <Drawer
                width={800}
                open={activePurchaseId ? true : false}
                key={activePurchaseId}
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ fontSize: '20px', fontWeight: '600', color: '#323F49' }}>Index Linked</span>
                        <Button style={{border: 'none', fontSize: '20px', color: '#6B7280', boxShadow: 'none'}} icon={<FontAwesomeIcon icon={faXmark} />} onClick={hideDrawer}></Button>
                    </div>
                } 
                closable={false}
                onClose={hideDrawer}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end', color: '#0083C9', fontSize: '14px', fontWeight: 400, margin: '8px'}}>
                        <a onClick={hideDrawer} >Close</a>
                    </div>
                }
          >
            <CoalIndexView indexedData={activeIndexedFormData} cancelHandler={cancelHandler} />
          </Drawer> 
        </>
  )
}

export default ProcurementReport;