import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ReferenceFeatures } from "@exportx/shared-models-and-services"
import moment from "moment";
import { internationalFormattedValue } from "@exportx/ui-utils";

interface PriceDetailsProps {
  costingDetails?: any,
  costingName?: ReferenceFeatures,
  barge?: any,
}

export const CostingPriceDetails = (props: PriceDetailsProps) => {
  const { costingName, costingDetails, barge } = props

  const styles = StyleSheet.create({
    amount: {
      paddingHorizontal: 8, 
      paddingVertical: 4,
      flexDirection: "row", 
      alignItems: "center", 
      justifyContent: 'space-between'
    }
});

  let advTotal;
  if(costingDetails?.advanceTarnsactionsData){
    advTotal = Number(
      costingDetails?.advanceTarnsactionsData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(3),
    );
  }

  return (
    <>
      {(costingName === ReferenceFeatures.MARKETING_FEES || costingName === ReferenceFeatures.PROCUREMENT_FEES) && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {Array.isArray(barge) && barge.length > 0 && (<View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Currency Type</Text>
                          <Text style={{ textAlign: 'right' }}>{barge[0]?.currencyType ? barge[0]?.currencyType  : "N/A"}</Text>
                        </View>)}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.exchangeRate, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Price After Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.exchangeRateValue, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Coal Price</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.adjustedPriceValue, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Fc Charges</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.fcCharges, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 1</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName1}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal1, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 2</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName2}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal2, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.tdsTotal, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalPayable, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(advTotal, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ backgroundColor: "#E3F2EB" }]}>
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.invoiceTotal, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                    </View>
                </View>)}

                {(costingName === ReferenceFeatures.COMMERCIAL_PC_COSTING || costingName === ReferenceFeatures.COMMERCIAL_SC_COSTING || costingName === ReferenceFeatures.PERFORMA_PC_COSTING || costingName === ReferenceFeatures.PERFORMA_SC_COSTING || costingName === ReferenceFeatures.SALES_FREIGHT_COSTING) && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {Array.isArray(barge) && barge.length > 0 && (<View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Currency Type</Text>
                          <Text style={{ textAlign: 'right' }}>{barge[0]?.currencyType ? barge[0]?.currencyType  : "N/A"}</Text>
                        </View>)}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.exchangeRate, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.costingsTotal, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalPayable, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 1</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName1}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal1, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 2</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName2}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal2, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.tdsTotal, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(advTotal, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ backgroundColor: "#E3F2EB" }]}>
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalAmount || costingDetails?.invoiceTotal, 3)} {costingDetails?.billingCurrency || costingDetails?.quoteCurrency}</Text>
                        </View>
                    </View>
                </View>)}

                {costingName === ReferenceFeatures.BARGE_COSTINGS && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {Array.isArray(barge) && barge.length > 0 && (<View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Currency Type</Text>
                          <Text style={{ textAlign: 'right' }}>{barge[0]?.currencyType ? barge[0]?.currencyType  : "N/A"}</Text>
                        </View>)}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.exchangeRate, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Base Freight</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.baseFreight, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Dead Freight</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.deadFreight, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.costingsTotal, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>VAT</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.vat ? 'Yes' : 'No'}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName || ''} {(costingDetails?.tdsPercentage || '')}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.tdsTotal, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        {costingDetails?.advanceTarnsactionsData?.map((adv) => (
                           <View style={styles.amount} key={adv.id}>
                           <Text style={{ textAlign: 'right' }}>Deduction ({adv.adjustedCostingNo})</Text>
                           <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(adv?.totalAdjustedAmount, 3)} {costingDetails?.billingCurrency}</Text>
                         </View>
                        ))}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(advTotal, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#E3F2EB" */}
                        <View style={[styles.amount, { color: "green" }]}> 
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalAmount - advTotal, 3)} {costingDetails?.billingCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#FFC0C0" */}
                        {/* <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Remaining Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount - advTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View> */}
                    </View>
                </View>)}

                {costingName === ReferenceFeatures.EXPENSE_ENTRY && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.exchangeRateValue, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.total, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>VAT</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.vatApplicable ? 'Yes' : 'No'}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.vat, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName || ''} {(costingDetails?.tdsPercent || '')}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.tds, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        {costingDetails?.advanceTarnsactionsData?.map((adv) => (
                           <View style={styles.amount} key={adv.id}>
                           <Text style={{ textAlign: 'right' }}>Deduction ({adv.adjustedCostingNo})</Text>
                           <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(adv?.totalAdjustedAmount, 3)} {costingDetails?.quoteCurrency}</Text>
                         </View>
                        ))}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Advance Adjustment</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(advTotal, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#E3F2EB" */}
                        <View style={[styles.amount, { color: "green" }]}> 
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalAmount - advTotal, 3)} {costingDetails?.quoteCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#FFC0C0" */}
                        {/* <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Remaining Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{Number(costingDetails?.totalAmount - advTotal).toFixed(3)} {costingDetails?.billingCurrency}</Text>
                        </View> */}
                    </View>
                </View>)}

                {(costingName === ReferenceFeatures.MV_DEAD_FREIGHT || costingName === ReferenceFeatures.MV_DEMURRAGE) && ( 
                  <View style={{ width: "100%", display: 'flex', justifyContent: "space-between",marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    {costingName !== ReferenceFeatures.MV_DEMURRAGE && (
                      <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                        <Text>Thanks For Your Business</Text>
                      </View>
                    )}
                    {costingName === ReferenceFeatures.MV_DEMURRAGE && (
                    <View style={{ width: "48%", height: "auto", padding: 8, backgroundColor: "#f2f7fd", borderRadius: 5 }}>
                        <>
                          <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Demurrage/Despatch Details</Text>
                            <Text style={{ textAlign: 'right' }}></Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Mv Stowage Plan</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.stowagePlanInMt}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Contract No</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.contractNo}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Shipping Type</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.shippingType}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Supply Tonnage</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.supplyTonnage}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Demurrage/Dispatch Applicable</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.costingApplicable ? "Yes" : "No"}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Notes</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.notes}</Text>
                          </View>
                        </>
                      </View>
                      )}
                    <View style={{ width: "48%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        {costingName === ReferenceFeatures.MV_DEAD_FREIGHT && (
                          <>
                            <View style={[styles.amount,{ fontSize: 9 }]}>
                              <Text style={{ textAlign: 'right' }}>Dead Freight</Text>
                              <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.deadFreight, 3)} {costingDetails?.baseCurrency}</Text>
                            </View>
                            <View style={[styles.amount,{ fontSize: 9 }]}>
                              <Text style={{ textAlign: 'right' }}>Price</Text>
                              <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.pricePerMT, 3)} {costingDetails?.quoteCurrency}</Text>
                            </View>
                          </>
                        )}
                        {costingName === ReferenceFeatures.MV_DEMURRAGE && (
                          <>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Load Rate Per Day</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.portRate} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Demurrage Rate Per Day</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.demurrgeRatePerDay} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Dispath Rate Per Day</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.dispatchRatePerDay} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Allowed Time To Load</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.allowedTimeToLoad} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>Time Completed To Load</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.timeCompletedForLoadMV} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                          <View style={[styles.amount,{ fontSize: 9 }]}>
                            <Text style={{ textAlign: 'right' }}>No Of Diff Days</Text>
                            <Text style={{ textAlign: 'right' }}>{costingDetails?.noofDaysDifference} {costingDetails?.quoteCurrency || costingDetails?.baseCurrency}</Text>
                          </View>
                        </>
                        )}
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Date</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.exchangeDate ? moment(costingDetails?.exchangeDate).format('DD MMM YYYY HH mm') : "N/A"}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Exchange Rate</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.exchangeRate, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.amount || costingDetails?.cost, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Discount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.discount, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={[styles.amount,{ fontSize: 9 }]}>
                          <Text style={{ textAlign: 'right' }}>Amount After Discount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.amtAftDiscount, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 1</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName1}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal1, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax 2</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxName2}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal2, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsName}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.tdsTotal, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalAmount, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#E3F2EB" */}
                        <View style={[styles.amount, { color: "green" }]}> 
                          <Text style={{ textAlign: 'right' }}>To be Paid to Supplier</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalAmount, 3)} {costingDetails?.baseCurrency}</Text>
                        </View>
                        {/* backgroungColor: "#FFC0C0" */}
                        {/* <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Remaining Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.totalAmount} {costingDetails?.billingCurrency}</Text>
                        </View> */}
                    </View>
                </View>)}

                {(costingName === ReferenceFeatures.CREDIT_NOTE || costingName === ReferenceFeatures.DEBIT_NOTE) && ( 
                  <View style={{ width: "100%", marginTop: 20, flexDirection: "row", fontSize: 9 }}>
                    <View style={{ width: "50%", height: "100%", backgroundColor: "white" }}>
                      <Text>Thanks For Your Business</Text>
                    </View>
                    <View style={{ width: "50%", height: "auto", padding: 8, backgroundColor: "#F5F3F4", borderRadius: 5 }}>
                        <View style={{ borderBottomWidth: 1, borderBottomColor: "#ddd", paddingHorizontal: 8, marginBottom: 4, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
                          <Text style={{ fontSize: 10, fontWeight: "bold", textAlign: 'right' }}>Price Details</Text>
                          <Text style={{ textAlign: 'right' }}></Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>VAT</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.vatApplicable ? 'Yes' : 'No'}</Text>
                          {/* <Text style={{ textAlign: 'right' }}>0</Text> */}
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>Tax Total</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.taxPercentage}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.taxTotal, 3)}</Text>
                        </View>
                        <View style={styles.amount}>
                          <Text style={{ textAlign: 'right' }}>TDS</Text>
                          <Text style={{ textAlign: 'right' }}>{costingDetails?.tdsPercentage}</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.tdsTotal, 3)}</Text>
                        </View>
                        <View style={[styles.amount, { color: "red" }]}>
                          <Text style={{ textAlign: 'right', color: "red" }}>Total Amount</Text>
                          <Text style={{ textAlign: 'right' }}>{internationalFormattedValue(costingDetails?.totalAmount, 3)}</Text>
                        </View>
                    </View>
                </View>)}

    </>
  )
}