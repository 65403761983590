import React from "react";
import { AdvancePreview, AdvanceReceiversService, ContractStatusEnum, ReferenceFeatures, VendorAdvService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Drawer, Flex, Form, Input, List, Popconfirm, Row, Select, Space, Table, Tabs, Timeline, Tooltip, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';
import { RightCircleOutlined, DownCircleOutlined, MessageFilled } from "@ant-design/icons"
import { InvoiceDetails } from "../../../../costings/costing-detail-view";
import { useIntl } from 'react-intl';
import { getLocalFormat, internationalFormattedValue } from "@exportx/ui-utils";

import { faCheckCircle, faCircleXmark, faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentsPage from "../../../../../common/comments/comments-page";
interface AdvanceApprovalProps {
  feature?: ReferenceFeatures;

}

export const AdvanceBusinessApprovals = (props: AdvanceApprovalProps) => {
  const { feature } = props
  const [advanceActive, setadvanceActive] = useState<any>();
  let pathToreDirect = feature === ReferenceFeatures.ADVANCE_RECEIVABLES ? '/advance-receivables-detailed-view' : '/advanceDetailView'
  const { authContext } = useAuthState()
  const service = new VendorAdvService()
  const advReceivableService = new AdvanceReceiversService()
  const [searchData, setSearchData] = useState("")
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [contractId, setContractId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');
  const [featuresRef, setFeaturesRef] = useState<string>('');
  const [activeTab, setActiveTab] = useState<any>('business-approval');
  const [status, setStatus] = useState<ContractStatusEnum>(ContractStatusEnum.PENDING_APPROVAL);

  const [formRef] = Form.useForm();
  const { formatMessage: fm } = useIntl();


  useEffect(() => {
    if (activeTab === 'business-approval') {
      setStatus(ContractStatusEnum.PENDING_APPROVAL)
    } else {
      setStatus(ContractStatusEnum.FREEZED)
    }
  }, [activeTab])

  useEffect(() => {
    if (feature === ReferenceFeatures.ADVANCE_INVOICE) {
      getAdvanceApprovalCostings();
      setFeaturesRef(ReferenceFeatures.ADVANCE_INVOICE);
    } else {
      getAdvanceReceivableApprovalCostings()
      setFeaturesRef(ReferenceFeatures.ADVANCE_RECEIVABLES);
    }
  }, [feature, dummyRefresh, status]);


  const getAdvanceReceivableApprovalCostings = () => {
    advReceivableService.getAdvanceReceivableApprovalCostings({ plantCode: authContext.defaultPlant, status }).then(res => {
      if (res.status) {
        setadvanceActive(res.data)
      } else {
        setadvanceActive([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }
  const getAdvanceApprovalCostings = () => {
    service.getAdvanceApprovalCostings({ plantCode: authContext.defaultPlant, status }).then(res => {
      if (res.status) {
        setadvanceActive(res.data)
        setDummyRefresh(false)
      }
      else {
        setadvanceActive([])
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const advanceApprovalOperations = (costingId: string, status: string) => {
    formRef.validateFields().then((values) => {
      const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status, undefined, values['remarks']);
      // if (feature === ReferenceFeatures.ADVANCE_INVOICE) {
      service.advanceApprovalOperations(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          setDummyRefresh(true)
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
      // }
      // else {
      //   console.log('req', req)
      //   advReceivableService.advanceReceivableApproveOrReject(req).then(res => {
      //     if (res.status) {
      //       AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      //       setDummyRefresh(true)
      //     } else {
      //       AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      //     }
      //   }).catch(err => {
      //     AlertMessages.getErrorMessage(err.message);
      //   })


      // }
    }).catch(err => {
      console.log('err', err)
      return;
    }
    )


  }

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData(featuresRef, 'All Comments');
    setContractId(record.id);
  }

  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contract_no',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contract_no)
            .toLowerCase()
            .includes(value.toLocaleString())
        )
      },
      render: (value, rowData) => {
        const link = `/#${pathToreDirect}?id=${rowData.id}`
        return (
          <>
            <a href={link} className="link">
              {value}
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {rowData.priority === 'HIGH' ? (
              <FontAwesomeIcon icon={faFlag} style={{ color: 'red' }} />
            ) : null}
          </>
        );
      }

    },

    {
      title: 'Contract Type',
      dataIndex: 'contract_type',
    },

    {
      title: 'Contract Date',
      dataIndex: 'contract_date',
      render: (text, record) => { return record.contract_date !== undefined ? moment(record.contract_date).format('DD\u2011MMM\u2011YYYY') : "" },
      sorter: (a, b) => moment(a.contract_date).unix() - moment(b.contract_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bp_name',
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      render: (value, record) => {
        return <>
          <Typography.Text>
            {internationalFormattedValue(value, 3)}
          </Typography.Text>
          <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
        </>
      }
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },

  ]

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.id);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }
  const renderItems = (record: any, index, indent, expanded) => {
    const timeLines = [];
    record?.workLog?.forEach((rec) => {
      timeLines.push({
        color: 'green',
        children: (
          <>
            <Typography style={{ fontSize: 10 }}> {rec?.actionType ?? rec?.approvalLevel} By <b>{rec?.createdUser}</b>  On <b>{rec?.createdAt ? moment(rec?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
            <pre>{rec.workLog}</pre>
          </>
        )

      });
    });
    return <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }} >

      <Card
        bordered={false}
        className="card-shadow mb-2"
        styles={{ body: { padding: '0px 10px 15px' } }}
      >
        <Form
          form={formRef}
          // initialValues={initialValues}
          layout='vertical'
          autoComplete='off'
        >
          <Row className='pt-2'>
            <Form.Item style={{ width: '100%' }} name='remarks' label="Remarks"
              rules={[
                {
                  required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                }
              ]}>
              <Input.TextArea placeholder="Notes" />
            </Form.Item>
          </Row>
          <br />
          <Row>
            <Col span={16}>
              <Timeline
                items={timeLines}
              />
            </Col>
          </Row>

          <Flex align='middle' justify='flex-end'>
            <Space size={10}>
              {(record?.contract_status === ContractStatusEnum.PENDING_APPROVAL) &&
                <Button className='approve' size='small'
                  onClick={() => advanceApprovalOperations(record?.id, ContractStatusEnum.ACTIVE)}
                  type="primary">
                  <FontAwesomeIcon icon={faCheckCircle} /> Approve
                </Button>}

              {(activeTab === 'additional-info') ?
                <Button size='small' className='new-button' onClick={() => advanceApprovalOperations(record?.id, ContractStatusEnum.PENDING_AUDIT_APPROVAL)}> <FontAwesomeIcon icon={faCheckCircle} />Send For Audit Approval</Button>
                :
                <Button size='small' className='reject' onClick={() => advanceApprovalOperations(record?.id, ContractStatusEnum.CANCELLED)}> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>

              }
            </Space>
          </Flex>


          {/* <Flex align="middle" justify="flex-end">
            <Space size={10}>
              {record?.contract_status === ContractStatusEnum.PENDING_APPROVAL &&
                <Button size="small" className="approve" onClick={() => advanceApproveOrReject(record?.id, ContractStatusEnum.ACTIVE)} > <FontAwesomeIcon icon={faCheckCircle} /> Approve</Button>
              }
              <Popconfirm
                onConfirm={e => {
                  advanceApproveOrReject(record?.id, ContractStatusEnum.CANCELLED);
                }}
                title={'Are you sure to reject'}>
                <Tooltip placement="top" title={'Reject'}>
                  <Button size="small" className="reject"> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>
                </Tooltip>
              </Popconfirm>


            </Space>
          </Flex> */}
        </Form>
      </Card>

      <InvoiceDetails
        costingId={record.id}
        status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
        level={ContractStatusEnum.BUSINESS_APPROVAL}
        invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
        invApproveDisabled={record.contract_status === ContractStatusEnum.PENDING_APPROVAL}
        setDummyRefresh={setDummyRefresh}
      />
    </div>
  };

  return (
    // <Card
    //   className='contracts-list card-radius'
    //   title={<Row className='card-title-buttons'>
    //     <Input.Search placeholder="Search Orders" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} className='input-search' />

    //   </Row>}
    // >
    <>

      <Card className="card-radius"
        title={<Row className='card-title-buttons'>
          <Input.Search placeholder="Search Orders" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} className='input-search' />

        </Row>}
      >
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            {
              key: 'business-approval',
              label: 'Business Approval',
              children: (
                <Table
                  className='contracts-list'
                  columns={tableColumns}
                  dataSource={advanceActive}
                  bordered={false}
                  rowKey={(rowData) => rowData.id}
                  expandable={{
                    expandedRowRender: renderItems,
                    expandedRowKeys: expandedIndex,
                    onExpand: setIndex,
                    fixed: 'right'
                  }}
                  expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                      <DownCircleOutlined
                        onClick={(e) => onExpand(record, e)}
                      >
                        Collapse
                      </DownCircleOutlined>
                    ) : (
                      <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
                    )
                  }
                />
              )
            },
            {
              key: 'additional-info',
              label: 'Additional Information',
              children: (
                <Table
                  className='contracts-list'
                  columns={tableColumns}
                  dataSource={advanceActive}
                  bordered={false}
                  rowKey={(rowData) => rowData.id}
                  expandable={{
                    expandedRowRender: renderItems,
                    expandedRowKeys: expandedIndex,
                    onExpand: setIndex,
                    fixed: 'right'
                  }}
                  expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                      <DownCircleOutlined
                        onClick={(e) => onExpand(record, e)}
                      >
                        Collapse
                      </DownCircleOutlined>
                    ) : (
                      <RightCircleOutlined onClick={(e) => onExpand(record, e)}>Expand</RightCircleOutlined>
                    )
                  }
                />
              )
            }
          ]}
        />

      </Card>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={contractId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
    // </Card>


    // <Form layout="vertical">
    //   <Row>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
    //     </Col>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
    //     <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

    //       <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
    //     </Col>
    //   </Row>

    // </Form>


    // </Card >
  )

}