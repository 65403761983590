import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined } from '@ant-design/icons';
import { AdvanceEnum, ContractStatusEnum, CostingInvoiceApproveReqDto, CostingInvoiceDto, CostingInvoiceReqDto, CostingInvoiceService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Form, Modal, Popconfirm, Table, Tooltip, Tag, TableColumnsType } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../common';
import AddCostingInvoicesForm from './add-invoice-form';
import ViewFileHandling from '../../common/file-upload-form/view-files-handling';
import { getLocalFormat, internationalFormattedValue } from '@exportx/ui-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

interface InvoiceFormIProps {
  costingData: any;
  vendorAdvanceType?: any;
  vendorId?: string;
  contractNo?: string;
  advanceType?: AdvanceEnum;
  accessId?: any;
}



const AddInvoice = (props: InvoiceFormIProps) => {
  const [invoiceData, setInvoiceData] = useState<CostingInvoiceDto[]>([]);
  const [invoiceFormDrawer, setInvoiceFormDrawer] = useState<boolean>(false);
  const { costingData, vendorAdvanceType, vendorId, contractNo, advanceType, accessId } = props;
  const [initialValues, setInitialValues] = useState<any>({})
  const invoiceService = new CostingInvoiceService();
  const [formRef] = Form.useForm();
  const { authContext } = useAuthState();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  let costingId = vendorAdvanceType === ReferenceFeatures.ADVANCE_INVOICE ? costingData?.id : costingData?.costingId;
  let referenceFeatures = costingData?.referenceFeatures


  useEffect(() => {
    getAllCostingInvoice();
  }, []);

  const hasPermission = (props: any) => {
    if (props) {
      return isPermissionExist([props])
    }
    return true;
  }



  useEffect(() => {
    if (vendorAdvanceType) {
      getAllCostingInvoice();
    }
  }, [vendorAdvanceType, costingId])



  useEffect(() => {
    if (initialValues) {
      formRef.setFieldsValue(initialValues);
    };
  }, [initialValues]);

  if (initialValues === undefined) {
    formRef.setFieldValue('invoicePercent', undefined);
    formRef.setFieldValue('invoiceNo', undefined);
    formRef.setFieldValue('remarks', undefined);
    formRef.setFieldValue('invoiceDate', undefined);
    formRef.setFieldValue('invoiceAmount', undefined);
    formRef.setFieldValue('invoiceId', undefined);
  };


  if (initialValues?.invoiceDate) {
    initialValues.invoiceDate = moment(initialValues?.invoiceDate)
  };

  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(costingId, authContext.defaultPlant, undefined, referenceFeatures)
    invoiceService.getAllCostingInvoice(req).then(res => {
      if (res.status) {
        setInvoiceData(res.data);
      } else {
        setInvoiceData([]);
        console.log(res.internalMessage);

      }
    }).catch(err => console.log(err.message));
  };



  const deleteCostingInvoice = (id: string) => {
    const req = new CostingInvoiceReqDto(costingId, authContext.defaultPlant, id, referenceFeatures)
    invoiceService.deleteInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllCostingInvoice();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);

      }
    }).catch(err => console.log(err.message))
  }

  const editClickHandler = (record) => {
    setDummyRefresh(prev => prev + 1);
    setInitialValues(record);
    setInvoiceFormDrawer(true);
  };

  const sendForApprovalHandler = (invoiceId: string) => {
    const req = new CostingInvoiceApproveReqDto(invoiceId, authContext.user.userName, authContext.defaultPlant, ContractStatusEnum.PENDING_APPROVAL, ContractStatusEnum.PENDING_APPROVAL)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllCostingInvoice();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);

      }
    }).catch(err => console.log(err.message))
  }

  const invoiceTotal: number = invoiceData.reduce((a, c) => a + Number(c.invoiceAmount), 0)

  const invoiceColumns: TableColumnsType = [

    {
      title: 'Invoice Ref',
      dataIndex: 'invoiceRef',
    },
    {
      title: 'Invoice No	',
      dataIndex: 'invoiceNo',
    },

    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      render: (text, record) => { return record.invoiceDate !== undefined ? moment(record.invoiceDate).format('DD\u2011MMM\u2011YYYY') : "" },
    },
    ...!costingData?.includeFreight ? [
      {
        title: 'Invoice %	',
        dataIndex: 'invoicePercent',
      }]
      : [],
    {
      title: 'Invoice Amount	',
      dataIndex: 'invoiceAmount',
      align: 'right',
      render: (text) => getLocalFormat(text, authContext.defaultPlantCurrency)

    },
    ...costingData?.includeFreight ? [
      {
        title: 'Coal %	',
        dataIndex: 'coal_percent',
      },
      {
        title: 'Coal Amount	',
        dataIndex: 'coal_amount',
        render: (text) => getLocalFormat(text, authContext.defaultPlantCurrency)

      },
      {
        title: 'Freight  %	',
        dataIndex: 'freight_percent',
      },
      {
        title: 'Freight Amount	',
        dataIndex: 'freight_amount',
        render: (text) => getLocalFormat(text, authContext.defaultPlantCurrency)

      }
    ] : [],


    {
      title: 'Invoice File',
      dataIndex: 'filesData',
      hidden: !hasPermission(accessId?.files),
      render: (value, record) => {
        return <>
          <ViewFileHandling filesData={value.length ? value : []} required={true} />

        </>
      }

    },
    {
      title: 'Outstanding Amount',
      dataIndex: 'outstanding_amount',
      align: 'right',
      render: (text) => getLocalFormat(text, authContext.defaultPlantCurrency)


    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => <StatusTag status={text} />
    },
    {
      title: 'Action',
      dataIndex: '',
      onCell: (record: any) => ({
        className: 'nowrap',
      }),
      render: (value, record: CostingInvoiceDto) =>
        <span>
          {(record.releaseStatus) ? <Tag color="lime">{`${+record.outstanding_amount > 0 ? 'Partial' : ''} Released`}</Tag>
            :
            <>
              {record.status === ContractStatusEnum.DRAFT &&
                <>
                  <Button type="primary" className='send-approval-button' onClick={() => sendForApprovalHandler(record.invoiceId)}><FontAwesomeIcon icon={faPaperPlane} />Send For Approval</Button>
                  &nbsp;&nbsp;
                </>
              }
              {hasPermission(accessId?.update) && (
                <Tooltip placement="topRight" title="Edit">
                  <EditFilled className='action-icons'
                    type="edit"
                    onClick={() => editClickHandler(record)}
                  // style={{ color: '#1890ff', fontSize: '20px' }}
                  /></Tooltip>)}&nbsp;&nbsp;

              {/* <Divider type="vertical" /> */}
              {hasPermission(accessId?.delete) && (
                <Popconfirm
                  onConfirm={e => {
                    deleteCostingInvoice(record.invoiceId)
                  }}
                  title={'Are you sure to Delete Record ?'}>
                  <Tooltip placement="topRight" title="Delete">
                    <DeleteFilled className='delete-filled'
                      type='delete'
                    // style={{ color: 'red', fontSize: '20px' }}
                    />
                  </Tooltip>
                </Popconfirm>)}

            </>

          }


        </span>
    },

  ];



  const modalOnCancel = () => {
    setDummyRefresh(prev => prev + 1);
    formRef.resetFields();
    setInitialValues(undefined);
    setInvoiceFormDrawer(false);
  }

  const addInvoice = () => {
    setDummyRefresh(prev => prev + 1);
    setInvoiceFormDrawer(true);
    setInitialValues(undefined);
    formRef.resetFields();

  }

  return (
    <>

      <Card title={<span className='card-title-md'>Invoice</span>} className='card-radius'
        headStyle={{ border: 0, paddingTop: '10px' }} extra={<span >
          {hasPermission(accessId?.create) && (<Button className='new-button' onClick={() => {
            addInvoice();
          }}>
            Add Invoice
          </Button>)}
        </span>}>


        <Modal
          title={`Invoice ${vendorAdvanceType ? internationalFormattedValue(costingData?.amount, 3) : internationalFormattedValue(costingData?.totalAmount, 3)}`}
          open={invoiceFormDrawer}
          onCancel={() => {
            modalOnCancel();
            getAllCostingInvoice();
          }}

          key={dummyRefresh}
          footer={null}
        >
          <AddCostingInvoicesForm
            initialValues={initialValues}
            dummyRefresh={dummyRefresh}
            formRef={formRef}
            getAllCostingInvoice={getAllCostingInvoice}
            setDummyRefresh={setDummyRefresh}
            setInvoiceFormDrawer={setInvoiceFormDrawer}
            costingData={costingData}
            invoiceTotal={Math.abs(invoiceTotal)}
            vendorAdvanceType={costingData?.contractType}
            vendorId={vendorId}
            contractNo={contractNo}
            advanceType={advanceType}
          />

        </Modal>
        <Table className='contracts-list' rowKey={record => record.invoiceId} columns={invoiceColumns} dataSource={invoiceData} pagination={false}
          scroll={{ x: costingData?.includeFreight ? '140%' : '100%' }}
        />
      </Card><br />

    </>
  )
}

export default AddInvoice