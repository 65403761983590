import { getLocalFormat, SequenceUtils } from '@exportx/ui-utils';
import { Typography, Table, Card } from 'antd';
import moment from 'moment';


interface BargeIProps {
  bargeGridData: any;
  rowSelection: {
    columnTitle: JSX.Element;
    onChange: (selectedRowKeys: string[], selectedRows: any[]) => void;
    getCheckboxProps: (record: any) => {
      disabled: boolean;
    } | {
      disabled?: undefined;
    };
    selectedRowKeys: any[];
  }

}


const PerformaCostingBargeSelection = (props: BargeIProps) => {

  const { bargeGridData, rowSelection } = props;

  const columns: any = [
    {
      title: 'Contract',
      dataIndex: 'contractNo',
      render: (value, record) => <Typography className="link">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Barge ID',
      dataIndex: 'bargeId',
      render: (text, record) => {
        if (record?.bgUId) {

          const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
          return <>
            <a href={link} className="link" >
              {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
            </a>
            <Typography className="small-text">{record?.bargeNomination}</Typography>
          </>
        } else return <span>NA</span>
      }
    },
    {
      title: 'Jetty',
      dataIndex: 'loadingJetty',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Loading Date',
      dataIndex: 'loadingDate',
      render: (value, record) => <Typography className="small-text">{value ? moment(value).format('DD-MM-YYYY') : 'NA'}</Typography>

    },
    {
      title: 'Mv Name',
      dataIndex: 'businessNo',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Quality',
      dataIndex: 'quality',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Inco Term',
      dataIndex: 'incoterm',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Quantity (MT)',
      dataIndex: 'quantityInMt',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Price Type',
      dataIndex: 'bargingType',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>
    },
    {
      title: 'Price',
      dataIndex: 'coalBasePrice',
      render: (value, record) => <Typography className="small-text">{`${getLocalFormat(Number(record?.coalBasePrice), record?.baseCurrency)}`}</Typography>
    },
    {
      title: 'Freight Pmt',
      dataIndex: 'freightPricePmt',
      render: (value, record) => <Typography className="small-text">{`${getLocalFormat(Number(record?.freightPricePmt), record?.baseCurrency)}`}</Typography>
    }

  ];

  return (

    <Table
      className='contracts-list'
      size='small'
      columns={columns}
      dataSource={bargeGridData}
      rowSelection={{ type: 'radio', ...rowSelection }}
      rowKey={record => record.bgUId}
      pagination={false}
      scroll={{ x: 1200 }}
    // bordered={true}
    />
  )
}

export default PerformaCostingBargeSelection