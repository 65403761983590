import {
  AdvanceEnumType,
  CommercialCoalCostingService,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTermEnum,
  CostingTypesEnum,
  CurrencyTypesEnum,
  PerformaCoalCostingService,
  PerFormaReqIdDto,
  PriceTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { faArrowLeft, faDownload, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Flex,
  Layout,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import queryString from 'query-string';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditFilled, InfoCircleOutlined, MessageFilled } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import { QualityPriceAdjustmentsDeatils } from '../../costing-detail-view';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import { CostingInvoice } from '../../costing-invoice';
import AddInvoice from '../../add-invoice';
import CommentsPage from '../../../../common/comments/comments-page';

export interface PerformaProps {
  costingType: CostingTypesEnum;
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
}

export const PerformaDetailView = (props: PerformaProps) => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingIds, setCostingId] = useState<any>();
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [data, setData] = useState<any>();
  const [advanceAdjustment, setAdvanceAdjustment] = useState([]);
  const [downloadPermission, setDownloadPermission] = useState<any>(1);
  const [editPermission, setEditPermission] = useState<any>();
  const [viewInvoice, setViewInvoice] = useState<any>();
  const [accessId, setAccessId] = useState<any>({});
  const { Text } = Typography;

  const { contractType, costingType, referenceFeatures } = props;
  const paramBargeId: any = queryString.parse(location.search)?.costing_id;
  let { costingId } =
    paramBargeId != null ? { costingId: paramBargeId } : { costingId: null };

  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');
  const [costPriority, setCostPriority] = useState<string>('')
  const [updatedCostPriority, setUpdatedCostPriority] = useState(false);
  const [BargeData, setBargeData] = useState([])

  const performaCostingsService = new PerformaCoalCostingService();
  const cmService = new CommercialCoalCostingService();

  useEffect(() => {
    let permission;
    let download;
    let edit;
    let viewInvoices;
    if (costingType === CostingTypesEnum.PERFORMA) {
      permission = contractType === ContractModesEnum.PURCHASE ? 271 : 313;
      edit = contractType === ContractModesEnum.PURCHASE ? 272 : 314;
      download = contractType === ContractModesEnum.PURCHASE ? 895 : 897;
      viewInvoices = contractType === ContractModesEnum.PURCHASE ? 280 : 322;
      if (contractType === ContractModesEnum.PURCHASE) {
        setAccessId({
          create: 279,
          view: 280,
          update: 281,
          delete: 282,
          approve: 283,
          reject: 284,
          release: 285,
          files: 286,
        });
      } else {
        setAccessId({
          create: 321,
          view: 322,
          update: 323,
          delete: 324,
          approve: 325,
          reject: 326,
          release: 327,
          files: 328,
        });
      }
    } else if (costingType === CostingTypesEnum.COMMERCIAL) {
      permission = contractType === ContractModesEnum.PURCHASE ? 292 : 334;
      edit = contractType === ContractModesEnum.PURCHASE ? 293 : 335;
      download = contractType === ContractModesEnum.PURCHASE ? 896 : 898;
      viewInvoices = contractType === ContractModesEnum.PURCHASE ? 301 : 343;
      if (contractType === ContractModesEnum.PURCHASE) {
        setAccessId({
          create: 300,
          view: 301,
          update: 302,
          delete: 303,
          approve: 304,
          reject: 305,
          release: 306,
          files: 307,
        });
      } else {
        setAccessId({
          create: 342,
          view: 343,
          update: 344,
          delete: 345,
          approve: 346,
          reject: 347,
          release: 348,
          files: 349,
        });
      }
    } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
      permission = edit = download = viewInvoices = 1;
      setAccessId(
        Object.fromEntries(
          [
            'create',
            'update',
            'delete',
            'approve',
            'reject',
            'release',
            'files',
          ].map((key) => [key, 1]),
        ),
      );
    } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
      permission = 376;
      edit = 377;
      download = 900;
      viewInvoices = 384;
      setAccessId({
        create: 383,
        update: 385,
        delete: 386,
        approve: 387,
        reject: 388,
        release: 389,
        files: 390,
      });
    } else {
      permission = 355;
      edit = 356;
      download = 899;
      viewInvoices = 364;
      setAccessId({
        create: 363,
        update: 365,
        delete: 366,
        approve: 367,
        reject: 368,
        release: 369,
        files: 370,
      });
    }
    setDownloadPermission(download);
    setEditPermission(edit);
    setViewInvoice(viewInvoices);
    if (!isPermissionExist([permission])) navigate('/');
  }, [costingType, contractType]);

  let path;
  let title;
  let pathToList;
  let featuresRef;

  if (costingType === CostingTypesEnum.PERFORMA) {
    path =
      contractType === ContractModesEnum.PURCHASE
        ? 'create-performa-coal-costing'
        : 'create-performa-costing-sale';
    title =
      contractType === ContractModesEnum.PURCHASE
        ? 'Performa Details (Coal Purchase)'
        : 'Performa Details (Coal Sales)';
    pathToList =
      contractType === ContractModesEnum.PURCHASE
        ? 'performaCoalCosting'
        : 'performaCostingSale';
    featuresRef =
      contractType === ContractModesEnum.PURCHASE
        ? ReferenceFeatures.PERFORMA_PC_COSTING
        : ReferenceFeatures.PERFORMA_SC_COSTING;
  } else if (costingType === CostingTypesEnum.COMMERCIAL) {
    path =
      contractType === ContractModesEnum.PURCHASE
        ? 'create-commercial-coal-costing'
        : 'create-commercial-costing-sale';
    title =
      contractType === ContractModesEnum.PURCHASE
        ? 'Commercial Details (Coal Purchase)'
        : 'Commercial Details (Coal Sales)';
    pathToList =
      contractType === ContractModesEnum.PURCHASE
        ? 'commercial-coal-costing'
        : 'commercial-costing-sales';
    featuresRef =
      contractType === ContractModesEnum.PURCHASE
        ? ReferenceFeatures.COMMERCIAL_PC_COSTING
        : ReferenceFeatures.COMMERCIAL_SC_COSTING;
  } else if (costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
    path = 'create-sale-freight-costing';
    title = 'Freight Costing Details (Sales)';
    pathToList = 'salesFreightCosting';
    featuresRef = ReferenceFeatures.SALES_FREIGHT_COSTING;
  } else if (costingType === CostingTypesEnum.MARKETING_FEES) {
    path = 'create-marketing-coal-costing';
    title = 'Marketing Fees Details';
    pathToList = 'marketing-coal-costing';
    featuresRef = ReferenceFeatures.MARKETING_FEES;
  } else {
    path = 'create-procurement-coal-costing';
    title = 'Procurement Fees Details';
    pathToList = 'procurement-coal-costing';
    featuresRef = ReferenceFeatures.PROCUREMENT_FEES;
  }

  useEffect(() => {
    if (
      costingType === CostingTypesEnum.PERFORMA ||
      costingType === CostingTypesEnum.SALE_FREIGHT_COSTING
    ) {
      getAllPerFormaData();
    }
    if (
      costingType === CostingTypesEnum.COMMERCIAL ||
      costingType === CostingTypesEnum.PROCUREMENT_FEES ||
      costingType === CostingTypesEnum.MARKETING_FEES
    ) {
      getAllCommercialData();
    }
  }, [costingId, updatedCostPriority]);

  const getAllPerFormaData = async () => {
    const req = new PerFormaReqIdDto(
      costingId,
      contractType,
      authContext.defaultPlant,
    );
    await performaCostingsService
      .getAllPerFormaData(req)
      .then((res) => {
        if (res.status) {
          const { contractId, contractNo, adjustedPriceValue, incoterm, totalAmount, advanceAdjustment, basePrice } = res.data || {}

          setData({
            ...res.data,
            includeFreight: !!res.data?.includeFreight,  // Simplified boolean check
          });
          const bargeDetails = res.data?.transactionBarges?.map((item) => ({
            ...item,
            pricePmt: basePrice,
            incoterm: incoterm,
            advAdjustment: advanceAdjustment,
            totalPayable: totalAmount,
            adjustedPriceValue: adjustedPriceValue,
            contractId: contractId,
            contractNo: contractNo,
            layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
            remaining: totalAmount

          }))
          setBargeData(bargeDetails)
          setAdvanceAdjustment(res.data?.advanceTarnsactionsData);
          setCostPriority(res?.data?.priority);

        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getAllCommercialData = () => {

    const req = new PerFormaReqIdDto(costingId, contractType, authContext.defaultPlant);
    cmService.getAllCommercialCostingsData(req).then(async res => {
      if (res.status) {
        const { contractId, contractNo, adjustedPriceValue, incoterm, totalAmount, advanceAdjustment, basePrice } = res.data || {}

        setData({
          ...res.data,
          includeFreight: !!res.data?.includeFreight,  // Simplified boolean check
        });
        const bargeDetails = res.data?.transactionBarges?.map((item) => ({
          ...item,
          pricePmt: basePrice,
          incoterm: incoterm,
          advAdjustment: advanceAdjustment,
          totalPayable: totalAmount,
          adjustedPriceValue: adjustedPriceValue,
          contractId: contractId,
          contractNo: contractNo,
          layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
          remaining: totalAmount

        }))
        setBargeData(bargeDetails)
        setAdvanceAdjustment(res.data?.advanceTarnsactionsData);
        setCostPriority(res?.data?.priority);

      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        // setInitialValues(null);
      }
    }).catch(err => { console.log(err.message) });
  };

  const showFreightPrice = () =>
    contractType !== ContractModesEnum.PROCUREMENT_FEES &&
    contractType !== ContractModesEnum.MARKETING_FEES;

  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: BargeData.length }
    else return { rowSpan: 0 }
  };


  const editHandler = () => {
    navigate(`/${path}?costingId=${costingId}`);
  };

  const updateCostingPriority = () => {
    const priority = costPriority === 'NORMAL' ? 'HIGH' : 'NORMAL'
    const req = { id: costingId, plantCode: authContext.defaultPlant, priority, featuresRef }
    performaCostingsService.updateCostingPriority(req).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setUpdatedCostPriority((prev) => !prev)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch((err) => {
      console.log(err);
    });

  }

  const columns2: any = [
    {
      title: 'Advance Type',
      dataIndex: 'advanceType',
    },
    {
      title: 'Costing No',
      dataIndex: 'adjustedCostingNo',
    },
    {
      title: 'Advance Balance',
      dataIndex: 'adjustAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>;
      },
    },

    {
      title: 'Adjusted Amount',
      dataIndex: 'totalAdjustedAmount',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>;
      },
    },
  ];

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  const columns = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      render: (value, record) => {
        if (record.bgUId) {
          const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a>
            <Typography className="small-text"> {record.bgName}</Typography>
          </div>

        }
        return <div>
          <Text className="small-text"> {value}</Text>
          <Typography className="small-text"> {record.bgName}</Typography>
        </div>

      }
    },
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      key: 'businessNo',
      onCell: sharedOnCell,
      render: (value, record) => {
        if (record.bnId) {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <div>
            <a href={link} className="link" target="_blank">{record.businessNo}</a>
            <Typography className="small-text"> {record.mvName}</Typography>
          </div>

        }

      }
    },
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      onCell: sharedOnCell,
      key: 'contractNo',
      render: (value, record) => {
        if (record.contractId) {
          let link = `/#/po-detail-view?pc_id=${record.contractId}`
          if (contractType === ContractModesEnum.SALES) {
            link = `/#/so-detail-view?sc_id=${record.contractId}`;
          }
          if (contractType === ContractModesEnum.PROCUREMENT_FEES) {
            link = `/#/procurement-contract-detailedView?cpcId=${record.contractId}`
          }
          if (contractType === ContractModesEnum.MARKETING_FEES) {
            link = `/#/marketing-detailedView?cpcId=${record.contractId}`

          }
          return <a href={link} className="link" target="_blank">
            {record.contractNo}
          </a>

        }
      }
    },
    {
      title: 'Laycan (Quality)',
      dataIndex: 'layCan',
      key: 'layCan',
      onCell: sharedOnCell,
      render: (value, record) => <>
        <Typography className="small-text">{record.layCan ?? 'NA'}</Typography>
        <Typography className="small-text">{record.incoterm ?? 'NA'}</Typography>
      </>
    },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      onCell: sharedOnCell,
      render: (value, record) => <Text className="small-text">{internationalFormattedValue(value, 3)}</Text>
    },

    {
      title: 'Price Type',
      dataIndex: 'layCan',
      key: 'layCan',
      onCell: sharedOnCell,
      render: (value, record) => <>
        <Typography className="small-text">{data?.priceType}</Typography>
      </>
    },

    {
      title: 'Price PMT',
      dataIndex: 'pricePmt',
      key: 'pricePmt',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(text, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    {
      title: `${data?.priceType === PriceTypeEnum.LUMP_SUM ? 'Lumpsum' : 'Adjusted Price'}`,
      dataIndex: 'adjustedPriceValue',
      key: 'adjustedPriceValue',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.adjustedPriceValue, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    // {
    //   title: 'Total',
    //   dataIndex: 'totalPayable',
    //   key: 'totalPayable',
    //   onCell: sharedOnCell,
    //   render: (text, record) => <>
    //     <Text className="small-text">{internationalFormattedValue(record?.totalPayable, 3)}</Text>
    //     <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

    //   </>

    // },
    // {
    //   title: 'Advance',
    //   dataIndex: 'advAdjustment',
    //   key: 'advAdjustment',
    //   onCell: sharedOnCell,
    //   render: (text, record) => <>
    //     <Text className="small-text">{internationalFormattedValue(record?.advAdjustment, 3)}</Text>
    //     <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

    //   </>

    // },
  ];

  return (
    <>
      <Layout style={{ backgroundColor: '#F5F5F5', border: '10px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'none',
            marginBottom: '10px',
          }}
        >
          <Space size={5}>
            <a
              href={`/#/${pathToList}`}
              style={{ fontSize: '18px', color: '#6B7280' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
            <span
              style={{ fontSize: '16px', fontWeight: 600, color: '#323F49' }}
            >
              {data?.costingNo}
            </span>
            &nbsp;
            <StatusTag status={data?.approvalStatus} />
            <Tooltip title={costPriority === 'NORMAL' ? "Set as High Priority" : "Remove High Priority"}>
              <FontAwesomeIcon
                icon={faFlag}
                style={{ color: costPriority === 'NORMAL' ? "gray" : "red", cursor: "pointer" }}
                onClick={() => updateCostingPriority()}
              />
            </Tooltip>
          </Space>
          <Space size={10}>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => commentsData(featuresRef, 'All Comments')}
              />
            </Tooltip>
            {isPermissionExist([downloadPermission]) && (
              <PDFDownloadLink
                document={
                  <CostingInvoice
                    costingDetails={data}
                    costingName={referenceFeatures}
                    plantCode={authContext.defaultPlant}
                  />
                }
                fileName={`${data?.costingNo}.pdf`}
              >
                <Tooltip placement="topRight" title="Costing Invoice">
                  <FontAwesomeIcon
                    style={{
                      border: 'none',
                      fontSize: '15px',
                      padding: '7px',
                      borderRadius: '50%',
                      color: '#0295D4',
                      backgroundColor: '#DEECF3',
                    }}
                    icon={faDownload}
                    onClick={() => {
                      setDummyRefresh((prev) => prev + 1);
                    }}
                  />
                </Tooltip>
              </PDFDownloadLink>
            )}

            {([
              ContractStatusEnum.CANCELLED,
              ContractStatusEnum.DRAFT,
              ContractStatusEnum.PENDING_APPROVAL,
            ].includes(data?.approvalStatus) ||
              data?.invoices?.some((invoice) => invoice.status === ContractStatusEnum.DRAFT)) &&
              isPermissionExist([editPermission]) && (
                <EditFilled
                  style={{
                    border: 'none',
                    fontSize: '15px',
                    padding: '7px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3',
                  }}
                  onClick={editHandler}
                />
              )}
            {isPermissionExist([viewInvoice]) && (
              <Button
                type="primary"
                htmlType="submit"
                className="new-button"
                onClick={() => {
                  setDrawerOpen(true);
                  setCostingId((prev) => ({
                    ...prev,
                    costingId: prev?.costingId, // Use previous costingId, if applicable
                    referenceFeatures, // Ensure this is defined in scope
                    ...data, // Spread data to include all its properties safely
                    coalAmount: Number(data?.totalAmount ?? 0) - Number(data?.freightInfo?.freightCostingTotal ?? 0),
                    freightAmt: Number(data?.freightInfo?.freightCostingTotal ?? 0),
                    totalAmount: Number(data?.totalAmount ?? 0),
                    amount: Number(data?.totalAmount ?? 0),
                  }));
                }}
              >
                Invoices
              </Button>
            )}
          </Space>
        </div>

        <br />
        <Row gutter={8}>
          <Col span={16}>
            <Card
              className="card-shadow"
              styles={{ body: { padding: '15px 10px 15px' } }}
              style={{ marginBottom: 8 }}
              title={`${title}`}
            >
              <Row>
                <Col span={16} xs={24} md={24} lg={24}>
                  <Descriptions
                    className="descriptions-label"
                    title=""
                    column={{
                      xxl: 6,
                      xl: 6,
                      lg: 6,
                      md: 3,
                      sm: 1,
                      xs: 1,
                    }}
                    layout="vertical"
                    colon={false}
                  >
                    <Descriptions.Item label={'Business Number'}>
                      <span className="descriptions">
                        <a
                          style={{ color: '#0083C9' }}
                          href={`/#/bn-detail-view?bn_Id=${data?.businessNumber}`}
                        >
                          {data?.mvName ? data?.mvName : 'NA'}
                        </a>
                      </span>
                    </Descriptions.Item>

                    <Descriptions.Item label="Coal Supplier">
                      <span className="descriptions">{data?.bpName}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Laycan" span={2}>
                      <span className="descriptions">
                        {`${moment(data?.laycanFromDate).format(
                          'DD-MMM-YYYY',
                        )} to ${moment(data?.laycanToDate).format(
                          'DD-MMM-YYYY',
                        )} - (${data?.quality})`}
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Purchase Type">
                      <span className="descriptions">{data?.incoterm}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Transaction Date">
                      <span className="descriptions">
                        {`${moment(data?.costingDate).format('DD-MMM-YYYY')}`}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            </Card>
            <Card
              className="card-shadow"
              styles={{ body: { padding: '0px 10px 15px' } }}
              style={{ marginBottom: 8 }}
              title={<h6>Barge Details</h6>}
            >
              <Table
                className='contracts-list'
                dataSource={BargeData}
                columns={columns}
                size="small"
                pagination={false}
                scroll={{ x: 1200 }}
              />

            </Card>

            {(data?.purchasingTerms === ContractTermEnum.SPEC && data?.noPriceAdjustment === 'false') &&

              <QualityPriceAdjustmentsDeatils
                contractType={contractType}
                selectedRowData={BargeData[0]}
                formInitialValues={data}

              />
            }

            <Card
              className="card-shadow"
              styles={{ body: { padding: '0px 10px 15px' } }}
              style={{ marginBottom: 8 }}
              title={<h6>Advance Adjustment</h6>}
            >
              <Table
                className="contracts-list"
                dataSource={advanceAdjustment}
                columns={columns2}
                pagination={false}
              />
            </Card>

          </Col >

          <Col span={8}>
            {data?.includeFreight && data?.includeFreight !== 0 &&

              <Card
                className="card-shadow"
                title={<span className="card-title-md">Freight Price Details</span>}
                styles={{ body: { padding: '6px 14px' } }}
                style={{ marginBottom: 8 }}
              >
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Freight Pmt</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightInfo?.freightPricePmt, 3)}  {data?.baseCurrency}
                  </Text>
                </Flex>

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Currency Type</Text>
                  <Text className="price-card-text"> {data?.currencyType ?? 'NA'}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Exchange Date
                    {data?.currencyType === "Middle Rate" && (
                      <span style={{ paddingLeft: 5 }}>
                        <Popover
                          content={
                            <>
                              <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{data?.purchaseRate}</b></Typography>
                              <Typography style={{ fontSize: 10 }}>Selling Rate <b>{data?.sellingRate}</b></Typography>
                            </>
                          }
                          title="Rates"
                          placement="bottom"
                        >
                          <InfoCircleOutlined style={{ color: "#0295D4" }} />
                        </Popover>
                      </span>
                    )}
                  </Text>
                  <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Exchange Rate</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Freight After Exchange Rate </Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightInfo?.freightAfterExchangeRate, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-heading">Total Freight</Text>
                  <Text className="price-card-heading">
                    {internationalFormattedValue(data?.freightInfo?.freightBeforeTax, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>
                <Divider dashed={true} />

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Tax 1</Text>
                  <Text className="price-card-text">{`${data?.freightInfo?.freightTaxName1 || 'NA'} (${data?.freightInfo?.freightTaxPerc1 || '0'})`}</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightInfo?.freightTaxTotal1, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">Tax 2</Text>
                  <Text className="price-card-text">{`${data?.freightInfo?.freightTaxName2 || ''} (${data?.freightInfo?.freightTaxPerc2 || ''})`}</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightInfo?.freightTaxTotal2, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">TDS</Text>
                  <Text className="price-card-text">{`${data?.freightInfo?.freightTdsName || ''} (${data?.freightInfo?.freightTdsPerc || ''})`}</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.freightInfo?.freightTdsTotal, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>

                <Divider dashed={true} />

                <Flex justify="space-between" align='center'>
                  <Text className="price-card-green">Total Freight</Text>
                  <Text className="price-card-green">
                    {internationalFormattedValue(data?.freightInfo?.freightCostingTotal, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>

              </Card>
            }
            <Card
              className="card-shadow"
              title={<span className="card-title-md">Price Details</span>}
              styles={{ body: { padding: '6px 14px' } }}
            >
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Currency Type</Text>
                <Text className="price-card-text"> {data?.currencyType ?? 'NA'}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Date</Text>
                <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Actual Exchange Rate</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.actualExchangeRate, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Rate Adjustment</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.exchangeRateAdjustment, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Exchange Rate
                  {data?.currencyType === CurrencyTypesEnum.MIDDLE_RATE && (
                    <span style={{ paddingLeft: 5 }}>
                      <Popover
                        content={
                          <>
                            <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{data?.purchaseRate}</b></Typography>
                            <Typography style={{ fontSize: 10 }}>Selling Rate <b>{data?.sellingRate}</b></Typography>
                          </>
                        }
                        title="Rates"
                        placement="bottom"
                      >
                        <InfoCircleOutlined style={{ color: "#0295D4" }} />
                      </Popover>
                    </span>
                  )}
                </Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.displayExchangeRate, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Price After Exchange Rate</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.priceAfterExchgRate, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Total Coal Price</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.totalCoalPrice, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
              {costingType !== CostingTypesEnum.MARKETING_FEES && costingType !== CostingTypesEnum.PROCUREMENT_FEES &&
                <Flex justify="space-between" align='center'>
                  <Text className="price-card-text">FC Charges</Text>
                  <Text className="price-card-text">
                    {internationalFormattedValue(data?.fcCharges, 3)}  {data?.quoteCurrency}
                  </Text>
                </Flex>
              }
              <Flex justify="space-between" align='center'>
                <Text className="price-card-heading">Amount</Text>
                <Text className="price-card-heading">
                  {internationalFormattedValue(data?.amountBeforeTax, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>

              <Divider dashed={true} />

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Tax 1</Text>
                <Text className="price-card-text">{`${data?.taxName1 || 'NA'} (${data?.taxPercentage1 || '0'})`}</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.taxTotal1, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">Tax 2</Text>
                <Text className="price-card-text">{`${data?.taxName2 || 'NA'} (${data?.taxPercentage2 || '0'})`}</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.taxTotal2, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
              <Flex justify="space-between" align='center'>
                <Text className="price-card-text">TDS</Text>
                <Text className="price-card-text">{`${data?.tdsName || 'NA'} (${data?.tdsPercentage || '0'})`}</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>

              <Divider dashed={true} />

              <Flex justify="space-between" align='center'>
                <Text className="price-card-heading">Total Amount</Text>
                <Text className="price-card-heading">
                  {internationalFormattedValue(data?.amountAfterTax, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>

              {data?.advanceTarnsactionsData?.map((adv) => {
                let link = `/#/advanceDetailView?id=${adv.adjustedCostingId}`;
                if (contractType === ContractModesEnum.SALES || contractType === ContractModesEnum.MARKETING_FEES) {
                  link = `/#/advance-receivables-detailed-view?id=${adv.adjustedCostingId}`
                }
                if (adv.advanceType === AdvanceEnumType.Against_Performa_Invoice) {
                  link = `/#/performa-pc-detail-view?costing_id=${adv.adjustedCostingId}`
                }
                return (
                  <Flex justify="space-between" align='center' key={adv.id}>
                    <Text className="price-card-text">Deduction (<a href={link} className="link" style={{ color: '#0083C9' }} target='_blank'>{adv.adjustedCostingNo}</a>)</Text>
                    <Text className="price-card-text">
                      {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}  {data?.quoteCurrency}
                    </Text>
                  </Flex>
                )
              })}

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text"><b>Total Advance Adjustment</b></Text>
                <Text className="price-card-text">
                  <b>{internationalFormattedValue(data?.advanceAdjustment, 3)}  {data?.quoteCurrency}</b>
                </Text>
              </Flex>

              <Flex justify="space-between" align='center'>
                <Text className="price-card-green"><b>To be paid to Supplier</b></Text>
                <Text className="price-card-green">
                  <b>{internationalFormattedValue(data?.totalAmount, 3)}  {data?.quoteCurrency}</b>
                </Text>
              </Flex>

              <Flex justify="space-between" align='center'>
                <Text className="price-card-text"><b>Previous Costing Value</b></Text>
                <Text className="price-card-text">
                  <b>{internationalFormattedValue(data?.previousCostingValue, 3)}  {data?.quoteCurrency}</b>
                </Text>
              </Flex>
            </Card>


          </Col>
        </Row>
        {/* <AccountPayableCostings
          costingId={costingId}
          costingType={referenceFeatures}
          showContractDetails={true}

        >

        </AccountPayableCostings> */}

        <Drawer
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
            setDummyRefresh((prev) => prev + 1);
          }}
          width={'1000'}
        >
          <AddInvoice costingData={costingIds} accessId={accessId} vendorId={data?.bpId} />
        </Drawer>
      </Layout>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
  );
};

export default PerformaDetailView;
