import { Button, Col, Divider, Flex, InputNumber, Modal, Row, Select, Space, Table, TablePaginationConfig, Tooltip } from "antd";
import moment from "moment";
import { Children, useEffect, useState } from "react";
import { DatePicker } from 'antd';
import { BusinessTypesEnum, ReportsService } from "@exportx/shared-models-and-services";
import { title } from "process";
import { AlertMessages, useAuthState } from "../../../../common";
import PoPerformanceNoBlBargeList from "./po-performance-barges";
import { internationalFormattedValue } from "@exportx/ui-utils";
import { Excel } from "antd-table-saveas-excel";
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
// import DatePicker from "../../../../common/data-picker/date-picker";
import dayjs from 'dayjs'

interface POPerformanceProps {
  supplier: any
}

export const POPerformanceBar = (props: POPerformanceProps) => {
  const { supplier } = props;
  const { Option } = Select;
  const currentYear = moment().year();
  const { authContext } = useAuthState()
  const [poPerformance, setPoPerformance] = useState<any[]> ([])
  const [selectedSupplier, setSelectedSupplier] = useState<any[]>([]);
  const [businessTyper, setBusinessTyper] = useState<string>('')
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState<boolean>(false)
  const [noBlBarge, setNoBlBarge] = useState([])
  const [edit, setEdit] = useState(false)
  const [selectedYear, setSelectedYear] = useState(currentYear.toString())
  const [modelTitle, setModelTitle] = useState('')

  const reportService = new ReportsService();

  useEffect(()=>{
    getPoSupplierPerformance()
  },[
    currentPage,
    pageSize,
    selectedSupplier,
    businessTyper,
    selectedYear
  ])

  let plantCode = authContext.defaultPlant === 'BKM' || authContext.defaultPlant === 'SDAM' ? true : false;

  const getPoSupplierPerformance = () => {
    const req = {
      offset: (currentPage - 1) * pageSize, 
      limit: pageSize, 
      plantCode: authContext.defaultPlant,
      businessTyper: businessTyper,
      supplierId: selectedSupplier,
      year: selectedYear
    }
    reportService.getPoByCompletionTime(req).then(res => {
      if(res.status) {
        setPoPerformance(res.data?.poPerformance)
        setTotal(res.data?.total)
      }
    }).catch(err => {
      console.error(err.message)
    })
  }

  const handleSubmit = () => {
    const updatedData = poPerformance.flatMap(bp => bp.mines.filter(mine => mine.isUpdated === true));

    if(updatedData.length === 0){
      return AlertMessages.getWarningMessage('No changes detected. Please update at least one field before submitting.')
    }

    reportService.createPoPerformancePlanned(updatedData).then(res => {
      if(res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage)
        setEdit(false)
        getPoSupplierPerformance()
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const handleCancel = () => {
    setEdit(false)
    setPoPerformance(prev => 
      prev.map(bp => ({
        ...bp,
        mines: bp.mines.map(mine => ({ 
          ...mine, 
          isUpdated: false
        }))
      }))
    );
  }

  const renderMonthColumn = (monthIndex: number) => (_, record) => {
    const isOddMonth = (monthIndex + 1) % 2 !== 0;
    const backgroundColor = isOddMonth ? '#f2f7fd' : 'white';
    if (!record?.mines?.length) return <div style={{ height: '140px', backgroundColor }}></div>;
  
    return record.mines.map((mine, index) => {
      let exportSalesTotal = 0;
      let dmoSalesTotal = 0;
      let naTotal = 0;
      let total = 0;
      let exportSales = [];
      let dmoSales = [];
      let naSales = [];
      let totalSales = []
  
      mine.blDates.forEach((blEntry) => {
        if (blEntry.blDate !== null) {
          const dateObj = new Date(blEntry.blDate);
          if (dateObj.getMonth() === monthIndex) {
            let salesArray, totalVar;
  
            if (blEntry.businessType === 'Export Sales') {
              salesArray = exportSales;
              totalVar = 'exportSalesTotal';
            } else if (blEntry.businessType === 'DMO Sales') {
              salesArray = dmoSales;
              totalVar = 'dmoSalesTotal';
            } else if (blEntry.businessType === 'NA') {
              salesArray = naSales;
              totalVar = 'naTotal';
            }
  
            if (salesArray) {
              let sale = salesArray.find(item => item.businessType === blEntry.businessType && item.bpId === blEntry.bgId);
              if (!sale) {
                salesArray.push({
                  bpId: blEntry.bgId,
                  businessType: blEntry.businessType,
                  bargeQty: Number(blEntry.blQty),
                  barges: `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${blEntry.blQty}`
                });
              } else {
                sale.bargeQty += Number(blEntry.blQty);
                sale.barges = `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${sale.bargeQty}`;
              }
              eval(totalVar + ' += blEntry.blQty');
            }
            let totalSale = totalSales.find(item => item.bpId === blEntry.bgId);
            if (!totalSale) {
              totalSales.push({
                bpId: blEntry.bgId,
                businessType: blEntry.businessType,
                bargeQty: Number(blEntry.blQty),
                barges: `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${blEntry.blQty}`
              });
            } else {
              totalSale.bargeQty += Number(blEntry.blQty);
              totalSale.barges = `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${totalSale.bargeQty}`;
            }
          }
        }
      });

      
  
      total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0);
  
      return (
        <div key={index} style={{ backgroundColor }}>
          {[exportSalesTotal, dmoSalesTotal, naTotal].map((salesTotal, idx) => (
            <>
              <div style={{ height: '35px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {salesTotal > 0 ? (
                  <span
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setNoBlBarge([exportSales, dmoSales, naSales][idx]);
                      setOpen(true);
                      setModelTitle('Barge List')
                    }}
                  >
                    {internationalFormattedValue(salesTotal, 3)}
                  </span>
                ) : ('-')}&nbsp;&nbsp;&nbsp;
              </div>
              {idx < 2 && <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />}
            </>
          ))}
          <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
          <div style={{ height: '35px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
            {total > 0 ? 
            <span
              className="link"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setNoBlBarge(totalSales);
                setOpen(true);
                setModelTitle('Barge List')
              }}
            >
              {internationalFormattedValue(total, 3)}
            </span> 
            : '-'}&nbsp;&nbsp;&nbsp;
          </div>
          {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }} />}
        </div>
      );
    });
  };

  
  const handlePlanned = (value, key, record) => {  
    const updatedData = [...poPerformance];
  
    const bpIndex = updatedData.findIndex(bp => bp.bpId === record.bpId);

    if (bpIndex !== -1) {
      const mineIndex = updatedData[bpIndex].mines.findIndex(mine => mine.mineId === record.mineId);
      if (mineIndex !== -1) {
        const mine = updatedData[bpIndex].mines[mineIndex];
        
        if (!mine.plannedQty) {
          mine.plannedQty = {};
        }

        if (mine.plannedQty.plantCode === null || mine.plannedQty.year === null) {
          mine.plannedQty.plantCode = authContext.defaultPlant;
          mine.plannedQty.year = selectedYear
        }

        if(mine.plannedQty.id === null){
          mine.plannedQty.createdAt = new Date();
          mine.plannedQty.createdBy = authContext.user.userName;
        } else {
          mine.plannedQty.updatedAt = new Date();
          mine.plannedQty.updatedBy = authContext.user.userName;
        }

        mine.isUpdated = true;
        mine.plannedQty[key] = value;
  
        setPoPerformance(updatedData);
      }
    }
  };


  const handleExport = (e) => {
    e.preventDefault();
  
    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split('-')
      .join('/');
  
    const flattenedData = [];
    poPerformance.forEach((supplier) => {
      supplier.mines.forEach((mine) => {
        let exportSalesTotal = 0;
        let dmoSalesTotal = 0;
        let naTotal = 0;
        let blNotAssignedExport = 0;
        let blNotAssignedDmo = 0;
        let blNotAssignedNa = 0;
  
        const monthlyActuals = Array(12).fill(null).map(() => ({
          exportSales: 0,
          dmoSales: 0,
          na: 0,
        }));
  
        mine.blDates.forEach((blEntry) => {
          if (blEntry.blDate !== null) {
            const dateObj = new Date(blEntry.blDate);
            const monthIndex = dateObj.getMonth();
            if (blEntry.businessType === 'Export Sales') {
              exportSalesTotal += Number(blEntry.blQty);
              monthlyActuals[monthIndex].exportSales += Number(blEntry.blQty);
            } else if (blEntry.businessType === 'DMO Sales') {
              dmoSalesTotal += Number(blEntry.blQty);
              monthlyActuals[monthIndex].dmoSales += Number(blEntry.blQty);
            } else if (blEntry.businessType === 'NA') {
              naTotal += Number(blEntry.blQty);
              monthlyActuals[monthIndex].na += Number(blEntry.blQty);
            }
          } else {
            if (blEntry.businessType === 'Export Sales') {
              blNotAssignedExport += Number(blEntry.blQty);
            } else if (blEntry.businessType === 'DMO Sales') {
              blNotAssignedDmo += Number(blEntry.blQty);
            } else if (blEntry.businessType === 'NA') {
              blNotAssignedNa += Number(blEntry.blQty);
            }
          }
        });
  
        const businessTypes = plantCode ? ['Export Sales', 'DMO Sales', 'NA'] : ['Export Sales', 'NA'];
        businessTypes.forEach((businessType) => {
          const row = {
            'Supplier Name': supplier.bpName,
            'Mine Name': mine.mineName,
            'Business Type': businessType,
          };
  
          ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].forEach((month, index) => {
            const actualKey = `${month.charAt(0).toUpperCase() + month.slice(1)} Actual`;
            const plannedKey = `${month.charAt(0).toUpperCase() + month.slice(1)} Planned`;
            if (businessType === 'Export Sales') {
              row[actualKey] = monthlyActuals[index].exportSales || '-';
              row[plannedKey] = Number(mine.plannedQty[`${month}Export`]) || '-';
            } else if (businessType === 'DMO Sales') {
              row[actualKey] = monthlyActuals[index].dmoSales || '-';
              row[plannedKey] = Number(mine.plannedQty[`${month}Dmo`]) || '-';
            } else if (businessType === 'NA') {
              row[actualKey] = monthlyActuals[index].na || '-';
              row[plannedKey] = Number(mine.plannedQty[`${month}Na`]) || '-';
            } else if (businessType === 'Total') {
              row[actualKey] = (monthlyActuals[index].exportSales + monthlyActuals[index].dmoSales + monthlyActuals[index].na) || '-';
              row[plannedKey] = (
                Number(mine.plannedQty[`${month}Export`] || 0) +
                Number(mine.plannedQty[`${month}Dmo`] || 0) +
                Number(mine.plannedQty[`${month}Na`] || 0)
              ) || '-';
            }
          });
  
          row['Total Quantity'] =
            businessType === 'Export Sales'
              ? exportSalesTotal || '-'
              : businessType === 'DMO Sales'
              ? dmoSalesTotal || '-'
              : businessType === 'NA'
              ? naTotal || '-'
              : (exportSalesTotal + dmoSalesTotal + naTotal) || '-';
  
          row['BL Not Assigned'] =
            businessType === 'Export Sales'
              ? blNotAssignedExport || '-'
              : businessType === 'DMO Sales'
              ? blNotAssignedDmo || '-'
              : businessType === 'NA'
              ? blNotAssignedNa || '-'
              : (blNotAssignedExport + blNotAssignedDmo + blNotAssignedNa) || '-';
  
          flattenedData.push(row); 
        });
      });
    });
  
    const exportingColumns = [
      { title: 'Supplier Name', dataIndex: 'Supplier Name' },
      { title: 'Mine Name', dataIndex: 'Mine Name' },
      { title: 'Business Type', dataIndex: 'Business Type' },
      { title: 'January Planned', dataIndex: 'January Planned' },
      { title: 'January Actual', dataIndex: 'January Actual' },
      { title: 'February Planned', dataIndex: 'February Planned' },
      { title: 'February Actual', dataIndex: 'February Actual' },
      { title: 'March Planned', dataIndex: 'March Planned' },
      { title: 'March Actual', dataIndex: 'March Actual' },
      { title: 'April Planned', dataIndex: 'April Planned' },
      { title: 'April Actual', dataIndex: 'April Actual' },
      { title: 'May Planned', dataIndex: 'May Planned' },
      { title: 'May Actual', dataIndex: 'May Actual' },
      { title: 'June Planned', dataIndex: 'June Planned' },
      { title: 'June Actual', dataIndex: 'June Actual' },
      { title: 'July Planned', dataIndex: 'July Planned' },
      { title: 'July Actual', dataIndex: 'July Actual' },
      { title: 'August Planned', dataIndex: 'August Planned' },
      { title: 'August Actual', dataIndex: 'August Actual' },
      { title: 'September Planned', dataIndex: 'September Planned' },
      { title: 'September Actual', dataIndex: 'September Actual' },
      { title: 'October Planned', dataIndex: 'October Planned' },
      { title: 'October Actual', dataIndex: 'October Actual' },
      { title: 'November Planned', dataIndex: 'November Planned' },
      { title: 'November Actual', dataIndex: 'November Actual' },
      { title: 'December Planned', dataIndex: 'December Planned' },
      { title: 'December Actual', dataIndex: 'December Actual' },
      { title: 'Total Quantity', dataIndex: 'Total Quantity' },
      { title: 'BL Not Assigned', dataIndex: 'BL Not Assigned' },
    ];
  
    const excel = new Excel();
    excel.addSheet('Sheet1');
    excel.addRow();
    excel
      .addColumns(exportingColumns)
      .addDataSource(flattenedData)
      .saveAs(`Mining-Performance-${currentDate}.xlsx`);
  };

  const renderPlannedColumn = (monthKey: string, monthIndex) => (value, record) => {
    const isOddMonth = monthIndex % 2 !== 0;
    const backgroundColor = isOddMonth ? '#f2f7fd' : 'white';
    if (!record?.mines?.length) return <div style={{ height: '140px', backgroundColor }}></div>;
    return record?.mines?.map((item, index) => (
      edit ? (
        <div style={{ backgroundColor }}>
          <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <InputNumber 
              style={{ width: '90%' }} 
              value={item.plannedQty[`${monthKey}Export`] ? Number(item.plannedQty[`${monthKey}Export`]) : null}
              onChange={(e) => handlePlanned(e, `${monthKey}Export`, item)} 
              />
          </div>
          <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
          {plantCode && (
            <>
              <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <InputNumber 
                  style={{ width: '90%' }} 
                  value={item.plannedQty[`${monthKey}Dmo`] ? Number(item.plannedQty[`${monthKey}Dmo`]) : null}
                  onChange={(e) => handlePlanned(e, `${monthKey}Dmo`, item)} 
                />
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
            </>
          )}
          <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <InputNumber 
              style={{ width: '90%' }} 
              value={item.plannedQty[`${monthKey}Na`] ? Number(item.plannedQty[`${monthKey}Na`]) : null}
              onChange={(e) => handlePlanned(e, `${monthKey}Na`, item)} 
            />
          </div>
          <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
          <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center', fontWeight: 'bold' }}>
            <span>
              {internationalFormattedValue(
                (Number(item.plannedQty[`${monthKey}Export`] || 0) +
                Number(item.plannedQty[`${monthKey}Na`] || 0) +
                (plantCode ? Number(item.plannedQty[`${monthKey}Dmo`] || 0) : 0)), 
                3
              )}
            </span>
          </div>
          {index !== record?.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0' }} />}
        </div>
      ) : (
        <div style={{ backgroundColor }}>
          <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <span>{internationalFormattedValue(item.plannedQty[`${monthKey}Export`] ? Number(item.plannedQty[`${monthKey}Export`]) : 0, 3)}</span>&nbsp;&nbsp;&nbsp;
          </div>
          <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
          {plantCode && (
            <>
              <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <span>{internationalFormattedValue(item.plannedQty[`${monthKey}Dmo`] ? Number(item.plannedQty[`${monthKey}Dmo`]) : 0, 3)}</span>&nbsp;&nbsp;&nbsp;
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
            </>
          )}
          <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <span>{internationalFormattedValue(item.plannedQty[`${monthKey}Na`] ? Number(item.plannedQty[`${monthKey}Na`]) : 0, 3)}</span>&nbsp;&nbsp;&nbsp;
          </div>
          <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
          <div style={{ height: '35px', display: 'flex', justifyContent: 'end', alignItems: 'center', fontWeight: 'bold' }}>
            <span>
              {internationalFormattedValue(
                (Number(item.plannedQty[`${monthKey}Export`] || 0) +
                Number(item.plannedQty[`${monthKey}Na`] || 0) +
                (plantCode ? Number(item.plannedQty[`${monthKey}Dmo`] || 0) : 0)), 
                3
              )}
            </span>&nbsp;&nbsp;&nbsp;
          </div>
          {index !== record?.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0' }} />}
        </div>
      )
    ));
  };

  const handleYearChange = (date, dateString) => {
    setSelectedYear(dateString)
  };

  const columns: any = [
    {
      title: 'Supplier Name',
      dataIndex: 'bpName',
      width: 280,
    },
    {
      title: 'Mine Name',
      dataIndex: 'mineName',
      width: 280,
      render: (value, record) => {
        return record?.mines?.map((item, index) => {
          const mineCount = item?.blDates?.length || 1;

          return (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: plantCode ? `144px` : '107px',
                }}
              >
                {item.mineName}
              </div>
              {index !== record?.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0' }} />}
            </div>
          );
        });
      },
    },
    {
      title: 'Business Type',
      dataIndex: '',
      render: (value, record) => {
        if(record?.mines.length === 0) {
          return (
            <div 
            >
              <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                <span>Export Sales</span>
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                  <span>DMO Sales</span>
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
              </> : null}
              <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                <span>NA</span>
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
              <div style={{ height: '35px',display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                <span>Total</span>
              </div>
            </div>)
        }
        return record?.mines?.map((item, index) => {
          return (
              <div 
              >
                <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                  <span>Export Sales</span>
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                {plantCode ? <>
                  <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                    <span>DMO Sales</span>
                  </div>
                </> : null}
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                  <span>NA</span>
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                <div style={{ height: '35px',display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                  <span>Total</span>
                </div>
                {index !== record?.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0' }} />}
              </div>
        );
      });
      },
    }, 
    {
      title: 'January',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      children: [
        {
          title: 'Planned',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderPlannedColumn('january', 1)
        },
        {
          title: 'Actual',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderMonthColumn(0),
        },
      ]
    },
    {
      title: 'February',
      children: [
        {
          title: 'Planned',
          width: 150,
          align: 'center',
          render: renderPlannedColumn('february', 2),
        },
        {
          title: 'Actual',
          width: 150,
          align: 'center',
          render: renderMonthColumn(1),
        },
      ]
    },
    {
      title: 'March',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      children: [
        {
          title: 'Planned',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderPlannedColumn('march', 3),
        },
        {
          title: 'Actual',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderMonthColumn(2),
        },
      ]
    },    
    {
      title: 'April',
      children: [
        {
          title: 'Planned',
          width: 150,
          align: 'center',
          render: renderPlannedColumn('april', 4),
        },
        {
          title: 'Actual',
          width: 150,
          align: 'center',
          render: renderMonthColumn(3),
        },
      ]
    }, 
    {
      title: 'May',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      children: [
        {
          title: 'Planned',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderPlannedColumn('may', 5),
        },
        {
          title: 'Actual',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderMonthColumn(4),
        },
      ]
    }, 
    {
      title: 'June',
      children: [
        {
          title: 'Planned',
          width: 150,
          align: 'center',
          render: renderPlannedColumn('june', 6),
        },
        {
          title: 'Actual',
          width: 150,
          align: 'center',
          render: renderMonthColumn(5),
        },
      ]
    }, 
    {
      title: 'July',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      children: [
        {
          title: 'Planned',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderPlannedColumn('july', 7),
        },
        {
          title: 'Actual',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderMonthColumn(6),
        },
      ]
    }, 
    {
      title: 'August',
      children: [
        {
          title: 'Planned',
          width: 150,
          align: 'center',
          render: renderPlannedColumn('august', 8),
        },
        {
          title: 'Actual',
          width: 150,
          align: 'center',
          render: renderMonthColumn(7),
        },
      ]
    }, 
    {
      title: 'September',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      children: [
        {
          title: 'Planned',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderPlannedColumn('september', 9),
        },
        {
          title: 'Actual',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderMonthColumn(8),
        },
      ]
    }, 
    {
      title: 'October',
      children: [
        {
          title: 'Planned',
          width: 150,
          align: 'center',
          render: renderPlannedColumn('october', 10),
        },
        {
          title: 'Actual',
          width: 150,
          align: 'center',
          render: renderMonthColumn(9),
        },
      ]
    }, 
    {
      title: 'November',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      children: [
        {
          title: 'Planned',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderPlannedColumn('november', 11),
        },
        {
          title: 'Actual',
          onHeaderCell: () => ({
            style: { backgroundColor: '#dbebff' }
          }),
          width: 150,
          align: 'center',
          render: renderMonthColumn(10),
        },
      ]
    }, 
    {
      title: 'December',
      children: [
        {
          title: 'Planned',
          width: 150,
          align: 'center',
          render: renderPlannedColumn('december', 12),
        },
        {
          title: 'Actual',
          width: 150,
          align: 'right',
          render: renderMonthColumn(11),
        },
      ]
    }, 
    {
      title: 'Total Quantity',
      dataIndex: '',
      onHeaderCell: () => ({
        style: { backgroundColor: '#dbebff' }
      }),
      width: 160,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return <div style={{ height: '140px', backgroundColor: '#f2f7fd' }}></div>;
       
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
          let exportSales = [];
          let dmoSales = [];
          let naSales = [];
          let totalSales = []
    
          mine.blDates.forEach((blEntry) => {
            let quantity = Number(blEntry.blQty) || 0;
            if(blEntry.blDate !== null){
              if (blEntry.businessType === 'Export Sales') {
                let exportSale = exportSales.find(item => item.businessType === blEntry.businessType && item.bpId === blEntry.bgId);
                if (!exportSale) {
                  exportSales.push({
                    bpId: blEntry.bgId,
                    businessType: blEntry.businessType,
                    bargeQty: quantity,
                    barges: `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${blEntry.blQty}`
                  });
                } else {
                  exportSale.bargeQty += quantity;
                  exportSale.barges = `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${exportSale.bargeQty}`;
                }
                exportSalesTotal += quantity;
              } else if (blEntry.businessType === 'DMO Sales') {
                let dmoSale = dmoSales.find(item => item.businessType === blEntry.businessType && item.bpId === blEntry.bgId);
                if (!dmoSale) {
                  dmoSales.push({
                    bpId: blEntry.bgId,
                    businessType: blEntry.businessType,
                    bargeQty: quantity,
                    barges: `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${blEntry.blQty}`
                  });
                } else {
                  dmoSale.bargeQty += quantity;
                  dmoSale.barges = `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${dmoSale.bargeQty}`;
                }
                dmoSalesTotal += quantity;
              } else if(blEntry.businessType === 'NA'){
                let naSale = naSales.find(item => item.businessType === blEntry.businessType && item.bpId === blEntry.bgId);
                if (!naSale) {
                  naSales.push({
                    bpId: blEntry.bgId,
                    businessType: blEntry.businessType,
                    bargeQty: quantity,
                    barges: `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${blEntry.blQty}`
                  });
                } else {
                  naSale.bargeQty += quantity;
                  naSale.barges = `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${naSale.bargeQty}`;
                }
                naTotal += blEntry.blQty
              }
              let totalSale = totalSales.find(item => item.businessType === blEntry.businessType && item.bpId === blEntry.bgId);
              if (!totalSale) {
                totalSales.push({
                  bpId: blEntry.bgId,
                  businessType: blEntry.businessType,
                  bargeQty: quantity,
                  barges: `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${blEntry.blQty}`
                });
              } else {
                totalSale.bargeQty += quantity;
                totalSale.barges = `${blEntry.bgNo},${blEntry.bgName},${blEntry.bgId},${totalSale.bargeQty}`;
              }
            }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index} style={{ backgroundColor: '#f2f7fd' }}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {exportSalesTotal > 0 ? 
                <span 
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setNoBlBarge(exportSales);
                    setOpen(true);
                    setModelTitle('Barge List')
                  }}
                >
                  {internationalFormattedValue(exportSalesTotal, 3)}
                  </span> : '-'}&nbsp;&nbsp;&nbsp;
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                  {dmoSalesTotal > 0 ? 
                  <span
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setNoBlBarge(dmoSales);
                      setOpen(true);
                      setModelTitle('Barge List')
                    }}
                  >
                    {internationalFormattedValue(dmoSalesTotal, 3)}
                  </span> : '-'}&nbsp;&nbsp;&nbsp;
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {naTotal > 0 ? 
                <span
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setNoBlBarge(naSales);
                    setOpen(true);
                    setModelTitle('Barge List')
                  }}
                >
                  {internationalFormattedValue(naTotal, 3)}
                </span> : '-'}&nbsp;&nbsp;&nbsp;
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? 
                <span
                  style={{ cursor: 'pointer', color: 'green',transition: 'text-decoration 0.2s ease-in-out' }}
                  onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                  onClick={() => {
                    setNoBlBarge(totalSales);
                    setOpen(true);
                    setModelTitle('Barge List')
                  }}
                >
                  {internationalFormattedValue(total, 3)}
                </span> : '-'}&nbsp;&nbsp;&nbsp;
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'Completion Date Not Assigned',
      dataIndex: '',
      width: 200,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0
    
          mine.noBargeBlDates?.forEach((blEntry) => {
            if (blEntry.businessType === 'Export Sales') {
              exportSalesTotal += Number(blEntry.bargeQty);
            } else if (blEntry.businessType === 'DMO Sales') {
              dmoSalesTotal += Number(blEntry.bargeQty);
            } else if(blEntry.businessType === 'NA'){
              naTotal += Number(blEntry.bargeQty);
            }
          });
          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)

          let exportSales = mine.noBargeBlDates?.find(item => item.businessType === 'Export Sales')
          let dmoSales = mine.noBargeBlDates?.find(item => item.businessType === 'DMO Sales')
          let na = mine.noBargeBlDates?.find(item => item.businessType === 'NA')

          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span 
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {setNoBlBarge([exportSales]);setOpen(true);setModelTitle('No Completion Date Barge List')}}
                >
                  {internationalFormattedValue(exportSalesTotal, 3)}
                </span> : '-'}&nbsp;&nbsp;&nbsp;
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span 
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {setNoBlBarge([dmoSales]);setOpen(true);setModelTitle('No Completion Date Barge List')}}
                  >
                    {internationalFormattedValue(dmoSalesTotal, 3)}
                  </span> : '-'}&nbsp;&nbsp;&nbsp;
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span 
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {setNoBlBarge([na]);setOpen(true);setModelTitle('No Completion Date Barge List')}}
                  >
                    {internationalFormattedValue(naTotal, 3)}
                  </span>  : '-'}&nbsp;&nbsp;&nbsp;
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {setNoBlBarge([...mine.noBargeBlDates]);setOpen(true);setModelTitle('No Completion Date Barge List')}}
                >{internationalFormattedValue(total, 3)}</span> : '-'}&nbsp;&nbsp;&nbsp;
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
  ]

  const handleSupplierChange = (value: any[]) => {
    setSelectedSupplier(value);
  };

  const handleBusinessTyper = (value: string) => {
    setBusinessTyper(value)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  return (
        <>
          <Row gutter={[10, 10]} style={{ marginBottom: 16 }} justify="start">
            <Col xs={24} md={24} lg={20}>
              <Flex wrap>
                <Col xs={24} md={8} lg={5}>
                  <Select
                    mode="multiple"
                    placeholder="Filter By Supplier"
                    style={{ width: "100%" }}
                    onChange={(value) => handleSupplierChange(value)}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={selectedSupplier || null}
                    allowClear
                    showSearch
                  >
                    {supplier?.map((rec) => (
                      <Option key={rec.bpId} value={rec.bpId}>{rec.bpName}</Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} md={8} lg={5}>
                  <Select
                    placeholder="Filter By Business Type"
                    style={{ width: "100%" }}
                    onChange={(value) => handleBusinessTyper(value)}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={businessTyper || null}
                    allowClear
                    showSearch
                  >
                    {Object.keys(BusinessTypesEnum).map((key) => (
                      <Option key={key} value={BusinessTypesEnum[key]}>
                        {BusinessTypesEnum[key]}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} md={8} lg={5}>
                  <DatePicker 
                    onChange={handleYearChange} 
                    picker="year"
                    defaultValue={dayjs()}
                    disabledDate={(current) => current.year() < 2018}
                    allowClear={false} 
                  />
                </Col>
              </Flex>
            </Col>
            <Col xs={24} md={8} lg={4} style={{ textAlign: 'end' }}>
              <Tooltip placement="top" title='Export As Excel'>
                <Button
                  icon={<FileExcelFilled style={{ color: 'green' }} />}
                  className="icon"
                  onClick={handleExport}
                />
              </Tooltip> &nbsp;&nbsp;
              {!edit ? (
                <Button type="primary" onClick={() => setEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Space>
                  <Button type="primary" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button  onClick={() => handleCancel()}>
                    Cancel
                  </Button>
                </Space>
              )}
            </Col>
          </Row>

          <Table
            className="procurement-list"
            columns={columns}
            dataSource={poPerformance}
            scroll={{ x: 'max-content' }}
            rowClassName={"main-row"}
            pagination={{
              current: currentPage,
              pageSize: pageSize, 
              total: total,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
          />
          <Modal
            open={open}
            onCancel={() => setOpen(false)}
            title={modelTitle}
            footer={null}

          >
            <PoPerformanceNoBlBargeList boBlBarges={noBlBarge} />
          </Modal>
        </>  
  )
}

export default POPerformanceBar;