import {
  AdvanceTypeReqDto,
  BGCoalCostingService,
  CalculationFilterDataReq,
  CommercialCoalCostingService,
  ContractModesEnum,
  ContractStatusEnum,
  CostingTypesEnum,
  PerformaAdvanceDataDto,
  PerformaCoalCostingService,
  PerformaPCCostingsTransactionsDto,
  PerFormaReqIdDto,
  PurchaseContractService,
  ReferenceFeatures,
  SalesContractService,
  ShippingTypes,
} from '@exportx/shared-models-and-services';
import { getBase64 } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Flex, Form, Input, Row, } from 'antd';
import { useEffect, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import {
  CoalCostingFilterComponent,
  ICoalCostingFilterInitialValues,
} from '../common';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import PerformaAdvanceAdjustments from './performa-advance-adjustment';
import moment from 'moment';
import PerformaCostingBargeSelection from './performa-barge-selection';
import PerformaPriceAdjustment from './performa-price-adjustment';

interface perFormaIProps {
  contractType: ContractModesEnum;
}

export const PerformaCoalCostings = (props: perFormaIProps) => {
  const { authContext } = useAuthState();
  const [priceCalculationData, setPriceCalculationData] = useState<any[]>([]);
  const [filterData, setFilterData] =
    useState<ICoalCostingFilterInitialValues>(null);
  const navigate = useNavigate();
  const [formRef] = Form.useForm();
  const { contractType } = props;

  const bgCoalCostingService = new BGCoalCostingService();
  const purchase = new PurchaseContractService();
  const sales = new SalesContractService();
  const performaCostingsService = new PerformaCoalCostingService();
  const location = useLocation();
  const costingId = queryString.parse(location.search)?.costingId;

  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<any>({});
  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  const [invoiceContracts, setInvoiceContracts] = useState<
    PerformaAdvanceDataDto[]
  >([]);

  const [performaAdvance, setPerformaAdvance] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const cmService = new CommercialCoalCostingService();


  const [bargeSelection, setBargeSelection] = useState<any>([]);
  const [bargeSelectionRowKeys, setBargeSelectionRowKeys] = useState<any[]>([]);

  const [accessId, setAccessId] = useState({});
  const [createPermission, setCreatePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  const [taxTotal, setTaxTotal] = useState<any>({

  });

  useEffect(() => {
    let permission;
    if (contractType === ContractModesEnum.PURCHASE) {
      permission = [271];
      setViewPermission(isPermissionExist([271]));
      setCreatePermission(isPermissionExist([270]));
      setAccessId({
        create: 279,
        view: 280,
        update: 281,
        delete: 282,
        approve: 283,
        reject: 284,
        release: 285,
        files: 286,
        purchaseEdit: 272,
      });
    } else {
      permission = [313];
      setViewPermission(isPermissionExist([313]));
      setCreatePermission(isPermissionExist([312]));
      setAccessId({
        create: 321,
        view: 322,
        update: 323,
        delete: 324,
        approve: 325,
        reject: 326,
        release: 327,
        files: 328,
        purchaseEdit: 314,
      });
    }
    if (!isPermissionExist(permission)) navigate('/');
  }, [contractType]);

  useEffect(() => {
    getAllData(costingId);
  }, [costingId]);

  let referenceFeatures: ReferenceFeatures;
  if (contractType === ContractModesEnum.PURCHASE) {
    referenceFeatures = ReferenceFeatures.PERFORMA_PC_COSTING;
  } else if (contractType === ContractModesEnum.SALES) {
    referenceFeatures = ReferenceFeatures.PERFORMA_SC_COSTING;
  }

  const getPerformaNonFOBBargeData = (req: CalculationFilterDataReq) => {
    purchase
      .getPerformaNonFOBBargeData(req)
      .then((res: any) => {
        if (res.status) {
          setPriceCalculationData(res.data);
        } else {
          setPriceCalculationData([]);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
        setPriceCalculationData([]);
      });
  };

  const getPerformaFOBBargeData = (req: CalculationFilterDataReq) => {
    bgCoalCostingService
      .getPerformaFOBBargeData(req)
      .then((res: any) => {
        if (res.status) {
          setPriceCalculationData(res.data);
        } else {
          setPriceCalculationData([]);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
        setPriceCalculationData([]);
      });
  };

  const getPerformaNonSalesFOBBargeData = (req: CalculationFilterDataReq) => {
    sales
      .getPerformaNonSalesFOBBargeData(req)
      .then((res: any) => {
        if (res.status) {
          setPriceCalculationData(res.data);
        } else {
          setPriceCalculationData([]);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
        setPriceCalculationData([]);
      });
  };

  const costingsSave = () => {
    formRef.validateFields().then(async (values) => {
      const formFilesData = formRef.getFieldValue('filesData');
      let filesData = [];
      if (formFilesData?.file) {
        const base64 = await getBase64(formFilesData.file.originFileObj);
        filesData = [
          {
            ...formFilesData.file,
            base64Url: base64,
            fileDescription: 'Invoice file',
          },
        ];
      }
      const transactionsArray: PerformaPCCostingsTransactionsDto[] = [];
      for (const item of bargeSelection) {
        const transactionsDto = {
          ...new PerformaPCCostingsTransactionsDto(
            item.quantityInMt,
            item.coalBasePrice,
            item.baseCurrency,
            item.quoteCurrency,
            item.exchangeRate,
            item.exchangeDate,
            item.total,
            item.bgUId,
            authContext.defaultPlant,
            authContext.user.userName,
            item.currencyType,
            item.exchangeRateAdjustment,
          ),
          perFormaTransactionId:
            initialValues?.transactionBarges[0]?.perFormaTransactionId,
        };
        transactionsArray.push(transactionsDto);
      }
      const payload = {
        ...taxTotal,
        ...values,
        exchangeRateValue: values.exchangeRate || 0,
        totalPenalty: values.totalBonusPrice,
        costingDate: values?.costingDate ? moment(values?.costingDate).format('YYYY-MM-DD') : null,
        exchangeDate: values?.exchangeDate ? moment(values.exchangeDate).format('YYYY-MM-DD') : null,
        costingType: referenceFeatures,
        costingTransactions: transactionsArray,
        quoteCurrency: authContext.defaultPlantCurrency,
        coalPricePMT: bargeSelection[0]?.coalBasePrice,
        contractType: contractType,
        incoterm: bargeSelection[0]?.incoterm,
        costingId: costingId,
        costingNo: initialValues?.costingNo,
        adjustmentTransactions: adjustedData,
        bpId: bargeSelection[0]?.coalSupplierId,
        vendorId: bargeSelection[0]?.vendorId,
        plantCode: authContext.defaultPlant,
        userName: authContext.user.userName,
      }
      console.log('payload', payload);
      performaCostingsService
        .savePerFormaCostings(payload)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              if (costingId) {
                navigate(`/performa-pc-detail-view?costing_id=${costingId}`);
              }
              else {
                navigate('/performaCoalCosting');
              }
            }, 3000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => console.log(err.message));
    });
  };

  const loadBargesOnClickHandler = (
    values: ICoalCostingFilterInitialValues,
  ) => {
    setFilterData(values);
    const req = new CalculationFilterDataReq(
      authContext.defaultPlant,
      values.businessNumber,
      values.contractId,
      values.qualityId,
      values.incoterm,
      values.purchaseType,
    );
    if (values.incoterm === 'FOB BARGE') {
      getPerformaFOBBargeData(req);
    } else {
      if (contractType === ContractModesEnum.SALES) {
        getPerformaNonSalesFOBBargeData(req);
      } else {
        getPerformaNonFOBBargeData(req);
      }
    }
  };
  const getAllData = async (id: string) => {
    const req = new PerFormaReqIdDto(
      id,
      contractType,
      authContext.defaultPlant,
    );
    await performaCostingsService
      .getAllPerFormaData(req)
      .then((res) => {
        if (res.status) {
          setInitialValues(res.data);

          const req = new CalculationFilterDataReq(
            authContext.defaultPlant,
            res.data.businessNumber,
            res.data.contractId,
            res.data.qualityId,
            res.data.incoterm,
            res.data.purchaseType,
          );
          if (res.data.incoterm === ShippingTypes.FOB_BARGE) {
            bgCoalCostingService
              .getPerformaFOBBargeData(req)
              .then((response: any) => {
                if (response.status) {
                  const bargeId = [];
                  const bargesData = [];
                  for (const bg of res.data.transactionBarges) {
                    bargeId.push(bg.bgId);
                    const bgD = response.data.filter(
                      (rec) => rec.bgUId === bg.bgId,
                    )[0];
                    bargesData.push(bgD);
                  }
                  rowSelection.onChange(bargeId, bargesData, false);
                  setPriceCalculationData(response.data);
                  setAdjustedData(res.data.advanceTarnsactionsData);
                  setTaxTotal((prev) => {
                    return {
                      ...prev,
                      ...res?.data,
                      totalPayable: res?.data?.totalAmount
                    };
                  });
                  formRef.setFieldsValue({
                    ...res?.data,

                    qualityAdjustPrice: res.data.adjustedPriceValue,
                    totalAdjustedValue: res.data.advanceAdjustment,
                    costingDate: res.data.costingDate ? moment(res.data.costingDate) : null,
                    exchangeDate: res.data.exchangeDate ? moment(res.data.exchangeDate) : null,
                  });
                } else {
                  setPriceCalculationData([]);
                }
              })
              .catch((err) => {
                console.log('error: ' + err.message);
                setPriceCalculationData([]);
              });

          }
          else {
            purchase
              .getPerformaNonFOBBargeData(req)
              .then((response: any) => {
                if (response.status) {
                  const bargeId = [];
                  const bargesData = [];
                  for (const bg of res.data.transactionBarges) {
                    bargeId.push(bg.bgId);
                    const bgD = response.data.filter(
                      (rec) => rec.bgUId === bg.bgId,
                    )[0];
                    bargesData.push(bgD);
                  }
                  rowSelection.onChange(bargeId, bargesData, false);
                  setPriceCalculationData(response.data);
                } else {
                  setPriceCalculationData([]);
                }
              })
              .catch((err) => {
                console.log('error: ' + err.message);
                setPriceCalculationData([]);
              });
          }



        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getInvoiceContracts = (req: AdvanceTypeReqDto, incoterm: string) => {
    if (req?.bargeId?.length) {
      cmService
        .getInvoiceContracts(req)
        .then((res) => {
          if (res.status) {
            setInvoiceContracts(res.data);
            if (incoterm === 'FOB BARGE') {
              setPerformaAdvance(res.data);
            }
          } else {
            // AlertMessages.getErrorMessage(res.internalMessage)
            setInvoiceContracts([]);
            setPerformaAdvance([]);
          }
        })
        .catch((err) => console.log(err.message));
    } else {
      formRef.setFieldValue('advanceType', undefined);
      formRef.setFieldValue('invoiceContractId', undefined);
      formRef.setFieldValue('advanceBalance', undefined);
      formRef.setFieldValue('advanceToAdjust', undefined);
    }
  };

  const qualityOnChange = (e: number) => {
    setDummyRefresh((prev) => prev + 1);
  };

  console.log('priceCalculationData', priceCalculationData);

  const rowSelection = {
    columnTitle: <></>,
    onChange: (selectedRowKeys: string[], selectedRows: any[], isUpdate?: boolean) => {
      console.log(isUpdate);

      setBargeSelection(selectedRows);
      setBargeSelectionRowKeys(selectedRowKeys);
      if (isUpdate === false) return;
      const totalQuantity = selectedRows.reduce((sum, row) => sum + Number(row.quantityInMt), 0);
      formRef.setFieldsValue({
        adjustedPriceValue: selectedRows[0]?.coalBasePrice,
        totalBargeQuantity: totalQuantity.toFixed(3),
        baseCurrency: selectedRows[0]?.baseCurrency,
        freightPricePmt: selectedRows[0]?.freightPricePmt,

      })
      setDummyRefresh((prev) => prev + 1);
    },
    getCheckboxProps: (record) => {
      if (costingId) {
        record.isBargeMapped = false;
      }
      if (bargeSelection[0]?.qualityGroupId || record.isBargeMapped) {
        if (
          bargeSelection[0]?.qualityGroupId !== record?.qualityGroupId ||
          record.isBargeMapped
        ) {
          return { disabled: true };
        }
      }

      return {};
    },
    selectedRowKeys: bargeSelectionRowKeys,
  };

  console.log('bargeSelection', bargeSelection);

  return (
    <>
      <h5>{`Performa Costings -  ${contractType === ContractModesEnum.PURCHASE
        ? 'Purchase'
        : 'Sales'
        } Contract`}</h5>
      <Row>
        <Col span={24}>
          {createPermission && (
            <CoalCostingFilterComponent
              formRef={formRef}
              loadBargesOnClickHandler={loadBargesOnClickHandler}
              isBusinessNumberMandatory={false}
              contractType={contractType}
              initialValues={initialValues}
              setBargeData={setPriceCalculationData}
              costingType={CostingTypesEnum.PERFORMA}
            />
          )}

        </Col>
      </Row>

      <br></br>

      <Row gutter={20}>
        <Col span={14}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Barge Details</h6>}
          >
            <PerformaCostingBargeSelection
              bargeGridData={priceCalculationData}
              rowSelection={rowSelection}

            />
            <br />
            <Flex align='center' justify='flex-end'>
              <Form form={formRef} layout="horizontal">
                <Form.Item name={'totalBargeQuantity'} label={<b>Quantity</b>}>
                  <Input
                    type="number"
                    placeholder="Quantity"
                    onChange={(e) =>
                      qualityOnChange(Number(e.target.value))
                    }
                  ></Input>
                </Form.Item>
              </Form>
            </Flex>
          </Card>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Advance Adjustment</h6>}
          >
            <PerformaAdvanceAdjustments
              bargeSelectionRowKeys={[bargeSelection[0]?.bgUId]}
              formRef={formRef}
              key={contractType}
              vendorId={bargeSelection[0]?.coalSupplierId}
              contractId={bargeSelection[0]?.contractId}
              performaAdvance={performaAdvance}
              setAdjustedData={setAdjustedData}
              adjustedData={adjustedData}
              setInvoiceContracts={setInvoiceContracts}
              invoiceContracts={invoiceContracts}
              contractType={contractType}
              setDummyRefresh={setDummyRefresh}
            />
          </Card>
        </Col>
        <Col span={10}>
          <Card
            // className="default-card-class"
            title={'Price Adjustment'}
          >
            {/* <PriceAdjustment
              key={counter}
              contractType={contractType}
              formRef={formRef}
              selectedRowsData={selectedRowsData}
              costingDate={formRef.getFieldValue('costingDate')}
              costingsSave={costingsSave}
              filterData={filterData}
              updateData={updatedData}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
            /> */}
            <PerformaPriceAdjustment
              formRef={formRef}
              bargeSelection={bargeSelection}
              initialValues={initialValues}
              setTaxTotal={setTaxTotal}
              taxTotal={taxTotal}
              setDummyRefresh={setDummyRefresh}
              dummyRefresh={dummyRefresh}
              contractType={contractType}
              adjustedData={adjustedData}
            />
          </Card>
        </Col>
      </Row>
      <Affix offsetBottom={20}>
        <Button className='new-button' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => costingsSave()} style={{ float: 'right' }}>Submit Invoice</Button>
      </Affix>

    </>
  );
};

export default PerformaCoalCostings;
