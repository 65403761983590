import {
  BargeCostingsService,
  BPFCostingRequestIdDto,
  ContractStatusEnum,
  GetDataDemurrageDto,
  GetExpApprovalDataDto,
  PaymentTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import {
  Input,
  Card,
  Table,
  Form,
  Row,
  Col,
  List,
  Button,
  Popconfirm,
  Tooltip,
  Flex,
  Space,
  Typography,
  Drawer,
  Timeline,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { SequenceUtils, internationalFormattedValue } from '@exportx/ui-utils';
import { RightCircleOutlined, DownCircleOutlined, MessageFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  InvoiceDetails,
  DemurrageDispatchDetails,
} from '../costing-detail-view';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircleXmark,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import CommentsPage from '../../../common/comments/comments-page';
interface IProps {
  type?: ContractStatusEnum;
  paymentType?: PaymentTypeEnum;
}

export const DemurrageApproval = (props: IProps) => {
  const { type, paymentType } = props;

  const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();
  const bargeCostingService = new BargeCostingsService();
  const { authContext } = useAuthState();
  const [searchedText, setSearchedText] = useState('');
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);
  const [workLogData, setWorkLogData] = useState<any>();
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [bgDemurrageId, setBgDemurrageId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');
  const [accessId, setAccessId] = useState<any>({});

  const [formRef] = Form.useForm();

  useEffect(() => {
    getDemurrageData();
  }, [dummyRefresh, type]);

  useEffect(() => {
    setAccessId({costingApprove: 730, costingReject: 730, costingInvoiceApprove: 738, costingInvoiceReject: 739});
  }, [paymentType]);

  const getDemurrageData = () => {
    const status =
      type === ContractStatusEnum.FREEZED ? ContractStatusEnum.FREEZED : type === ContractStatusEnum.BUSINESS_APPROVAL
        ? ContractStatusEnum.PENDING_APPROVAL
        : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    const req = new GetExpApprovalDataDto(
      paymentType,
      authContext.defaultPlant,
      status,
    );
    bargeCostingService
      .getDemurrageDataForApproval(req)
      .then((res) => {
        if (res.status) {
          setGridData(res.data);
          setDummyRefresh(false);
        } else {
          setGridData([]);
        }
      })
      .catch((err) => {
        setGridData([]);
        console.log(err.message);
      });
  };

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.bgDemurrageId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const approveDemurrage = (costingId: string, status: ContractStatusEnum) => {
    formRef
      .validateFields()
      .then((values) => {
        const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
          costingId,
          values['remarks'],
          status,
          authContext.user.userName,
          authContext.defaultPlant,
          type,
        );
        bargeCostingService
          .demurrageApproval2(req)
          .then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              getDemurrageData();
              formRef.resetFields();
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderItems = (record: any) => {
    const timeLines = [];
    record?.workLog?.forEach((rec) => {
      timeLines.push({
        color: 'green',
        children: (
          <>
            <Typography style={{ fontSize: 10 }}> {rec?.actionType ?? rec?.approvalLevel} By <b>{rec?.createdUser}</b>  On <b>{rec?.createdAt ? moment(rec?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
            <pre>{rec.workLog}</pre>
          </>
        )

      });
    });
    return (
      <DemurrageDispatchDetails
        costingId={record.bgDemurrageId}
        remainingAmount={0}
        workLogdata={setWorkLogData}
        status={record.approvalStatus}
      >
        <Card
          bordered={false}
          className="card-shadow mb-2"
          styles={{ body: { padding: '0px 10px 15px' } }}
        >
          <Form
            form={formRef}
            // initialValues={initialValues}
            layout="vertical"
            autoComplete="off"
          >
            <Row className="p-2">
              <Form.Item
                style={{ width: '100%' }}
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: 'Enter Work Log', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                  },
                ]}
              >
                <Input.TextArea placeholder="Notes" />
              </Form.Item>
            </Row>
            <Row>
              <Col span={16}>
                <Timeline
                  items={timeLines}
                />
              </Col>
            </Row>
            <Flex align='middle' justify='flex-end'>
              <Space size={10}>
                { isPermissionExist([accessId?.costingApprove]) && (record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL) &&
                  <Button className='approve' size='small'
                    onClick={() => approveDemurrage(record?.bgDemurrageId, ContractStatusEnum.ACTIVE)} type="primary">
                    <FontAwesomeIcon icon={faCheckCircle} /> Approve
                  </Button>}

                {(record.costing_status === ContractStatusEnum.FREEZED) &&
                  <Button size='small' className='new-button' onClick={() => approveDemurrage(record?.expenseEntryId, ContractStatusEnum.PENDING_AUDIT_APPROVAL)}> <FontAwesomeIcon icon={faCheckCircle} />Send For Audit Approval</Button>
                }
                { isPermissionExist([accessId?.costingReject]) && !(record?.invoices?.some(rec => rec.releaseStatus === true)) &&

                  <Button size='small' className='reject' onClick={() => approveDemurrage(record?.expenseEntryId, ContractStatusEnum.CANCELLED)}> <FontAwesomeIcon icon={faCircleXmark} />Reject</Button>

                }
              </Space>
            </Flex>
          </Form>
        </Card>

        <InvoiceDetails
          costingId={record.bgDemurrageId}
          status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
          level={ContractStatusEnum.BUSINESS_APPROVAL}
          invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
          invApproveDisabled={
            record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL
          }
          setDummyRefresh={setDummyRefresh}
          accessId={accessId}
        />
      </DemurrageDispatchDetails>
    );
  };

  const commentsData = (refName, title) => {
    setCommentsVisible(true);
    setFeaturesRefName(refName);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData(ReferenceFeatures.MV_DEMURRAGE, 'All Comments');
    setBgDemurrageId(record.bgDemurrageId);
  }

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Demurrage Ref #',
      dataIndex: 'costingNo',
      width: '20%',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
            <a
              className="link"
              href={`/#/demurrage-detail-view?costing_id=${record.bgDemurrageId}`}
            >
              {record.costingNo}
            </a>
            &nbsp;&nbsp;&nbsp;
            { record?.priority === "HIGH" && <FontAwesomeIcon icon={faFlag} style={{color: 'red'}} /> }
          </>
        );
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'businessNumber',
      // width: '40%',
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`;
        return (
          <>
            <a href={href} className="link">
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Cost Type',
      dataIndex: 'allowedTimeToLoad', //timeCompletedForLoadMV
      render: (value, record) => {
        let costType: string = '';
        if (Number(value) > Number(record.timeCompletedForLoadMV)) {
          costType = 'Dispatch';
        } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
          costType = 'Demurrage';
        } else {
          costType = ' ';
        }
        return <>{costType}</>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'demurrageDate',
      render: (value, record) => {
        return <>{moment(value)?.format('DD-MMM-YYYY')}</>;
      },
    },
    {
      title: 'Account Type',
      dataIndex: 'account',
    },
    {
      title: 'Business Partner',
      dataIndex: 'bpName',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${record.quoteCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card
        className="card-radius"
        title={
          <Row className="mt-16">
            <Input.Search
              placeholder="Search Orders"
              allowClear
              value={searchedText}
              onChange={(e) => setSearchedText(e.target.value)}
              className="input-search"
            />
          </Row>
        }
      >
        <Table
          size="small"
          expandIconColumnIndex={0}
          className="contracts-list"
          rowKey={(record) => record.bgDemurrageId}
          columns={tableColumns}
          dataSource={gridData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
                Expand
              </RightCircleOutlined>
            )
          }
          pagination={false}
          scroll={{ x: 500 }}
          bordered={false}
        />
      </Card>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={bgDemurrageId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </>
  );
};
export default DemurrageApproval;
