import {
  DeleteOutlined,
  EditOutlined,
  FilePdfTwoTone,
  PlusSquareTwoTone,
  MinusSquareTwoTone,
} from '@ant-design/icons';
import {
  BargeCostingVouchersParentDto,
  BargeService,
  BNoRequest,
  BusinessNoDropDownDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  ContractStatusEnum,
  ContractTypeDto,
  ExpenseEntryIdReq,
  ExpenseEntryService,
  NoteTypeReq,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  TablePaginationConfig,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  StatusTag,
  useAuthState,
} from '../../../../common';
import Invoice from '../../invoice/invoice';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import TabPane from 'antd/es/tabs/TabPane';
import PaymentNoticeApproval, {
  PaymentNoticeApprovalScreen,
} from './payment-notice-expensive-approval';
import { useNavigate } from 'react-router-dom';
import { PaymentNoticePageEnum } from '../../payment-notice';
import debounce from 'lodash/debounce';
import moment from 'moment';
export interface ExpenseEntryRejectedListProps {
  setEditDataToForm?: (values) => void;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;

  updateExpensive?: any;
  type: string;
  accessId?: any;
}

export default function ExpenseEntryRejectedList(
  props: ExpenseEntryRejectedListProps,
) {
  const { setDummyRefresh, updateExpensive, accessId } = props;
  const [rejectedEntries, setRejectedEntries] = useState<any[]>([]);
  const { authContext } = useAuthState();
  const [vouchers, setVouchers] = useState<BargeCostingVouchersParentDto>();
  const [searchedText, setSearchedText] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [drawerOpen, setDrawerOpen] = useState<boolean>();
  const [costingId, setCostingId] = useState<any>();
  const [businessDropDown, setBusinessDropDown] =
    useState<BusinessNoDropDownDto[]>();
  const [bargeDropDown, setBargeDropDown] = useState<any[]>();
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [selectedBarge, setSelectedBarge] = useState(null);
  const [expenseLedgers, setExpenseLedgers] = useState<any>([]);
  const [selectedExpenseLedger, setSelectedExpenseLedger] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<any>('costing-creation');
  const [expenseLedgerIds, setExpenseLedgerIds] = useState<any>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 25,
    total: 0,
  });
  const [dateRangeType, setDateRangeType] = useState<string | null>(null);
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: null,
    to: null,
  });

  const navigate = useNavigate();

  const businessService = new BusinessNumberService();
  const bargeService = new BargeService();
  const service = new ExpenseEntryService();

  useEffect(() => {
    if (
      !isPermissionExist([
        417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667, 687,
        421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670, 690,
      ])
    ) {
      return navigate('/');
    }
  }, []);

  useEffect(() => {
    setActiveTab('costing-creation');
    getExpenseLedgers();
    getBusinessNoDropDown();
    getBargeDropDown();
    setSearchedText(null);
    setSelectedExpenseLedger(null);
    setSelectedBusinessNo(null);
    setSelectedBarge(null);
    setDateRangeType(null);
  }, [props.type]);

  useEffect(() => {
    if (expenseLedgerIds.length) {
      getRejectedEntries();
    }
  }, [
    searchedText,
    expenseLedgerIds,
    selectedExpenseLedger,
    selectedBusinessNo,
    selectedBarge,
    dateRangeValues,
    pagination.pageSize,
    pagination.current
  ]);

  const deleteEntry = (value) => {
    const req = new ExpenseEntryIdReq();
    req.expenseEntryId = value;

    service
      .deleteEntry(req)
      .then((res) => {
        if (res.status) {
          setDummyRefresh((prev) => prev + 1);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage('');
      });
  };

  const getRejectedEntries = () => {
    console.log('expenseLedgerIds', expenseLedgerIds);
    const req = new NoteTypeReq(
      props.type === 'Account Receivable' ? 'Other Income' : '',
      authContext.defaultPlant,
      pagination.pageSize,
      (pagination.current - 1) * pagination.pageSize,
      searchedText,
      selectedExpenseLedger ? [selectedExpenseLedger] : expenseLedgerIds,
      selectedBusinessNo,
      selectedBarge,
      dateRangeValues.from?.format('YYYY-MM-DD'),
      dateRangeValues.to?.clone().add(1, 'day').format('YYYY-MM-DD'),
    );
    const service = new ExpenseEntryService();
    service
      .getRejectedEntries(req)
      .then((res) => {
        if (res.status) {
          setRejectedEntries(res.data);
          setPagination((prev) => ({
            ...prev,
            total: res.totalCount || 0,
          }));
        } else {
          setRejectedEntries([]);
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        setRejectedEntries([]);
        console.log(err.message);
      });
  };

  const ledgerPermissions = {
    'Chemical Spray': 417,
    'Export Tax': 438,
    'Documentation Charges': 459,
    'Laporan Charges': 480,
    'Sampling & Analysis Charges': 501,
    'Pre Shipment Inspection': 522,
    'Other Expenses': 543,
    'Return Cargo Charges': 564,
    'Floating Crane Charges': 585,
    'Stevedore Costing': 606,
    'Insurance': 687,
    'Freight Costing': 627,
    'PNBP Charges': 647,
    'Other Income': 667,
  };
  
  const hasLedgerPermission = (ledger) => {
    const permission = ledgerPermissions[ledger.expenseLedger]
    return isPermissionExist([permission]) ? ledger : null;
  };
  

  const getExpensiveInvoiceData = (id: string) => {
    const req = new ExpenseEntryIdReq();
    req.expenseEntryId = id;
    req.plantCode = authContext.defaultPlant;
    service
      .getExpensiveInvoiceData(req)
      .then((res) => {
        if (res.status) {
          setVouchers(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getExpenseLedgers = () => {
    service
      .getExpenseLedgers()
      .then((res) => {
        if (res.status) {
          const permittedLedgers = res.data?.filter((ledger) => {
            return hasLedgerPermission(ledger);
          });
          setExpenseLedgers(permittedLedgers);
          setExpenseLedgerIds(permittedLedgers?.map((el) => el.actLedId));
        } else {
          setExpenseLedgers([]);
          setExpenseLedgerIds([]);
        }
      })
      .catch((err) => console.log(err.message, 'err message'));
  };

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessService
      .getBusinessNoDropDown(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        } else {
          console.log(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBargeDropDown = () => {
    const req = new BNoRequest('');
    req.plantCode = authContext.defaultPlant;
    bargeService
      .getBargeDropForBusinessNo(req)
      .then((res) => {
        if (res.status) {
          let barge = res.data?.map((item) => {
            let bargeNo =
              SequenceUtils.formatNumberToSpecificLength(
                item.bargeId.toString(),
              ) +
              ' ' +
              `(${item.bargeNomination})`;
            return {
              id: item.id,
              bargeNo,
            };
          });
          setBargeDropDown(barge);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleExpenseLedgerChange = (value: string) => {
    setSelectedExpenseLedger(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleBusinessNoChange = (value: string) => {
    setSelectedBusinessNo(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleBargeChange = (value: string) => {
    setSelectedBarge(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const debouncedSearch = useCallback((searchValue: string) => {
    setSearchedText(searchValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
  }, []);

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((searchValue: string) => {
        debouncedSearch(searchValue);
      }, 500),
    [debouncedSearch],
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSearchHandler(value);
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setPagination((prev) => ({
      ...prev,
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 25
    }));
  };

  const dateRangeDropDown = [
    { value: 'this_month', label: 'This Month' },
    { value: 'previous_month', label: 'Previous Month' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'previous_quarter', label: 'Previous Quarter' },
    { value: 'this_year', label: 'This Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Date Range' },
  ];

  const handleDateRangeTypeChange = (value: string | null) => {
    setDateRangeType(value);

    if (value === 'custom') {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    if (!value) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const now = moment();
    let fromDate: moment.Moment;
    let toDate: moment.Moment;

    switch (value) {
      case 'this_month':
        fromDate = now.clone().startOf('month');
        toDate = now.clone().endOf('month');
        break;
      case 'previous_month':
        fromDate = now.clone().subtract(1, 'month').startOf('month');
        toDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'this_quarter':
        fromDate = now.clone().startOf('quarter');
        toDate = now.clone().endOf('quarter');
        break;
      case 'previous_quarter':
        fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
        toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'this_year':
        fromDate = now.clone().startOf('year');
        toDate = now.clone().endOf('year');
        break;
      case 'previous_year':
        fromDate = now.clone().subtract(1, 'year').startOf('year');
        toDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      default:
        return;
    }

    setDateRangeValues({ from: fromDate, to: toDate });
  };

  const handleDateRangeValuesChange = (
    dates: [any | null, any | null] | null,
  ) => {
    if (!dates || !dates[0] || !dates[1]) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const [from, to] = dates;
    setDateRangeValues({ from, to });
  };

  const columns: any = [
    // {
    //     title: 'Actin',
    //     dataIndex: 'isInPmtNotice',
    //     width: '15%',
    //     align: 'center',
    //     key: 'expenseEntryId',
    //     onCell: (record: any) => ({
    //         rowSpan: record.rowSpan
    //     }),
    //     render: (text, rowData) => (
    //         <span>
    //           {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([rowData?.editPermission]) && (

    //             <Tooltip placement="topRight" title="Edit">
    //                 <EditOutlined
    //                     type="edit"
    //                     onClick={() => updateExpensive(rowData.expenseEntryId)}
    //                     style={{ color: '#1890ff', fontSize: '18px' }}
    //                 /></Tooltip>)}

    //             <Divider type="vertical" />
    //             {isPermissionExist([rowData?.deletePermission]) && (
    //             <Popconfirm
    //                 disabled={text}
    //                 onConfirm={e => {
    //                     deleteEntry(rowData.expenseEntryId)
    //                 }}
    //                 title={'Are you sure to delete ?'}>
    //                 <Tooltip placement="topRight" title={`${text === true ? 'You Cant Delete This Record' : 'Delete'}`}>
    //                     <DeleteOutlined type='delete' style={{ color: `${text === true ? '#d1b2b2' : 'red'}`, fontSize: '18px' }} />
    //                 </Tooltip>
    //             </Popconfirm>)}
    //             <Divider type="vertical" />
    //             {isPermissionExist([rowData?.addInvoicePermission?.view]) && (
    //             <Tooltip placement="topRight" title="Add Invoice">
    //                 <PlusSquareTwoTone onClick={() => {
    //                     setDrawerOpen(true);
    //                     setCostingId((prev) => { return { ...prev, costingId: rowData.expenseEntryId, totalAmount: rowData.totalAmount, accessId: rowData.addInvoicePermission } });
    //                     // setDummyRefresh(prev => prev + 1);
    //                 }}
    //                     style={{ color: '#1890ff', fontSize: '20px' }} />
    //             </Tooltip>)}
    //              <Tooltip placement="topRight" title="Add Invoice">
    //                 {!invoiceFrom ? <>
    //                     <PlusSquareTwoTone
    //                         key={rowData.expenseEntryId}
    //                         onClick={() => {
    //                             setInvoiceForm(true);
    //                             setInvoiceCostingData((prev) => { return { ...prev, referenceFeatures: ReferenceFeatures.EXPENSE_ENTRY, costingId: rowData.expenseEntryId, totalAmount: Number(rowData.totalAmount) } })

    //                         }}
    //                         style={{ color: '#1890ff', fontSize: '20px' }} />

    //                 </> : <>

    //                     <MinusSquareTwoTone key={rowData.expenseEntryId} style={{ color: '#1890ff', fontSize: '20px' }} onClick={() => {
    //                         setInvoiceForm(false);
    //                     }} />
    //                 </>

    //                 }

    //             </Tooltip>

    //             <Divider type='vertical'></Divider>

    //             <PDFDownloadLink
    //                 document={<Invoice invoice={vouchers} demurrageName={''} costingName={vouchers?.costingName} />}
    //                 fileName={`${rowData?.expenseEntryNo}.pdf`}
    //             >
    //                 <Tooltip placement="topRight" title="Invoice">
    //                     <FilePdfTwoTone onClick={() => getExpensiveInvoiceData(rowData.expenseEntryId)} style={{ color: '#1890ff', fontSize: '20px' }}
    //                     />
    //                 </Tooltip>
    //             </PDFDownloadLink>

    //         </span>
    //     )
    // },
    {
      title: 'Entry Ref#',
      dataIndex: 'expenseEntryNo',
      // sorter: (a, b) => a.expenseEntryNo.localeCompare(b.expenseEntryNo),
      // sortDirections: ['descend', 'ascend'],
      // filteredValue: [String(searchedText).toLowerCase()],
      // onFilter: (value, record) => {
      //   return SequenceUtils.globalFilter(value, record);
      // },
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, record) => {
        let path;
        if (props.type === 'Account Payable') {
          path = 'expense-entry-detail-view';
        } else {
          path = 'other-income-expense-entry-detail-view';
        }
        return (
          <>
            <a
              className="link"
              href={`/#/${path}?costing_id=${record.expenseEntryId}`}
            >
              {record.expenseEntryNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'Expense Ledger',
      dataIndex: 'actLedName',
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
    },
    {
      title: 'Expense Type',
      dataIndex: 'expensiveType',
      // sorter: (a, b) => a.expensiveType.localeCompare(b.expensiveType),
      // sortDirections: ['descend', 'ascend'],
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
    },
    {
      title: 'BusinessNo',
      dataIndex: 'expenseEntryBusinessNos',
      // sorter: (a, b) => a.businessNumber.localeCompare(b.businessNumber),
      // sortDirections: ['descend', 'ascend'],
      // render: (value, record) => {
      //   const link = `/#/bn-detail-view?bn_Id=${record.bnId}`;
      //   return (
      //     <>
      //       <a href={link} className="link">
      //         {value}
      //       </a>
      //     </>
      //   );
      // },
      render: (expenseEntryBusinessNos) => {
        return (
          <>
            {expenseEntryBusinessNos?.map((business, index) => {
              const link = `/#/bn-detail-view?bn_Id=${business.bnId}`;
              return (
                <>
                  {business.bnId === null || business.bnId === undefined ? (
                    '(null)'
                  ) : (
                    <div key={business.bnId}>
                      <a href={link} className="link" target="_blank">
                        {business.businessNo} {`(${business.mvName})`}
                      </a>
                      {index !== expenseEntryBusinessNos.length - 1 && (
                        <Divider />
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Barges',
      dataIndex: 'expenseEntryBarges',
      // sorter: (a, b) => a.bargeSupplier.localeCompare(b.bargeSupplier),
      // sortDirections: ['descend', 'ascend'],
      // render: (v, record) => {
      //   const link = `/#/barge-detail-view?barge_id=${record.bgId}`;
      //   return (
      //     <>
      //       {v === undefined || v === null ? (
      //         '(null)'
      //       ) : (
      //         <a href={link} className="link">
      //           {SequenceUtils.formatNumberToSpecificLength(v) +
      //             ' ' +
      //             `(${record.bargeName})`}
      //         </a>
      //       )}
      //     </>
      //   );
      // },
      render: (expenseEntryBarges) => {
        return (
          <>
            {expenseEntryBarges?.map((barge, index) => {
              const link = `/#/barge-detail-view?barge_id=${barge.bargeId}`;
              return (
                <>
                  {barge.bargeId === undefined || barge.bargeId === null ? (
                    '(null)'
                  ) : (
                    <div key={barge.bargeId}>
                      <a href={link} className="link" target="_blank">
                        {SequenceUtils.formatNumberToSpecificLength(
                          barge.bargeNo,
                        ) +
                          ' ' +
                          `(${barge.bargeNomination})`}
                      </a>
                      {index !== expenseEntryBarges.length - 1 && <Divider />}
                    </div>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      // sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
      // sortDirections: ['descend', 'ascend'],
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },

    {
      title: 'Status',
      dataIndex: 'approvalStatus',
      // onCell: (record: any) => ({
      //   rowSpan: record.rowSpan,
      // }),
      render: (value, _) => {
        return (
          <>
            <StatusTag status={value}></StatusTag>
          </>
        );
      },
    },
  ];

  const entriesDataModification = (data: any) => {
    const entriesData = [];
    data.forEach((parent) => {
      parent.transactionalData.forEach((child, index) => {
        entriesData.push({
          expenseEntryNo: parent.expenseEntryNo,
          expensiveType: parent.expensiveType,
          totalAmount: parent.totalAmount,
          // rowSpan: index === 0 ? parent.transactionalData.length : 0,
          businessNumber: child.businessNumber + `(${child.motherVesselName})`,
          bargeId: child.bargeId,
          approvalStatus: parent.approvalStatus,
          bnId: child.bnId,
          bgId: child.bgId,
          expenseEntryId: parent.expenseEntryId,
          bargeName: child.bargeName,
          isInPmtNotice: parent.isInPmtNotice,
          act_led_name: parent.act_led_name,
          addInvoicePermission: parent.addInvoicePermission,
          editPermission: parent.editPermission,
          deletePermission: parent.deletePermission,
        });
      });
    });

    return entriesData;
  };

  return (
    <>
      <Card className="card-radius">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            ...(isPermissionExist([
              417, 438, 459, 480, 501, 522, 543, 564, 585, 606, 627, 647, 667,
              687,
            ])
              ? [
                {
                  key: 'costing-creation',
                  label: 'Costing Creation',
                  children: (
                    <>
                      <Row
                        justify="space-between"
                        className="mb-12 mt-8"
                        gutter={[0, 8]}
                      >
                        <Col xs={24} md={20}>
                          <Flex gap={10} wrap="wrap">
                            <Input.Search
                              className="input-search"
                              style={{ width: 225 }}
                              placeholder="Search"
                              allowClear
                              value={searchInput}
                              onChange={(e) => handleSearch(e.target.value)}
                            />
                            {props.type !== 'Account Receivable' && (
                              <Select
                                style={{ width: 175 }}
                                placeholder="Expense Ledger"
                                value={selectedExpenseLedger}
                                onChange={handleExpenseLedgerChange}
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                    .toString()
                                    .toLocaleLowerCase()
                                    .includes(input.toLocaleLowerCase())
                                }
                              >
                                {expenseLedgers
                                  ?.filter((led) =>
                                    props.type === 'Account Payable'
                                      ? led.expenseLedger !== 'Other Income'
                                      : led.expenseLedger === 'Other Income',
                                  )
                                  .map((el, idx) => {
                                    return (
                                      <Select.Option
                                        value={el.actLedId}
                                        key={el.actLedId + '' + idx}
                                      >
                                        {el.expenseLedger}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            )}
                            <Select
                              style={{ width: 175 }}
                              placeholder="Business"
                              value={selectedBusinessNo}
                              onChange={handleBusinessNoChange}
                              filterOption={(input, option) =>
                                (option!.children as unknown as string)
                                  .toString()
                                  .toLocaleLowerCase()
                                  .includes(input.toLocaleLowerCase())
                              }
                              showSearch
                              allowClear
                            >
                              {businessDropDown?.map((item, index) => (
                                <Select.Option
                                  value={item.bnId}
                                  key={item.bnId + '-' + index}
                                >
                                  {item.businessName}
                                </Select.Option>
                              ))}
                            </Select>
                            <Select
                              style={{ width: 175 }}
                              placeholder="Barge"
                              value={selectedBarge}
                              onChange={handleBargeChange}
                              filterOption={(input, option) =>
                                (option!.children as unknown as string)
                                  .toString()
                                  .toLocaleLowerCase()
                                  .includes(input.toLocaleLowerCase())
                              }
                              showSearch
                              allowClear
                            >
                              {bargeDropDown?.map((item, index) => (
                                <Select.Option
                                  value={item.id}
                                  key={item.id + '-' + index}
                                >
                                  {item.bargeNo}
                                </Select.Option>
                              ))}
                            </Select>
                            <Select
                              placeholder="Date Range"
                              style={{ width: 175 }}
                              allowClear
                              value={dateRangeType}
                              onChange={handleDateRangeTypeChange}
                            >
                              {dateRangeDropDown.map((item, index) => (
                                <Select.Option
                                  value={item.value}
                                  key={item.value + '-' + index}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                            {dateRangeType === 'custom' && (
                              <DatePicker.RangePicker
                                style={{ maxWidth: 175 }}
                                value={[
                                  dateRangeValues.from,
                                  dateRangeValues.to,
                                ]}
                                onChange={handleDateRangeValuesChange}
                              />
                            )}
                          </Flex>
                        </Col>
                        <Col>
                          <Button
                            className="new-button"
                            onClick={() =>
                              navigate(
                                props.type === 'Account Receivable'
                                  ? '/create-other-income-expense-entry'
                                  : '/create-expense-entry',
                              )
                            }
                          >
                            New Expense Entry
                          </Button>
                        </Col>
                      </Row>
                      <Table
                        className="contracts-list"
                        scroll={{ x: 500 }}
                        columns={columns}
                        dataSource={rejectedEntries}
                        rowKey={(row) => row.expenseEntryId}
                        pagination={{
                          ...pagination,
                          showSizeChanger: true,
                          pageSizeOptions: ['25', '50', '100'],
                          showTotal: (total) => (
                            <span className="page-total-items">
                              Total {total} items
                            </span>
                          ),
                          style: {
                            paddingTop: '12px',
                          },
                        }}
                        onChange={handlePageChange}
                      />
                    </>
                  ),
                },
              ]
              : []),
            ...(isPermissionExist([
              421, 442, 463, 484, 505, 526, 547, 568, 589, 610, 630, 650, 670,
              690,
            ])
              ? [
                {
                  key: 'business-approval',
                  label: 'Business Approval',
                  children: (
                    <PaymentNoticeApproval
                      type={PaymentNoticeApprovalScreen.BUSINESS_APPROVAL}
                      paymentType={
                        props.type === 'Account Receivable'
                          ? PaymentNoticePageEnum.RECEIVABLES
                          : undefined
                      }
                      key={`${props.type} - expense-entry-approval`}
                    />
                  ),
                },
                {
                  key: 'additional-info',
                  label: 'Additional Information',
                  children: (
                    <PaymentNoticeApproval
                      type={PaymentNoticeApprovalScreen.FREEZED}
                      paymentType={
                        props.type === 'Account Receivable'
                          ? PaymentNoticePageEnum.RECEIVABLES
                          : undefined
                      }
                      key={`${props.type} - expense-entry-approval`}
                    />
                  ),
                },
              ]
              : []),
          ]}
        />
      </Card>
      {/* <AddInvoiceDrawer
        openDrawer={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        costingId={costingId}
        setDummyRefresh={setDummyRefresh}
        referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY}
      /> */}
      {/* {invoiceFrom &&

                <AddInvoice key={inVoiceCostingData.expenseEntryId} costingData={inVoiceCostingData} />
            } */}
    </>
  );
}
