import {
    AccountsVoucherTypesEnum,
    ActionEnum,
    AdvanceEnum,
    BusinessPartnerTypeEnum,
    ColaIndexTypeEnum,
    ContractModesEnum,
    ContractStatusEnum,
    CostingTypesEnum,
    CostingWorkLogsEnum,
    PaymentTypeEnum,
    ProcurementContractTypeEnum,
    ReferenceFeatures,
    SamplingTypes,
  } from '@exportx/shared-models-and-services';
  import { Route, Routes } from 'react-router-dom';
  import BasicLayout, { menu } from './components/BasicLayout';
  import {
    ChildProtectionWrapper,
    ExceptionComponent,
    LoginComponent,
  } from './components/common';
  import SalesContractDetailView from './components/pages/contracts/view-components/sales-contract-detail-view';
  import TransferLogGrid from './components/pages/contracts/view-components/transfer-log-grid';
  import {
    BargeCreation,
    BargeMappingView,
    BasisForm,
    BasisGrid,
    BpCategoryForm,
    BpCategoryGrid,
    BusinessPartnerForm,
    BusinessPartnerGrid,
    CommodityGrid,
    CompanyForm,
    CompanyGrid,
    CountriesGrid,
    CountryForm,
    CurrencyForm,
    CurrencyGrid,
    DestinationForm,
    DestinationGrid,
    FixtureNotesGrid,
    PaymentModesGrid,
    PurchaseContract,
    PurchaseContractDetailView,
    PurchaseContractView,
    SalesContract,
    SalesContractView,
    ShippingTermsForm,
    ShippingTermsGrid,
    TaxesAndItemsForm,
    TaxesAndItemsGrid,
    UomsForm,
    UomsGrid,
  } from './components/pages/masters';
  import AdvanceGrid from './components/pages/masters/components/advance/advance-Grid';
  import AdvanceContractDetails from './components/pages/masters/components/advance/advance-detail-view';
  import DownPaymentsGrid from './components/pages/masters/components/advance/downpaymentsGrid';
  import VendorAdvancePreview from './components/pages/masters/components/advance/vendoradvance-preview';
  import AnchorageForm from './components/pages/masters/components/anchorage-name/anchorageForm';
  import AnchorageGrid from './components/pages/masters/components/anchorage-name/anchorageGrid';
  import ContractApproval from './components/pages/masters/components/approval/contract-approval';
  import BargeAgreementDetailedView from './components/pages/masters/components/barge-agreements/barge-agreement-detailed-view';
  import { BargeAgreementGrid } from './components/pages/masters/components/barge-agreements/barge-agreement-grid';
  import BargeAgreementPreview from './components/pages/masters/components/barge-agreements/barge-agreement-preview';
  import BargeGrid from './components/pages/masters/components/barge/barge-grid';
  import BargeDetailView from './components/pages/masters/components/barge/bargeDetails-preview';
  import { BargeSales } from './components/pages/masters/components/barge/bargeSalesQuality';
  import BudgetGrid from './components/pages/masters/components/budget-and-costing/budget-and-costing-grid';
  import BusinessPartnerDetailView from './components/pages/masters/components/business-partner/business-partner-detail-view';
  import {
    CoalIndexPricesPage,
    IndexedPriceCalculationForm,
  } from './components/pages/masters/components/coal-index-prices';
  import FixtureNoteDetails from './components/pages/masters/components/fixture-note/Fixturenote-details';
  import LcTracker from './components/pages/masters/components/lc-tracker/lc-tracker';
  import {
    TaxesForm,
    TaxesGrid,
  } from './components/pages/masters/components/taxes';
  import { PurchaseSampleReport } from './components/pages/sampling-and-analysis';
  import PurchaseSampling from './components/pages/sampling-and-analysis/purchasequality/purchase-sampling';
  import AgingReport from './components/pages/sampling-and-analysis/purchasequality/reports/aging-report';
  import PreShipment from './components/pages/sampling-and-analysis/purchasequality/reports/pre-shipment';
  import SupplierReports from './components/pages/sampling-and-analysis/sales-quality/reports/supplier-report';
  
  import {
    AccountCreationF,
    AccountCreationG,
    AccountsPayable,
    AccountsReceivables,
    BargeCostingApproval,
    BargeCostingApprovalScreen,
    BargeCostingDetailsView,
    BargeCostings,
    BargeCostingsList,
    CoalCostings,
    DeadFreight,
    DeadFreightApproval,
    DeadFreightList,
    Demurrage,
    DemurrageList,
    GroupEntryForm,
    GroupEntryGrid,
    JournalF,
    JournalG,
    LandingPageDashBoard,
    Ledger,
    PaymentF,
    PaymentG,
    PaymentNoticePage,
    PaymentNoticePageEnum,
    PerformaCoalCostings,
    PerformaDetailView,
    ProcurementCoalCoasting,
    RolesPage,
    UserPage,
    UserRoleMappings,
  } from './components/pages';
  import DebitCreditNoteApproval from './components/pages/accounts/debit-credit-note/debit-credit-note-approval/debit-credit-note-approval';
  import DebitCreditNoteEntry from './components/pages/accounts/debit-credit-note/debit-credit-note-entry/debit-credit-note-entry';
  import {
    ProcurementContractDetailedView,
    ProcurementServiceContract,
    ProcurementServiceGrid,
  } from './components/pages/contracts/procurement-service';
  import BargePaymentNotice from './components/pages/costings/barge-costings/barge-costing-Approval/barge-payment-notice';
  import PaymentNoticeExpensive from './components/pages/costings/barge-costings/barge-costing-Approval/payment-notice-expensive';
  import PaymentNoticeApproval, {
    PaymentNoticeApprovalScreen,
  } from './components/pages/costings/barge-costings/barge-costing-Approval/payment-notice-expensive-approval';
  import CoalCostingDetailView from './components/pages/costings/barge-costings/coal/coal-costing-detailView';
  import CmCoalCostingMainForm from './components/pages/costings/coal-costings/commercial-coal-costing/cm-pc-coal-costing-main';
  import DemurrageApproval from './components/pages/costings/demurrage/demurrage-approval';
  import AdvanceReceivablesGrid from './components/pages/masters/components/advance-receivables/advance-receivables.grid';
  import AdvanceReceivablesDetailedView from './components/pages/masters/components/advance-receivables/advance-recievables-detailedview';
  import { BudgetAndCostingForm } from './components/pages/masters/components/budget-and-costing/BudgetAndCostingForm';
  import BACostingDetailedView from './components/pages/masters/components/budget-and-costing/bac-detailed-view';
  import {
    BusinessNumberDetails,
    BusinessNumberGrid,
  } from './components/pages/masters/components/business-number';
  import BusinessNumberDetailView from './components/pages/masters/components/business-number/business-number-detail-view';
  import MotherVesselDetailView from './components/pages/masters/components/business-number/motherVessel-detail-view';
  import { CompanyTdsForm } from './components/pages/masters/components/company-tds/company-tds-form';
  import { CompanyTdsGrid } from './components/pages/masters/components/company-tds/company-tds-grid';
  import CurrencyF from './components/pages/masters/components/currency-converter/currency-converter-form';
  import CurrencyG from './components/pages/masters/components/currency-converter/currency-converter-grid';
  import JettyForm from './components/pages/masters/components/jetty/jetty-form';
  import JettyGrid from './components/pages/masters/components/jetty/jetty-grid';
  import AdvanceReceivablesPreview from './components/pages/masters/components/advance-receivables/advance-receivables.preview';
  import { MarketingCoalCoasting } from './components/pages/costings/coal-costings/marketing-coal-costing';
  import { CmSalesCostingMainForm } from './components/pages/costings/coal-costings/commercial-sale-costing';
  import Receivables from './components/pages/costings/receivables/receivables';
  import ScLcTracker from './components/pages/masters/components/lc-tracker/sc-lc-tracker';
  import PlChildReports from './components/pages/masters/components/reports/pl-child-reports';
  import PlReports from './components/pages/masters/components/reports/pl-reports';
  import { AuditApprovals } from './components/pages/costings/audit-approvals';
  import { AdvanceBusinessApprovals } from './components/pages/masters/components/approval/advance-contract/advance-business-approval';
  import { VesselAgreementGrid } from './components/pages/masters/components/vessel-agreements/vessel-agreement-grid';
  import VesselAgreementPreview from './components/pages/masters/components/vessel-agreements/vessel-agreement-preview';
  import { VesselAgreementDetailedView } from './components/pages/masters/components/vessel-agreements';
  import CostingReports from './components/pages/costings/reports/costing-reports';
  import {
    MarkUpGrid,
    MarkupPriceForm,
  } from './components/pages/masters/components/markup-price';
  import MarkupPriceDetailedView from './components/pages/masters/components/markup-price/markup-price-detailed-view';
  import { SalesFreightCosting } from './components/pages/costings/coal-costings/freight-costing';
  import LTCReports from './components/pages/costings/reports/LTC-reports';
  import LoadingPlansList from './components/pages/costings/reports/loading-plans';
  import BusinessPartner from './components/pages/masters/components/business-partner/business-partner-grid/business-partner';
  import PartnerType from './components/pages/masters/components/bp-category/bp-category-grid/partner-type';
  import Vouchers from './components/pages/accounts/vouchers';
  import ProfitLossReports from './components/pages/costings/reports/pl-reports';
  import { SavedCostingsGrid } from './components/pages/costings/coal-costings/common';
  import { ExpenseEntryDetailView } from './components/pages/costings/barge-costings/barge-costing-Approval/expense-entry-detail-view';
  import { DeadFrieghtDetailView } from './components/pages/costings/costing-detail-view/dead-frieght-detail-view';
  import DemurrageDetailView from './components/pages/costings/costing-detail-view/demurrage-detail-view';
  import DebitCreditNoteDetailView from './components/pages/accounts/debit-credit-note/debit-credit-note-entry/debit-credit-note-detail-view';
  import ExpenseEntryRejectedList from './components/pages/costings/barge-costings/barge-costing-Approval/expense-entry-rejected-list';
  import DebitCreditRejectedEntries from './components/pages/accounts/debit-credit-note/debit-credit-note-entry/debit-credit-rejected-entries';
  import CostingDetailReport from './components/pages/costings/reports/costing-detail-report';
  import ProcurementDetailReport from './components/pages/costings/reports/procurement-report/purcurement-report-details';
  import { HBAIndexes } from './components/pages/masters/components/coal-index-prices/hba-indexes';
  
  const AppRoutes = () => {
    const getRoute = (route: any) => {
      if (route && route.children && route.children.length) {
        return route.children.map((item: any) => getRoute(item));
      } else {
        return (
          <Route
            key={`${route.key}`}
            path={`/${route.path}`}
            element={route.component}
          />
        );
      }
    };
    const getAllRoutes = () => {
      const umsMenus = [menu[menu.length - 1]];
      const subMenus: any[] = [];
      umsMenus.forEach((eachRoutes) => {
        const abc = getRoute(eachRoutes);
        subMenus.push(abc);
      });
      return subMenus;
    };
  
    return (
      <Routes>
        <Route
          path="/"
          element={
            <ChildProtectionWrapper>
              <BasicLayout />
            </ChildProtectionWrapper>
          }
        >
          <Route index element={<LandingPageDashBoard />} />
          <Route
            path="/customerType"
            element={
              <BpCategoryGrid
                bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
                key={BusinessPartnerTypeEnum.CUSTOMER}
              />
            }
          />
          <Route
            path="/createCustomerType"
            element={
              <BpCategoryForm
                bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
                key={BusinessPartnerTypeEnum.CUSTOMER}
              />
            }
          />
  
          <Route
            path="/vendorType"
            element={
              <BpCategoryGrid
                bpCategory={BusinessPartnerTypeEnum.VENDOR}
                key={BusinessPartnerTypeEnum.VENDOR}
              />
            }
          />
          <Route
            path="/createVendorType"
            element={
              <BpCategoryForm
                bpCategory={BusinessPartnerTypeEnum.VENDOR}
                key={BusinessPartnerTypeEnum.VENDOR}
              />
            }
          />
  
          <Route
            path="/facilitatorType"
            element={
              <BpCategoryGrid
                bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
                key={BusinessPartnerTypeEnum.FACILITATOR}
              />
            }
          />
          <Route
            path="/createFacilitatorType"
            element={
              <BpCategoryForm
                bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
                key={BusinessPartnerTypeEnum.FACILITATOR}
              />
            }
          />
  
          <Route path="/partnerType" element={<PartnerType />} />
  
          <Route
            path="/customer"
            element={
              <BusinessPartnerGrid
                bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
                key={BusinessPartnerTypeEnum.CUSTOMER}
              />
            }
          />
          <Route
            path="/createCustomer"
            element={
              <BusinessPartnerForm
                bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
                key={BusinessPartnerTypeEnum.CUSTOMER}
              />
            }
          />
          <Route
            path="/detailCustomer"
            element={
              <BusinessPartnerDetailView
                bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
                key={BusinessPartnerTypeEnum.CUSTOMER}
              />
            }
          />
          <Route
            path="/updateCustomer"
            element={
              <BusinessPartnerForm
                bpCategory={BusinessPartnerTypeEnum.CUSTOMER}
                key={BusinessPartnerTypeEnum.CUSTOMER}
              />
            }
          />
  
          <Route
            path="/vendor"
            element={
              <BusinessPartnerGrid
                bpCategory={BusinessPartnerTypeEnum.VENDOR}
                key={BusinessPartnerTypeEnum.VENDOR}
              />
            }
          />
          <Route
            path="/createVendor"
            element={
              <BusinessPartnerForm
                bpCategory={BusinessPartnerTypeEnum.VENDOR}
                key={BusinessPartnerTypeEnum.VENDOR}
              />
            }
          />
          <Route
            path="/detailVendor"
            element={
              <BusinessPartnerDetailView
                bpCategory={BusinessPartnerTypeEnum.VENDOR}
                key={BusinessPartnerTypeEnum.VENDOR}
              />
            }
          />
          <Route
            path="/updateVendor"
            element={
              <BusinessPartnerForm
                bpCategory={BusinessPartnerTypeEnum.VENDOR}
                key={BusinessPartnerTypeEnum.VENDOR}
              />
            }
          />
  
          <Route
            path="/facilitator"
            element={
              <BusinessPartnerGrid
                bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
                key={BusinessPartnerTypeEnum.FACILITATOR}
              />
            }
          />
          <Route
            path="/createFacilitator"
            element={
              <BusinessPartnerForm
                bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
                key={BusinessPartnerTypeEnum.FACILITATOR}
              />
            }
          />
          <Route
            path="/detailFacilitator"
            element={
              <BusinessPartnerDetailView
                bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
                key={BusinessPartnerTypeEnum.FACILITATOR}
              />
            }
          />
          <Route
            path="/updateFacilitator"
            element={
              <BusinessPartnerForm
                bpCategory={BusinessPartnerTypeEnum.FACILITATOR}
                key={BusinessPartnerTypeEnum.FACILITATOR}
              />
            }
          />
  
          <Route path="/businessPartner" element={<BusinessPartner />} />
  
          <Route path="/createCountry" element={<CountryForm />} />
          <Route path="/countries" element={<CountriesGrid />} />
  
          <Route path="/createCurrency" element={<CurrencyForm />} />
          <Route path="/currency" element={<CurrencyGrid />} />
  
          <Route path="/company" element={<CompanyGrid />} />
          <Route path="/createCompany" element={<CompanyForm />} />
  
          <Route path="/commodity" element={<CommodityGrid />} />
  
          <Route path="/createBasis" element={<BasisForm />} />
          <Route path="/basis" element={<BasisGrid />} />
  
          <Route path="/taxes-view" element={<TaxesGrid />} />
          <Route path="/taxes-form" element={<TaxesForm />} />
  
          <Route path="/items-and-taxes" element={<TaxesAndItemsGrid />} />
          <Route path="/create-items-and-taxes" element={<TaxesAndItemsForm />} />
  
          <Route path="/company-vat-form" element={<CompanyTdsForm />} />
          <Route path="/company-vat" element={<CompanyTdsGrid />} />
  
          <Route path="/currency-conversion" element={<CurrencyG />} />
          <Route path="/save-currency-conversion" element={<CurrencyF />} />
  
          <Route path="/shippingTerms" element={<ShippingTermsGrid />} />
          <Route path="/createShippingTerm" element={<ShippingTermsForm />} />
  
          <Route path="/paymentModes" element={<PaymentModesGrid />} />
  
          <Route path="/UOMs-view" element={<UomsGrid />} />
          <Route
            path="/UOMs-form"
            element={
              <UomsForm
                uomsData={undefined}
                isUpdate={false}
                closeForm={() => {}}
                updateItem={(abc) => {}}
              />
            }
          />
  
          <Route path="/anchoragegrid" element={<AnchorageGrid />} />
          <Route path="/anchorageform" element={<AnchorageForm />} />
  
          <Route path="/jettyGrid" element={<JettyGrid />} />
          <Route path="/jettyForm" element={<JettyForm />} />
  
          <Route path="/destination-view" element={<DestinationGrid />} />
          <Route
            path="/destination-form"
            element={
              <DestinationForm
                destinatonData={undefined}
                isUpdate={false}
                closeForm={() => {}}
              />
            }
          />
  
          <Route
            path="/po-creation"
            element={<PurchaseContract key="/po-creation" poAction={'create'} />}
          />
          <Route
            path="/po-update"
            element={<PurchaseContract key={'po-update'} poAction={'update'} />}
          />
          <Route
            path="/po-addendum"
            element={
              <PurchaseContract key={'po-addendum'} poAction={'addendum'} />
            }
          />
          <Route path="/po-tracker" element={<PurchaseContractView />} />
          <Route path="/po-detail-view" element={<PurchaseContractDetailView />}>
            <Route path=":pcId" element={<PurchaseContractDetailView />} />
          </Route>
  
          <Route
            path="/so-creation"
            element={<SalesContract key={'sale-create'} soAction={'create'} />}
          />
          <Route
            path="/so-update"
            element={<SalesContract key={'sale-update'} soAction={'update'} />}
          />
          <Route
            path="/so-addendum"
            element={
              <SalesContract key={'sale-addendum'} soAction={'addendum'} />
            }
          />
          <Route path="/so-tracker" element={<SalesContractView />} />
          <Route path="/so-detail-view" element={<SalesContractDetailView />}>
            <Route path=":sc_id" element={<SalesContractDetailView />} />
          </Route>
  
          <Route
            path="/sales-transfer-log"
            element={
              <TransferLogGrid
                key={'sales-transfer-log'}
                contractType={ContractModesEnum.SALES}
              />
            }
          />
          <Route
            path="/purchase-transfer-log"
            element={
              <TransferLogGrid
                key={'purchase-transfer-log'}
                contractType={ContractModesEnum.PURCHASE}
              />
            }
          />
  
          <Route
            path="/monthly-coal-price-index"
            element={
              <CoalIndexPricesPage
                coalIndexType={ColaIndexTypeEnum.MONTHLY}
                key={ColaIndexTypeEnum.MONTHLY}
              />
            }
          />
          <Route
            path="/weekly-coal-price-index"
            element={
              <CoalIndexPricesPage
                coalIndexType={ColaIndexTypeEnum.WEEKLY}
                key={ColaIndexTypeEnum.WEEKLY}
              />
            }
          />
          <Route
            path="/coal-price-calculation"
            element={
              <IndexedPriceCalculationForm
                initialValues={undefined}
                coalIndexType={ColaIndexTypeEnum.WEEKLY}
                cancelHandler={() => {}}
                indexFormSubmitButtonHandler={(values) => {}}
              />
            }
          />
          <Route path="/hba-indexes" element={<HBAIndexes />} />
  
          <Route path="/fixtureNoteDetails" element={<FixtureNoteDetails />} />
          <Route path="/fixtureNotes" element={<FixtureNotesGrid />} />
  
          <Route
            path="/bargeAgreement-creation"
            element={
              <BargeAgreementPreview
                key={'bargeAgreement-creation'}
                baAction={'create'}
              />
            }
          />
          <Route
            path="/bargeAgreement-update"
            element={
              <BargeAgreementPreview
                key={'bargeAgreement-update'}
                baAction={'update'}
              />
            }
          />
          <Route
            path="/bargeAgreement-addendum"
            element={
              <BargeAgreementPreview
                key={'bargeAgreement-addendum'}
                baAction={'addendum'}
              />
            }
          />
          <Route path="/bargeAgreement-grid" element={<BargeAgreementGrid />} />
          <Route
            path="/bargeAgreement-detailed-view"
            element={<BargeAgreementDetailedView />}
          />
          <Route path="/contract-approval" element={<ContractApproval />} />
          <Route
            path="/advance-business-approval"
            element={
              <AdvanceBusinessApprovals
                key="advance-business-approval"
                feature={ReferenceFeatures.ADVANCE_INVOICE}
              />
            }
          />
          <Route
            path="/advance-receivable-business-approval"
            element={
              <AdvanceBusinessApprovals
                key="advance-receivable-business-approval"
                feature={ReferenceFeatures.ADVANCE_RECEIVABLES}
              />
            }
          />
  
          <Route path="/vesselAgreement-grid" element={<VesselAgreementGrid />} />
          <Route
            path="/vesselAgreement-creation"
            element={
              <VesselAgreementPreview
                key={'vesselAgreement-creation'}
                vaAction={'create'}
              />
            }
          />
          <Route
            path="/vesselAgreement-update"
            element={
              <VesselAgreementPreview
                key={'vesselAgreement-update'}
                vaAction={'update'}
              />
            }
          />
          <Route
            path="/vesselAgreement-addendum"
            element={
              <VesselAgreementPreview
                key={'vesselAgreement-addendum'}
                vaAction={'addendum'}
              />
            }
          />
          <Route
            path="/vesselAgreement-detailed-view"
            element={<VesselAgreementDetailedView />}
          />
  
          <Route
            path="/procurementServiceFee"
            element={
              <ProcurementServiceGrid
                contractTypeCategory={
                  ProcurementContractTypeEnum.Procurement_Fees
                }
                key={ProcurementContractTypeEnum.Procurement_Fees + 'gird'}
              />
            }
          />
          <Route
            path="/procurement-service-create"
            element={
              <ProcurementServiceContract
                key={'procurement-create'}
                contractType={ProcurementContractTypeEnum.Procurement_Fees}
                poAction={'create'}
              />
            }
          />
          <Route
            path="/procurement-service-update"
            element={
              <ProcurementServiceContract
                key={'procurement-update'}
                contractType={ProcurementContractTypeEnum.Procurement_Fees}
              />
            }
          />
          <Route
            path="/procurement-addendum"
            element={
              <ProcurementServiceContract
                key={'procurement-addendum'}
                contractType={ProcurementContractTypeEnum.Procurement_Fees}
                poAction={'addendum'}
              />
            }
          />
  
          <Route
            path="/marketing"
            element={
              <ProcurementServiceGrid
                contractTypeCategory={ProcurementContractTypeEnum.Marketing_Fees}
                key={ProcurementContractTypeEnum.Marketing_Fees + 'gird'}
              />
            }
          />
          <Route
            path="/marketing-service-create"
            element={
              <ProcurementServiceContract
                key={'marketing-create'}
                contractType={ProcurementContractTypeEnum.Marketing_Fees}
                poAction={'create'}
              />
            }
          />
          <Route
            path="/marketing-service-update"
            element={
              <ProcurementServiceContract
                key={'marketing-update'}
                contractType={ProcurementContractTypeEnum.Marketing_Fees}
              />
            }
          />
          <Route
            path="/marketing-addendum"
            element={
              <ProcurementServiceContract
                key={'marketing-addendum'}
                contractType={ProcurementContractTypeEnum.Marketing_Fees}
                poAction={'addendum'}
              />
            }
          />
  
          <Route
            path="/coalServiceFee"
            element={
              <ProcurementServiceGrid
                contractTypeCategory={
                  ProcurementContractTypeEnum.Coal_Service_Fees
                }
                key={ProcurementContractTypeEnum.Coal_Service_Fees + 'gird'}
              />
            }
          />
          <Route
            path="/coalServiceFee-service-create"
            element={
              <ProcurementServiceContract
                key={'coalServiceFee-create'}
                contractType={ProcurementContractTypeEnum.Coal_Service_Fees}
                poAction={'create'}
              />
            }
          />
          <Route
            path="/coalServiceFee-service-update"
            element={
              <ProcurementServiceContract
                key={'coalServiceFee-update'}
                contractType={ProcurementContractTypeEnum.Coal_Service_Fees}
              />
            }
          />
          <Route
            path="/coalServiceFee-addendum"
            element={
              <ProcurementServiceContract
                key={'coalServiceFee-addendum'}
                contractType={ProcurementContractTypeEnum.Coal_Service_Fees}
                poAction={'addendum'}
              />
            }
          />
  
          <Route
            path="/procurementAddendumDetailView"
            element={
              <ProcurementContractDetailedView key={'procurementAddendum'} />
            }
          />
          <Route
            path="/procurement-contract-detailedView"
            element={
              <ProcurementContractDetailedView
                contractTypeCategory={
                  ProcurementContractTypeEnum.Procurement_Fees
                }
              />
            }
          />
          <Route
            path="/coalServiceFee-detailedView"
            element={
              <ProcurementContractDetailedView
                contractTypeCategory={
                  ProcurementContractTypeEnum.Coal_Service_Fees
                }
              />
            }
          />
          <Route
            path="/marketing-detailedView"
            element={
              <ProcurementContractDetailedView
                contractTypeCategory={ProcurementContractTypeEnum.Marketing_Fees}
              />
            }
          />
  
          <Route
            path="/vendorAdvance"
            element={
              <VendorAdvancePreview
                key={'vendorAdvance-Create'}
                vAction={'create'}
              />
            }
          />
          <Route
            path="/vendorAdvance-UpDate"
            element={<VendorAdvancePreview key={'vendorAdvance-UpDate'} />}
          />
          <Route
            path="/vendorAdvance-Addendum"
            element={<VendorAdvancePreview key={'vendorAdvance-Addendum'} />}
          />
          <Route
            path="/vendor-advance"
            element={<AdvanceGrid advanceCategory={AdvanceEnum.VendorAdvance} />}
          />
          <Route
            path="/down-payment"
            element={<AdvanceGrid advanceCategory={AdvanceEnum.DownPayment} />}
          />
          <Route
            path="/vendor-advance-against-contract"
            element={
              <AdvanceGrid
                advanceCategory={AdvanceEnum.VendorAdvanceAgainstContract}
              />
            }
          />
          <Route path="/advanceDetailView" element={<AdvanceContractDetails />} />
          <Route path="/downPaymentGrid" element={<DownPaymentsGrid />} />
          <Route
            path="/advance-receivables"
            element={
              <AdvanceReceivablesGrid
                advanceCategory={AdvanceEnum.AdvanceReceivables}
              />
            }
          />
          <Route
            path="/advance-receivables-detailed-view"
            element={<AdvanceReceivablesDetailedView />}
          />
          <Route
            path="/advance-receivables-create"
            element={
              <AdvanceReceivablesPreview
                key={'advance-receivables-create'}
                arAction={'create'}
              />
            }
          />
          <Route
            path="/advance-receivables-addendum"
            element={
              <AdvanceReceivablesPreview
                key={'advance-receivables-create'}
                arAction={'addendum'}
              />
            }
          />
          <Route
            path="/advance-receivables-update"
            element={
              <AdvanceReceivablesPreview
                key={'advance-receivables-update'}
                arAction={'update'}
              />
            }
          />
  
          <Route
            path="/businessnumberDetails"
            element={
              <BusinessNumberDetails
                key={'businessNumber-create'}
                bnAction={'create'}
              />
            }
          />
          <Route
            path="/businessnumber-update"
            element={<BusinessNumberDetails key={'businessNumber-update'} />}
          />
          <Route path="/mothervessel-view" element={<MotherVesselDetailView />} />
          <Route path="/bargeMapping-view" element={<BargeMappingView />} />
          <Route path="/businessnumber" element={<BusinessNumberGrid />} />
          <Route path="/bn-detail-view" element={<BusinessNumberDetailView />}>
            <Route path=":businessNo" element={<BusinessNumberDetailView />} />
          </Route>
  
          {/* <Route path="/PLChildReports" element={<PlChildReports />} /> */}
  
          <Route path="/PLReports" element={<PlReports />} />
  
          <Route
            path="/createBarge"
            element={<BargeCreation Action={'Craete'} />}
          />
          <Route
            path="/barge-update"
            element={<BargeCreation key={'barge-update'} Action={'Update'} />}
          />
          <Route path="/barge-grid" element={<BargeGrid />} />
          <Route path="/barge-detail-view" element={<BargeDetailView />}>
            <Route path=":bargeId" element={<BargeDetailView />} />
          </Route>
          <Route
            path="barge-sales"
            element={<BargeSales gridData={undefined} />}
          />
  
          <Route
            path="/performa-pc-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.PERFORMA}
                contractType={ContractModesEnum.PURCHASE}
                referenceFeatures={ReferenceFeatures.PERFORMA_PC_COSTING}
                key={`performaDetailsView${ContractModesEnum.PURCHASE}`}
              />
            }
          />
  
          <Route
            path="/performa-sc-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.PERFORMA}
                contractType={ContractModesEnum.SALES}
                referenceFeatures={ReferenceFeatures.PERFORMA_SC_COSTING}
                key={`performaDetailsView${ContractModesEnum.SALES}`}
              />
            }
          />
  
          <Route
            path="/commercial-pc-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.COMMERCIAL}
                contractType={ContractModesEnum.PURCHASE}
                referenceFeatures={ReferenceFeatures.COMMERCIAL_PC_COSTING}
                key={`commercialDetailsView${ContractModesEnum.PURCHASE}`}
              />
            }
          />
  
          <Route
            path="/commercial-sc-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.COMMERCIAL}
                contractType={ContractModesEnum.SALES}
                referenceFeatures={ReferenceFeatures.COMMERCIAL_SC_COSTING}
                key={`commercialDetailsView${ContractModesEnum.SALES}`}
              />
            }
          />
  
          <Route
            path="/procurement-fee-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.PROCUREMENT_FEES}
                contractType={ContractModesEnum.PROCUREMENT_FEES}
                referenceFeatures={ReferenceFeatures.PROCUREMENT_FEES}
                key={`procurementFeeDetailsView${ContractModesEnum.PURCHASE}`}
              />
            }
          />
  
          <Route
            path="/marketing-fee-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.MARKETING_FEES}
                contractType={ContractModesEnum.MARKETING_FEES}
                referenceFeatures={ReferenceFeatures.MARKETING_FEES}
                key={`marketingFeeDetailsView${ContractModesEnum.PURCHASE}`}
              />
            }
          />
  
          <Route
            path="/barge-costing-detail-view"
            element={
              <BargeCostingDetailsView
                // costingType={}
                // contractType={ContractModesEnum.MARKETING_FEES}
                referenceFeatures={ReferenceFeatures.BARGE_COSTINGS}
                key={`bargeCostingDetailsView${ContractModesEnum.PURCHASE}`}
              />
            }
          />
  
          <Route
            path="/freight-detail-view"
            element={
              <PerformaDetailView
                costingType={CostingTypesEnum.SALE_FREIGHT_COSTING}
                contractType={ContractModesEnum.FREIGHT_COSTING}
                referenceFeatures={ReferenceFeatures.SALES_FREIGHT_COSTING}
                key={`freightDetailsView${ContractModesEnum.FREIGHT_COSTING}`}
              />
            }
          />
  
          <Route
            path="/expense-entry-detail-view"
            element={
              <ExpenseEntryDetailView
                contractType={ContractModesEnum.PURCHASE}
                referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY}
                key={`expenseEntryPurchaseDetailsView${ReferenceFeatures.EXPENSE_ENTRY}`}
              />
            }
          />
  
          <Route
            path="/other-income-expense-entry-detail-view"
            element={
              <ExpenseEntryDetailView
                contractType={ContractModesEnum.SALES}
                referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY}
                key={`expenseEntrySalesDetailsView${ReferenceFeatures.EXPENSE_ENTRY}`}
              />
            }
          />
  
          <Route
            path="/dead-freight-detail-view"
            element={
              <DeadFrieghtDetailView
                referenceFeatures={ReferenceFeatures.MV_DEAD_FREIGHT}
                key={`deadFreightDetailsView${ReferenceFeatures.MV_DEAD_FREIGHT}`}
              />
            }
          />
  
          <Route
            path="/demurrage-detail-view"
            element={
              <DemurrageDetailView
                referenceFeatures={ReferenceFeatures.MV_DEMURRAGE}
                key={`demurrageDetailsView${ReferenceFeatures.MV_DEMURRAGE}`}
              />
            }
          />
  
          <Route
            path="/debit-detail-view"
            element={
              <DebitCreditNoteDetailView
                referenceFeatures={ReferenceFeatures.DEBIT_NOTE}
                key={`debitDetailsView${ReferenceFeatures.DEBIT_NOTE}`}
              />
            }
          />
  
          <Route
            path="/credit-detail-view"
            element={
              <DebitCreditNoteDetailView
                referenceFeatures={ReferenceFeatures.CREDIT_NOTE}
                key={`creditDetailsView${ReferenceFeatures.CREDIT_NOTE}`}
              />
            }
          />
  
          {/* //performaCoalCosting */}
          <Route
            path="/performaCoalCosting"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.PERFORMA_PC_COSTING}
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingTypesEnum.PERFORMA}
              />
            }
          />
          <Route
            path="/create-performa-coal-costing"
            element={
              <PerformaCoalCostings
                contractType={ContractModesEnum.PURCHASE}
                key={'createPerformaCoalCosting' + ContractModesEnum.PURCHASE}
              />
            }
          />
          <Route
            path="/salesFreightCosting"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.SALES_FREIGHT_COSTING}
                contractType={ContractModesEnum.FREIGHT_COSTING}
                costingType={CostingTypesEnum.SALE_FREIGHT_COSTING}
              />
            }
          />
          <Route
            path="/create-sale-freight-costing"
            element={
              <SalesFreightCosting contractType={ContractModesEnum.SALES} />
            }
          />
  
          {/* commercialCoalCosting */}
          <Route
            path="/commercial-coal-costing"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.COMMERCIAL_PC_COSTING}
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingTypesEnum.COMMERCIAL}
              />
            }
          />
          <Route
            path="/create-commercial-coal-costing"
            element={
              <CmCoalCostingMainForm
                costingType={CostingTypesEnum.COMMERCIAL}
                referenceFeatures={ReferenceFeatures.COMMERCIAL_PC_COSTING}
                contractType={ContractModesEnum.PURCHASE}
                key={`commercialCoalCosting${ContractModesEnum.PURCHASE}`}
              />
            }
          />
          <Route
            path="/performaCostingSale"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.PERFORMA_SC_COSTING}
                contractType={ContractModesEnum.SALES}
                costingType={CostingTypesEnum.PERFORMA}
              />
            }
          />
          <Route
            path="/create-performa-costing-sale"
            element={
              <CmSalesCostingMainForm
                costingType={CostingTypesEnum.PERFORMA}
                referenceFeatures={ReferenceFeatures.PERFORMA_SC_COSTING}
                contractType={ContractModesEnum.SALES}
                key={`performaCostingSale${ContractModesEnum.SALES}`}
              />
            }
          />
  
          <Route
            path="/commercial-costing-sales"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.COMMERCIAL_SC_COSTING}
                contractType={ContractModesEnum.SALES}
                costingType={CostingTypesEnum.COMMERCIAL}
              />
            }
          />
          <Route
            path="/create-commercial-costing-sale"
            element={
              <CmSalesCostingMainForm
                costingType={CostingTypesEnum.COMMERCIAL}
                referenceFeatures={ReferenceFeatures.COMMERCIAL_SC_COSTING}
                contractType={ContractModesEnum.SALES}
                key={`commercialCoalCosting${ContractModesEnum.SALES}`}
              />
            }
          />
  
          {/* Procurement */}
          <Route
            path="/procurement-coal-costing"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.PROCUREMENT_FEES}
                contractType={ContractModesEnum.PROCUREMENT_FEES}
                costingType={CostingTypesEnum.PROCUREMENT_FEES}
              />
            }
          />
          <Route
            path="/create-procurement-coal-costing"
            element={
              <ProcurementCoalCoasting
                costingType={CostingTypesEnum.PROCUREMENT_FEES}
                key={`procurementCoalCosting${ProcurementContractTypeEnum.Procurement_Fees}  `}
                referenceFeatures={ReferenceFeatures.PROCUREMENT_FEES}
                pcrContractType={ProcurementContractTypeEnum.Procurement_Fees}
                contractType={ContractModesEnum.PROCUREMENT_FEES}
              />
            }
          />
          <Route
            path="/marketing-coal-costing"
            element={
              <SavedCostingsGrid
                referenceFeatures={ReferenceFeatures.MARKETING_FEES}
                contractType={ContractModesEnum.MARKETING_FEES}
                costingType={CostingTypesEnum.MARKETING_FEES}
              />
            }
          />
          <Route
            path="/create-marketing-coal-costing"
            element={
              <MarketingCoalCoasting
                costingType={CostingTypesEnum.MARKETING_FEES}
                key={`marketingCoalCosting${ProcurementContractTypeEnum.Marketing_Fees}  `}
                referenceFeatures={ReferenceFeatures.MARKETING_FEES}
                pcrContractType={ProcurementContractTypeEnum.Marketing_Fees}
                contractType={ContractModesEnum.MARKETING_FEES}
              />
            }
          />
  
          <Route path="/coalCostView" element={<CoalCostingDetailView />} />
          <Route
            path="/expense-entry"
            element={<ExpenseEntryRejectedList type="Account Payable" />}
            key={'Account Payable'}
          />
          <Route
            path="/create-expense-entry"
            element={<PaymentNoticeExpensive type="Account Payable" />}
            key={'Account Payable'}
          />
          <Route
            path="/other-income-expense-entry"
            element={<ExpenseEntryRejectedList type="Account Receivable" />}
            key={'Account Receivable'}
          />
          <Route
            path="/create-other-income-expense-entry"
            element={<PaymentNoticeExpensive type="Account Receivable" />}
            key={'Account Receivable'}
          />
          <Route path="/barge-payment-notice" element={<BargePaymentNotice />} />
          <Route
            path="/dead-freight"
            element={<DeadFreightList />}
            key="dead-freight"
          />
          <Route
            path="/create-dead-freight"
            element={<DeadFreight />}
            key="create-dead-freight"
          />
          <Route path="/demurrage" element={<DemurrageList />} key="demurrage" />
          <Route
            path="/create-demurrage"
            element={<Demurrage />}
            key="create demurrage"
          />
  
          {/* Costing_Approval */}
  
          <Route
            path="performa-pc-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingWorkLogsEnum.PERFORMA_PC_COSTING}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="performa-pc-approval"
              />
            }
          />
          <Route
            path="performa-sc-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.SALES}
                costingType={CostingWorkLogsEnum.PERFORMA_SC_COSTING}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                paymentType={PaymentTypeEnum.RECEIVABLE}
                key="performa-sc-approval"
              />
            }
          />
  
          <Route
            path="sc-freight-costing-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.SALES}
                costingType={CostingWorkLogsEnum.SALES_FREIGHT_COSTING}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                paymentType={PaymentTypeEnum.RECEIVABLE}
                key="sc-freight-costing-approval"
              />
            }
          />
  
          <Route
            path="commercial-pc-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingWorkLogsEnum.COMMERCIAL_PC_COSTING}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="commercial-pc-approval"
              />
            }
          />
          <Route
            path="marketing-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.MARKETING_FEES}
                costingType={CostingWorkLogsEnum.MARKETING_FEES}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="marketing-approval"
              />
            }
          />
          <Route
            path="procurement-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PROCUREMENT_FEES}
                costingType={CostingWorkLogsEnum.PROCUREMENT_FEES}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="procurement-approval"
              />
            }
          />
          <Route
            path="/expense-entry-approval"
            element={
              <PaymentNoticeApproval
                type={PaymentNoticeApprovalScreen.BUSINESS_APPROVAL}
                key="expense-entry-approval"
              />
            }
          />
          <Route
            path="/dead-freight-approval"
            element={
              <DeadFreightApproval
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="dead-freight-approval"
              />
            }
          />
          <Route
            path="/demurrage-approval"
            element={
              <DemurrageApproval
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                paymentType={PaymentTypeEnum.PAYABLE}
              />
            }
            key="demurrage-approval"
          />
  
          {/* Audit Costing_Approval */}
          <Route
            path="/audit-approvals-payables"
            element={
              <AuditApprovals
                type={PaymentTypeEnum.PAYABLE}
                key="account-payable-audit-approval"
              />
            }
          />
          <Route
            path="/audit-approvals-receivables"
            element={
              <AuditApprovals
                type={PaymentTypeEnum.RECEIVABLE}
                key="account-receivable-audit-approval"
              />
            }
          />
  
          <Route
            path="performa-pc-audit-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingWorkLogsEnum.PERFORMA_PC_COSTING}
                type={ContractStatusEnum.AUDIT_APPROVAL}
                key="performa-pc-audit-approval"
              />
            }
          />
          <Route
            path="performa-sc-audit-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.SALES}
                costingType={CostingWorkLogsEnum.PERFORMA_SC_COSTING}
                type={ContractStatusEnum.AUDIT_APPROVAL}
                paymentType={PaymentTypeEnum.RECEIVABLE}
                key="performa-sc-audit-approval"
              />
            }
          />
  
          <Route
            path="commercial-pc-audit-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingWorkLogsEnum.COMMERCIAL_PC_COSTING}
                type={ContractStatusEnum.AUDIT_APPROVAL}
                key="commercial-pc-audit-approval"
              />
            }
          />
          <Route
            path="marketing-audit-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.MARKETING_FEES}
                costingType={CostingWorkLogsEnum.MARKETING_FEES}
                type={ContractStatusEnum.AUDIT_APPROVAL}
                key="marketing-audit-approval"
              />
            }
          />
          <Route
            path="procurement-audit-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PROCUREMENT_FEES}
                costingType={CostingWorkLogsEnum.PROCUREMENT_FEES}
                type={ContractStatusEnum.AUDIT_APPROVAL}
                key="procurement-audit-approval"
              />
            }
          />
          <Route
            path="/expense-entry-audit-approval"
            element={
              <PaymentNoticeApproval
                type={PaymentNoticeApprovalScreen.AUDIT_APPROVAL}
                key="expense-entry-audit-approval"
              />
            }
          />
          <Route
            path="/dead-freight-audit-approval"
            element={
              <DeadFreightApproval type={ContractStatusEnum.AUDIT_APPROVAL} />
            }
            key="dead-freight-audit-approval"
          />
          <Route
            path="/demurrage-audit-approval"
            element={
              <DemurrageApproval
                type={ContractStatusEnum.AUDIT_APPROVAL}
                paymentType={PaymentTypeEnum.PAYABLE}
              />
            }
            key="demurrage-audit-approval"
          />
  
          {/* Management Costing_Approval */}
  
          <Route
            path="commercial-pc-management-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.PURCHASE}
                costingType={CostingWorkLogsEnum.COMMERCIAL_PC_COSTING}
                type={ContractStatusEnum.MANAGEMENT_APPROVAL}
                key="commercial-pc-management-approval"
              />
            }
          />
          <Route
            path="/dead-freight-management-approval"
            element={
              <DeadFreightApproval
                type={ContractStatusEnum.MANAGEMENT_APPROVAL}
              />
            }
            key="dead-freight-management-approval"
          />
          <Route
            path="/demurrage-management-approval"
            element={
              <DemurrageApproval type={ContractStatusEnum.MANAGEMENT_APPROVAL} />
            }
            key="demurrage-management-approval"
          />
  
          {/* Costing Finance */}
  
          <Route
            path="payment-notice-creation"
            element={
              <PaymentNoticePage
                type={PaymentNoticePageEnum.CREATE}
                key="payment-notice-creation"
              />
            }
          />
          <Route
            path="payment-notice-approval"
            element={
              <PaymentNoticePage
                type={PaymentNoticePageEnum.APPROVAL}
                key="payment-notice-approval"
              />
            }
          />
          <Route
            path="payment-notice-release"
            element={
              <PaymentNoticePage
                type={PaymentNoticePageEnum.RELEASE}
                key="payment-notice-release"
              />
            }
          />
          <Route
            path="closed-payment-notices"
            element={
              <PaymentNoticePage
                type={PaymentNoticePageEnum.CLOSED}
                key="closed-payment-notices"
              />
            }
          />
          {/* <Route path='/dead-freight-accounts-entry' element={<DeadFreightApproval key={'/dead-freight-accounts-entry'} deadFreightStatus={ContractStatusEnum.ACTIVE} accountType={BusinessPartnerTypeEnum.CUSTOMER} />} /> */}
          {/* <Route path='/demurrage-accounts-entry' element={<DemurrageApproval key={'/demurrage-accounts-entry'} demurrageStatus={ContractStatusEnum.ACTIVE} accountType={BusinessPartnerTypeEnum.CUSTOMER} />} /> */}
  
          {/* Receivables Approvals*/}
          <Route
            path="commercial-sc-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.SALES}
                costingType={CostingWorkLogsEnum.COMMERCIAL_SC_COSTING}
                paymentType={PaymentTypeEnum.RECEIVABLE}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key="commercial-sc-approval"
              />
            }
          />
          <Route
            path="commercial-sc-audit-approval"
            element={
              <BargeCostingApproval
                contractType={ContractModesEnum.SALES}
                costingType={CostingWorkLogsEnum.COMMERCIAL_SC_COSTING}
                paymentType={PaymentTypeEnum.RECEIVABLE}
                type={ContractStatusEnum.AUDIT_APPROVAL}
                key="commercial-sc-audit-approval"
              />
            }
          />
  
          <Route
            path="rec-expense-entry-approval"
            element={
              <PaymentNoticeApproval
                paymentType={PaymentNoticePageEnum.RECEIVABLES}
                type={PaymentNoticeApprovalScreen.BUSINESS_APPROVAL}
                key="rec-expense-entry-approval"
              />
            }
          />
          <Route
            path="rec-expense-entry-audit-approval"
            element={
              <PaymentNoticeApproval
                paymentType={PaymentNoticePageEnum.RECEIVABLES}
              />
            }
            key="rec-expense-entry-audit-approval"
          />
          <Route
            path="/dead-freight-receivable-approval"
            element={
              <DeadFreightApproval
                paymentType={PaymentNoticePageEnum.RECEIVABLES}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key={'deadFreightStatus-Receivables'}
              />
            }
          />
          <Route
            path="/demurrage-receivable-approval"
            element={
              <DemurrageApproval
                paymentType={PaymentTypeEnum.RECEIVABLE}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
                key={'demurrageStatus-Receivables'}
              />
            }
          />
          <Route
            path="debit-note-approval"
            element={<DebitCreditNoteApproval noteType={'Debit Note'} />}
          />
  
          {/* receivable payment transactions */}
          <Route
            path="approve-payment"
            element={
              <Receivables
                type={PaymentNoticePageEnum.CREATE}
                paymentType={PaymentNoticePageEnum.RECEIVABLES}
                key="approve-payment"
              />
            }
          />
          <Route
            path="release-payment"
            element={
              <Receivables
                type={PaymentNoticePageEnum.RELEASE}
                paymentType={PaymentNoticePageEnum.RECEIVABLES}
                key="release-payment"
              />
            }
          />
          <Route
            path="receivable-closed-payment"
            element={
              <Receivables
                type={PaymentNoticePageEnum.CLOSED}
                paymentType={PaymentNoticePageEnum.RECEIVABLES}
                key="receivable-closed-payment"
              />
            }
          />
          {/* <Route path='/dead-freight-receivable-transactions' element={<DeadFreightApproval key={'/dead-freight-receivable-transactions'} deadFreightStatus={ContractStatusEnum.ACTIVE} accountType={BusinessPartnerTypeEnum.VENDOR} />} /> */}
          {/* <Route path='/demurrage-receivable-transactions' element={<DemurrageApproval key={'/demurrage-receivable-transactions'} demurrageStatus={ContractStatusEnum.ACTIVE} accountType={BusinessPartnerTypeEnum.VENDOR} />} /> */}
  
          {/*Purchase sampling And Analysis  creation and Update*/}
  
          <Route path="purchase-sampling">
            <Route
              index
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.SUPPLIER}
                  contractType={ContractModesEnum.PURCHASE}
                  key={SamplingTypes.SUPPLIER + `purchase-sampling`}
                />
              }
            />
  
            <Route
              path="supplier"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.SUPPLIER}
                  contractType={ContractModesEnum.PURCHASE}
                  key={SamplingTypes.SUPPLIER + `purchase-sampling-create`}
                />
              }
            />
            <Route
              path="supplier-update"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.UPDATE}
                  sampleType={SamplingTypes.SUPPLIER}
                  contractType={ContractModesEnum.PURCHASE}
                  key={SamplingTypes.SUPPLIER + `purchase-sampling-update`}
                />
              }
            />
  
            <Route
              path="company"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.INTERNAL}
                  key={SamplingTypes.INTERNAL + `purchase-sampling-create`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route
              path="company-update"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.UPDATE}
                  sampleType={SamplingTypes.INTERNAL}
                  key={SamplingTypes.INTERNAL + `purchase-sampling-update`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
  
            <Route
              path="actual"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.ACTUAL}
                  key={SamplingTypes.ACTUAL + `purchase-sampling-create`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route
              path="actual-update"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.UPDATE}
                  sampleType={SamplingTypes.ACTUAL}
                  key={SamplingTypes.ACTUAL + `purchase-sampling-update`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
  
            <Route
              path="shipment"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.PRE_SHIPMENT}
                  key={SamplingTypes.PRE_SHIPMENT + `purchase-sampling-create`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route
              path="shipment-update"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.UPDATE}
                  sampleType={SamplingTypes.PRE_SHIPMENT}
                  key={SamplingTypes.PRE_SHIPMENT + `purchase-sampling-update`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
          </Route>
          {/*Purchase  sampling And Analysis  Grid*/}
  
          <Route path="purchase-sampling-reports">
            <Route
              path="supplier"
              element={
                <PurchaseSampleReport
                  sampleType={SamplingTypes.SUPPLIER}
                  key={SamplingTypes.SUPPLIER + `purchase-sampling-reports-`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route
              path="company"
              element={
                <PurchaseSampleReport
                  sampleType={SamplingTypes.INTERNAL}
                  key={SamplingTypes.INTERNAL + `purchase-sampling-reports`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route
              path="actual"
              element={
                <PurchaseSampleReport
                  sampleType={SamplingTypes.ACTUAL}
                  key={SamplingTypes.ACTUAL + `purchase-sampling-reports`}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route
              path="aging-report"
              element={
                <AgingReport
                  key={'purchase-aging-report'}
                  contractType={ContractModesEnum.PURCHASE}
                />
              }
            />
            <Route path="pre-shipment" element={<PreShipment />} />
          </Route>
  
          {/*Sale sampling And Analysis  creation and Update*/}
  
          <Route path="sales-sampling">
            <Route
              index
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.SUPPLIER}
                  contractType={ContractModesEnum.SALES}
                  key={SamplingTypes.SUPPLIER + `sale-sampling`}
                />
              }
            />
  
            <Route
              path="actual"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.CREATE}
                  sampleType={SamplingTypes.ACTUAL}
                  contractType={ContractModesEnum.SALES}
                  key={SamplingTypes.SUPPLIER + `sale-sampling-create`}
                />
              }
            />
            <Route
              path="actual-update"
              element={
                <PurchaseSampling
                  actionType={ActionEnum.UPDATE}
                  sampleType={SamplingTypes.ACTUAL}
                  contractType={ContractModesEnum.SALES}
                  key={SamplingTypes.SUPPLIER + `sale-sampling-update`}
                />
              }
            />
          </Route>
  
          <Route path="sales-sampling-reports">
            <Route
              path="actual"
              element={
                <SupplierReports
                  contractType={ContractModesEnum.SALES}
                  sampleType={SamplingTypes.ACTUAL}
                  key={'supplier-reports'}
                />
              }
            />
            <Route
              path="aging-report"
              element={
                <AgingReport
                  key={'sales-aging-report'}
                  contractType={ContractModesEnum.SALES}
                />
              }
            />
          </Route>
  
          <Route path="barge-costings">
            <Route index element={<CoalCostings />} />
            <Route path="coal" element={<CoalCostings />} />
            <Route path="barge" element={<BargeCostingsList />} />
            <Route path="barge-creation" element={<BargeCostings />} />
            <Route
              path="barge-approval"
              element={
                <BargeCostingApproval
                  type={ContractStatusEnum.BUSINESS_APPROVAL}
                  costingType={CostingWorkLogsEnum.BARGE_COSTING}
                  key="barge-approval"
                />
              }
            />
            <Route
              path="barge-audit-approval"
              element={
                <BargeCostingApproval
                  type={ContractStatusEnum.AUDIT_APPROVAL}
                  costingType={CostingWorkLogsEnum.BARGE_COSTING}
                  key="barge-audit-approval"
                />
              }
            />
            <Route
              path="barge-pmt-notice"
              element={
                <BargeCostingApproval
                  type={ContractStatusEnum.PMT_NOTICE}
                  costingType={CostingWorkLogsEnum.BARGE_COSTING}
                  key="barge-pmt-notice"
                />
              }
            />
          </Route>
  
          <Route path="/purchase-lc-tracker" element={<LcTracker />} />
          <Route path="/sales-lc-tracker" element={<ScLcTracker />} />
  
          <Route path="/budget-costing" element={<BudgetGrid />} />
          <Route path="/budget-costing-form" element={<BudgetAndCostingForm />} />
          <Route
            path="/budget-costing-detailed-view"
            element={<BACostingDetailedView />}
          />
  
          <Route path="/markup-price" element={<MarkUpGrid />} />
          <Route path="/markup-price-form" element={<MarkupPriceForm />} />
          <Route
            path="/markup-price-detailed-view"
            element={<MarkupPriceDetailedView />}
          />
  
          <Route path="/account-groups" element={<GroupEntryGrid />} />
          <Route path="/create-account-group" element={<GroupEntryForm />} />
  
          <Route path="/accounts" element={<AccountCreationG />} />
          <Route path="/create-accounts" element={<AccountCreationF />} />
  
          <Route
            path="/payment-voucher"
            element={
              <PaymentG
                paymentTypeCategory={AccountsVoucherTypesEnum.PAYMENT_VOUCHER}
                key={'payment-voucher'}
              />
            }
          />
          <Route
            path="/create-payment-voucher"
            element={
              <PaymentF
                paymentTypeCategory={AccountsVoucherTypesEnum.PAYMENT_VOUCHER}
                key={'create-payment-voucher'}
              />
            }
          />
  
          <Route
            path="/create-receipt-voucher"
            element={
              <PaymentF
                paymentTypeCategory={AccountsVoucherTypesEnum.RECEIPT_VOUCHER}
                key={'create-receipt-voucher'}
              />
            }
          />
          <Route
            path="/receipt-voucher"
            element={
              <PaymentG
                paymentTypeCategory={AccountsVoucherTypesEnum.RECEIPT_VOUCHER}
                key={'receipt-voucher'}
              />
            }
          />
  
          <Route
            path="/journalVoucher"
            element={<JournalG key={'journalVoucher'} />}
          />
          <Route
            path="/createJournalVoucher"
            element={<JournalF key={'createJournalVoucher'} />}
          />
  
          <Route path="/vouchers" element={<Vouchers />} />
  
          <Route
            path="/credit-note-entry"
            element={<DebitCreditRejectedEntries noteType="Credit Note" />}
          />
          <Route
            path="/create-credit-note-entry"
            element={<DebitCreditNoteEntry noteType="Credit Note" />}
          />
          <Route
            path="/debit-note-entry"
            element={<DebitCreditRejectedEntries noteType="Debit Note" />}
          />
          <Route
            path="/create-debit-note-entry"
            element={<DebitCreditNoteEntry noteType="Debit Note" />}
          />
          <Route
            path="credit-note-approval"
            element={
              <DebitCreditNoteApproval
                noteType="Credit Note"
                type={ContractStatusEnum.BUSINESS_APPROVAL}
              />
            }
            key="credit-note-approval"
          />
          <Route
            path="credit-note-audit-approval"
            element={
              <DebitCreditNoteApproval
                noteType="Credit Note"
                type={ContractStatusEnum.AUDIT_APPROVAL}
              />
            }
            key="credit-note-audit-approval"
          />
  
          <Route path="/ledger" element={<Ledger />} />
          <Route path="/accountsPayable" element={<AccountsPayable />} />
          <Route path="/accountReceivables" element={<AccountsReceivables />} />
  
          <Route path="/costing-reports" element={<CostingReports />} />
          <Route path="/LTC-report" element={<LTCReports />} />
          <Route path="/loading-plans" element={<LoadingPlansList />} />
          <Route path="/pl-report" element={<ProfitLossReports />} />
          <Route
            path="/procurement-report"
            element={<ProcurementDetailReport />}
          />
          <Route
            path="/costing-detail-report"
            element={<CostingDetailReport />}
          />
          <Route path="/roles" element={<RolesPage />} />
          <Route path="/user-creation" element={<UserPage />} />
          <Route path="/user-role-mappings" element={<UserRoleMappings />} />
  
          {getAllRoutes().map((rec) => rec)}
          {/* Page Not Found */}
          <Route
            path="/*"
            element={
              <ExceptionComponent
                statusCode={404}
                statusMessage="Sorry, the page you visited does not exist."
              />
            }
          />
          <Route
            path="/403"
            element={
              <ExceptionComponent
                statusCode={403}
                statusMessage="Sorry, you are not authorized to access this page."
              />
            }
          />
          {/* End Page Not Found */}
        </Route>
        <Route path="/login" element={<LoginComponent />} />
      </Routes>
    );
  };
  
  export default AppRoutes;