import React, { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { Table, Typography, Col, Card, Row, Flex, Divider, Popover, Descriptions } from "antd";
import { AdvanceEnumType, CommercialCoalCostingService, ContractModesEnum, ContractTermEnum, CurrencyConverterService, CurrencyExchangeRequestDto, CurrencyTypesEnum, PerFormaReqIdDto, PriceTypeEnum } from "@exportx/shared-models-and-services";
import moment from "moment";
import { QualityPriceAdjustmentsDeatils } from "./quality-price-adjustments-deatils";
import { InfoCircleOutlined } from "@ant-design/icons";

interface CmApprovalDetailsProps {
  costingId: string;
  contractType: ContractModesEnum;
  remainingAmount?: number;
  children?: React.ReactNode;

}

export const CmApprovalDetails = (props: CmApprovalDetailsProps) => {
  const cmService = new CommercialCoalCostingService();
  const { costingId, contractType, children, remainingAmount } = props;
  const { authContext } = useAuthState();

  const [data, setData] = useState<any>();
  const [BargeData, setBargeData] = useState([])
  const { Text } = Typography;

  useEffect(() => {
    getAllCommercialData()
  }, [])

  const getAllCommercialData = () => {

    const req = new PerFormaReqIdDto(costingId, contractType, authContext.defaultPlant);
    cmService.getAllCommercialCostingsData(req).then(async res => {
      if (res.status) {
        const { contractId, contractNo, adjustedPriceValue, incoterm, totalAmount, advanceAdjustment, basePrice } = res.data || {}

        setData({
          ...res.data,
          includeFreight: !!res.data?.includeFreight,  // Simplified boolean check
        });
        // const bargeDetails = res.data?.transactionBarges?.map((item) => ({ ...item, businessNo: res.data?.bnId, incoterm: res.data?.incoterm, advAdjustment: advTotal, paid: 0 }))
        const bargeDetails = res.data?.transactionBarges?.map((item) => ({
          ...item,
          pricePmt: basePrice,
          incoterm: incoterm,
          advAdjustment: advanceAdjustment,
          totalPayable: totalAmount,
          adjustedPriceValue: adjustedPriceValue,
          contractId: contractId,
          contractNo: contractNo,
          layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
          remaining: totalAmount

        }))
        setBargeData(bargeDetails)

      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        // setInitialValues(null);
      }
    }).catch(err => { console.log(err.message) });
  };

  const showFreightPrice = () => (contractType !== ContractModesEnum.PROCUREMENT_FEES && contractType !== ContractModesEnum.MARKETING_FEES)

  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: BargeData.length }
    else return { rowSpan: 0 }
  };

  const columns = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      render: (value, record) => {
        if (record.bgUId) {
          const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a>
            <Typography className="small-text"> {record.bgName}</Typography>
          </div>

        }
        return <div>
          <Text className="small-text"> {value}</Text>
          <Typography className="small-text"> {record.bgName}</Typography>
        </div>

      }
    },
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      key: 'businessNo',
      render: (value, record) => {
        if (record.bnId) {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <div>
            <a href={link} className="link" target="_blank">{record.businessNo}</a>
            <Typography className="small-text"> {record.mvName}</Typography>
          </div>

        }

      }
    },
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      key: 'contractNo',
      render: (value, record) => {
        if (record.contractId) {
          let link = `/#/po-detail-view?pc_id=${record.contractId}`
          if (contractType === ContractModesEnum.SALES) {
            link = `/#/so-detail-view?sc_id=${record.contractId}`;
          }
          if (contractType === ContractModesEnum.PROCUREMENT_FEES) {
            link = `/#/procurement-contract-detailedView?cpcId=${record.contractId}`
          }
          if (contractType === ContractModesEnum.MARKETING_FEES) {
            link = `/#/marketing-detailedView?cpcId=${record.contractId}`

          }
          return <a href={link} className="link" target="_blank">
            {record.contractNo}
          </a>

        }
      }
    },
    {
      title: 'Laycan (Quality)',
      dataIndex: 'layCan',
      key: 'layCan',
      onCell: sharedOnCell,
      render: (value, record) => <>
        <Typography className="small-text">{record.layCan ?? 'NA'}</Typography>
        <Typography className="small-text">{record.incoterm ?? 'NA'}</Typography>
      </>
    },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      onCell: sharedOnCell,
      render: (value, record) => <Text className="small-text">{internationalFormattedValue(value, 3)}</Text>
    },

    {
      title: 'Price Type',
      dataIndex: 'layCan',
      key: 'layCan',
      onCell: sharedOnCell,
      render: (value, record) => <>
        <Typography className="small-text">{data?.priceType}</Typography>
      </>
    },

    {
      title: 'Price PMT',
      dataIndex: 'pricePmt',
      key: 'pricePmt',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(text, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    {
      title: `${data?.priceType === PriceTypeEnum.LUMP_SUM ? 'Lumpsum' : 'Price'}`,
      dataIndex: 'adjustedPriceValue',
      key: 'adjustedPriceValue',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.adjustedPriceValue, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    // {
    //   title: 'Total',
    //   dataIndex: 'totalPayable',
    //   key: 'totalPayable',
    //   onCell: sharedOnCell,
    //   render: (text, record) => <>
    //     <Text className="small-text">{internationalFormattedValue(record?.totalPayable, 3)}</Text>
    //     <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

    //   </>

    // },
    // {
    //   title: 'Advance',
    //   dataIndex: 'advAdjustment',
    //   key: 'advAdjustment',
    //   onCell: sharedOnCell,
    //   render: (text, record) => <>
    //     <Text className="small-text">{internationalFormattedValue(record?.advAdjustment, 3)}</Text>
    //     <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

    //   </>

    // },
    // {
    //   title: 'Balance',
    //   dataIndex: 'remaining',
    //   key: 'remaining',
    //   onCell: sharedOnCell,
    //   render: (text, record) => <>
    //     <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
    //     <span className="currency-style">{record?.quoteCurrency}</span>

    //   </>

    // },
  ];

  return <>
    <Row gutter={8}>
      <Col span={16}>
        <Card
          className="card-shadow"
          styles={{ body: { padding: '0px 10px 15px' } }}
          style={{ marginBottom: 8 }}
          title={<h6>Barge Details</h6>}
        >
          <Table
            className='contracts-list'
            dataSource={BargeData}
            columns={columns}
            size="small"
            pagination={false}
            scroll={{ x: 1200 }}
          />

        </Card>

        {(data?.purchasingTerms === ContractTermEnum.SPEC && data?.noPriceAdjustment === 'false') &&

          <QualityPriceAdjustmentsDeatils
            contractType={contractType}
            selectedRowData={BargeData[0]}
            formInitialValues={data}

          />
        }

        {children}

      </Col >

      <Col span={8}>
        {data?.includeFreight && data?.includeFreight !== 0 &&

          <Card
            className="card-shadow"
            title={<span className="card-title-md">Freight Price Details</span>}
            styles={{ body: { padding: '6px 14px' } }}
            style={{ marginBottom: 8 }}
          >
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Freight Pmt</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightInfo?.freightPricePmt, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Currency Type</Text>
              <Text className="price-card-text"> {data?.currencyType ?? 'NA'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Date
                {data?.currencyType === CurrencyTypesEnum.MIDDLE_RATE && (
                  <span style={{ paddingLeft: 5 }}>
                    <Popover
                      content={
                        <>
                          <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{data?.purchaseRate}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Selling Rate <b>{data?.sellingRate}</b></Typography>
                        </>
                      }
                      title="Rates"
                      placement="bottom"
                    >
                      <InfoCircleOutlined style={{ color: "#0295D4" }} />
                    </Popover>
                  </span>
                )}
              </Text>
              <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Freight Price After Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightInfo?.freightAfterExchangeRate, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Freight</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(data?.freightInfo?.freightBeforeTax, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 1</Text>
              <Text className="price-card-text">{`${data?.freightInfo?.freightTaxName1 || 'NA'} (${data?.freightInfo?.freightTaxPerc1 || '0'})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightInfo?.freightTaxTotal1, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 2</Text>
              <Text className="price-card-text">{`${data?.freightInfo?.freightTaxName2 || ''} (${data?.freightTaxPerc2 || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightInfo?.freightTaxTotal2, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text className="price-card-text">{`${data?.freightInfo?.freightTdsName || ''} (${data?.freightInfo?.freightTdsPerc || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightInfo?.freightTdsTotal, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">Total Freight</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(data?.freightInfo?.freightCostingTotal, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>

          </Card>
        }
        <Card
          className="card-shadow"
          title={<span className="card-title-md">Price Details</span>}
          styles={{ body: { padding: '6px 14px' } }}
        >
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Currency Type</Text>
            <Text className="price-card-text"> {data?.currencyType ?? 'NA'}</Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Exchange Date</Text>
            <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Actual Exchange Rate</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.actualExchangeRate, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Exchange Rate Adjustment</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.exchangeRateAdjustment, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Exchange Rate
              {data?.currencyType === CurrencyTypesEnum.MIDDLE_RATE && (
                <span style={{ paddingLeft: 5 }}>
                  <Popover
                    content={
                      <>
                        <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{data?.purchaseRate}</b></Typography>
                        <Typography style={{ fontSize: 10 }}>Selling Rate <b>{data?.sellingRate}</b></Typography>
                      </>
                    }
                    title="Rates"
                    placement="bottom"
                  >
                    <InfoCircleOutlined style={{ color: "#0295D4" }} />
                  </Popover>
                </span>
              )}
            </Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.displayExchangeRate, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Price After Exchange Rate</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.priceAfterExchgRate, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Total Coal Price</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.totalCoalPrice, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">FC Charges</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.fcCharges, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-heading">Amount</Text>
            <Text className="price-card-heading">
              {internationalFormattedValue(data?.amountBeforeTax, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          <Divider dashed={true} />

          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Tax 1</Text>
            <Text className="price-card-text">{`${data?.taxName1 || 'NA'} (${data?.taxPercentage1 || '0'})`}</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.taxTotal1, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Tax 2</Text>
            <Text className="price-card-text">{`${data?.taxName2 || 'NA'} (${data?.taxPercentage2 || '0'})`}</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.taxTotal2, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">TDS</Text>
            <Text className="price-card-text">{`${data?.tdsName || 'NA'} (${data?.tdsPercentage || '0'})`}</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          <Divider dashed={true} />

          <Flex justify="space-between" align='center'>
            <Text className="price-card-heading">Total Amount</Text>
            <Text className="price-card-heading">
              {internationalFormattedValue(data?.amountAfterTax, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          {data?.advanceTarnsactionsData?.map((adv) => {
            let link = `/#/advanceDetailView?id=${adv.adjustedCostingId}`;
            if (contractType === ContractModesEnum.SALES || contractType === ContractModesEnum.MARKETING_FEES) {
              link = `/#/advance-receivables-detailed-view?id=${adv.adjustedCostingId}`
            }
            if (adv.advanceType === AdvanceEnumType.Against_Performa_Invoice) {
              link = `/#/performa-pc-detail-view?costing_id=${adv.adjustedCostingId}`
            }
            return (
              <Flex justify="space-between" align='center' key={adv.id}>
                <Text className="price-card-text">Deduction (<a href={link} className="link" style={{ color: '#0083C9' }} target='_blank'>{adv.adjustedCostingNo}</a>)</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
            )
          })}

          <Flex justify="space-between" align='center'>
            <Text className="price-card-text"><b>Total Advance Adjustment</b></Text>
            <Text className="price-card-text">
              <b>{internationalFormattedValue(data?.advanceAdjustment, 3)}  {data?.quoteCurrency}</b>
            </Text>
          </Flex>

          <Flex justify="space-between" align='center'>
            <Text className="price-card-green"><b>To be paid to Supplier</b></Text>
            <Text className="price-card-green">
              <b>{internationalFormattedValue(data?.totalAmount, 3)}  {data?.quoteCurrency}</b>
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text"><b>Previous Costing Value</b></Text>
            <Text className="price-card-text">
              <b>{internationalFormattedValue(data?.previousCostingValue, 3)}  {data?.quoteCurrency}</b>
            </Text>
          </Flex>


          {Number(remainingAmount) > 0 &&

            <Flex justify="space-between" align='center'>
              <Text className="price-card-red">Remaining Amount</Text>
              <Text className="price-card-red">
                {internationalFormattedValue(Number(remainingAmount), 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
          }
        </Card>


      </Col>
    </Row>
  </>
}