import {
  BargeCostingsService,
  BgDemurrageIdReqDto,
  BusinessNoPlantCodeReq,
  BusinessNumberService,
  BusinessPartnerCategoryReqDto,
  BusinessPartnerTypeEnum,
  PaymentTypeEnum,
  CompanyCodeReq,
  CompanyTdsService,
  ContractIdRequest,
  ContractModesEnum,
  ContractStatusEnum,
  ContractStatusReqDto,
  ContractTypeAndBnReqDto,
  CurrencyConverterService,
  CurrencyDto,
  CurrencyExchangeRequestDto,
  CurrencyService,
  GetDataDemurrageDto,
  GetTaxesByBpIdDto,
  ItemsCodesEnum,
  ItemsIdRequestDto,
  PortTypeEnum,
  ShippingTypes,
  TaxDropDownDto,
  TaxesAndItemsService,
  TaxesService,
  TdsResModel,
  BNoRequest,
  DemurrageQtyTypeEnum,
  CurrencyTypesEnum,
} from '@exportx/shared-models-and-services';
import { getLocalFormat } from '@exportx/ui-utils';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tabs,
} from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../common';
import DatePicker from '../../../common/data-picker/date-picker';
import { DemurrageList } from './demurrage-entry-list';
import TabPane from 'antd/es/tabs/TabPane';
import DemurrageApproval from './demurrage-approval';
interface IProps {
  demurrageApproval?: boolean;
  demurrageStatus?: ContractStatusEnum;
}

export const Demurrage = (props: IProps) => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [formRef] = Form.useForm();
  const businessNumberService = new BusinessNumberService();
  const bargeCostingService = new BargeCostingsService();
  const [initialValues, setInitialValues] = useState<any>({
    baseCurrency: authContext.defaultPlantCurrency,
  });
  const { Option } = Select;
  const { demurrageStatus } = props;
  const [supplier, setSupplier] = useState<any[]>();
  const companyTdsService = new CompanyTdsService();
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const taxesService = new TaxesService();

  const [account, setAccount] = useState<any>();
  const defaultCurrency = authContext.defaultPlantCurrency;
  const [contractsDropDown, setContractsDropDown] = useState<any>();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
  const currencyService = new CurrencyService();
  const currencyConverterService = new CurrencyConverterService();
  const [exChangeDate, setExChangeDate] = useState<boolean>(false);
  const [costLabelName, setCostLabelName] = useState<string>('');
  let location = useLocation();
  let paramsId = queryString.parse(location.search).costingId;
  const [gridDrawer, setGridDrawer] = useState<boolean>(false);
  const [dummyRefresh, setDummyRefresh] = useState<number>(1);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([]);
  const [incoTerms, setIncoTerms] = useState<any[]>([]);
  const [portRates, setPortRates] = useState<any>({
    loadRatePerDay: 0,
    dischargeRatePerDay: 0,
  });
  const [demurrageApplicable, setDemurrageApplicable] =
    useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [accessId, setAccessId] = useState({});

  useEffect(() => {
    setAccessId({
      create: 734,
      view: 735,
      update: 736,
      delete: 737,
      approve: 738,
      reject: 739,
      release: 740,
      files: 741,
      demurrageEdit: 728,
      demurrageDelete: 729,
    });
    // if(!isPermissionExist([727])) navigate('/')
  }, []);

  useEffect(() => {
    getBusinessNoDropDown();
    getTdsData();
    getCurrency();
    taxActiveDropDown();
  }, []);

  const taxActiveDropDown = () => {
    taxesService
      .getActiveTaxDropDown()
      .then((res) => {
        if (res.status) {
          setTaxDropDown(res.data);
        } else {
          setTaxDropDown([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setTaxDropDown([]);
      });
  };

  useEffect(() => {
    if (paramsId) {
      bargeCostingService
        .getBgDemurrageById(
          new BgDemurrageIdReqDto(paramsId, authContext.defaultPlant),
        )
        .then(async (res) => {
          if (res.status) {
            if (res.data) {
              await businessNoOnChange(res.data?.businessNo);
              await supplierDropDown(res.data?.businessNo, res.data?.account);
              setAccount(res.data.account);
              setInitialValues((prev) => {
                return {
                  ...prev,
                  ...res.data,
                  exchangeDate: moment(res.data.exchangeDate),
                  demurrageDate: moment(res.data.demurrageDate),
                  supplierName: res.data.bpId,
                  contractNo: res.data.contractId,
                };
              });
              setGridDrawer(false);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      setGridDrawer(false);
    } else {
      setInitialValues(undefined);
      formRef.resetFields();
    }
  }, [paramsId]);

  useEffect(() => {
    formRef.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    accountOnChange(initialValues?.supplierName);
  }, [initialValues?.supplierName]);

  if (initialValues?.demurrageDate) {
    initialValues.demurrageDate = moment(initialValues?.demurrageDate);
  }

  if (initialValues?.exchangeDate) {
    initialValues.exchangeDate = moment(initialValues?.exchangeDate);
  }

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant);
    businessNumberService
      .dropDownForDemurrage(req)
      .then((res) => {
        if (res.status) {
          setBusinessDropDown(res.data);
        }
      })
      .catch((err) => {
        console.log('error: ' + err.message);
      });
  };

  const currencyOnChangeHandler = (value) => {
    if (value) {
      setExChangeDate(value !== authContext.defaultPlantCurrency);
      exChangeDateHandler()
    } else {
      setExChangeDate(false);
    }
  };

  const suffixSelector = (
    <Form.Item name="baseCurrency" noStyle label="Please Select">
      <Select
        onChange={currencyOnChangeHandler}
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toString()
            .toLocaleLowerCase()
            .includes(input.toLocaleLowerCase())
        }
        allowClear
        showSearch
        style={{ width: 80 }}
      >
        {currencies?.map((rec) => {
          return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>;
        })}
      </Select>
    </Form.Item>
  );

  const getCurrencyExchangeRate = (fromCurrency, toCurrency, date, currencyType) => {
    const req = new CurrencyExchangeRequestDto(
      fromCurrency,
      toCurrency,
      moment(date).format('YYYY-MM-DD'),
      currencyType
    );
    if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
      return;
    }
    currencyConverterService
      .getCurrencyExchangeRate(req)
      .then((res) => {
        if (res.status) {
          formRef.setFieldValue('exchangeRate', Number(res.data.exchangeRate))
          setInitialValues((prev) => {
            return {
              ...prev,
              exchangeRate: Number(res.data.exchangeRate),
              displayExchangeRate: res.data.displayExchangeRate,
              currencyType: res.data.currencyType,
            };
          });
        } else {
          delete initialValues?.exchangeRate;
        }
        daysDiff();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const daysDiff = () => {
    setDisableBtn(false);
    const timeCompletedForLoadMV =
      formRef.getFieldValue('timeCompletedForLoadMV') ?? 0;
    const allowedTimeToLoad = formRef.getFieldValue('allowedTimeToLoad') ?? 0;
    const validation =
      Number(timeCompletedForLoadMV) - Number(allowedTimeToLoad);
    const noOfDaysDifference = Number(validation.toFixed(10)) ?? 0;
    if (noOfDaysDifference < 0) {
      setCostLabelName('Dispatch');
    } else if (noOfDaysDifference > 0) {
      setCostLabelName('Demurrage');
    } else {
      setCostLabelName(' ');
    }

    const exchangeRate = formRef.getFieldValue('exchangeRate') ? Number(formRef.getFieldValue('exchangeRate')) : 1;
    const exchAdjustValue = formRef.getFieldValue('exchangeRateAdjustment') ? Number(formRef.getFieldValue('exchangeRateAdjustment')) : 0;
    const exchgRateAftAdjust = exchangeRate + exchAdjustValue;

    setInitialValues((prev) => {
      let multiplier =
        noOfDaysDifference < 0
          ? Number(prev?.dispatchRatePerDay ?? 1)
          : Number(prev?.demurrgeRatePerDay ?? 1);
      const cost =
        Math.abs(Number(noOfDaysDifference)) *
        Number(multiplier) *
        exchgRateAftAdjust;
      const amtAftDiscount = cost - (prev.discount ?? 0); //
      const taxPercent1 = prev.taxPercent1 ?? 0;
      const taxPercent2 = prev.taxPercent2 ?? 0;
      const tdsPercent = prev.tdsPercent ?? 0;
      const tdsTotal = (tdsPercent / 100) * amtAftDiscount;
      const taxTotal1 = (taxPercent1 / 100) * amtAftDiscount;
      const taxTotal2 = (taxPercent2 / 100) * amtAftDiscount;
      const withTax = amtAftDiscount + taxTotal1 + taxTotal2 - tdsTotal;

      let paymentType: string;

      if (prev.shippingType === ShippingTypes.FOB_BARGE) {
        setDisableBtn(true);
      }
      if (prev.account === BusinessPartnerTypeEnum.VENDOR) {
        if (prev.shippingType === ShippingTypes.FAS_ANCHORAGE) {
          if (noOfDaysDifference > 0) {
            // Demurrage
            paymentType = PaymentTypeEnum.RECEIVABLE;
            setDisableBtn(false);
          }
          if (noOfDaysDifference < 0) {
            // Dispatch
            setDisableBtn(true);
          }
        }
        if (prev.shippingType === ShippingTypes.FOB_MV) {
          if (noOfDaysDifference < 0) {
            // Dispatch
            paymentType = PaymentTypeEnum.PAYABLE;
          } else if (noOfDaysDifference > 0) {
            // Demurrage
            paymentType = PaymentTypeEnum.RECEIVABLE;
          }
        }
        if (
          [ShippingTypes.CFR_DP, ShippingTypes.CIF_DP].includes(
            prev.shippingType,
          )
        ) {
          if (noOfDaysDifference < 0) {
            // Dispatch
            paymentType = PaymentTypeEnum.RECEIVABLE;
          } else if (noOfDaysDifference > 0) {
            // Demurrage
            paymentType = PaymentTypeEnum.PAYABLE;
          }
        }
      } else if (prev.account === BusinessPartnerTypeEnum.CUSTOMER) {
        if (prev.shippingType === ShippingTypes.FAS_ANCHORAGE) {
          if (noOfDaysDifference > 0) {
            // Demurrage
            paymentType = PaymentTypeEnum.PAYABLE;
            setDisableBtn(false);
          }
          if (noOfDaysDifference < 0) {
            // Dispatch
            setDisableBtn(true);
          }
        }
        if (prev.shippingType === ShippingTypes.FOB_MV) {
          if (noOfDaysDifference < 0) {
            // Dispatch
            paymentType = PaymentTypeEnum.RECEIVABLE;
          } else if (noOfDaysDifference > 0) {
            // Demurrage
            paymentType = PaymentTypeEnum.PAYABLE;
          }
        }
        if (
          [ShippingTypes.CFR_DP, ShippingTypes.CIF_DP].includes(
            prev.shippingType,
          )
        ) {
          if (prev.portType === PortTypeEnum.DISCHARGE_PORT) {
            if (noOfDaysDifference < 0) {
              // Dispatch
              paymentType = PaymentTypeEnum.PAYABLE;
            } else if (noOfDaysDifference > 0) {
              // Demurrage
              paymentType = PaymentTypeEnum.RECEIVABLE;
            }
          } else {
            if (noOfDaysDifference < 0) {
              // Dispatch
              paymentType = PaymentTypeEnum.RECEIVABLE;
            } else if (noOfDaysDifference > 0) {
              // Demurrage
              paymentType = PaymentTypeEnum.PAYABLE;
            }
          }
        }
      } else {
        if (
          prev.shippingType === ShippingTypes.FAS_ANCHORAGE ||
          prev.shippingType === ShippingTypes.FOB_MV
        ) {
          setDisableBtn(true);
        }

        if (
          [ShippingTypes.CFR_DP, ShippingTypes.CIF_DP].includes(
            prev.shippingType,
          )
        ) {
          if (prev.portType === PortTypeEnum.DISCHARGE_PORT) {
            if (noOfDaysDifference < 0) {
              // Dispatch
              paymentType = PaymentTypeEnum.RECEIVABLE;
            } else if (noOfDaysDifference > 0) {
              // Demurrage
              paymentType = PaymentTypeEnum.PAYABLE;
            }
          } else {
            if (noOfDaysDifference < 0) {
              // Dispatch
              paymentType = PaymentTypeEnum.RECEIVABLE;
            } else if (noOfDaysDifference > 0) {
              // Demurrage
              paymentType = PaymentTypeEnum.PAYABLE;
            }
          }
        }
      }

      return {
        ...prev,
        timeCompletedForLoadMV,
        noofDaysDifference:
          Math.abs(Number(noOfDaysDifference.toFixed(10))) ?? 0,
        cost: cost ?? 0,
        amtAftDiscount: +amtAftDiscount?.toFixed(2),
        tdsTotal,
        taxTotal1,
        taxTotal2,
        totalAmount: withTax ?? 0,
        paymentType,
      };
    });
  };
  const exChangeDateHandler = () => {
    const currencyType = formRef.getFieldValue('currencyType');
    const exchangeDate = formRef.getFieldValue('exchangeDate');

    if (currencyType && exchangeDate) {
      getCurrencyExchangeRate(formRef.getFieldValue('baseCurrency'), authContext.defaultPlantCurrency, exchangeDate, currencyType);
    } else {
      formRef.setFieldValue('exchangeRate', 1);

    }
  };

  const businessNoOnChange = (value) => {
    formRef.setFieldValue('supplierId', undefined);
    formRef.setFieldValue('stowagePlanInMt', undefined);
    formRef.setFieldValue('supplierName', undefined);
    formRef.setFieldValue('contractNo', undefined);

    if (value) {
      supplierDropDown(value, formRef.getFieldValue('account'));
      const contractType =
        formRef.getFieldValue('account') === BusinessPartnerTypeEnum.CUSTOMER
          ? ContractModesEnum.SALES
          : formRef.getFieldValue('account') === BusinessPartnerTypeEnum.VENDOR
            ? ContractModesEnum.PURCHASE
            : ContractModesEnum.SALES;
      const req1 = new ContractTypeAndBnReqDto(
        contractType,
        authContext.defaultPlant,
        value,
      );
      businessNumberService
        .getAllocatedTypesThroughBusinessNo(req1)
        .then((res) => {
          if (res.status) {
            setIncoTerms(res.data);
          } else {
            setIncoTerms([]);
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setIncoTerms([]);
        });

      const filteredValue = businessDropDown?.filter((rec) => {
        return rec.bnId === value;
      });
      const accountType = formRef.getFieldValue('account');
      formRef.setFieldValue(
        'stowagePlanInMt',
        filteredValue[0]?.stowagePlanInMt,
      );
      setInitialValues((prev) => {
        return {
          ...prev,
          account: accountType,
          bnLoadRatePerDay: filteredValue[0]?.loadRatePerDay,
        };
      });
    }
  };

  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = formRef.getFieldValue('demurrageDate');
    companyTdsService
      .getTdsByCompanyCode(req)
      .then((res) => {
        if (res.status) {
          setTdsModel(res.data);
        } else {
          setTdsModel([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setTdsModel([]);
      });
  };

  const submit = () => {
    formRef.validateFields().then((values) => {
      const allowedTimeToLoad = values.allowedTimeToLoad;
      const payload = {
        ...values,
        ...initialValues,
        userName: authContext.user.userName,
        plantCode: authContext.defaultPlant,
        bgDemurrageId: initialValues?.bgDemurrageId,
        versionFlag: initialValues?.versionFlag,
        baseCurrency: formRef.getFieldValue('baseCurrency'),
        quoteCurrency: authContext.defaultPlantCurrency,
        costingType: costLabelName,
        allowedTimeToLoad,
        exchangeDate: formRef.getFieldValue('exchangeDate'),
        costingApplicable: values.costingApplicable ? 0 : 1,
      };
      console.log('Payload =>', payload);
      // return;
      bargeCostingService
        .createBgDemurrage(payload)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            formRef.resetFields();
            setInitialValues(undefined);
            setExChangeDate(false);
            window.location.reload();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
    supplierDropDown(formRef.getFieldValue('businessNo'), value);
    setAccount(value);
    const resetFields = businessDropDown.filter((rec) => {
      return rec.bnId === formRef.getFieldValue('businessNo');
    });
    if (resetFields !== formRef.getFieldValue('businessNo')) {
      setInitialValues(undefined);
      setContractsDropDown(undefined);
      formRef.setFieldValue('pricePerMT', undefined);
      formRef.setFieldValue('businessNo', undefined);
      formRef.setFieldValue('supplierId', undefined);
      formRef.setFieldValue('timeCompletedForLoadMV', undefined);
      formRef.setFieldValue('allowedTimeToLoad', undefined);
      formRef.setFieldValue('demurrgeRatePerDay', undefined);
      formRef.setFieldValue('supplyTonnage', undefined);
      formRef.setFieldValue('stowagePlanInMt', undefined);
      formRef.setFieldValue('contractNo', undefined);
      formRef.setFieldValue('supplierName', undefined);
      formRef.setFieldValue('shippingType', undefined);
    }
  };

  const supplierDropDown = (businessNo, type) => {
    const req = new BusinessPartnerCategoryReqDto(
      businessNo,
      type,
      authContext.defaultPlant,
    );
    businessNumberService
      .allocatedBpNames(req)
      .then((res) => {
        if (res.status) {
          setSupplier(res.data);
        } else {
          setSupplier([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const accountOnChange = (value) => {
    getContractsDropDownThroughBpId(value);
  };

  const contractOnChange = (value) => {
    if (account === BusinessPartnerTypeEnum.VESSEL_OWNER) {
      businessNumberService
        .getVesselPortRatesByBn(
          new BNoRequest(
            formRef.getFieldValue('businessNo'),
            authContext.defaultPlant,
          ),
        )
        .then((res) => {
          if (res.status) {
            if (res.data) {
              setPortRates(res.data);
              setInitialValues((prevState) => {
                return {
                  ...prevState,
                  portRate:
                    formRef.getFieldValue('portType') === 'Discharge Port'
                      ? res.data.dischargeRatePerDay
                      : res.data.loadRatePerDay,
                };
              });
            }
          } else {
            delete initialValues?.portRate;
          }
        })
        .catch((err) => console.log(err.message));
    } else {
      const type =
        account === BusinessPartnerTypeEnum.CUSTOMER
          ? ContractModesEnum.SALES
          : account === BusinessPartnerTypeEnum.VENDOR
            ? ContractModesEnum.PURCHASE
            : undefined;
      const req = new ContractIdRequest(value, authContext.defaultPlant, type);
      bargeCostingService
        .getContractsLoadingRate(req)
        .then((res) => {
          if (res.status) {
            if (res.data) {
              setPortRates(res.data);
              setInitialValues((prevState) => {
                return {
                  ...prevState,
                  portRate:
                    formRef.getFieldValue('portType') === 'Discharge Port'
                      ? res.data.dischargeRatePerDay
                      : res.data.loadRatePerDay,
                };
              });
            }
          } else {
            delete initialValues?.portRate;
          }
        })
        .catch((err) => console.log(err.message));
    }
  };

  const incoTermOnChange = (value, option) => {
    setInitialValues((prevState) => {
      return {
        ...prevState,
        shippingType: value,
      };
    });
  };

  const getContractsDropDownThroughBpId = (value) => {
    const req = new BusinessPartnerCategoryReqDto(
      formRef.getFieldValue('businessNo'),
      account ? account : initialValues?.account,
      authContext.defaultPlant,
      value,
    );
    bargeCostingService
      .getContractsDropDownThroughBpId(req)
      .then((res) => {
        if (res.status) {
          setContractsDropDown(res.data);
        } else {
          setContractsDropDown([]);
          formRef.resetFields(['contractNo']);
          delete initialValues?.portRate;
        }
      })
      .catch((err) => console.log(err.message));
  };

  const getCurrency = () => {
    currencyService
      .getAllCurrenciesForDropDown()
      .then((res) => {
        if (res.status) {
          setCurrencies(res.data);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message));
  };

  const onChangePortType = ({ target: { value } }: RadioChangeEvent) => {
    setInitialValues((prevState) => {
      return {
        ...prevState,
        portType: value,
        portRate:
          value === 'Discharge Port'
            ? portRates.dischargeRatePerDay
            : portRates.loadRatePerDay ?? 0,
      };
    });
  };

  const onChangeQtyType = ({ target: { value } }: RadioChangeEvent) => {
    setInitialValues((prev) => {
      let allowedTime = 0;
      if (value === DemurrageQtyTypeEnum.LOADED_QTY) {
        allowedTime =
          Number(formRef.getFieldValue('supplyTonnage') ?? 0) /
          Number(prev.portRate);
      } else {
        allowedTime =
          Number(formRef.getFieldValue('stowagePlanInMt') ?? 0) /
          Number(prev.portRate);
      }
      formRef.setFieldValue('allowedTimeToLoad', allowedTime.toFixed(2));
      return {
        ...prev,
        qtyType: value,
        allowedTimeToLoad: allowedTime.toFixed(2),
      };
    });
  };

  function onTaxChange1(taxId) {
    const vat = taxDropDown.filter((res) => res.taxId === taxId);
    const taxId1 = vat[0]?.taxId ? vat[0]?.taxId : null;
    const taxPercent1 = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
    setInitialValues((prevState) => {
      const taxTotal1 = (taxPercent1 / 100) * (prevState.amtAftDiscount ?? 0);
      const taxTotal2 = prevState.taxTotal2 ?? 0;
      const tdsTotal = prevState.tdsTotal ?? 0;
      const withTax =
        Number(prevState.amtAftDiscount ?? 0) +
        taxTotal1 +
        taxTotal2 -
        tdsTotal;
      return {
        ...prevState,
        taxId1,
        taxPercent1,
        taxTotal1,
        totalAmount: withTax,
      };
    });
  }

  function onTaxChange2(taxId) {
    const vat = taxDropDown.filter((res) => res.taxId === taxId);
    const taxId2 = vat[0]?.taxId ? vat[0]?.taxId : null;
    const taxPercent2 = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
    setInitialValues((prevState) => {
      const taxTotal2 = (taxPercent2 / 100) * (prevState.amtAftDiscount ?? 0);
      const taxTotal1 = prevState.taxTotal1 ?? 0;
      const tdsTotal = prevState.tdsTotal ?? 0;
      const withTax =
        (Number(prevState.amtAftDiscount) ?? 0) +
        taxTotal1 +
        taxTotal2 -
        tdsTotal;
      return {
        ...prevState,
        taxId2,
        taxPercent2,
        taxTotal2,
        totalAmount: withTax,
      };
    });
  }

  const supplyTunnageOnchange = (value) => {
    setInitialValues((prev) => {
      const allowedTime = Number(value ?? 1) / Number(prev?.portRate ?? 1);
      formRef.setFieldValue('allowedTimeToLoad', allowedTime.toFixed(2));
      return {
        ...prev,
        allowedTimeToLoad: allowedTime.toFixed(2),
      };
    });
  };

  const demurrageRateOnchange = (value) => {
    setInitialValues((prev) => {
      return {
        ...prev,
        demurrgeRatePerDay: Number(value),
        dispatchRatePerDay: value * 0.5,
      };
    });
    daysDiff();
  };

  const tdsOnchange = (tdsId) => {
    const obj = tdsModel.filter((res) => res.companyTdsId === tdsId)[0];
    setInitialValues((prevState) => {
      const tdsPercent = obj?.tdsValue ? obj?.tdsValue : 0;
      const tdsTotal = (tdsPercent / 100) * (prevState.amtAftDiscount ?? 0);
      const taxTotal1 = prevState.taxTotal1 ?? 0;
      const taxTotal2 = prevState.taxTotal2 ?? 0;
      const withTax =
        (prevState.amtAftDiscount ?? 0) + taxTotal1 + taxTotal2 - tdsTotal;
      return {
        ...prevState,
        tdsId,
        tdsPercent,
        tdsTotal,
        totalAmount: withTax,
      };
    });
  };

  const discountOnchangeHandler = (dis: number) => {
    setInitialValues((prev) => {
      const afterDiscount = prev?.cost - dis || 0;
      const taxPercent1 = prev.taxPercent1 ?? 0;
      const taxPercent2 = prev.taxPercent2 ?? 0;
      const tdsPercentage = prev.tdsPercentage ?? 0;
      const tdsTotal = (tdsPercentage / 100) * afterDiscount;
      const taxTotal1 = (taxPercent1 / 100) * afterDiscount;
      const taxTotal2 = (taxPercent2 / 100) * afterDiscount;
      const withTax = afterDiscount + taxTotal1 + taxTotal2 - tdsTotal;
      return {
        ...prev,
        discount: dis,
        amtAftDiscount: +afterDiscount?.toFixed(2),
        tdsTotal,
        taxTotal1,
        taxTotal2,
        totalAmount: withTax,
      };
    });
  };

  const onChangeExchangeRateAdjust = () => {
    daysDiff();
  }

  return (
    <>
      <Card className="card-radius">
        <Card
          title={<span style={{ color: 'white' }}>MV Demurrage</span>}
          style={{ textAlign: 'left' }}
          headStyle={{ backgroundColor: '#2196f3', border: 0 }}
        // extra={
        //   <Button
        //     onClick={() => {
        //       setGridDrawer(true);
        //       setDummyRefresh(dummyRefresh + 1);
        //     }}
        //     className="panel_button"
        //   >
        //     View
        //   </Button>
        // }
        >
          {isPermissionExist([726]) && (
            <Row>
              <Col span={11}>
                <Form
                  key={dummyRefresh}
                  form={formRef}
                  initialValues={initialValues}
                  autoComplete={'off'}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14 }}
                  style={{ maxWidth: 600 }}
                >
                  <Card
                    title={
                      <Row className="mt-2">
                        <Col span={8}>
                          <Form.Item
                            name={'demurrageDate'}
                            label="Date"
                            initialValue={moment()}
                          >
                            <DatePicker defaultValue={moment()} />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item name="account">
                            <Radio.Group onChange={radioOnChange}>
                              <Radio
                                value={`${BusinessPartnerTypeEnum.VENDOR}`}
                              >
                                Against Seller
                              </Radio>
                              <Radio
                                value={`${BusinessPartnerTypeEnum.CUSTOMER}`}
                              >
                                Against Buyer
                              </Radio>
                              <Radio
                                value={`${BusinessPartnerTypeEnum.VESSEL_OWNER}`}
                              >
                                Vessel Owner
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    }
                  >
                    <Form.Item
                      label={`MV:`}
                      name="businessNo"
                      rules={[
                        {
                          required: true,
                          message: 'Business Number is Required',
                        },
                      ]}
                    >
                      <Select
                        disabled={
                          formRef.getFieldValue('account') ? false : true
                        }
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toString()
                            .toLocaleLowerCase()
                            .includes(input.toLocaleLowerCase())
                        }
                        allowClear
                        placeholder="Select Business No"
                        onChange={(value, text) =>
                          businessNoOnChange(value)
                        }
                        showSearch
                      >
                        {businessDropDown.map((item) => {
                          return (
                            <Option value={item.bnId}>
                              {item.motherVesselName +
                                ' ' +
                                ' ' +
                                `(${item.businessNo})`}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={'stowagePlanInMt'}
                      label={'Stowage plan'}
                    >
                      <Input
                        placeholder="MV Capacity"
                        disabled={true}
                        type={'number'}
                      ></Input>
                    </Form.Item>

                    {account === BusinessPartnerTypeEnum.VESSEL_OWNER &&
                      portRates.agreementId && (
                        <Form.Item label={<b>Vessel Agreement</b>}>
                          <a
                            href={`#/vesselAgreement-detailed-view?va_Id=${portRates.agreementId}`}
                            target="_blank"
                          >
                            {portRates?.agreementNo}{' '}
                          </a>
                        </Form.Item>
                      )}
                    <Form.Item
                      name={'shippingType'}
                      label={`Inco Term :`}
                      rules={[
                        {
                          required: true,
                          message: 'Inco term is Required',
                        },
                      ]}
                    >
                      <Select
                        onChange={(value, option) =>
                          incoTermOnChange(value, option)
                        }
                        placeholder={'Select Inco Term'}
                      >
                        {incoTerms?.map((rec) => {
                          return (
                            <Option value={rec.type} key={rec.typeId}>
                              {rec.type}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={`${account === BusinessPartnerTypeEnum.VENDOR
                        ? 'Supplier'
                        : account === BusinessPartnerTypeEnum.CUSTOMER
                          ? 'Buyer'
                          : 'Vessel Owner'
                        }`}
                      name="supplierName"
                      rules={[
                        {
                          required: demurrageStatus ? false : true,
                          message: `${account === BusinessPartnerTypeEnum.VENDOR
                            ? 'Supplier'
                            : account ===
                              BusinessPartnerTypeEnum.CUSTOMER
                              ? 'Buyer'
                              : 'Vessel Owner'
                            } is Required`,
                        },
                      ]}
                    >
                      <Select
                        disabled={
                          formRef.getFieldValue('businessNo')
                            ? false
                            : true
                        }
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toString()
                            .toLocaleLowerCase()
                            .includes(input.toLocaleLowerCase())
                        }
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select Account"
                        onChange={accountOnChange}
                      >
                        {supplier?.map((item) => {
                          return (
                            <Option value={item.bpId}>
                              {item.bpName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={'contractNo'}
                      label={`${account === BusinessPartnerTypeEnum.VENDOR
                        ? 'Purchase'
                        : account === BusinessPartnerTypeEnum.CUSTOMER
                          ? 'Sale'
                          : ''
                        } Contract No :`}
                    >
                      <Select
                        onChange={contractOnChange}
                        placeholder={'Select Contract No'}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toString()
                            .toLocaleLowerCase()
                            .includes(input.toLocaleLowerCase())
                        }
                        allowClear
                        showSearch
                      >
                        {contractsDropDown?.map((rec) => {
                          return (
                            <Option value={rec.id}>
                              {rec.contractNo}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={'costingApplicable'}
                      label={<b>LTC Applicable</b>}
                    >
                      <Checkbox
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setDemurrageApplicable(isChecked);
                          formRef.setFieldValue('costingApplicable', isChecked ? true : false);
                        }}
                      >
                        Not Applicable
                      </Checkbox>
                    </Form.Item>

                    {!demurrageApplicable && (
                      <>
                        <Form.Item
                          label={' Loaded Qty '}
                          name="supplyTonnage"
                        >
                          <Input
                            type="number"
                            onChange={(value) =>
                              supplyTunnageOnchange(value.target.value)
                            }
                            min={0}
                          />
                        </Form.Item>

                        {([
                          ShippingTypes.CFR_DP,
                          ShippingTypes.CIF_DP,
                        ].includes(
                          formRef.getFieldValue('shippingType'),
                        ) ||
                          [
                            ShippingTypes.CFR_DP,
                            ShippingTypes.CIF_DP,
                          ].includes(initialValues?.shippingType)) && (
                            <Form.Item
                              name={'portType'}
                              label={`Port Type :`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Port Type is Required',
                                },
                              ]}
                            >
                              <Radio.Group onChange={onChangePortType}>
                                <Radio value={PortTypeEnum.LOADING_PORT}>
                                  Loading Port
                                </Radio>
                                <Radio value={PortTypeEnum.DISCHARGE_PORT}>
                                  Discharge Port
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                          )}

                        <Form.Item
                          name="portRate"
                          label={
                            <b>{`${formRef.getFieldValue(['portType']) ===
                              'Discharge Port'
                              ? 'Discharge Rate'
                              : 'Load Rate'
                              }`}</b>
                          }
                        >
                          <Input type="number" disabled />
                        </Form.Item>

                        <Form.Item
                          name={'qtyType'}
                          label={`Stowage or Loaded Qty`}
                          rules={[
                            {
                              required: true,
                              message:
                                'Stowage or Loaded Qty is Required',
                            },
                          ]}
                        >
                          <Radio.Group onChange={onChangeQtyType}>
                            <Radio
                              value={DemurrageQtyTypeEnum.STOWAGE_PLAN}
                            >
                              Stowage Plan
                            </Radio>
                            <Radio
                              value={DemurrageQtyTypeEnum.LOADED_QTY}
                            >
                              Loaded Qty
                            </Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          label={'Demurrage Rate'}
                          name="demurrgeRatePerDay"
                        >
                          <Input
                            addonAfter={suffixSelector}
                            type="number"
                            placeholder={'0.0000'}
                            onChange={(value) =>
                              demurrageRateOnchange(value.target.value)
                            }
                          />
                        </Form.Item>

                        <Form.Item label={<b>Dispatch Rate</b>}>
                          {' '}
                          <span>
                            <b>
                              {initialValues?.dispatchRatePerDay ?? 0}
                            </b>
                          </span>{' '}
                        </Form.Item>

                        <Form.Item
                          label={'Laytime Allowed'}
                          name="allowedTimeToLoad"
                        >
                          <Input
                            type="number"
                            onChange={(evt) => {
                              setInitialValues((prev) => ({
                                ...prev,
                                allowedTimeToLoad: evt.target.value,
                              }));
                              daysDiff();
                            }}
                            placeholder={'0.00'}
                            min={0}
                          />
                        </Form.Item>
                        <Form.Item
                          label={<b>Laytime Used</b>}
                          name="timeCompletedForLoadMV"
                        >
                          <Input
                            type="number"
                            onChange={(evt) => {
                              setInitialValues((prev) => ({
                                ...prev,
                                timeCompletedForLoadMV: evt.target.value,
                              }));
                              daysDiff();
                            }}
                            placeholder={'0.00'}
                            min={0}
                          />
                        </Form.Item>

                        <Form.Item
                          label={<b>{`${costLabelName} Days`}</b>}
                        >
                          <span>
                            <b>
                              {initialValues?.noofDaysDifference ?? 0}
                            </b>
                          </span>
                        </Form.Item>



                        {disableBtn && (
                          <>
                            <b
                              style={{
                                color: 'red',
                                marginLeft: '20%',
                                marginBottom: 10,
                              }}
                            >
                              Demurrage/Dispatch not applicable for given
                              shipment type
                            </b>
                            <br />
                            <br />
                          </>
                        )}

                        {exChangeDate ? (
                          <>
                            <Form.Item
                              label="Currency Type"
                              name="currencyType"
                            >
                              <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear
                                showSearch
                                defaultValue={!formRef.getFieldValue('currencyType') && formRef.setFieldValue('currencyType', CurrencyTypesEnum.JISDOR)}
                                placeholder='Select Currency'
                                onChange={exChangeDateHandler}

                              >
                                {Object.values(CurrencyTypesEnum).map((item, index) => {
                                  return <Option value={item} key={index}>{item}</Option>
                                })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name={'exchangeDate'}
                              label={'Exchange Date'}
                            >
                              <DatePicker
                                onChange={exChangeDateHandler}
                              />
                            </Form.Item>

                            <Form.Item label={'Exchange Rate'} name='exchangeRate'>
                              <InputNumber
                                disabled
                              >
                              </InputNumber>
                            </Form.Item>
                            <Form.Item label={"Exchange Rate Adjustment"} name={'exchangeRateAdjustment'}>
                              <InputNumber
                                onChange={onChangeExchangeRateAdjust}
                              >
                              </InputNumber>
                            </Form.Item>
                          </>
                        ) : (
                          <></>
                        )}

                        <Form.Item label={`${costLabelName} `}>
                          <span>
                            <b>
                              {getLocalFormat(
                                initialValues?.cost,
                                defaultCurrency,
                              )}
                            </b>
                          </span>
                        </Form.Item>

                        <Form.Item
                          name={'discount'}
                          label={<b>Discount</b>}
                        >
                          <Input
                            addonAfter={defaultCurrency}
                            type="number"
                            placeholder="Enter Discount"
                            onChange={(e) => {
                              discountOnchangeHandler(
                                Number(e.target.value),
                              );
                            }}
                          ></Input>
                        </Form.Item>
                        <Form.Item
                          label={<b>Notes</b>}
                          name="notes"
                          rules={[
                            { required: false, message: 'Please input!' },
                          ]}
                        >
                          <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                          name={'amtAftDiscount'}
                          label={<b>Amount After Discount</b>}
                        >
                          <Input
                            addonAfter={defaultCurrency}
                            type="number"
                            disabled
                          ></Input>
                        </Form.Item>

                        <Form.Item name={'taxId1'} label={<b>TAX 1</b>}>
                          <Select
                            onChange={(v) => onTaxChange1(v)}
                            placeholder="Select tax"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toString()
                                .toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                          >
                            <Option value={undefined}>
                              Not Applicable
                            </Option>
                            {taxDropDown?.map((rec) => {
                              return (
                                <Option
                                  value={rec.taxId}
                                >{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item label={' '} name="taxTotal1">
                          <b>
                            {getLocalFormat(
                              initialValues?.taxTotal1,
                              defaultCurrency,
                            )}
                          </b>
                        </Form.Item>
                        <Form.Item name={'taxId2'} label={<b>TAX 2</b>}>
                          <Select
                            onChange={(v) => onTaxChange2(v)}
                            placeholder="Select tax"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toString()
                                .toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                          >
                            <Option value={undefined}>
                              Not Applicable
                            </Option>
                            {taxDropDown?.map((rec) => {
                              return (
                                <Option
                                  value={rec.taxId}
                                >{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item label={' '} name="taxTotal2">
                          <b>
                            {getLocalFormat(
                              initialValues?.taxTotal2,
                              defaultCurrency,
                            )}
                          </b>
                        </Form.Item>
                        <Form.Item name={'tdsId'} label={<b>TDS</b>}>
                          <Select
                            onChange={(v) => tdsOnchange(v)}
                            placeholder="Select TDS"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toString()
                                .toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                          >
                            <Option value={undefined}>
                              Not Applicable
                            </Option>
                            {tdsModel?.map((rec) => {
                              return (
                                <Option value={rec.companyTdsId}>
                                  {rec.tdsName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item label={' '} name="tdsTotal">
                          <b>
                            {getLocalFormat(
                              initialValues?.tdsTotal,
                              defaultCurrency,
                            )}
                          </b>
                        </Form.Item>
                        <Form.Item label={<b>Total Amount</b>}>
                          <span>
                            <b>
                              {getLocalFormat(
                                initialValues?.totalAmount,
                                defaultCurrency,
                              )}
                            </b>
                          </span>
                        </Form.Item>
                      </>
                    )}

                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      disabled={disableBtn}
                      onClick={submit}
                    >
                      Submit
                    </Button>
                  </Card>
                </Form>
              </Col>
            </Row>
          )}
          <Drawer
            open={gridDrawer}
            onClose={() => setGridDrawer(false)}
            key={dummyRefresh}
            width={'50%'}
          >
            <DemurrageList accessId={accessId} />
          </Drawer>
        </Card>
      </Card>
    </>
  );
};
export default Demurrage;
