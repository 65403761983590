import { CoalIndexedPriceService } from '@exportx/shared-models-and-services';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { AlertMessages } from '../../../../../common';
import { Select, Table, Col, Flex, Row, DatePicker, Card } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import dayjs from 'dayjs';

interface HBAData {
  fromDate: string;
  toDate: string;
  commodity: string;
  price: number;
}

export const HBAIndexes = () => {
  // Create service instance once using useMemo to prevent it from being recreated on each render
  const service = useMemo(() => new CoalIndexedPriceService(), []);
  const [hbaData, setHbaData] = useState<HBAData[]>([]);
  const [selectedHbaType, setSelectedHbaType] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);

  const hbaTypes = [
    {
      actualText: 'Batubara (USD/ton)',
      displayText: 'HBA',
    },
    {
      actualText: 'Batubara (hba 1) (USD/ton)',
      displayText: 'HBA I',
    },
    {
      actualText: 'Batubara (hba 2) (USD/ton)',
      displayText: 'HBA II',
    },
    {
      actualText: 'Batubara (hba 3) (USD/ton)',
      displayText: 'HBA III',
    },
  ];

  const getHbaType = (text: string) => {
    const type = hbaTypes.find((t) => t.actualText === text);
    return type ? type.displayText : 'Others';
  };

  const columns: ColumnsType<HBAData> = [
    {
      title: 'From Date',
      dataIndex: 'fromDate',
      render: (text: string) => {
        return <>{moment(text).format('DD-MMM-YYYY')}</>;
      },
    },
    {
      title: 'To Date',
      dataIndex: 'toDate',
      render: (text: string) => {
        return <>{moment(text).format('DD-MMM-YYYY')}</>;
      },
    },
    {
      title: 'HBA Type',
      dataIndex: 'commodity',
      render: (text: string) => {
        return <>{getHbaType(text)}</>;
      },
    },
    {
      title: 'PMT in USD',
      dataIndex: 'price',
      align: 'right' as const,
      render: (text: number) => {
        return <>${text}</>;
      },
    },
  ];

  const getIndexesData = useCallback(() => {
    const req = {
      hbaType: selectedHbaType,
      year: selectedYear,
    };
    service
      .getHBAIndexes(req)
      .then((res) => {
        if (res.status) {
          setHbaData(res.data);
        } else {
          AlertMessages.getErrorMessage('Failed to fetch HBA indexes');
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  }, [selectedHbaType, selectedYear, service]);

  useEffect(() => {
    getIndexesData();
  }, [getIndexesData]);

  return (
    <Card>
      <Row gutter={[10, 10]} style={{ marginBottom: 16 }} justify="start">
        <Col xs={24} md={24} lg={20}>
          <Flex wrap>
            <Col xs={24} md={8} lg={5}>
              <Select
                placeholder="Filter By HBA Type"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedHbaType(value)}
                value={selectedHbaType || null}
                allowClear
              >
                {hbaTypes.map((type) => (
                  <Select.Option key={type.actualText} value={type.actualText}>
                    {type.displayText}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} md={8} lg={5}>
              <DatePicker
                onChange={(value) =>
                  setSelectedYear(value?.year().toString() || null)
                }
                picker="year"
                defaultValue={dayjs()}
                disabledDate={(current) => current.year() < 2024}
                allowClear={false}
              />
            </Col>
          </Flex>
        </Col>
      </Row>
      <Table
        className="contracts-list"
        columns={columns}
        dataSource={hbaData}
        pagination={false}
      />
    </Card>
  );
};
