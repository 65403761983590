import React, { useCallback, useEffect, useState } from 'react';
import { DownCircleOutlined, MessageFilled, RightCircleOutlined } from '@ant-design/icons';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
// import { Button, Card, Col, Flex, Form, Input, Row, Space, Table, Timeline, Typography } from 'antd';
import { Button, Card, Col, Drawer, Flex, Form, Input, Row, Table, Space, Timeline, Tooltip, Typography, Divider } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { AccountPayableCostings, InvoiceDetails } from "../costing-detail-view";
import { BGCostingParentReqDto, CostingWorkLogsEnum, ContractStatusEnum, PaymentNoticeGetDataDto, PaymentNoticeService, ReferenceFeatures, CmApprovalsReqDto, BargeCostingsService, PerformaCoalCostingService, CommercialCoalCostingService, BPFCostingRequestIdDto, ContractModesEnum, ExpenseEntryApprovalDto, ExpenseEntryService, AdvancePreview, VendorAdvService, AdvanceReceiversService, PaymentTypeEnum, CostingsGroupEnum } from "@exportx/shared-models-and-services";
import { AlertMessages, isPermissionExist, useAuthState } from "../../../common";
import { faCircleXmark, faFlag, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from 'lodash/debounce';
import { CostingFilters } from '../costing-detail-view/costing-filters';
import moment from 'moment';
import CommentsPage from '../../../common/comments/comments-page';
import { useNavigate } from 'react-router-dom';

interface IPropsAuditApprovals {
  type: PaymentTypeEnum;
}

export const AuditApprovals = (props: IPropsAuditApprovals) => {
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [searchedText, setSearchedText] = useState('');
  const [paymentNoticeData, setPaymentNoticeData] = useState<
    PaymentNoticeGetDataDto[]
  >([]);
  const { authContext } = useAuthState();
  const [formRef] = Form.useForm();
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [costingId, setCostingId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');

  const paymentNoticeService = new PaymentNoticeService();
  const service = new BargeCostingsService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();
  const expenseEntryService = new ExpenseEntryService();
  const advService = new VendorAdvService();
  const advReceivableService = new AdvanceReceiversService();
  const bargeCostingService = new BargeCostingsService();

  // const [type] = useState<PaymentTypeEnum>(
  //   PaymentTypeEnum.PAYABLE,
  // );
  const { type } = props;
  const [costingsGroup, setCostingsGroup] = useState<CostingsGroupEnum>(type === PaymentTypeEnum.PAYABLE ? CostingsGroupEnum.PROCUREMENT : CostingsGroupEnum.SALES);

  const [serachInput, setSerachInput] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [businessNo, setBusinessNo] = useState(null);
  const [costingType, setCostingType] = useState<CostingWorkLogsEnum>(null);
  const [bargeId, setBarge] = useState<string>(null);
  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    getParentData();
  }, [
    searchedText,
    type,
    currentPage,
    pageSize,
    businessNo,
    bargeId,
    costingType,
    dummyRefresh,
    costingsGroup
  ]);

  useEffect(() => {
      if (costingsGroup === CostingsGroupEnum.PROCUREMENT) {
        if (!isPermissionExist([276, 297, 360, 380]))  navigate('/');
      } else if (costingsGroup === CostingsGroupEnum.OPERATIONS) {
        if (!isPermissionExist([401, 422, 443, 464, 485, 506, 527, 548, 569, 590, 611, 631, 651, 671, 691])) navigate('/');
      } else if (costingsGroup === CostingsGroupEnum.COMMERCIAL) {
        if (!isPermissionExist([422, 443, 464, 485, 506, 527, 548, 569, 590, 611, 631, 651, 671, 691, 711, 731])) navigate('/');
      } else if (costingsGroup === CostingsGroupEnum.OTHERS) {
        if (!isPermissionExist([751])) navigate('/');
      } else {
        if (!isPermissionExist([318, 339])) navigate('/');
      }
    }, [costingsGroup]);
  
  const getParentData = () => {
    const req = new BGCostingParentReqDto();
    req.plantCode = authContext.defaultPlant;
    req.status = ContractStatusEnum.DRAFT;
    req.paymentType = type;
    req.page = currentPage;
    req.pageSize = pageSize;
    req.searchTerm = searchedText;
    req.businessNo = businessNo;
    req.bargeId = bargeId;
    req.costingType = costingType;
    req.costingsGroup = costingsGroup;
    paymentNoticeService
      .getPaymentNoticeAuditApprovals(req)
      .then((res) => {
        if (res.status) {
          setPaymentNoticeData(
            res.data?.paymentNotices ? res.data?.paymentNotices : [],
          );
          setTotal(res.data?.total);
          setExpandedIndex([]);
          setDummyRefresh(false)
        } else {
          setPaymentNoticeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const routes = (key) => {
    let object = {
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: `performa-pc-detail-view`,
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: `performa-sc-detail-view`,
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-pc-detail-view`,
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-sc-detail-view`,
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-fee-detail-view`,
      [CostingWorkLogsEnum.SALES_FREIGHT_COSTING]: `freight-detail-view`,
      [CostingWorkLogsEnum.BARGE_COSTING]: `barge-costing-detail-view`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-fee-detail-view`,
      [CostingWorkLogsEnum.DEMURRAGE]: `demurrage-detail-view`,
      [CostingWorkLogsEnum.DISPATCH]: `demurrage-detail-view`,
      [CostingWorkLogsEnum.DEAD_FREIGHT]: `dead-freight-detail-view`,
      [CostingWorkLogsEnum.ADVANCE_INVOICE]: `advanceDetailView`,
      [CostingWorkLogsEnum.EXPENSE_ENTRY]: 'expense-entry-detail-view',
      [CostingWorkLogsEnum.ADVANCE_RECEIVABLES]:
        'advance-receivables-detailed-view',
    };

    let id = '?costing_id=';

    if (key === ReferenceFeatures.ADVANCE_INVOICE) {
      id = '?id=';
    } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      id = '?id=';
    }

    return object[key] + id;
  };

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setSearchedText(searchValue);
      setCurrentPage(1);
    }, 500),
    [],
  );

  // Handle search input change
  const handleSearch = (value: string) => {
    setSerachInput(value);
    debouncedSearch(value);
  };

  const handleBusinessNoChange = (value: string) => {
    setBusinessNo(value);
    setCurrentPage(1);
  };

  const handleCostingTypeChange = (value: CostingWorkLogsEnum) => {
    setCostingType(value);
    setCurrentPage(1);
  };

  const bargeChangeHandler = (value: string) => {
    setBarge(value);
    setCurrentPage(1);
  };

  const rejectedHandler = (costingType, costingId, status: ContractStatusEnum) => {
    let contractType;

    if (
      costingType == CostingWorkLogsEnum.PERFORMA_PC_COSTING ||
      costingType == CostingWorkLogsEnum.COMMERCIAL_PC_COSTING
    ) {
      contractType = ContractModesEnum.PURCHASE;
    }
    if (
      costingType == CostingWorkLogsEnum.PERFORMA_SC_COSTING ||
      costingType == CostingWorkLogsEnum.COMMERCIAL_SC_COSTING
    ) {
      contractType = ContractModesEnum.SALES;
    }
    if (costingType == CostingWorkLogsEnum.PROCUREMENT_FEES) {
      contractType = CostingWorkLogsEnum.PROCUREMENT_FEES;
    }
    if (costingType == CostingWorkLogsEnum.MARKETING_FEES) {
      contractType = CostingWorkLogsEnum.MARKETING_FEES;
    }
    if (costingType == CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
      contractType = ContractModesEnum.FREIGHT_COSTING;
    }



    if (
      costingType === CostingWorkLogsEnum.BARGE_COSTING ||
      costingType === ReferenceFeatures.BARGE_COSTINGS
    ) {
      bpfStatusRejected(costingId, status);
    } else if (
      costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ||
      costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING ||
      costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING
    ) {
      performaApprovalOperations(costingId, costingType, contractType, status);
    } else if (
      costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING ||
      costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING ||
      costingType === CostingWorkLogsEnum.PROCUREMENT_FEES ||
      costingType === CostingWorkLogsEnum.MARKETING_FEES
    ) {
      commercialApprovalOperations(costingId, costingType, contractType, status);
    } else if (costingType === CostingWorkLogsEnum.EXPENSE_ENTRY) {
      approveExpenses(costingId, status);
    } else if (costingType === CostingWorkLogsEnum.ADVANCE_INVOICE || costingType === CostingWorkLogsEnum.ADVANCE_RECEIVABLES) {
      advanceApprovalOperations(costingId, status);

    } else if (costingType === CostingWorkLogsEnum.DEMURRAGE || costingType === CostingWorkLogsEnum.DISPATCH) {
      approveDemurrage(costingId, status);
    } else if (costingType === CostingWorkLogsEnum.DEAD_FREIGHT) {
      deadFreightApproval(costingId, status)
    }
  };

  const advanceReceivableApproveOrReject = (
    costingId: string,
    status: string,
  ) => {
    const req = new AdvancePreview(
      costingId,
      authContext.defaultPlant,
      authContext.user.userName,
      status,
    );

    advReceivableService
      .advanceReceivableApproveOrReject(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(true)
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const advanceApprovalOperations = (costingId: string, status: string) => {
    formRef.validateFields().then((values) => {
      const req = new AdvancePreview(
        costingId,
        authContext.defaultPlant,
        authContext.user.userName,
        status,
        undefined,
        values.remarks,
      );
      advService
        .advanceApprovalOperations(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true)
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
    });

  };

  const bpfStatusRejected = (costingId, status) => {
    formRef.validateFields().then((values) => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
        costingId,
        values['remarks'],
        status,
        authContext.user.userName,
        authContext.defaultPlant,
      );
      bargeCostingService
        .bpfStatusRejected(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true)
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const deadFreightApproval = (costingId, status) => {
    formRef.validateFields().then((values) => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
        costingId,
        values['remarks'],
        status,
        authContext.user.userName,
        authContext.defaultPlant,
      );
      bargeCostingService
        .deadFreightApproval2(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true)
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const performaApprovalOperations = (costingId, costingType, contractType, status) => {
    formRef.validateFields().then((values) => {
      const req = new CmApprovalsReqDto(
        costingId,
        authContext.defaultPlant,
        authContext.user.userName,
        status,
        costingType,
        contractType,
        values.remarks,
      );
      perFormaService
        .performaApprovalOperations(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true);
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  const commercialApprovalOperations = (costingId, costingType, contractType, status) => {
    formRef.validateFields().then((values) => {
      const req = new CmApprovalsReqDto(
        costingId,
        authContext.defaultPlant,
        authContext.user.userName,
        status,
        costingType,
        contractType,
        values.remarks,
      );
      commercialService
        .commercialApprovalOperations(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true);
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };
  const approveExpenses = (expenseId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then((values) => {
      const req = new ExpenseEntryApprovalDto();
      req.expenseEntryId = expenseId;
      req.plantCode = authContext.defaultPlant;
      req.status = status;
      req.approvalLevel = ContractStatusEnum.AUDIT_APPROVAL;
      req.userName = authContext.user.userName;
      req.remarks = values['remarks'];
      expenseEntryService
        .approveExpense(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true);
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log('err : ', err);
        });
    });
  };

  const approveDemurrage = (costingId: string, status: ContractStatusEnum) => {
    formRef
      .validateFields()
      .then((values) => {
        const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
          costingId,
          values['remarks'],
          status,
          authContext.user.userName,
          authContext.defaultPlant,
        );
        bargeCostingService
          .demurrageApproval2(req)
          .then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              formRef.resetFields();
              setDummyRefresh(true);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const commentsData = (title) => {
    setCommentsVisible(true);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData('All Comments');
    setCostingId(record.costingId);
    setFeaturesRefName(record.expenseType === 'Demurrage' || record.expenseType === 'Dispatch' ? ReferenceFeatures.MV_DEMURRAGE : record.expenseType);
  }

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Expense Reference',
      dataIndex: 'costingNo',
      render: (value, record) => {
        return (
          <>
            <a
              href={`/#/${routes(record.expenseType) + record.costingId}`}
              className="link"
              target='_blank'
            >
              {value}
            </a>
            &nbsp;&nbsp;&nbsp;
            { record.priority === 'HIGH' && <FontAwesomeIcon icon={faFlag}  style={{color: 'red'}}/> }
          </>
        );
      },
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
          { record?.expenseLedger ? `${value} (${record?.expenseLedger})` : value}
          </>
        )
      }
    },
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (_, row: PaymentNoticeGetDataDto) => {
        const { associatedBusinessNos = [] } = row;

        return associatedBusinessNos.length > 0 ? (
          associatedBusinessNos.map((bn, index) => {
            const link = `/#/bn-detail-view?bn_Id=${bn.bnId}`;
            return (
              <div key={bn.bnId}>
                <Typography className="link">
                  <a key={bn.bnId} href={link} className="link">
                    {bn.businessNo + ` (${bn.businessNoName})`}
                  </a>
                </Typography>
                {index !== associatedBusinessNos.length - 1 && <Divider />}
              </div>
            );
          })
        ) : (
          <Typography.Text>NA</Typography.Text>
        );
      }
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',
      render: (_, row: PaymentNoticeGetDataDto) => {
        const { associatedBarges = [] } = row;

        return associatedBarges.length > 0 ? (
          associatedBarges.map((bg, index) => {
            const link = `/#/barge-detail-view?barge_id=${bg?.bargeId}`;
            return (
              <div key={bg.bargeId}>
                <Typography className="link">
                  <a href={link} className="link">
                    {SequenceUtils.formatNumberToSpecificLength(bg?.bargeNo) + ` (${bg.bargeNomination})`}
                  </a>
                </Typography>
                {index !== associatedBarges.length - 1 && <Divider />}
              </div>
            );
          })
        ) : (
          <Typography.Text>NA</Typography.Text>
        );
      },
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return <>
          <Typography.Text>{internationalFormattedValue(value, 3)}</Typography.Text>
          <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>

        </>

      }
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const accessIdMap = {
    [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: { costingInvoiceApprove: 283, costingInvoiceReject: 284 },
    [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: { costingInvoiceApprove: 325, costingInvoiceReject: 326 },
    [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: { costingInvoiceApprove: 304, costingInvoiceReject: 305 },
    [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: { costingInvoiceApprove: 346, costingInvoiceReject: 347 },
    [CostingWorkLogsEnum.SALES_FREIGHT_COSTING]: { costingInvoiceApprove: 1, costingInvoiceReject: 1 },
    [CostingWorkLogsEnum.PROCUREMENT_FEES]: { costingInvoiceApprove: 367, costingInvoiceReject: 368 },
    [CostingWorkLogsEnum.MARKETING_FEES]: { costingInvoiceApprove: 387, costingInvoiceReject: 388 },
    [CostingWorkLogsEnum.BARGE_COSTING]: { costingInvoiceApprove: 408, costingInvoiceReject: 409 },
    ['Chemical Spray']: { costingInvoiceApprove: 429, costingInvoiceReject: 430 },
    ['Export Tax']: { costingInvoiceApprove: 450, costingInvoiceReject: 451 },
    ['Documentation Charges']: { costingInvoiceApprove: 471, costingInvoiceReject: 472 },
    ['Laporan Charges']: { costingInvoiceApprove: 492, costingInvoiceReject: 493 },
    ['Sampling & Analysis Charges']: { costingInvoiceApprove: 513, costingInvoiceReject: 514 },
    ['Pre Shipment Inspection']: { costingInvoiceApprove: 534, costingInvoiceReject: 535 },
    ['Other Expenses']: { costingInvoiceApprove: 555, costingInvoiceReject: 556 },
    ['Return Cargo Charges']: { costingInvoiceApprove: 576, costingInvoiceReject: 577 },
    ['Floating Crane Charges']: { costingInvoiceApprove: 597, costingInvoiceReject: 598 },
    ['Stevedore Costing']: { costingInvoiceApprove: 618, costingInvoiceReject: 619 },
    ['Freight Costing']: { costingInvoiceApprove: 638, costingInvoiceReject: 639 },
    ['PNBP Charges']: { costingInvoiceApprove: 658, costingInvoiceReject: 659 },
    ['Other Income']: { costingInvoiceApprove: 678, costingInvoiceReject: 679 },
    ['Insurance']: { costingInvoiceApprove: 698, costingInvoiceReject: 699 },
    [CostingWorkLogsEnum.DEAD_FREIGHT]: { costingInvoiceApprove: 718, costingInvoiceReject: 719 },
    [CostingWorkLogsEnum.DEMURRAGE]: { costingInvoiceApprove: 738, costingInvoiceReject: 739 },
    ['Debit Note']: { costingInvoiceApprove: 758, costingInvoiceReject: 759 },
    ['Credit Note']: { costingInvoiceApprove: 758, costingInvoiceReject: 759 },
  };

  const renderItems = (record: any, index, indent, expanded) => {
    let accessId;

    if (record?.expenseType === CostingWorkLogsEnum.EXPENSE_ENTRY && record?.expenseLedger) {
      accessId = accessIdMap[record.expenseLedger];
    } else {
      accessId = accessIdMap[record?.expenseType];
    }

    const timeLines = [];
    record.workLog?.forEach((rec) => {
      timeLines.push({
        color: 'green',
        children: (
          <>
            <Typography style={{ fontSize: 10 }}> {rec?.approvalLevel ?? rec?.actionType} By <b>{rec?.createdUser}</b>  On <b>{rec?.createdAt ? moment(rec?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
            <pre>{rec.workLog}</pre>
          </>
        )

      });
    });
    return (
      <>
        <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
          <AccountPayableCostings
            costingId={record.costingId}
            costingType={record.expenseType}
            remainingAmount={0}
          >
            <Card
              bordered={false}
              className="card-shadow mb-2"
              styles={{ body: { padding: '0px 10px 15px' } }}
            >
              <Form
                form={formRef}
                // initialValues={initialValues}
                layout="vertical"
                autoComplete="off"
              >
                <Row className="pt-2">
                  <Col span={16}>
                    <Form.Item
                      label="remarks"
                      name="remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Remarks', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Notes" onChange={() => { }} />
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <Row className="pt-2">
                  <Col span={16}>
                    <Timeline
                      items={timeLines}
                    />
                  </Col>
                </Row>
                <Flex align="middle" justify="flex-end">
                  <Space size={10}>

                    <Button
                      size="small"
                      className="new-button"
                      onClick={() => {
                        rejectedHandler(record.expenseType, record.costingId, ContractStatusEnum.FREEZED);
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Need More Info
                    </Button>
                    {/* {!(record?.invoices?.some(rec => rec.releaseStatus === true)) &&

                      <Button
                        size="small"
                        className="reject"
                        onClick={() => {
                          rejectedHandler(record.expenseType, record.costingId, ContractStatusEnum.CANCELLED);
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} />
                        Reject
                      </Button>
                    } */}
                  </Space>
                </Flex>
              </Form>
            </Card>
            <InvoiceDetails
              costingId={record.costingId}
              status={ContractStatusEnum.ACTIVE}
              level={ContractStatusEnum.AUDIT_APPROVAL}
              invoiceStatus={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
              includeFreight={record?.includeFreight}
              workLog={record.workLog}
              setDummyRefresh={setDummyRefresh}
              accessId={accessId}
            />
          </AccountPayableCostings>
        </div>
      </>
    );
  };

  const tabListNoTitle = [
    isPermissionExist([276, 297, 360, 380, 318, 339]) && {
      key: type === PaymentTypeEnum.PAYABLE ? CostingsGroupEnum.PROCUREMENT : CostingsGroupEnum.SALES,
      label: type === PaymentTypeEnum.PAYABLE ? CostingsGroupEnum.PROCUREMENT : CostingsGroupEnum.SALES,
    },
    isPermissionExist([401, 422, 443, 464, 485, 506, 527, 548, 569, 590, 611, 631, 651, 671, 691]) && {
      key: CostingsGroupEnum.OPERATIONS,
      label: CostingsGroupEnum.OPERATIONS,
    },
    isPermissionExist([422, 443, 464, 485, 506, 527, 548, 569, 590, 611, 631, 651, 671, 691, 711, 731]) && {
      key: CostingsGroupEnum.COMMERCIAL,
      label: CostingsGroupEnum.COMMERCIAL,
    },
    isPermissionExist([751]) && {
      key: CostingsGroupEnum.OTHERS,
      label:CostingsGroupEnum.OTHERS,
    },
  ];

  const onTabChange = (key: CostingsGroupEnum) => {
    setCostingsGroup(key);
    setCurrentPage(1);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  return (
    <Card className="card-radius">
      <Card
        className="no-shadow"
        tabList={tabListNoTitle}
        bordered={false}
        activeTabKey={costingsGroup}
        size="small"
        onTabChange={onTabChange}
        tabProps={{
          size: 'small',
          tabBarStyle: { width: '100%' },
        }}
      >
      <CostingFilters
        handleSearch={handleSearch}
        serachInput={serachInput}
        handleBusinessNoChange={handleBusinessNoChange}
        businessNo={businessNo}
        handleCostingTypeChange={handleCostingTypeChange}
        costingType={costingType}
        bargeChangeHandler={bargeChangeHandler}
        bargeId={bargeId}
        paymentType={type}
        activeTab={costingsGroup}
      />

      <Table
        className="contracts-list"
        rowKey={(record) => record.costingId}
        columns={tableColumns}
        dataSource={paymentNoticeData}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right',
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <DownCircleOutlined onClick={(e) => onExpand(record, e)}>
              Collapse
            </DownCircleOutlined>
          ) : (
            <RightCircleOutlined onClick={(e) => onExpand(record, e)}>
              Expand
            </RightCircleOutlined>
          )
        }
        scroll={{ x: 500 }}
        size="small"
        bordered={false}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          // size: 'small',
        }}
        onChange={handleTableChange}
      />
      </Card>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
      {/* </Card> */}
    </Card>
  );
};
