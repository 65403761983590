import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  ContractStatusEnum,
  DebCreIdReq,
  DebitCreditService,
  NoteTypeReq,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Row,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import TabPane from 'antd/es/tabs/TabPane';
import DebitCreditNoteApproval from '../debit-credit-note-approval/debit-credit-note-approval';

export interface DebitCreditRejectedEntriesProps {
  setEditDataToForm?: (values) => void;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
  noteType: string;
}

export default function DebitCreditRejectedEntries(
  props: DebitCreditRejectedEntriesProps,
) {
  const { formatMessage: fm } = useIntl();
  const [data, setData] = useState<any[]>([]);
  const [searchedText, setSearchedText] = useState('');
  // const [noteType, setNoteype] = useState<any>(props.noteType);
  const [form] = Form.useForm();
  const { authContext } = useAuthState();
  const service = new DebitCreditService();
  const location = useLocation();
  const paramCpcId = queryString.parse(location.search)?.reference_id;
  let { reference_id }: any =
    paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };

  const navigate = useNavigate();

  // const options = [
  //     { label: 'Debit Note', value: 'Debit Note' },
  //     { label: 'Credit Note', value: 'Credit Note' },
  // ];

  let path;
  let createPath;
  if (props.noteType === 'Debit Note') {
    path = 'debit-detail-view';
    createPath = '/create-debit-note-entry';
  } else {
    path = 'credit-detail-view';
    createPath = '/create-credit-note-entry';
  }

  useEffect(() => {
    if(!isPermissionExist([747, 750])) navigate('/')
  }, []);

  useEffect(() => {
    editHandler(reference_id);
  }, [reference_id]);

  useEffect(() => {
    const req = new NoteTypeReq(props.noteType, authContext.defaultPlant);
    service
      .getRejectedEntries(req)
      .then((res) => {
        if (res.status) {
          setData(res.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [props.noteType]);

  const getData = () => {
    const req = new NoteTypeReq(
      form.getFieldValue('noteType'),
      authContext.defaultPlant,
    );
    service
      .getRejectedEntries(req)
      .then((res) => {
        if (res.status) {
          setData(res.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const deleteDebitCreditEntry = (value) => {
    const req = new DebCreIdReq(
      value,
      authContext.defaultPlant,
      props.noteType,
    );
    service
      .deleteDebitCreditEntry(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const editHandler = (value: string) => {
    const req = new DebCreIdReq(
      value,
      authContext.defaultPlant,
      props.noteType,
    );
    service
      .getDataForUpdateDebit(req)
      .then((res) => {
        if (res.status) {
          props.setEditDataToForm(res.data);
        } else {
          //setBargeCostingData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // setBargeCostingData([]);
      });
  };

  // const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
  //     setNoteype(value);
  // };

  const columns: any = [
    // {
    //     title: 'Action',
    //     dataIndex: 'action',
    //     width: '20%',
    //     align: 'center',
    //     render: (text, rowData) => (
    //         <span>
    //             {isPermissionExist([748]) && (<Tooltip placement="topRight" title="Edit">
    //                 <EditOutlined type="edit"
    //                     onClick={() => editHandler(rowData.debCreId)}
    //                     style={{ color: '#1890ff', fontSize: '18px' }}
    //                 /></Tooltip>)}

    //             <Divider type="vertical" />
    //             {isPermissionExist([749]) && (<Popconfirm
    //                 onConfirm={e => {
    //                     //activateOrDeactivate(RowData);
    //                     deleteDebitCreditEntry(rowData.debCreId)
    //                 }}
    //                 title={fm({ id: "common.deleteConfirmMsg", defaultMessage: 'Are you sure to Delete Record ?' })}>
    //                 <Tooltip placement="topRight" title="Delete">
    //                     <DeleteOutlined type='delete' onClick={() => { }} style={{ color: 'red', fontSize: '18px' }} />
    //                 </Tooltip>
    //             </Popconfirm>)}

    //         </span>
    //     )
    // },
    {
      title: 'No#',
      dataIndex: 'refrenceNo',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
      render: (value, record) => {
        return (
          <>
            <a
              className="link"
              href={`/#/${path}?costing_id=${record.debCreId}`}
            >
              {record.refrenceNo}
            </a>
          </>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (text) => moment(text).format('DD\u2011MMM\u2011YYYY'),
    },
    {
      title: 'Account',
      dataIndex: 'account',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* <Form form={form} autoComplete="off"> */}
      {/* <Row gutter={24} justify='center'>
                <Col span={12} >
                    <Form.Item name='noteType' label='Note Type:'   >
                        <Radio.Group options={options} onChange={radioOnChange} value={noteType} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button type='primary' onClick={() => { getData() }}>Get Data</Button>
                </Col>
            </Row> */}
      <Card className='card-radius'>
        <Tabs>
          {isPermissionExist([747]) && (
            <TabPane tab="Costing Creation" key="costing-creation">
              <Row justify={'space-between'} className="mb-12 mt-8">
                <Col>
                  <Flex gap={10}>
                    <Input.Search
                      className="input-search"
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearchedText(e.target.value);
                      }}
                      onSearch={(value) => {
                        setSearchedText(value);
                      }}
                    />
                  </Flex>
                </Col>
                <Col>
                  <Button
                    className="new-button"
                    onClick={() => navigate(createPath)}
                  >
                    {`New ${props.noteType}`}
                  </Button>
                </Col>
              </Row>
              <Table
                className="contracts-list"
                scroll={{ x: true, y: 1000 }}
                // size="small"
                // bordered
                rowKey={(row) => row.debCreId}
                columns={columns}
                dataSource={data}
                pagination={{
                  showTotal: (total) => (
                    <span className="page-total-items">
                      Total {total} items
                    </span>
                  ),
                  showSizeChanger: true,
                  pageSizeOptions: ['25', '50', '100'],
                  defaultPageSize: 25,
                  style: {
                    paddingTop: '12px',
                  },
                }}
              />
            </TabPane>
          )}
          {isPermissionExist([750]) && (
            <TabPane tab="Business Approval" key="business-approval">
              <DebitCreditNoteApproval
                noteType={props.noteType}
                type={ContractStatusEnum.BUSINESS_APPROVAL}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>
      {/* </Form> */}
    </>
  );
}
