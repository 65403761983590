import { useState, useEffect } from "react";
import { Row, Input, Select } from "antd";
import { BusinessAllocationDropdownDto, BargeDropDownDto, BusinessNumberService, BargeService, ContractIdRequest, PayableCostingsEnum, PaymentTypeEnum, ReceivableCostingsEnum, CostingWorkLogsEnum, CostingsGroupEnum } from "@exportx/shared-models-and-services";
import { useAuthState } from '../../../common';
import { SequenceUtils } from "@exportx/ui-utils";

const { Option } = Select;

interface filterProps {
    serachInput: string;
    bargeChangeHandler: (value) => void;
    handleSearch: (value) => void;
    handleBusinessNoChange: (value) => void;
    businessNo: string;
    handleCostingTypeChange: (value) => void;
    costingType: string;
    bargeId: string;
    paymentType: PaymentTypeEnum;
    activeTab?: CostingsGroupEnum;

}


export const CostingFilters = (props: filterProps) => {
    const { bargeChangeHandler,
        handleSearch,
        handleBusinessNoChange,
        handleCostingTypeChange,
        serachInput,
        businessNo,
        costingType,
        bargeId,
        paymentType = PaymentTypeEnum.PAYABLE,
        activeTab
    } = props;

    const [businessNumbers, setBusinessNumbers] = useState<BusinessAllocationDropdownDto[]>([]);
    const [bargeData, setBargeData] = useState<BargeDropDownDto[]>([]);

    const businessNumberService = new BusinessNumberService();
    const bargeService = new BargeService();

    const { authContext } = useAuthState();

    useEffect(() => {
        getAllAllocatedBns();
        getAllBargeDropDown();

    }, [])


    const getAllBargeDropDown = () => {
        bargeService.getAllBargeDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setBargeData(res.data);
            }

            else {
                setBargeData([]);
            }
        }).catch(err => {
            setBargeData([]);
        })
    }


    const getAllAllocatedBns = () => {
        businessNumberService.getBusinessAllocationDropDown(new ContractIdRequest('withOutContractId', authContext.defaultPlant)).then(res => {
            if (res.status) {
                setBusinessNumbers(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const filterByActiveTab = (costingType: CostingWorkLogsEnum, activeTab?: CostingsGroupEnum) => {
        if (!activeTab) return true;
    
        const procurementTypes = [
            CostingWorkLogsEnum.PERFORMA_PC_COSTING,
            CostingWorkLogsEnum.PERFORMA_SC_COSTING,
            CostingWorkLogsEnum.COMMERCIAL_PC_COSTING,
            CostingWorkLogsEnum.COMMERCIAL_SC_COSTING,
            CostingWorkLogsEnum.PROCUREMENT_FEES,
            CostingWorkLogsEnum.MARKETING_FEES,
            CostingWorkLogsEnum.ADVANCE_INVOICE,
            CostingWorkLogsEnum.ADVANCE_RECEIVABLES,
        ];
    
        const operationsTypes = [
            CostingWorkLogsEnum.BARGE_COSTING,
            CostingWorkLogsEnum.EXPENSE_ENTRY
        ];
    
        const commercialTypes = [
            CostingWorkLogsEnum.DEAD_FREIGHT,
            CostingWorkLogsEnum.SALES_FREIGHT_COSTING,
            CostingWorkLogsEnum.DEMURRAGE,
            CostingWorkLogsEnum.DISPATCH,
            CostingWorkLogsEnum.EXPENSE_ENTRY
        ];
    
        switch (activeTab) {
            case paymentType === PaymentTypeEnum.PAYABLE ? CostingsGroupEnum.PROCUREMENT : CostingsGroupEnum.SALES:
                return procurementTypes.includes(costingType);
            case CostingsGroupEnum.OPERATIONS:
                return operationsTypes.includes(costingType);
            case CostingsGroupEnum.COMMERCIAL:
                return commercialTypes.includes(costingType);
            case CostingsGroupEnum.OTHERS:
                return (
                    !procurementTypes.includes(costingType) &&
                    !operationsTypes.includes(costingType) &&
                    !commercialTypes.includes(costingType)
                );
            default:
                return true;
        }
    };
    

    return (
        <Row className='card-title-buttons mb-3'>
            <Input.Search
                placeholder="Search"
                allowClear
                value={serachInput}
                onChange={(e) => handleSearch(e.target.value)}
                className='input-search'
            />
            <Select
                placeholder="Filter by Costing Type"
                className='filter-by-status'
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                value={costingType}
                onChange={handleCostingTypeChange}
            >
                <Option value={0}>All</Option>
                {paymentType === PaymentTypeEnum.PAYABLE
                    ? Object.keys(PayableCostingsEnum)
                          .filter(rec => !activeTab || filterByActiveTab(PayableCostingsEnum[rec], activeTab))
                          .map(rec => (
                              <Option key={PayableCostingsEnum[rec]} value={PayableCostingsEnum[rec]}>
                                  {PayableCostingsEnum[rec]}
                              </Option>
                          ))
                    : Object.keys(ReceivableCostingsEnum)
                          .filter(rec => !activeTab || filterByActiveTab(ReceivableCostingsEnum[rec], activeTab))
                          .map(rec => (
                              <Option key={ReceivableCostingsEnum[rec]} value={ReceivableCostingsEnum[rec]}>
                                  {ReceivableCostingsEnum[rec]}
                              </Option>
                          ))}
            </Select>
            <Select
                placeholder="Filter by Business No"
                className='filter-by-status'
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                value={businessNo}
                onChange={handleBusinessNoChange}
            >
                {/* <Option value={null}>Please Select</Option> */}
                {businessNumbers.map(item => {
                    return <Option key={item.businessNoId} value={item.businessNoId}>{item.businessNo}</Option>
                })}
            </Select>
            <Select
                placeholder="Filter by Barge"
                className='filter-by-status'
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                value={bargeId}
                onChange={bargeChangeHandler}
            >
                {bargeData.map(dropData => {
                    return <Option key={dropData.id} value={dropData.id}>{SequenceUtils.formatNumberToSpecificLength(dropData.bargeId.toString()) + " " + `(${dropData.bargeNomination})`}</Option>
                })}
            </Select>
        </Row>
    )
}