import { Card, Tabs } from "antd";
import PoHeaderReport from "./po-header-report";
import { BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, PurchaseContractService } from "@exportx/shared-models-and-services";
import { useEffect, useState } from "react";
import { useAuthState } from "../../../../common";
import ProcurementReport from "./procurement-report";
import POPerformance from "./po-performance";
import POPerformanceBar from "./po-performance-bar";



export const ProcurementDetailReport = () => {
  const { authContext } = useAuthState()
  const [pcContract, setPcContract] = useState<any[]>([]);
  const [supplier, setSupplier] = useState<any[]>([]);

  const businessPartnerService = new BusinessPartnerService();
  const purchaseService = new PurchaseContractService()

  useEffect(() => {
    getAllSupplierDropDown();
    getAllPurchaseContracts();
  }, []);

  const getAllSupplierDropDown = () => {
      const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)
      businessPartnerService.getAllBusinessPartnersForDropDown(req).then(res => {
        if (res.status) {
          setSupplier(res.data)
        }
      }).catch(err => {
        console.log(err.message)
      })
    }
  
    const getAllPurchaseContracts = () => {
      purchaseService.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant })
        .then((res) => {
          if (res.status) {
            let contract = res.data?.map((item) => {
              let id = item.pcId;
              let contractNo = item.pcNo.toString() + ' ' + `(${item.bpName})`;
              return {
                id,
                contractNo,
              };
            });
  
            setPcContract(contract);
          }
        })
        .catch((err) => {
          console.log('error: ' + err.message);
        });
    };
  
  return (<>
      <Card 
        className='card-radius'>
        <Tabs defaultActiveKey="1" items={[
        {
          key: '1',
          label: 'PO Header Report',
          children: [
            <div style={{ marginTop: 6 }}>
              <PoHeaderReport supplier={supplier} pcContract={pcContract}/>
            </div>
          ]
        },
        {
          key: '2',
          label: 'PO Detail Report',
          children: [
            <div style={{ marginTop: 6 }}>
              <ProcurementReport supplier={supplier} pcContract={pcContract}/>
            </div>
          ],
        },
        {
          key: '3',
          label: 'PO Performance (BL Date)',
          children: [
            <div style={{ marginTop: 6 }}>
              <POPerformance supplier={supplier}/>
            </div>
          ],
        },
        {
          key: '4',
          label: 'PO Performance (Completion Date)',
          children: [
            <div style={{ marginTop: 6 }}>
              <POPerformanceBar supplier={supplier}/>
            </div>
          ],
        },
      ]}/>
      </Card>
  </>)
}

export default ProcurementDetailReport;